declare global {
  interface Window {
    dataLayer: any[];
  }
}

function sendToDataLayer(params: Record<string, any> = {}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(params);
}

function sendEventToDataLayer(
  eventName: string,
  params: Record<string, any> = {}
) {
  sendToDataLayer({
    event: eventName,
    ...params
  });
}

type GAItem = {
  item_id: string;
  item_name: string;
  currency: string;
  affiliation?: string;
  coupon?: string;
  discount?: number;
  price?: number;
  quantity?: number;
};

type BookingSuccessEvent = {
  bookingId: string;
  vehicleId: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleCategory: string;
  vehicleType: string;
  vehicleCity: string;
  vehicleCountry: string;
  vehicleHasInsurance: boolean;
  amountPaidOnline: number;
  amountTotal: number;
  currency: string;
  paymentProvider: string;
  ga_items: GAItem[];
};

type VehicleAddedtoFavsEvent = {
  vehicleId: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleCategory: string;
  vehicleType: string;
  vehicleCity: string;
  vehicleCountry: string;
  vehicleHasInsurance: boolean;
  userId: string;
};

type VehicleUploadEvent = {
  userId: string;
  vehicleId: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleCategory: string;
  vehicleType: string;
  vehicleCountry: string;
  vehicleCity: string;
  vehicleHasInsurance: boolean;
  currency: string;
  price: number;
};

export type SignupEvent = {
  userId: string;
  firstName: string;
  lastName: string;
  authProvider: 'email' | 'facebook' | 'phone' | 'google' | 'unknown';
};

type HomeSearch = {
  searchInputValue: string;
  from: string;
  to: string;
};

type StartBooking = {
  from: string;
  to: string;
  userId: string;
  vehicleId: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleCategory: string;
  vehicleType: string;
  vehicleCountry: string;
  vehicleCity: string;
  vehicleHasInsurance: boolean;
};

type CheckoutClick = {
  from: string;
  to: string;
  userId: string;
  vehicleId: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleCategory: string;
  vehicleType: string;
  vehicleCountry: string;
  vehicleCity: string;
  vehicleHasInsurance: boolean;
  price: number;
  currency: string;
};

type UserData = {
  userId: string;
  uFirstName: string;
  uLastName: string;
  uNationality: string;
  uEmail: string;
  uPhoneNumber: string;
  uCountry: string;
  uCity: string;
  /** If available, format is YYYY-MM-DD */
  uDob: string;
  uZipCode: string;
  uHasStripeConnected: boolean;
  uHasPaypalConnected: boolean;
  uIsBusinessProfile: boolean;
  uAvgRating: number;
  /** If user has at least 1 booking */
  uIsRenter: boolean;
  /** If user has at least 1 rental out, OR has at least one vehicle */
  uIsOwner: boolean;
  uHasDriverIdUploaded: boolean;
  uHasDriverIdVerified: boolean;
  uHasPhoneVerified: boolean;
  uHasFacebookVerified: boolean;
  uHasGoogleVerified: boolean;
  uIsSignedUpThroughAffiliate: boolean;
};

/** Interface to send events to GTM data layer */
export const gtmDataLayer = {
  bookingSuccess: (params: BookingSuccessEvent) => {
    sendEventToDataLayer('user_success_booking', params);
  },
  vehicleAddToFavs: (params: VehicleAddedtoFavsEvent) => {
    sendEventToDataLayer('vehicle_add_to_favorites', params);
  },
  vehicleUpload: (params: VehicleUploadEvent) => {
    sendEventToDataLayer('vehicle_upload', params);
  },
  signup: (params: SignupEvent) => {
    sendEventToDataLayer('user_sign_up', params);
  },
  homeSearch: (params: HomeSearch) => {
    sendEventToDataLayer('home_search', params);
  },
  startBooking: (params: StartBooking) => {
    sendEventToDataLayer('user_start_booking', params);
  },
  checkoutClick: (params: CheckoutClick) => {
    sendEventToDataLayer('checkout_click', params);
  },
  setUserData: (params: UserData) => {
    sendToDataLayer(params);
  }
};
