import { useConst, useEventCallback } from '@b2w/shared/react-hooks';
import { CSSProperties, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './Collapse.module.scss';

export type CollapseProps = {
  /**
   * Trigger collapse
   */
  in: boolean;
  /**
   * Animation duration in miliseconds
   *
   * @default 150
   */
  duration?: number;
  /**
   * How much of height must remain visible. Supplied in pixels. Pass an object
   * to specify whether content must have fadeout effect when collapsed.
   *
   * Might be useful for "Show more" text.
   *
   * @default 0 (none)
   */
  visibleHeight?: number | { px: number; fadeoutEffect: boolean };
  children: React.ReactNode;
  key?: any;
};

const animationClassnames = {
  enter: styles.collapseEnter,
  enterActive: styles.collapseEnterActive,
  enterDone: styles.collapseEnterDone,
  exit: styles.collapseExit,
  exitActive: styles.collapseExitActive,
  exitDone: styles.collapseExitDone
};

const normalizeVisibleHeight = (val: CollapseProps['visibleHeight']) => {
  if (typeof val === 'number') {
    return {
      px: val,
      fadeout: false
    };
  }

  if (typeof val === 'object') {
    return {
      px: val.px,
      fadeout: val.fadeoutEffect
    };
  }

  return {
    px: 0,
    fadeout: false
  };
};

/**
 * Provides collapse animation.
 */
export const Collapse = ({
  in: animate,
  duration = 300,
  visibleHeight: visibleHeightProp,
  key,
  children
}: CollapseProps): JSX.Element => {
  const heightRef = useRef(0);
  const isInitiallyExited = useConst(animate === false);
  const visibleHeight = normalizeVisibleHeight(visibleHeightProp);

  const wrapStyles = {
    // css variables are used inside classnames
    '--collapse-duration': duration + 'ms',
    '--collapse-element-height': heightRef.current + 'px',
    '--collapse-visible-height': visibleHeight.px + 'px',
    '--collapse-opacity-collapsed': visibleHeight.px ? 1 : 0
  } as CSSProperties;

  const registerWrapper = useEventCallback((node: HTMLDivElement) => {
    if (node) {
      if (node.offsetHeight !== 0 && node.offsetHeight !== heightRef.current) {
        // for collapse to run smoothly we need to know exact element height
        heightRef.current = node.offsetHeight;
      }

      if (isInitiallyExited) {
        // CSSTransition component does not add exitDone class
        // if initially in={false}, do that manually
        node.classList.add(animationClassnames.exitDone);
      }
    }
  });

  return (
    <CSSTransition
      in={animate}
      timeout={duration}
      classNames={animationClassnames}
      key={key}
    >
      <div style={wrapStyles} ref={registerWrapper}>
        {visibleHeight.fadeout && !animate && (
          <div className={styles.fadeout} />
        )}
        {children}
      </div>
    </CSSTransition>
  );
};
