/**
 * Here are located country-specific driver license notes
 * that people have to consider when making a booking in target country.
 */

enum COUNTRY {
  Vietnam = 'Vietnam',
  Philippines = 'Philippines'
}

const LICENSE_NOTES = {
  [COUNTRY.Vietnam]:
    'Vietnam recognizes the International Driving Permit only from the convention of 1968. However, the traffic police requires an official translation. If you come under a tourist visa, kindly make a notarized translation to Vietnamese so that police can accept your international permit. If you come under a business or longer period visa, you are required to convert your license into a Vietnamese one. In this case you have to obtain local residence permit.',
  [COUNTRY.Philippines]:
    'Foreign driving licenses are valid in most countries including the Philippines for the next 90 days after arrival. After 90 days foreign driving license is required to be converted to the local standard. Remember that if you are planning a long trip!'
};

/**
 * Get information that user must be aware of when making a booking
 * in target country.
 */
export const getDriverLicenseNoteForCountry = (country: string) => {
  const note = LICENSE_NOTES[country as COUNTRY];
  const fallback = LICENSE_NOTES.Philippines;

  return note ?? fallback;
};
