import { UserConnection, UserPresence } from '@b2w/shared/types';
import { asyncLoadDatabase } from '../firebase/loaders';
import { authService } from './auth.service';

class PresenceService {
  private static _instance: PresenceService;
  private currentConnectionId: string;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  getCurrentConnectionId() {
    return this.currentConnectionId;
  }

  async handleUserPresence(
    uid: string,
    onConnectReconnect?: (connectionId: string) => any
  ) {
    const { database, ref, onValue, set, serverTimestamp, onDisconnect, push } =
      await asyncLoadDatabase();

    const chatPresenceRef = ref(
      database,
      `unread_counters/${uid}/chat_presence`
    );
    const supportInboxPresenceRef = ref(
      database,
      `unread_counters/${uid}/support_inbox_presence`
    );

    // For multiple devices (logins), store each connection separately
    const myConnectionsRef = ref(database, `users/${uid}/presence/connections`);
    const lastOnlineRef = ref(database, `users/${uid}/presence/lastOnline_ms`);

    const connectedRef = ref(database, '.info/connected');
    onValue(
      connectedRef,
      (snap) => {
        if (snap.val() === true) {
          // We're connected (or reconnected)
          const currentConnection = this.currentConnectionId
            ? ref(
                database,
                `users/${uid}/presence/connections/${this.currentConnectionId}`
              )
            : push(myConnectionsRef);

          this.currentConnectionId = currentConnection.key;

          const connectionData: UserConnection = {
            platform: 'web',
            connectedAt_ms: serverTimestamp() as any
          };

          // Add this device to my connections list
          set(currentConnection, connectionData);

          onDisconnect(currentConnection).remove();
          onDisconnect(chatPresenceRef).remove();
          onDisconnect(supportInboxPresenceRef).remove();
          onDisconnect(lastOnlineRef).set(serverTimestamp());

          onConnectReconnect && onConnectReconnect(currentConnection.key);
        }
      },
      (err) => {
        console.log('[Connection err]', err.message);
      }
    );
  }

  async $subToUserPresence(
    uid: string,
    subscription: (userPresence: UserPresence | null) => void,
    cancelCallback?: (error: Error) => void
  ) {
    const { database, ref, onValue, off } = await asyncLoadDatabase();

    const presenceStatusRef = ref(database, `users/${uid}/presence`);

    onValue(
      presenceStatusRef,
      (snap) => {
        if (snap.exists()) {
          const data = snap.val() as UserPresence;
          subscription(data);
        } else {
          subscription(null);
        }
      },
      cancelCallback
    );

    return () => off(presenceStatusRef, 'value');
  }

  async handleJoinedToChat(chatId: string, userId: string) {
    const { database, ref, update } = await asyncLoadDatabase();

    const updates = {
      [`unread_counters/${userId}/chat_presence/${chatId}`]: true,
      [`unread_counters/${userId}/chat_unread_messages/${chatId}`]: null
    };

    await update(ref(database), updates);
  }

  async handleLeftChat(chatId: string, userId: string) {
    const [currentUser, { database, ref, set }] = await Promise.all([
      authService.getAuthUser(),
      asyncLoadDatabase()
    ]);

    if (currentUser) {
      await set(
        ref(database, `unread_counters/${userId}/chat_presence/${chatId}`),
        null
      );
    }
  }

  async handleJoinedSupportInbox(inboxId: string, userId: string) {
    const { database, ref, update } = await asyncLoadDatabase();

    const updates = {
      [`unread_counters/${userId}/support_inbox_presence/${inboxId}`]: true,
      [`unread_counters/${userId}/support_inbox_unread_messages/${inboxId}`]:
        null
    };

    await update(ref(database), updates);
  }

  async handleLeftSupportInbox(inboxId: string, userId: string) {
    const [currentUser, { database, ref, set }] = await Promise.all([
      authService.getAuthUser(),
      asyncLoadDatabase()
    ]);

    if (currentUser) {
      await set(
        ref(
          database,
          `unread_counters/${userId}/support_inbox_presence/${inboxId}`
        ),
        null
      );
    }
  }
}

export const presenceService = PresenceService.Instance;
