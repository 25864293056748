/**
 * @desc Async wait for `ms` of miliseconds
 */
export const waitSome = (ms = 1000): Promise<void> => {
  return new Promise((rs: any) => {
    setTimeout(() => {
      rs();
    }, Math.max(0, ms));
  });
};

/**
 * @desc Retry async operation until resolves
 */
export const retryAsyncOperation = <T>(
  runOperation: () => Promise<T>,
  waitMsBetweenRetry = 500,
  numOfRetries = 5
): Promise<T> =>
  new Promise((resolve, reject) => {
    return runOperation()
      .then(resolve)
      .catch((reason) => {
        if (numOfRetries > 0) {
          return waitSome(waitMsBetweenRetry)
            .then(() =>
              retryAsyncOperation(
                runOperation,
                waitMsBetweenRetry,
                numOfRetries - 1
              )
            )
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
