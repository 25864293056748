export class BrowserStorageService {
  private static _instance: BrowserStorageService;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  localStorage(cb: (localStorage: Storage) => void) {
    this.runStorageOperation('localStorage', cb);
  }

  sessionStorage(cb: (localStorage: Storage) => void) {
    this.runStorageOperation('sessionStorage', cb);
  }

  private runStorageOperation(
    storage: 'localStorage' | 'sessionStorage',
    cb: (localStorage: Storage) => void
  ) {
    try {
      if (storage === 'localStorage' && this.canUseLocalStorage()) {
        return cb(window.localStorage);
      }

      if (storage === 'sessionStorage' && this.canUseSessionStorage()) {
        return cb(window.sessionStorage);
      }
    } catch (err) {
      //
    }
  }

  canUseLocalStorage() {
    return this.isStorageAvailable('localStorage');
  }

  canUseSessionStorage() {
    return this.isStorageAvailable('sessionStorage');
  }

  private isStorageAvailable(kind: 'sessionStorage' | 'localStorage') {
    let storage: Storage;

    try {
      storage = window[kind];

      const x = '__storage_test__';

      storage.setItem(x, x);
      storage.getItem(x);
      storage.removeItem(x);

      return true;
    } catch (e) {
      return (
        e &&
        // everything except Firefox
        (e.code === 22 ||
          // Firefox
          e.code === 1014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        // acknowledge QuotaExceededError only if there's something already stored
        storage &&
        storage.length !== 0
      );
    }
  }
}

export const browserStorageService = BrowserStorageService.Instance;
