import { ReportTypeEnum } from '@b2w/shared/types';

export const mapReportTypeToLabel = (type: ReportTypeEnum) => {
  switch (type) {
    case ReportTypeEnum.inappropriateBehavior: {
      return 'Inappropriate behavior';
    }

    case ReportTypeEnum.rentalDataMismatch: {
      return 'Mismatch in real data and data on the website';
    }

    case ReportTypeEnum.scam: {
      return 'Fraudulent business (scam)';
    }

    case ReportTypeEnum.spam: {
      return 'Spam';
    }

    default: {
      return 'Something bad';
    }
  }
};

export const mapPreparationTimeToLabel = (timeHour: number) => {
  if (timeHour === 1) {
    return `${timeHour} hour`;
  }

  return `${timeHour} hours`;
};
