import {
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
  useCallback
} from 'react';
import {
  B2wApiError,
  PrivateProfileDTO,
  RoleEnum,
  VehicleDTO
} from '@b2w/shared/types';
import { useProfile } from './profile.context';
import useSWR from 'swr';
import { vehicleService } from '@/lib/services/vehicle.service';

type ContextData = {
  vehicle: VehicleDTO | null;
  isLoading: boolean;
  error: string | null;
};

const VehicleContext = createContext({} as ContextData);
export const useVehicle = () => useContext(VehicleContext);

const SetVehicleContext = createContext(
  {} as Dispatch<SetStateAction<VehicleDTO>>
);
export const useSetVehicle = () => useContext(SetVehicleContext);

const allowedRoles = [RoleEnum.admin, RoleEnum.vehicleEditor];
async function getVehicleByIdWithPermissionError(
  vehicleId: string,
  profile: PrivateProfileDTO
) {
  const v = await vehicleService.getVehicleById(vehicleId);

  if (
    v.ownerUid === profile.id ||
    profile.roles?.some((r) => allowedRoles.includes(r))
  ) {
    return v;
  }

  throw new Error('You are not allowed to view this page');
}

export const VehicleProvider: React.FC<{
  vehicleId: string;
  children: React.ReactNode;
}> = ({ children, vehicleId }) => {
  const { profile } = useProfile();
  const cacheKey = vehicleId && profile ? ['getVehicleById', vehicleId] : null;

  const {
    data: vehicle,
    error,
    mutate
  } = useSWR<VehicleDTO | null, B2wApiError>(cacheKey, ([_key, vId]) =>
    getVehicleByIdWithPermissionError(vId, profile)
  );

  const setVehicle = useCallback(
    (arg) => {
      return mutate(arg, false);
    },
    [mutate]
  );

  return (
    <VehicleContext.Provider
      value={{
        vehicle: vehicle as any,
        error: error?.message,
        isLoading: !vehicle && !error
      }}
    >
      <SetVehicleContext.Provider value={setVehicle}>
        {children}
      </SetVehicleContext.Provider>
    </VehicleContext.Provider>
  );
};
