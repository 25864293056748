import { CSSProperties } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styles from './Fade.module.scss';
import { CommonFadeProps } from './types';

export interface TransientFadeProps extends CommonFadeProps {
  /**
   * Unique key for current active child element
   */
  animationKey: string | number;
}

const animationClassnames = {
  enter: styles.fadeEnter,
  enterActive: styles.fadeEnterActive,
  exit: styles.fadeExit,
  exitActive: styles.fadeExitActive
};

/**
 * Fade animation when transitioning between elements
 *
 * Useful for animation route/tab navigation
 */
export const TransientFade = ({
  children,
  animationKey,
  duration = 300,
  offsetX = 0,
  offsetY = 0,
  initialScale = 1
}: TransientFadeProps): JSX.Element => {
  const wrapStyles = {
    // css variables are used inside classnames
    '--fade-duration': duration + 'ms',
    '--fade-offsetX': offsetX + 'px',
    '--fade-offsetY': offsetY + 'px',
    '--fade-initial-scale': initialScale
  } as CSSProperties;

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={animationKey}
        timeout={duration}
        classNames={animationClassnames}
      >
        <div style={wrapStyles}>{children}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};
