import { useEffect } from 'react';

/**
 * Proper state management for nested modals.
 * Simplified, but inspired by material-ui's ModalManager class.
 */
class ModalManager {
  modals: string[];

  constructor() {
    this.modals = [];
  }

  add(modalId: string) {
    this.modals = this.modals.concat(modalId);
    return this.modals.length;
  }

  remove(modalId: string) {
    this.modals = this.modals.filter((_modal) => _modal !== modalId);
  }

  isTopModal(modalId: string) {
    return this.modals[this.modals.length - 1] === modalId;
  }

  isFirstModal(modalId: string) {
    return this.modals[0] === modalId;
  }
}

export const modalManager = new ModalManager();

/**
 * Hook to manage multiple nested modals
 */
export function useModalManager(modalId: string, isOpen?: boolean) {
  useEffect(() => {
    if (isOpen) {
      modalManager.add(modalId);
    }
    return () => {
      modalManager.remove(modalId);
    };
  }, [isOpen, modalId]);
}
