import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSlidersH,
  faMapMarkedAlt,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faUser,
  faHeart,
  faUserAlt,
  faBell,
  faFlag,
  faEllipsisH,
  faUserPlus,
  faGripVertical,
  faHistory,
  faPaperclip,
  faExclamationCircle,
  faFileAlt,
  faSignal,
  faImage,
  faCamera,
  faUserSecret,
  faLock,
  faUnlock,
  faArrowLeft,
  faStarHalfAlt,
  faPlane,
  faKey,
  faHandshake,
  faSyncAlt,
  faExclamation,
  faAddressCard,
  faCaretDown,
  faCog,
  faExternalLinkAlt,
  faSearch,
  faSearchLocation,
  faShippingFast,
  faPencilAlt,
  faLongArrowAltDown,
  faStar,
  faCheck,
  faTimes,
  faSpinner,
  faSignOutAlt,
  faCar,
  faInfoCircle,
  faMapMarked,
  faDollarSign,
  faArrowRight,
  faPen,
  faCreditCard,
  faUserEdit,
  faComments,
  faEnvelope,
  faInfo,
  faCarSide,
  faThumbsUp,
  faLongArrowAltRight,
  faMobileAlt,
  faTrashAlt,
  faPlusCircle,
  faPlus,
  faDownload,
  faCheckCircle,
  faCaretRight,
  faCircleNotch,
  faBookmark,
  faEdit,
  faPhoneAlt,
  faRoad,
  faReply,
  faMapMarker,
  faMapMarkerAlt,
  faGlobe,
  faLocationArrow,
  faWrench,
  faMotorcycle,
  faBicycle,
  faShareAlt,
  faRedoAlt,
  faExclamationTriangle,
  faBolt,
  faBriefcase,
  faUserCheck,
  faCoins
} from '@fortawesome/free-solid-svg-icons';
import {
  faClock,
  faCalendarAlt,
  faQuestionCircle as faQuestionCircleFar,
  faHeart as faHeartFar
} from '@fortawesome/free-regular-svg-icons';
import {
  faViber,
  faWhatsapp,
  faInstagram,
  faFacebook,
  faLinkedin,
  faSkype,
  faTwitter,
  faStripeS
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faClock,
  faUserAlt,
  faBell,
  faFlag,
  faEllipsisH,
  faCalendarAlt,
  faSignOutAlt,
  faSpinner,
  faCheck,
  faExclamationTriangle,
  faBolt,
  faTimes,
  faMapMarker,
  faChevronLeft,
  faChevronRight,
  faViber,
  faInstagram,
  faFacebook,
  faTwitter,
  faWhatsapp,
  faUser,
  faExclamation,
  faCaretDown,
  faCog,
  faChevronDown,
  faChevronUp,
  faExternalLinkAlt,
  faPencilAlt,
  faLongArrowAltDown,
  faStar,
  faCar,
  faInfoCircle,
  faMapMarked,
  faDollarSign,
  faCreditCard,
  faUserEdit,
  faComments,
  faInfo,
  faCarSide,
  faThumbsUp,
  faLongArrowAltRight,
  faMobileAlt,
  faSlidersH,
  faStripeS,
  faTrashAlt,
  faPlusCircle,
  faCheckCircle,
  faCaretRight,
  faPlus,
  faDownload,
  faCircleNotch,
  faBookmark,
  faEdit,
  faPhoneAlt,
  faQuestionCircleFar,
  faMapMarker,
  faEnvelope,
  faGlobe,
  faLocationArrow,
  faHeart,
  faHeartFar,
  faWrench,
  faImage,
  faArrowRight,
  faCamera,
  faSkype,
  faPen,
  faSearch,
  faSearchLocation,
  faShippingFast,
  faMapMarkerAlt,
  faUserSecret,
  faLock,
  faUnlock,
  faArrowLeft,
  faStarHalfAlt,
  faKey,
  faMapMarkedAlt,
  faMotorcycle,
  faRoad,
  faReply,
  faBicycle,
  faAddressCard,
  faSyncAlt,
  faRedoAlt,
  faHistory,
  faPaperclip,
  faExclamationCircle,
  faFileAlt,
  faSignal,
  faHandshake,
  faPlane,
  faLinkedin,
  faShareAlt,
  faGripVertical,
  faUserPlus,
  faBriefcase,
  faUserCheck,
  faCoins
);
