import { useMediaQuery } from '@/custom-hooks/useMediaQuery';
import { throttle } from '@b2w/shared/utility';
import AppLink from '@/components/AppLink';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { twCx } from '@b2w/react-ui/core2';
import { bottomSafeSpace } from './safeSpace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useUserTempData } from '@/context/usertempdata.context';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useProfile } from '@/context/profile.context';
import { PrivateProfileDTO, UnreadCountersForUser } from '@b2w/shared/types';
import { getTotalFromObject } from '@/lib/totalFromObj';
import css from 'styled-jsx/css';

type MenuItem = {
  icon: IconProp | IconProp[];
  iconSize?: { width: number; height: number };
  href: string;
  text: string | React.ReactNode;
};

const notLoggedInItems: MenuItem[] = [
  {
    icon: 'search-location',
    href: '/search',
    text: 'Search'
  },
  {
    icon: 'sign-out-alt',
    href: '/signin',
    text: 'Login'
  },
  {
    icon: 'user-plus',
    href: '/signup',
    text: 'Signup'
  }
];

const getLoggedInItems = (
  profile: PrivateProfileDTO,
  unreadCounters: UnreadCountersForUser,
  pathname: string
): MenuItem[] => {
  const hasVehicles = profile.totalVehicles > 0;
  const unreadMessages = getTotalFromObject(
    unreadCounters?.chat_unread_messages
  );

  const showUnread = unreadMessages > 0;
  const highlightUnread = showUnread && pathname !== '/inbox';

  return [
    {
      icon: 'key',
      href: '/dashboard/rentals',
      text: 'Rentals'
    },
    {
      icon: ['car-side', 'motorcycle'],
      iconSize: { width: 20, height: 17 },
      href: hasVehicles ? '/dashboard/vehicles' : '/dashboard/vehicles/add',
      text: hasVehicles ? 'My vehicles' : 'Add vehicle'
    },
    {
      icon: 'comments',
      href: '/inbox',
      text: (
        <div className={twCx(highlightUnread && 'text-accent font-weight-500')}>
          Inbox
          {showUnread && <span className="ml-1">({unreadMessages})</span>}
        </div>
      )
    },
    {
      icon: 'search-location',
      href: '/search',
      text: 'Search'
    }
  ];
};

function getMenuStyles(showMenu: boolean) {
  return css.resolve`
    @supports (padding-top: env(safe-area-inset-bottom)) {
      .mob-menu-container {
        padding-bottom: env(safe-area-inset-bottom);
      }
    }

    .mob-menu-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 99;
      border-top: 1px solid #ddd;
      background-color: white;
      transition: transform 0.25s ease-in;
      transform: translateY(${showMenu ? '0%' : '100%'});
    }

    .mob-menu-link {
      padding: 0 2px;
      flex: 1;
      text-align: center;
      line-height: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mob-menu-link.active > .mob-menu-link-text {
      font-weight: 500;
      color: var(--primary-light);
    }

    .mob-menu-link-icon {
      width: auto;
      max-width: 100%;

      margin-left: auto;
      margin-right: auto;
    }

    .mob-menu-link-text {
      color: var(--dark);
      margin-top: 6px;
      white-space: nowrap;

      font-size: 14px;
      font-weight: 400;
    }

    @media screen and (min-width: 769px) {
      .mob-menu-container {
        display: none;
      }
    }
  `;
}

const useMobileNavMenu = () => {
  const isMobileScreen = useMediaQuery('(max-width: 768px)');
  const prevOffsetY = useRef<number | undefined>();

  const router = useRouter();
  const { unreadCounters } = useUserTempData();
  const { profile, isProfileLoading } = useProfile();
  const [showMenu, setShowMenu] = useState(false);

  const items = profile
    ? getLoggedInItems(profile, unreadCounters, router.pathname)
    : notLoggedInItems;

  useEffect(() => {
    if (!isProfileLoading) {
      setShowMenu(true);
    }
  }, [isProfileLoading]);

  useEffect(() => {
    if (!isMobileScreen) {
      return;
    }

    bottomSafeSpace.add('50px');

    let timeout: any;

    const onScroll = throttle(() => {
      clearTimeout(timeout);

      const yOffset = window.pageYOffset;
      const prevYOffset = prevOffsetY.current;

      if (prevYOffset !== undefined && yOffset !== undefined) {
        if (prevYOffset < yOffset) {
          // scroll down
          setShowMenu(false);

          timeout = setTimeout(() => {
            setShowMenu(true);
          }, 600);
        } else {
          setShowMenu(true);
        }
      }

      prevOffsetY.current = yOffset;
    }, 50);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      bottomSafeSpace.remove();
    };
  }, [isMobileScreen]);

  return { showMenu, items };
};

const MobileNavMenu = () => {
  const { showMenu, items } = useMobileNavMenu();
  const { className: menuClassName, styles } = getMenuStyles(showMenu);
  const router = useRouter();

  return (
    <div className={twCx(menuClassName, 'mob-menu-container')}>
      <div className="d-flex align-items-center" style={{ height: 50 }}>
        {items.map((item) => {
          const isActive = router.pathname === item.href;

          return (
            <AppLink
              key={item.href}
              href={item.href}
              className={twCx(
                menuClassName,
                'mob-menu-link link-unstyled',
                isActive && 'active'
              )}
            >
              <div className="d-flex justify-content-center">
                {(Array.isArray(item.icon) ? item.icon : [item.icon]).map(
                  (icon, idx) => (
                    <div
                      key={idx}
                      className={twCx(
                        isActive ? 'text-primary-light' : 'text-dark',
                        idx > 0 && 'ml-1'
                      )}
                    >
                      <FontAwesomeIcon
                        icon={icon}
                        className="mx-auto font-weight-normal"
                        style={{
                          width: item.iconSize?.width ?? 17,
                          height: item.iconSize?.height ?? 17
                        }}
                      />
                    </div>
                  )
                )}
              </div>
              <div className={twCx(menuClassName, 'mob-menu-link-text')}>
                {item.text}
              </div>
            </AppLink>
          );
        })}
      </div>

      {styles}
    </div>
  );
};

export default MobileNavMenu;
