import {
  PopperArrowProps,
  UsePopperProps,
  usePopper
} from '@b2w/shared/react-popper';
import {
  PropGetter,
  PropGetterCustomAttr,
  mergeRefs
} from '@b2w/shared/react-utils';
import { createContext, useContext } from 'react';
import { FocusLockProps } from '../FocusLock';

export type UseInitPopupProps = Pick<
  UsePopperProps,
  'placement' | 'matchWidth' | 'strategy'
> & {
  isOpen?: boolean;
  /**
   * Whether to render tooltip in React Portal
   *
   * @default true
   */
  inPortal?: boolean;
  /**
   * @default 150
   */
  animationsMs?: number;
  /**
   * Set the size of popup arrow in PX
   */
  arrowSize?: number;
  /** Background color value is set to currentColor. Set text color to see desired background color. */
  arrowClassName?: string;
  /**
   * Distance in PX between popup and trigger.
   *
   * @default 8
   */
  distancePx?: number;
  /**
   * Calculate distance between popup and trigger with padding instead of margin.
   * It can be useful for tooltip when open state should be preserved while hovering from trigger to popup.
   *
   * @default false
   */
  distanceAsPadding?: boolean;
  /**
   * If true, focus will be locked within active popup elements.
   * When popup closes, focus will be returned to trigger.
   *
   * @default false
   */
  focusLock?: boolean;
  focusLockInitialFocusRef?: FocusLockProps['initialFocusRef'];
  focusLockFinalFocusRef?: FocusLockProps['finalFocusRef'];
  focusLockAutofocus?: FocusLockProps['autoFocus'];
  focusLockRestoreFocus?: FocusLockProps['restoreFocus'];
};

export const useInitPopup = (props: UseInitPopupProps) => {
  const {
    isOpen = false,
    inPortal = true,
    animationsMs = 150,
    arrowSize,
    arrowClassName,
    distancePx = 8,
    distanceAsPadding = false,
    focusLock: focusLockEnabled = false,
    focusLockFinalFocusRef,
    focusLockInitialFocusRef,
    focusLockAutofocus = true,
    focusLockRestoreFocus = true,
    placement,
    matchWidth,
    strategy
  } = props;

  const popper = usePopper({
    placement,
    matchWidth,
    gutter: distanceAsPadding ? 0 : distancePx,
    strategy
  });

  const arrow =
    arrowSize && arrowSize > 0
      ? {
          size: arrowSize + 'px',
          className: arrowClassName ?? ''
        }
      : undefined;

  const focusLock = {
    enabled: focusLockEnabled,
    initialRef: focusLockInitialFocusRef,
    finalRef: focusLockFinalFocusRef,
    autofocus: focusLockAutofocus,
    restoreFocus: focusLockRestoreFocus
  };

  const getTriggerProps: PropGetter = (
    props = {},
    triggerRef = null,
    refPropName = 'ref'
  ) => {
    const refProps = popper.getRefProps();

    return {
      ...props,
      [refPropName]: mergeRefs(triggerRef, refProps.ref)
    };
  };

  const getPositionerProps: PropGetter = (props = {}, ref = null) => {
    const elProps = popper.getElProps();

    return {
      ...props,
      ...elProps,
      style: {
        ...elProps.style,
        ...props.style
      },
      ref: mergeRefs(ref, elProps.ref)
    };
  };

  const getArrowProps: PropGetterCustomAttr<'div', PopperArrowProps> = (
    props = {},
    ref = null
  ) => {
    const { size, shadowColor, bg, ...htmlProps } = props;
    const arrowProps = popper.getArrowProps({ size, shadowColor, bg });

    return {
      ...htmlProps,
      ...arrowProps,
      style: {
        ...htmlProps.style,
        ...arrowProps.style
      },
      ref
    };
  };

  return {
    isOpen,
    inPortal,
    animationsMs,
    placement,
    distanceAsPadding,
    distancePx,
    getTriggerProps,
    getPositionerProps,
    getArrowProps,
    getArrowInnerProps: popper.getArrowInnerProps,
    transformOrigin: popper.transformOrigin,
    arrow,
    focusLock
  };
};

type PopupCtxValue = ReturnType<typeof useInitPopup>;

export const PopupCtx = createContext<PopupCtxValue>({} as PopupCtxValue);
export const usePopupCtx = () => useContext(PopupCtx);
