import { PrivateProfileDTO, RoleEnum } from '@b2w/shared/types';

class RoleManager {
  static canAccessAdmin(profile: PrivateProfileDTO) {
    return RoleManager.checkPermission(profile, [
      RoleEnum.admin,
      RoleEnum.agent,
      RoleEnum.viewer,
      RoleEnum.bookingsViewer,
      RoleEnum.vehicleEditor,
      RoleEnum.cityLandingEditor
    ]);
  }

  static checkPermission(profile: PrivateProfileDTO, allowedRoles: RoleEnum[]) {
    if (!profile || !profile.roles) return false;

    for (const role of allowedRoles) {
      if (profile.roles.includes(role)) {
        return true;
      }
    }

    return false;
  }
}

export default RoleManager;
