import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useToggler } from '@/custom-hooks/useToggler';
import { theme } from '@/styles/sc-theme';
import { useRouter } from 'next/router';
import BellList from '@/components/bell/BellList';
import { useEffect } from 'react';
import BellIcon from './BellIcon';

const BellDropdown = () => {
  const [isBellToggled, toggleBell, setBellOpened] = useToggler(false);
  const router = useRouter();

  useEffect(() => {
    function onRouteChangeComplete() {
      setBellOpened(false);
    }

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      inNavbar
      isOpen={isBellToggled}
      toggle={toggleBell}
      className="d-flex align-items-center"
    >
      <DropdownToggle
        tag="div"
        data-toggle="dropdown"
        aria-expanded={isBellToggled}
        className="cursor-pointer"
      >
        <BellIcon />
      </DropdownToggle>

      <DropdownMenu
        right
        className="text-left py-0 px-0 overflow-y-auto"
        style={{
          maxHeight: `calc(100vh - ${theme.navbarHeight}px)`,
          minWidth: '360px',
          maxWidth: 'calc(100vw - 15px)'
        }}
      >
        <div className="py-3">
          <h6 className="mb-2 px-2 tw-font-semibold">Notifications</h6>
          {isBellToggled && <BellList showSeeAllButton />}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default BellDropdown;
