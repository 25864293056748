import React from 'react';
import { EventKeys } from '@b2w/shared/utility';

export type PropGetter<T extends React.ElementType = any> = (
  props?: React.ComponentPropsWithoutRef<T>,
  ref?: React.Ref<any> | React.RefObject<any>,
  ...args: any[]
) => React.ComponentPropsWithRef<T>;

export type PropGetterRenderAs<T extends HTMLElement = any> = (
  props?: React.HTMLProps<T>,
  ref?: React.Ref<any> | React.RefObject<any>,
  renderAs?: React.ElementType<any>
) => React.HTMLProps<T>;

export type PropGetterCustomAttr<
  T extends React.ElementType = any,
  P = Record<string, unknown>
> = (
  props?: Omit<React.ComponentPropsWithoutRef<T>, keyof P> & P,
  ref?: React.Ref<any> | React.RefObject<any>,
  ...args: any[]
) => React.ComponentPropsWithRef<T>;

export type EventKeyMap = Partial<
  Record<EventKeys, React.KeyboardEventHandler>
>;
