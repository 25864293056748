import {
  FontAwesomeIconProps,
  FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import { twCx } from '@b2w/react-ui/core2';

type Props = {
  onClick?: any;
  size: FontAwesomeIconProps['size'];
  isSelected: boolean;
  showHalfStar?: boolean;
};

const SingleStar = ({
  onClick,
  size,
  isSelected,
  showHalfStar = false
}: Props) => {
  return (
    <FontAwesomeIcon
      {...(onClick
        ? {
            onClick,
            className: twCx(
              'cursor-pointer stars cover-on-hover',
              isSelected && 'selected'
            )
          }
        : {
            style: { color: '#e6e6e6' },
            className: twCx('stars', isSelected && 'selected')
          })}
      icon={showHalfStar ? 'star-half-alt' : 'star'}
      size={size}
    />
  );
};

export default memo(SingleStar);
