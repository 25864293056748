//? hoistStatics needed to attach CustomCompProps on page components
//? Example: Page.someProp = val;

const hoistNonReactStatics = require('hoist-non-react-statics');

module.exports = {
  staticsHoc: hoistNonReactStatics,
  locales: ['en'],
  defaultLocale: 'en',
  loadLocaleFrom: (locale, namespace) =>
    import(`./locales/${locale}/${namespace}`).then((m) => m.default),
  pages: {
    '*': ['common'],
    '/support': ['inbox'],
    '/activity/[slug]': ['months'],
    '/reviews/[reviewId]': ['booking'],
    '/_admin/bookings': ['booking', 'months'],
    '/_admin/support-inbox': ['inbox'],
    '/_admin/misc': ['months'],
    '/booking/[bookingId]': ['booking', 'cancel_policy'],
    '/dashboard/payouts': ['dashboard_gateway'],
    '/dashboard/rentals/history': ['dashboard_rentals'],
    '/dashboard/rentals': ['dashboard_rentals'],
    '/dashboard/vehicles/add': ['add_vehicle', 'phone_auth', 'email_auth'],
    '/dashboard/vehicles': ['dashboard_myvehicles'],
    '/dashboard/earnings': ['dashboard_earnings'],
    '/dashboard/favorites': ['dashboard_myvehicles', 'dashboard_favorites'],
    '/dashboard/verifications': ['dashboard_index', 'phone_auth', 'email_auth'],
    '/dashboard': ['dashboard_index', 'phone_auth', 'email_auth'],
    '/dashboard/settings': [
      'dashboard_index',
      'dashboard_settings',
      'phone_auth',
      'email_auth'
    ],
    '/inbox/[chatId]': ['inbox', 'booking'],
    '/inbox': ['inbox'],
    '/profile/complete': ['completeProfileForm'],
    '/user/[uid]': ['user', 'send_msg', 'rankings', 'sign_in', 'phone_auth'],
    '/vehicle/[vId]/edit/calendar': ['vId_edit', 'vId_edit_calendar', 'months'],
    '/vehicle/[vId]/edit/details': ['add_vehicle', 'vId_edit', 'vId_edit_main'],
    '/vehicle/[vId]/edit/insurance': [
      'add_vehicle',
      'vId_edit',
      'vId_edit_insurance'
    ],
    '/vehicle/[vId]/edit/location': [
      'add_vehicle',
      'vId_edit',
      'vId_edit_location'
    ],
    '/vehicle/[vId]/edit/pictures': ['add_vehicle', 'vId_edit', 'vId_edit_pic'],
    '/vehicle/[vId]/edit/pricing': [
      'add_vehicle',
      'vId_edit',
      'vId_edit_pricing'
    ],
    '/vehicle/[vId]/edit/settings': [
      'add_vehicle',
      'vId_edit',
      'vId_edit_settings'
    ],
    '/vehicle/[vId]/booking': [
      'vId_booking',
      'pricing_total',
      'months',
      'dashboard_index',
      'phone_auth',
      'cancel_policy'
    ],
    '/vehicle/[vId]/ask_owner': [
      'vId_booking',
      'pricing_total',
      'months',
      'dashboard_index',
      'phone_auth',
      'cancel_policy',
      'months',
      'vId'
    ],
    '/vehicle/[vId]': [
      'vId',
      'cancel_policy',
      'pricing_total',
      'months',
      'send_msg',
      'rankings',
      'sign_in',
      'phone_auth'
    ],
    '/about': ['about_us', 'about_common'],
    '/contact': ['contact', 'home'],
    '/howitworks': ['how_it_works'],
    '/': ['home', 'months'],
    '/insurance': ['insurance'],
    '/jobs': ['about_jobs', 'about_common'],
    '/mission': ['our_mission', 'about_common'],
    '/policy': ['policy'],
    '/press': ['about_press', 'about_common'],
    '/search': ['search', 'owner_about', 'months', 'pricing_total'],
    '/signin': ['sign_in', 'phone_auth'],
    '/signup': ['sign_in', 'phone_auth'],
    '/story': ['about_story', 'about_common'],
    '/team': ['about_team', 'about_common']
  }
};
