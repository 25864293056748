import { debounce } from '@b2w/shared/utility';
import { useEffect, useRef } from 'react';

type UseStickyBoxShadowProps = {
  enabled?: boolean;
};

/** Automatically attach box-shadow on scroll to sticky elements */
export const useStickyBoxShadow = <
  Container extends HTMLElement = HTMLDivElement,
  Top extends HTMLElement = HTMLDivElement,
  Bottom extends HTMLElement = HTMLDivElement
>(
  props: UseStickyBoxShadowProps = {}
) => {
  const { enabled = true } = props;

  const scrollContainerRef = useRef<Container | null>(null);
  const stickyTopElRef = useRef<Top | null>(null);
  const stickyBotElRef = useRef<Bottom | null>(null);

  useEffect(() => {
    if (!enabled) return;

    const c = scrollContainerRef.current;

    if (!c) return;

    const transitionCl = 'tw-tranisiton-all';
    const durationCl = 'tw-duration-150';
    const topShadowCl = 'tw-shadow-top-for-sticky';
    const botShadowCl = 'tw-shadow-bot-for-sticky';

    const handleScroll = debounce(() => {
      const isAtTop = c.scrollTop <= 0;
      const isAtBottom =
        Math.round(c.scrollTop + c.getBoundingClientRect().height) ===
        c.scrollHeight;

      if (isAtTop) {
        stickyTopElRef.current?.classList.remove(botShadowCl);
      } else {
        stickyTopElRef.current?.classList.add(
          botShadowCl,
          transitionCl,
          durationCl
        );
      }

      if (isAtBottom) {
        stickyBotElRef.current?.classList.remove(topShadowCl);
      } else {
        stickyBotElRef.current?.classList.add(
          topShadowCl,
          transitionCl,
          durationCl
        );
      }
    }, 50);

    handleScroll();

    c.addEventListener('scroll', handleScroll);

    return () => c.removeEventListener('scroll', handleScroll);
  }, [enabled]);

  return {
    scrollContainerRef,
    stickyTopElRef,
    stickyBotElRef
  };
};
