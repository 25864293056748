import { LocalStorageKeyEnum } from '@/lib/browser-storage.keys';
import Script from 'next/script';

const authRedirectPaths = ['/dashboard', '/inbox', '/_admin'];

type Props = {
  path: string;
};

const RedirectScript = ({ path }: Props) => {
  const authMatch = authRedirectPaths.some((p) => path.startsWith(p));

  if (authMatch) {
    return (
      <Script
        id="auth-check"
        dangerouslySetInnerHTML={{
          __html: `
      try {
        if(!localStorage.getItem('${LocalStorageKeyEnum.AUTH_USER_ID}')) {
          var nextParam = 'next=' + encodeURIComponent(location.pathname + location.search);
          var queryStr = location.search ? location.search + '&' + nextParam : '?' + nextParam; 
          location.replace('/signup' + queryStr);
        }
      } catch(e) {
        // nothing
      }
    `
        }}
      />
    );
  }

  return null;
};

export default RedirectScript;
