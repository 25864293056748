import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const DotsLoader = ({ radius = 8, ...props }: IContentLoaderProps) => {
  return (
    <ContentLoader speed={2} viewBox="0 0 100 50" {...props}>
      <circle cx="20" cy="25" r={radius} />
      <circle cx="50" cy="25" r={radius} />
      <circle cx="80" cy="25" r={radius} />
    </ContentLoader>
  );
};

export default DotsLoader;
