import { useEffect } from 'react';
import { isBrowser } from '@b2w/shared/utility';

/**
 * Executes `handler` when clicked outside of passed `refs`
 */
export function useOnClickOutside(
  refs: React.RefObject<any>[],
  handler: (ev: MouseEvent) => void | null,
  canExecuteHandler = true,
  eventsToListen = ['mousedown'] as (keyof DocumentEventMap)[]
) {
  useEffect(() => {
    if (!isBrowser || !handler || !refs.length || !canExecuteHandler) {
      return;
    }

    const listener = (event: MouseEvent) => {
      if (
        refs.some((r) => !r.current) ||
        refs.some((r) => r.current.contains(event.target as Node))
      ) {
        return;
      }

      handler(event);
    };

    eventsToListen.forEach((eventName) => {
      document.addEventListener(eventName, listener);
    });

    return () => {
      eventsToListen.forEach((eventName) => {
        document.removeEventListener(eventName, listener);
      });
    };
  }, [refs, handler, canExecuteHandler, eventsToListen]);
}
