import { createContext, forwardRef, useContext } from 'react';
import { twCx } from '../twMerge';

const ListCtx = createContext<boolean>(false as boolean);
const useListCtx = () => useContext(ListCtx);

export type SelectableListProps = React.ComponentPropsWithRef<'ul'> & {
  /** Whether list and all options are disabled */
  isDisabled?: boolean;
};

const List = forwardRef<HTMLUListElement, SelectableListProps>((props, ref) => {
  const { isDisabled = false, ...htmlProps } = props;

  return (
    <ul
      {...htmlProps}
      ref={ref}
      className={twCx(
        'tw-py-2 tw-px-2 tw-overscroll-y-contain tw-relative',
        isDisabled
          ? 'tw-bg-gray-50 tw-overflow-y-hidden'
          : 'tw-overflow-y-auto',
        htmlProps.className
      )}
    >
      <ListCtx.Provider value={isDisabled}>
        {htmlProps.children}
      </ListCtx.Provider>
    </ul>
  );
});

export type SelectableListItemProps = React.ComponentPropsWithRef<'li'> & {
  isDisabled?: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
};

const ListItem = forwardRef<HTMLLIElement, SelectableListItemProps>(
  (props, ref) => {
    const {
      isDisabled = false,
      isSelected = false,
      isHovered,
      ...htmlProps
    } = props;

    const isListDisabled = useListCtx();
    const isSelectable = !isListDisabled && !isDisabled;
    const isProgrammaticHover = typeof isHovered === 'boolean';

    return (
      <li
        {...htmlProps}
        ref={ref}
        className={twCx(
          'tw-block tw-text-center tw-py-2 tw-px-4 tw-text-base tw-rounded-lg',
          isSelectable
            ? 'tw-cursor-pointer'
            : 'tw-text-gray-200 tw-cursor-default',
          isProgrammaticHover && isHovered && 'tw-bg-gray-100',
          !isProgrammaticHover &&
            isSelectable &&
            !isSelected &&
            'hover:tw-bg-gray-100',
          isSelectable && isSelected && 'tw-text-main-50 tw-bg-main-500',
          htmlProps.className
        )}
        onClick={isListDisabled ? undefined : htmlProps.onClick}
      >
        {htmlProps.children}
      </li>
    );
  }
);

export const SelectableList = Object.assign(List, {
  Item: ListItem
});
