import { ToastPlacement, TOAST_PLACEMENT_POOL } from './placement';
import { ToastList, RenderToastObjectProps } from './ToastList';
import {
  ReactToastManager,
  ReactToastManagerInitOptions
} from './ReactToastManager';

export type AppToastManagerInitOptions = Omit<
  ReactToastManagerInitOptions<RenderToastObjectProps, ToastPlacement>,
  | 'renderToasts'
  | 'placements'
  | 'removeFromTopWhen'
  | 'rootId'
  | 'defaultPlacement'
> & {
  rootId?: string;
  defaultPlacement?: ToastPlacement;
};

export class AppToastManager extends ReactToastManager<
  RenderToastObjectProps,
  ToastPlacement
> {
  constructor(options: AppToastManagerInitOptions = {}) {
    const {
      rootId = 'portal-root',
      defaultPlacement = 'bottomCenter',
      limit = 0
    } = options;

    super({
      limit,
      rootId,
      defaultPlacement,
      placements: TOAST_PLACEMENT_POOL,
      removeFromTopWhen: (placement) => placement.includes('top'),
      renderToasts(toasts, listPlacement) {
        return (
          <ToastList
            key={listPlacement}
            toasts={toasts}
            placement={listPlacement}
          />
        );
      }
    });
  }
}
