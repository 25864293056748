import { useState, useCallback } from 'react';

type InitialState = boolean | (() => boolean);

/**
 * React hook to manage boolean toggle state
 *
 * @param initialState the initial boolean state value
 *
 * @example
 * const [isOpen, toggle] = useToggle();
 *
 * <button onClick={toggle}>
 *   {isOpen ? 'Opened' : 'Closed'}
 * </button>
 *
 */
export function useToggle(
  initialState: InitialState = false
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [value, setValue] = useState(initialState);

  const toggle = useCallback(() => setValue((prev) => !prev), []);

  return [value, toggle, setValue];
}
