import { numberRange } from '@b2w/shared/utility';
import { useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { AnimatedScroll } from '../animatedScroll';
import { useRefsArray } from '@b2w/shared/react-hooks';
import { SelectableList } from '../SelectableList';

export type PickerTime = {
  hours: number;
  minutes: number;
};

const isSame = (target?: PickerTime, target2?: PickerTime) =>
  target &&
  target2 &&
  target.hours === target2.hours &&
  target.minutes === target2.minutes;
const isBefore = (target: PickerTime, pivot: PickerTime) =>
  target.hours < pivot.hours ||
  (target.hours === pivot.hours && target.minutes < pivot.minutes);
const isAfter = (target: PickerTime, pivot: PickerTime) =>
  target.hours > pivot.hours ||
  (target.hours === pivot.hours && target.minutes > pivot.minutes);
const isInTimeRange = (timeRange: TimeRange, time: PickerTime) =>
  isAfter(time, timeRange.from) && isBefore(time, timeRange.to);

export function generateTimesArray(
  options: {
    minTime?: PickerTime;
    maxTime?: PickerTime;
    allowMidnight?: boolean;
    interval?: number;
  } = {}
) {
  const { maxTime, minTime, interval = 30, allowMidnight = false } = options;

  const minuteIntervals = numberRange(0, 60 / interval).map(
    (t) => t * interval
  );

  const result: PickerTime[] = [];

  const min: PickerTime =
    minTime ??
    (allowMidnight
      ? { hours: 0, minutes: 0 }
      : { hours: 0, minutes: interval });

  const max: PickerTime = maxTime ?? { hours: 23, minutes: 59 };

  for (let hours = 0; hours < 24; hours++)
    minuteIntervals.forEach((minutes) => {
      const time = {
        hours,
        minutes
      };

      if (!isBefore(time, min) && !isAfter(time, max)) {
        result.push(time);
      }
    });

  return result;
}

export const formatTime = (time: PickerTime) => {
  const paddedHours = time.hours < 10 ? `0${time.hours}` : time.hours;
  const paddedMinutes = time.minutes < 10 ? `0${time.minutes}` : time.minutes;

  return `${paddedHours}:${paddedMinutes}`;
};

export type TimePickerController = {
  scrollToNearestValidTime: () => any;
};

export type TimeRange = {
  from: PickerTime;
  to: PickerTime;
};

export type TimePickerProps = {
  time?: PickerTime;
  onSelectTime?: (time: PickerTime) => any;
  minTime?: PickerTime;
  maxTime?: PickerTime;
  interval?: number;
  disabled?: boolean;
  allowMidnight?: boolean;
  disabledTimeRanges?: TimeRange[];
  className?: string;
  controller?: React.Ref<TimePickerController>;
};

export const TimePicker = (props: TimePickerProps) => {
  const {
    time,
    onSelectTime,
    maxTime,
    minTime,
    disabledTimeRanges,
    className: containerClassName,
    controller,
    interval = 30,
    disabled: isPickerDisabled = false,
    allowMidnight = false
  } = props;

  const containerRef = useRef<HTMLUListElement | null>(null);
  const [registerItemRef, itemsRef] = useRefsArray<HTMLLIElement>();

  const times = useMemo(
    () => generateTimesArray({ allowMidnight, interval, maxTime, minTime }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allowMidnight,
      interval,
      maxTime?.hours,
      maxTime?.minutes,
      minTime?.hours,
      minTime?.minutes
    ]
  );

  const isDisabledTime = (time: PickerTime) =>
    disabledTimeRanges?.some((timeRange) => isInTimeRange(timeRange, time));

  const onTimeClick = (time: PickerTime) => {
    if (!isPickerDisabled && !isDisabledTime(time)) onSelectTime?.(time);
  };

  const scrollToNearestValidTime = () => {
    const container = containerRef.current;

    if (!container) return;

    const validTimes = times.filter((t) => !isDisabledTime(t));

    const targetTime: PickerTime = time || { hours: 8, minutes: 0 };
    const targetTimeIdx = validTimes.findIndex((t) => isSame(t, targetTime));

    const timeItem = itemsRef.current[targetTimeIdx];

    if (timeItem) {
      AnimatedScroll.scrollToSelectorOrElement(timeItem, container, 'start', 0);
    }
  };

  useImperativeHandle(controller, () => ({
    scrollToNearestValidTime
  }));

  useEffect(() => {
    scrollToNearestValidTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectableList
      ref={containerRef}
      isDisabled={isPickerDisabled}
      className={containerClassName}
    >
      {times.map((t, idx) => {
        const formatted = formatTime(t);

        return (
          <SelectableList.Item
            key={formatted}
            ref={registerItemRef(idx)}
            isDisabled={isDisabledTime(t)}
            isSelected={isSame(t, time)}
            onClick={() => onTimeClick(t)}
            className="tw-font-medium"
          >
            {formatted}
          </SelectableList.Item>
        );
      })}
    </SelectableList>
  );
};
