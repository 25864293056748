import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export type UseNextQueryParamOptions = {
  /**
   * Value to use while query string is still being parsed and page is not
   * yet hydrated
   */
  loadingValue?: any;
  /**
   * Value to use while if supplied query key is not present in the query string
   */
  fallbackValue?: any;
};

/**
 * USE ONLY WITH STATIC PAGES
 *
 * This will get query param once page is hydrated.
 *
 * @param key the query key to retrieve
 *
 * @returns param value
 */
export function useNextQueryParam<T = string | string[] | null>(
  key: string,
  options: UseNextQueryParamOptions = {}
): T {
  const { fallbackValue = '___', loadingValue = null } = options;

  const { isReady, query } = useRouter();
  const [value, setValue] = useState<T>(loadingValue);

  const queryKeyValue = query[key];

  useEffect(() => {
    if (isReady) {
      if (queryKeyValue) {
        setValue(queryKeyValue as any as T);
      } else {
        setValue(fallbackValue);
      }
    }
  }, [queryKeyValue, isReady, fallbackValue]);

  return value as T;
}
