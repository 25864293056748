import { useEffect, useId, useState } from 'react';
import { PropGetter } from '@b2w/shared/react-utils';
import { callAllHandlers } from '@b2w/shared/utility';
import { PopupProps } from '../Popup';

export interface UseTooltipProps
  extends Pick<
    PopupProps,
    'placement' | 'matchWidth' | 'animationsMs' | 'inPortal' | 'distancePx'
  > {
  /**
   * Content of tooltip
   */
  title?: React.ReactNode;
  /**
   * Whether to display tooltip arrow
   *
   * @default false
   */
  arrow?: boolean;
  /**
   * If tooltip is meant to provide visual look only, keep it false.
   *
   * If tooltip is meant to provide accessible description to the trigger, keep
   * it true (E.g. Icon without a text).
   *
   * @default false
   */
  ariaActAsTriggerDescription?: boolean;
  /**
   * The `id` of the tooltip
   */
  id?: string;
  /**
   * Whether to keep tooltip opened if mouse is over tooltip content
   *
   * @default false
   */
  keepOpenOnHover?: boolean;
}

export const useTooltip = (props: UseTooltipProps) => {
  const {
    id: idProp,
    ariaActAsTriggerDescription = false,
    keepOpenOnHover = false,
    title,
    placement,
    matchWidth = false,
    arrow = false,
    inPortal = true,
    animationsMs = 150,
    distancePx = 8
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  const uuid = useId();
  const id = idProp ?? uuid;
  const tooltipId = `tooltip-${id}`;

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }

    function handleKeyDown(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        close();
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const titleIsString = typeof title === 'string';
  const triggerAriaProps: React.HTMLProps<any> = ariaActAsTriggerDescription
    ? {
        title: titleIsString && !isOpen ? title : undefined,
        'aria-describedby': isOpen ? tooltipId : undefined
      }
    : {
        'aria-label': titleIsString ? title : undefined,
        'aria-labelledby': !titleIsString && isOpen ? tooltipId : undefined
      };

  const getTriggerProps: PropGetter = (props = {}, ref = null) => {
    return {
      ...triggerAriaProps,
      ...props,
      ref,
      onMouseOver: callAllHandlers(props.onMouseOver, open),
      onMouseLeave: callAllHandlers(props.onMouseLeave, close),
      onFocus: callAllHandlers(props.onFocus, open),
      onBlur: callAllHandlers(props.onBlur, close)
    };
  };

  const getTooltipPositionerProps: PropGetter = (props = {}, ref = null) => {
    return {
      ...props,
      ref,
      onMouseOver: callAllHandlers(
        props.onMouseOver,
        keepOpenOnHover ? open : close
      ),
      onMouseLeave: callAllHandlers(props.onMouseLeave, close)
    };
  };

  const getTooltipBodyProps: PropGetter = (props = {}, ref = null) => {
    return {
      ...props,
      ref,
      id: tooltipId,
      role: 'tooltip'
    };
  };

  return {
    isOpen,
    getTriggerProps,
    getTooltipPositionerProps,
    getTooltipBodyProps,
    title,
    placement,
    matchWidth,
    arrow,
    inPortal,
    animationsMs,
    distancePx
  };
};
