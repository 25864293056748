import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const AvatarLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader speed={2} viewBox="0 0 50 50" {...props}>
      <circle cx="25" cy="25" r={25} />
    </ContentLoader>
  );
};

export default AvatarLoader;
