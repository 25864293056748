import { forwardRef } from 'react';
import {
  FormControlContext,
  InitFormControlCtxOptions,
  useInitFormControl
} from './form-control.init';
import { twCx } from '../twMerge';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';

export type FormGroupProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentPropsWithRef<C>;

type FormGroupComponent = <C extends React.ElementType = 'div'>(
  props: FormGroupProps<C>
) => JSX.Element;

export const FormGroup = forwardRef(
  <T extends React.ElementType = 'div'>(
    props: PolymorphicComponentProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      children,
      className: classNameProp,
      as: Component = 'div',
      ...restOfProps
    } = props;

    const className = twCx(
      '[&+&]:tw-mt-4',
      '[&+[type="submit"]]:tw-mt-4',
      classNameProp
    );
    const role =
      restOfProps.role ?? Component === 'fieldset' ? undefined : 'group';

    return (
      <Component {...restOfProps} ref={ref} className={className} role={role}>
        {children}
      </Component>
    );
  }
) as FormGroupComponent;

export type FormControlOwnProps<C extends React.ElementType = 'div'> = Omit<
  FormGroupProps<C>,
  keyof InitFormControlCtxOptions
> &
  InitFormControlCtxOptions;

export type FormControlProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentPropsWithRef<C, FormControlOwnProps<C>>;

type FormControlComponent = <C extends React.ElementType = 'div'>(
  props: FormControlProps<C>
) => JSX.Element;

/**
 * FormControl provides context such as
 * `isInvalid`, `isDisabled`, and `isRequired` to form elements.
 *
 * This is commonly used in form elements such as `input`,
 * `select`, `textarea`, etc.
 */
export const FormControl = forwardRef(
  <T extends React.ElementType = 'div'>(
    props: PolymorphicComponentProps<T, FormControlOwnProps<T>>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      id,
      isRequired,
      isDisabled,
      isInvalid,
      isReadOnly,
      children,
      ...restOfProps
    } = props;

    const context = useInitFormControl({
      id,
      isRequired,
      isDisabled,
      isInvalid,
      isReadOnly
    });

    return (
      <FormControlContext.Provider value={context}>
        <FormGroup {...(restOfProps as any)} id={id} ref={ref}>
          {children}
        </FormGroup>
      </FormControlContext.Provider>
    );
  }
) as FormControlComponent;
