function findIndexFromPos<T>(
  array: T[],
  predicate: (e: T, index: number, list: T[]) => boolean,
  startFrom: number
): number {
  for (let i = startFrom; i < array.length; i++) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }

  return -1;
}

function findIndexBeforePos<T>(
  array: T[],
  predicate: (e: T, index: number, list: T[]) => boolean,
  startFrom: number
): number {
  for (let i = startFrom; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }

  return -1;
}

export type OptionObject<TValue = any> = {
  node: HTMLLIElement | null;
  index: number;
  value: TValue;
  disabled: boolean;
};

export class OptionsManager<TValue> {
  private options: OptionObject[] = [];

  setNodeForOptionAtIndex(index: number, node: HTMLLIElement | null) {
    const pos = this.options.findIndex((o) => o.index === index);

    if (pos >= 0) {
      this.options[pos].node = node;
    }
  }

  initOptions(options: OptionObject<TValue>[]) {
    this.options = options;
    return this;
  }

  getByIndex(index: number) {
    return this.options[index] ?? null;
  }

  getByNode(node: HTMLLIElement) {
    return this.options.find((opt) => opt.node === node) ?? null;
  }

  getByCallback(cb: (optionObject: OptionObject) => boolean) {
    return this.options.find(cb) ?? null;
  }

  getNextEnabledOption(index: number) {
    if (index < 0) {
      return this.options.find((opt) => !opt.disabled) ?? null;
    }

    const posOfCurrent = this.options.findIndex((o) => o.index === index);

    if (posOfCurrent >= this.options.length - 1) {
      return null;
    }

    const nextOptionPos = findIndexFromPos(
      this.options,
      (opt) => !opt.disabled,
      posOfCurrent + 1
    );

    return this.options[nextOptionPos] ?? null;
  }

  getPrevEnabledOption(index: number) {
    if (index < 0) {
      return this.options.find((opt) => !opt.disabled) ?? null;
    }

    const posOfCurrent = this.options.findIndex((o) => o.index === index);

    if (posOfCurrent > this.options.length) {
      return this.options[this.options.length - 1] ?? null;
    }

    const prevOptionPos = findIndexBeforePos(
      this.options,
      (opt) => !opt.disabled,
      posOfCurrent - 1
    );

    return this.options[prevOptionPos] ?? null;
  }

  getFirstEnabledOption() {
    return this.getNextEnabledOption(-1);
  }

  getLastEnabledOption() {
    return this.getPrevEnabledOption(this.options ? this.options.length : -1);
  }
}
