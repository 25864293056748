import {
  BellNotificationItemModel,
  GetBellNotificationsQueryDTO,
  MarkBellNotificationQueryDTO
} from '@b2w/shared/types';
import { MainApiService } from './main-api.service';

// https://stackoverflow.com/a/36978360
class BellService extends MainApiService {
  private prefix = '/bell';
  private static _instance: BellService;

  private constructor() {
    super();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async getNotificationsList(query?: GetBellNotificationsQueryDTO) {
    const endpoint = this.buildEndpointWithQueryString(this.prefix, query);

    return this.get<BellNotificationItemModel[]>(endpoint);
  }

  async getTotalNotificationCountForCurrentUser() {
    return this.get<number>(this.prefix + '/count');
  }

  async markAllAsRead() {
    return this.post<void>(this.prefix + '/read-all');
  }

  async markAllAsUnread() {
    return this.post<void>(this.prefix + '/unread-all');
  }

  async clearAll() {
    return this.post<void>(this.prefix + '/clear-all');
  }

  async markNotification(query?: MarkBellNotificationQueryDTO) {
    const endpoint = this.buildEndpointWithQueryString(
      this.prefix + '/mark',
      query
    );

    return this.post<void>(endpoint);
  }

  async deleteNotificationById(id: string) {
    return this.post<void>(this.prefix + `/clear/${id}`);
  }
}

export const bellService = BellService.Instance;
