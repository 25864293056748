export const accountLinks = [
  {
    i18nKey: 'dashboard',
    href: '/dashboard'
  },
  {
    i18nKey: 'settings',
    href: '/dashboard/settings'
  },
  {
    i18nKey: 'verifications',
    href: '/dashboard/verifications'
  },
  {
    i18nKey: 'gateway',
    href: '/dashboard/payouts'
  },
  {
    i18nKey: 'earnings',
    href: '/dashboard/earnings'
  }
];

export const rentalsLinks = [
  {
    i18nKey: 'rentals',
    href: '/dashboard/rentals'
  },
  {
    i18nKey: 'booking_schedule',
    href: '/dashboard/bookingschedule'
  },
  {
    i18nKey: 'my_vehicles',
    href: '/dashboard/vehicles'
  },
  {
    i18nKey: 'add_new_vehicle',
    href: '/dashboard/vehicles/add'
  },
  {
    i18nKey: 'favorites',
    href: '/dashboard/favorites'
  }
];

export const helpLinks = [
  {
    text: 'Help & Support',
    href: '/support'
  },
  {
    text: 'How it works?',
    href: '/howitworks'
  },
  {
    text: 'Insurance',
    href: '/insurance'
  },
  {
    text: 'Contact us',
    href: '/contact'
  }
];
