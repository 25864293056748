import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { useEventCallback } from '@b2w/shared/react-hooks';
import { debounce } from '@b2w/shared/utility';
import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

export type CollapseProps = {
  /**
   * Trigger collapse
   */
  in: boolean;
  /**
   * Whether to mount and unmout children when animation start/completes
   *
   * @default false
   */
  mountUnmout?: boolean;
  /**
   * Animation duration in miliseconds
   *
   * @default 200
   */
  duration?: number;
  /**
   * @default false
   */
  applyFadeOnCollapse?: boolean;
  /**
   * How much of height must remain visible. Supplied in pixels. Might be useful for "Show more" text.
   *
   * @default 0 (none)
   */
  heightWhenClosed?: number;
  children: React.ReactNode;
};

const transition =
  '[transition:height_var(--collapse-duration)_ease-out,opacity_calc(var(--collapse-duration)*1.5)] ';

const enter =
  'tw-h-[--collapse-height-when-closed] tw-opacity-[--collapse-opacity-when-closed] tw-overflow-y-hidden';

const enterActive =
  transition + '!tw-h-[--collapse-element-height] !tw-opacity-100';

const exit =
  'tw-h-[--collapse-element-height] tw-opacity-100 tw-overflow-y-hidden';

const exitActive =
  transition +
  '!tw-h-[--collapse-height-when-closed] !tw-opacity-[--collapse-opacity-when-closed]';

const exitDone = enter;

const animationClassnames: CSSTransitionClassNames = {
  enter,
  enterActive,
  exit,
  exitActive,
  exitDone
};

/**
 * Provides collapse animation.
 */
export const Collapse = ({
  in: animate,
  mountUnmout = false,
  duration = 200,
  applyFadeOnCollapse = false,
  heightWhenClosed: heightWhenClosedProp = 0,
  children
}: CollapseProps): JSX.Element => {
  const heightRef = useRef(0);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [isInitiallyExited] = useState(animate === false);
  const [mustPrecomputeHeight, setMustPrecomputeHeight] = useState(false);

  const heightWhenClosed = Math.max(0, heightWhenClosedProp);
  const isTextFadeOut = applyFadeOnCollapse && heightWhenClosed > 0 && !animate;
  const isBgFadeOut = applyFadeOnCollapse && heightWhenClosed === 0;

  const wrapStyles = {
    '--collapse-duration': duration + 'ms',
    '--collapse-element-height': heightRef.current + 'px',
    '--collapse-height-when-closed': heightWhenClosed + 'px',
    '--collapse-opacity-when-closed': isBgFadeOut ? 0 : 1
  } as CSSProperties;

  // if content is being mounted, unmounted
  const dontListenForResize = mountUnmout && !animate;

  // listen for content height changes to recalculate collapse height
  useEffect(() => {
    if (dontListenForResize) {
      setMustPrecomputeHeight(true);
      return;
    }

    const content = contentRef.current;

    if (!content) return;

    const handleResize = debounce(() => {
      if (content && content.scrollHeight !== 0) {
        heightRef.current = content.scrollHeight;
      }
    }, 500);

    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(content);

    return () => {
      resizeObserver.disconnect();
    };
  }, [dontListenForResize]);

  const registerWrapper = useEventCallback((node: HTMLDivElement) => {
    if (node) {
      const content = contentRef.current;

      if (content && content.scrollHeight !== 0) {
        heightRef.current = content.scrollHeight;
      }

      if (isInitiallyExited) {
        // CSSTransition component does not add exitDone class
        // if initially in={false}, do that manually
        node.classList.add(...animationClassnames.exitDone!.split(' '));
      }
    }
  });

  return (
    <>
      <CSSTransition
        in={animate}
        timeout={duration}
        classNames={animationClassnames}
        unmountOnExit={mountUnmout}
      >
        <div
          style={wrapStyles}
          ref={registerWrapper}
          className="tw-relative tw-min-h-[--collapse-height-when-closed]"
        >
          {isTextFadeOut && (
            <div
              className={
                'tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-h-[30px] tw-bg-gradient-to-b tw-from-white/0 tw-from-0% tw-to-white tw-to-90%'
              }
            />
          )}
          <div ref={contentRef}>{children}</div>
        </div>
      </CSSTransition>
      {mustPrecomputeHeight && (
        <div
          ref={(node) => {
            if (node && node.scrollHeight !== 0) {
              heightRef.current = node.scrollHeight;
              setMustPrecomputeHeight(false);
            }
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};
