import * as Sentry from '@sentry/nextjs';

export type ExceptionSeverity = Sentry.Severity;

type ReportOptions = {
  extra?: Record<string, any>;
  tags?: Record<string, any>;
  level?: ExceptionSeverity;
};

export function reportException(exception: any, options: ReportOptions = {}) {
  return Sentry.captureException(exception, options);
}

export async function reportExceptionWithFlush(
  exception: any,
  options: ReportOptions = {},
  flushTimeout = 2000
) {
  const result = Sentry.captureException(exception, options);
  await Sentry.flush(flushTimeout);

  return result;
}

export function setUserForExceptionReport(userId: string) {
  return Sentry.setUser({ id: userId });
}

export function clearUserForExceptionReport() {
  return Sentry.configureScope((scope) => scope.setUser(null));
}
