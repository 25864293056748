import { useEffect, useState, useRef, useCallback } from 'react';

type IsPlaying = boolean;
type Toggle = () => void;

const useAudio = (url: string): [IsPlaying, Toggle] => {
  const audioRef = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const audio = new Audio(url);
    audioRef.current = audio;

    audio.addEventListener('ended', () => setIsPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [url]);

  const toggle = useCallback(() => setIsPlaying((p) => !p), []);

  useEffect(() => {
    const handleSound = async () => {
      try {
        if (isPlaying) {
          await audioRef.current?.play();
        } else {
          audioRef.current?.pause();
        }
      } catch (err) {
        console.info('[useAudio]', err.message);
      }
    };

    handleSound();
  }, [isPlaying]);

  return [isPlaying, toggle];
};

export const useAudioNotification = () => useAudio('/notification.mp3');
