import { Fragment, forwardRef } from 'react';
import {
  IconCheckCircle,
  IconInfo,
  IconProps,
  IconSize,
  IconWarning
} from '../Icon';
import { twCx } from '../twMerge';

export type AlertStatus = 'success' | 'info' | 'warning' | 'error';
export type AlertVariant = 'soft' | 'solid' | 'outline' | 'accent';
export type AlertSize = 'md' | 'lg';

const statuses: AlertStatus[] = ['success', 'info', 'warning', 'error'];
const variants: AlertVariant[] = ['soft', 'solid', 'accent', 'outline'];
const sizes: AlertSize[] = ['md', 'lg'];

export const alertDefinition = {
  statuses,
  variants,
  sizes
};

const softStatus: Record<AlertStatus, string> = {
  success: 'tw-bg-success-50 tw-text-success-700',
  info: 'tw-bg-gray-50 tw-text-gray-700',
  warning: 'tw-bg-warning-50 tw-text-warning-700',
  error: 'tw-bg-error-50 tw-text-error-700'
};

const solidStatus: Record<AlertStatus, string> = {
  success: 'tw-bg-success-700 tw-text-white',
  info: 'tw-bg-gray-700 tw-text-white',
  warning: 'tw-bg-warning-700 tw-text-white',
  error: 'tw-bg-error-700 tw-text-white'
};

const outlineStatus: Record<AlertStatus, string> = {
  success:
    'tw-bg-transparent tw-text-success-600 tw-border tw-border-success-600',
  info: 'tw-bg-transparent tw-text-gray-600 tw-border tw-border-gray-600',
  warning:
    'tw-bg-transparent tw-text-warning-600 tw-border tw-border-warning-600',
  error: 'tw-bg-transparent tw-text-error-600 tw-border tw-border-error-600'
};

const accentStatus: Record<AlertStatus, string> = {
  success:
    'tw-bg-success-50 tw-text-success-700 tw-border-l-4 tw-border-l-success-600',
  info: 'tw-bg-gray-50 tw-text-gray-700 tw-border-l-4 tw-border-l-gray-600',
  warning:
    'tw-bg-warning-50 tw-text-warning-700 tw-border-l-4 tw-border-l-warning-600',
  error: 'tw-bg-error-50 tw-text-error-700 tw-border-l-4 tw-border-l-error-600'
};

const variant: Record<AlertVariant, typeof softStatus> = {
  soft: softStatus,
  solid: solidStatus,
  outline: outlineStatus,
  accent: accentStatus
};

const size: Record<AlertSize, string> = {
  md: 'tw-text-base tw-p-3',
  lg: 'tw-text-xl tw-p-4'
};

const css = {
  variant,
  size
};

export type AlertTruncateOptions = {
  title: boolean;
  message: boolean;
};

const normalizeTruncate = (
  truncateProp: boolean | AlertTruncateOptions
): AlertTruncateOptions => {
  if (typeof truncateProp === 'boolean') {
    return {
      message: truncateProp,
      title: truncateProp
    };
  }

  return {
    message: truncateProp.message,
    title: truncateProp.title
  };
};

const statusToIcon: Record<AlertStatus, React.ComponentType<IconProps>> = {
  error: IconInfo,
  info: IconInfo,
  success: IconCheckCircle,
  warning: IconWarning
};

const sizeToIconSize: Record<AlertSize, IconSize> = {
  md: 'lg',
  lg: 'xl'
};

export type AlertOwnProps = {
  children?: React.ReactNode;
  /**
   * The status of the alert.
   *
   * @default 'info'
   */
  status?: AlertStatus;
  /**
   * @default 'soft'
   */
  variant?: AlertVariant;
  /**
   * @default 'md'
   */
  size?: AlertSize;
  /**
   * Whether to truncate alert text if overflows.
   *
   * Supply boolean to truncate both title and message, supply object to
   * configure them separately.
   *
   * @default false
   */
  truncate?: boolean | AlertTruncateOptions;
  /**
   * Custom icon to use regardless of passed `status` prop.
   *
   * Pass `null` to not render any icon at all
   */
  icon?: JSX.Element | ((iconSize: IconSize) => JSX.Element) | null;
  /**
   * Alert title
   */
  title?: React.ReactNode;
  /**
   * Optional block for action elements.
   */
  actions?: React.ReactNode[];
};

export type AlertProps = Omit<React.ComponentPropsWithRef<'div'>, 'title'> &
  AlertOwnProps;

export const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const {
    children,
    status = 'info',
    title,
    variant = 'soft',
    size = 'md',
    truncate: truncateProp = false,
    icon,
    actions = [],
    ...otherProps
  } = props;

  const truncate = normalizeTruncate(truncateProp);

  const className = twCx(
    'tw-flex tw-gap-2 tw-rounded-md',
    !children || !title ? 'tw-items-center' : 'tw-items-start',
    css.variant[variant][status],
    css.size[size],
    otherProps.className
  );

  const renderIconComponent = () => {
    if (icon === null) return null;
    if (icon) {
      return typeof icon === 'function' ? icon(sizeToIconSize[size]) : icon;
    }

    const IconComponent = statusToIcon[status];

    if (!IconComponent) return null;

    return (
      <div className="tw-flex tw-flex-shrink-0">
        <IconComponent size={sizeToIconSize[size]} />
      </div>
    );
  };

  const renderActions = () => {
    if (!actions.length) return null;

    return (
      <div className="tw-flex tw-ml-auto tw-flex-shrink-0 tw-gap-2">
        {actions.map((action, key) => (
          <Fragment key={key}>{action}</Fragment>
        ))}
      </div>
    );
  };

  return (
    <div {...otherProps} role="alert" ref={ref} className={className}>
      {renderIconComponent()}
      <div className="tw-min-w-0 tw-break-words">
        {!!title && (
          <div
            className={twCx(
              'tw-font-medium',
              children && 'tw-mb-2',
              truncate.title && 'tw-truncate'
            )}
          >
            {title}
          </div>
        )}
        {!!children && (
          <div className={twCx(truncate.message && 'tw-truncate')}>
            {children}
          </div>
        )}
      </div>
      {renderActions()}
    </div>
  );
});
