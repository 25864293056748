import { isLocalhost } from '@b2w/shared/utility';

declare global {
  interface Window {
    __authEmulator__?: boolean;
    __databaseEmulator__?: boolean;
    __firestoreEmulator__?: boolean;
    __storageEmulator__?: boolean;
  }
}

export const asyncLoadAuth = async () => {
  const mod = await import('./auth');

  if (isLocalhost && !window.__authEmulator__) {
    mod.connectAuthEmulator(mod.auth, 'http://localhost:9099', {
      disableWarnings: true
    });
    window.__authEmulator__ = true;
  }

  return mod;
};

export const asyncLoadDatabase = async () => {
  const mod = await import('./database');

  if (isLocalhost && !window.__databaseEmulator__) {
    mod.connectDatabaseEmulator(mod.database, 'localhost', 9000);
    window.__databaseEmulator__ = true;
  }

  return mod;
};

export const asyncLoadFirestore = async () => {
  const mod = await import('./firestore');

  if (isLocalhost && !window.__firestoreEmulator__) {
    mod.connectFirestoreEmulator(mod.firestore, 'localhost', 8080);
    window.__firestoreEmulator__ = true;
  }

  return mod;
};

export const asyncLoadStorage = async () => {
  const mod = await import('./storage');

  if (isLocalhost && !window.__storageEmulator__) {
    mod.connectStorageEmulator(mod.storage, 'localhost', 9199);
    window.__storageEmulator__ = true;
  }

  return mod;
};

export const asyncLoadMessaging = async () => {
  const mod = await import('./messaging');

  return mod;
};
