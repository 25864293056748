/**
 * @Note Regex can return true for a string that contains 5+ digits which
 * do NOT form specifically a phone number
 *
 * @example
 * '+123456789' // matches
 * '123456789' // matches
 * '123' // does not match
 * 'hello123456789' // does not match
 */
export const phoneRegex = /\b[+]?[(]?[0-9]{2,6}[)]?[-\s.]?[-\s/.0-9]{5,15}\b/gm;

/**
 * Regex for phone numbers sent in chat. It matches not only digits, but
 * also digit words considering whitespaces. For usecases that exclude text
 * messages, use `phoneRegex` since it would be a better fit.
 *
 * @example
 * '+123456789' // matches
 * '+ 11five six 15 nine six five' // matches
 * 'plus six 424 five' // matches
 * '123' // does not match
 * 'six seven eight' // does not match
 * 'six.seven.eight.4561' // does not match
 */
export const phoneRegexForTextMessages =
  /\b([+]|plus)?[(]?(zero|one|two|three|four|five|six|seven|eight|nine|ten|[0-9\s]){2,6}[)]?[-\s.]?(zero|one|two|three|four|five|six|seven|eight|nine|ten|[-\s/.0-9]){5,15}\b/gm;

/**
 * Matches date ISO-8601 format
 * @example
 * '2022-05-30T17:33:27.750Z'
 */
export const iso8601Regex =
  /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

/**
 * Matches short date from ISO-8601 format
 * @example
 * '2022-05-30'
 */
export const isoDateOnlyRegex = /^\d{4}-\d\d-\d\d$/i;

/**
 * Matches websites
 *
 * @example
 * website.com
 * www.website.com
 * my.website.app
 * https://website.co.uk/
 * https://website.co.uk/path
 */
export const websiteUrlRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.*/;
