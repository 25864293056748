import { CSSProperties, useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ToastPlacement } from './placement';
import {
  ToastItem,
  ToastPassableProps,
  UnderlyingToastComponentProps
} from './ToastItem';
import { ToastObject } from './ReactToastManager';
import { TransientFade } from '@b2w/react-ui/transitions2';
import { twCx } from '../twMerge';

type AnimatedLiProps = {
  children: React.ReactNode;
};

const AnimatedLi: React.FC<AnimatedLiProps> = ({ children }) => {
  const heightRef = useRef(0);

  const style = {
    // used in CSS files
    '--toast-height': heightRef.current + 'px'
  } as CSSProperties;

  const registerLi = (node: HTMLLIElement) => {
    if (
      node &&
      node.offsetHeight !== 0 &&
      node.offsetHeight !== heightRef.current
    ) {
      heightRef.current = node.offsetHeight;
    }
  };

  return (
    <li ref={registerLi} style={style}>
      {children}
    </li>
  );
};

const transitionClassNames = {
  enter: 'tw-opacity-0 tw-scale-75',
  enterActive: '!tw-opacity-100 !tw-scale-100 tw-transition tw-duration-500',
  exit: 'tw-opacity-100 tw-scale-100',
  exitActive:
    '!tw-opacity-0 !tw-scale-75 tw-transition tw-duration-300 tw-animate-[toast-slide_ease-out_200ms_300ms_forwards]'
};

const placementToClassName: Record<ToastPlacement, [string, string]> = {
  topLeft: ['tw-top-2', 'tw-left-0'],
  topCenter: ['tw-top-2', 'tw-left-1/2 -tw-translate-x-1/2'],
  topRight: ['tw-top-2', 'tw-right-0'],
  bottomLeft: ['tw-bottom-2', 'tw-left-0'],
  bottomCenter: ['tw-bottom-2', 'tw-left-1/2 -tw-translate-x-1/2'],
  bottomRight: ['tw-bottom-2', 'tw-right-0']
};

export interface RenderToastObjectProps
  extends UnderlyingToastComponentProps,
    ToastPassableProps {
  /**
   * Pass `true` to include default animated transition between toast updates.
   *
   * Pass callback to manually handle toast rendering.
   * Use case can be providing animation component.
   *
   * @example
   * // If `title` gets updated, trigger transition
   * withTransition: (toastItem, { componentProps }) => (
   *   <TransientFade animationKey={componentProps.title}>
   *     {toastItem}
   *   </TransientFade>
   * )
   */
  withTransition?:
    | boolean
    | ((
        renderedToast: React.ReactNode,
        toastObject: ToastObject<RenderToastObjectProps>
      ) => React.ReactNode);
}

const renderToastObject = (
  toastObject: ToastObject<RenderToastObjectProps>
) => {
  const { componentProps, dismissItself, id } = toastObject;
  const { withTransition, ...toastItemProps } = componentProps;

  const renderedToast = (
    <div className="tw-shadow-md tw-rounded-md">
      <ToastItem
        {...toastItemProps}
        key={id}
        id={id}
        removeToast={dismissItself}
      />
    </div>
  );

  if (withTransition !== undefined) {
    if (typeof withTransition === 'function') {
      return withTransition(renderedToast, toastObject);
    }
    if (typeof withTransition === 'boolean' && withTransition) {
      return (
        <TransientFade
          animationKey={
            (componentProps.children as any) +
            componentProps.title +
            componentProps.status
          }
          duration={300}
          initialOffsetX={-10}
          finalOffsetX={10}
        >
          {renderedToast}
        </TransientFade>
      );
    }
  }

  return renderedToast;
};

export interface ToastListProps {
  toasts: ToastObject<RenderToastObjectProps>[];
  placement: ToastPlacement;
}

export const ToastList = (props: ToastListProps) => {
  const { toasts, placement } = props;

  const className = twCx(
    'tw-fixed tw-z-toast tw-flex tw-flex-col tw-gap-2 [--toast-item-gap:0.5rem] tw-w-[400px] tw-mx-auto tw-max-w-full tw-px-2',
    placementToClassName[placement]
  );

  return (
    <TransitionGroup
      component="ul"
      className={className}
      role="region"
      aria-live="polite"
    >
      {toasts.map((toastObject) => (
        <CSSTransition
          key={toastObject.id}
          timeout={500}
          classNames={transitionClassNames}
        >
          <AnimatedLi>{renderToastObject(toastObject)}</AnimatedLi>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};
