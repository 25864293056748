import { callAllHandlers } from '@b2w/shared/utility';
import { useCallback, useState, useId } from 'react';
import { useEventCallback } from './useEventCallback';

export type UseDisclosureProps = {
  defaultIsOpen?: boolean;
  isOpen?: boolean;
  close?(): void;
  open?(): void;
  id?: string;
};

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;

/**
 * Reack hook that returns a set of props that can be used to control a
 * disclosure component.
 *
 * This hook supports both controlled and uncontrolled modes.
 */
export function useDisclosure(props: UseDisclosureProps = {}) {
  const {
    defaultIsOpen = false,
    close: closeProp,
    open: openProp,
    isOpen: isOpenProp,
    id: idProp
  } = props;

  const uuid = useId();
  const id = idProp ?? `${uuid}-disclosure`;

  const openPropCallback = useEventCallback(openProp);
  const closePropCallback = useEventCallback(closeProp);

  const [isOpenState, setIsOpen] = useState(defaultIsOpen);
  const isControlled = isOpenProp !== undefined;
  const isOpen = !!(isControlled ? isOpenProp : isOpenState);

  const close = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }

    if (closePropCallback) {
      closePropCallback();
    }
  }, [isControlled, closePropCallback]);

  const open = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }

    if (openPropCallback) {
      openPropCallback();
    }
  }, [isControlled, openPropCallback]);

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  return {
    isOpen,
    open,
    close,
    toggle,
    isControlled,
    getButtonProps: (props: any = {}) => ({
      ...props,
      'aria-expanded': 'true',
      'aria-controls': id,
      onClick: callAllHandlers(props.onClick, toggle)
    }),
    getDisclosureProps: (props: any = {}) => ({
      ...props,
      hidden: !isOpen,
      id
    })
  };
}
