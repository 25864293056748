import { useRef, useState } from 'react';
import { BoxModel, getBox } from '@b2w/shared/utility';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

export function useDimensions(
  ref: React.RefObject<HTMLElement>,
  observe?: boolean
) {
  const [dimensions, setDimensions] = useState<BoxModel | null>(null);
  const rafId = useRef<number>();

  useSafeLayoutEffect(() => {
    if (!ref.current) return undefined;

    const node = ref.current;

    function measure() {
      rafId.current = requestAnimationFrame(() => {
        const boxModel = getBox(node);
        setDimensions(boxModel);
      });
    }

    measure();

    if (observe) {
      window.addEventListener('resize', measure);
      window.addEventListener('scroll', measure);
    }

    return () => {
      if (observe) {
        window.removeEventListener('resize', measure);
        window.removeEventListener('scroll', measure);
      }

      if (rafId.current) {
        cancelAnimationFrame(rafId.current);
      }
    };
  }, [observe]);

  return dimensions;
}
