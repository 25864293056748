import { publicEnv } from '@config';
import type { NextFn } from 'firebase/auth';
import type { MessagePayload, Observer } from 'firebase/messaging';
import { LocalStorageKeyEnum } from '../browser-storage.keys';
import { browserStorageService } from '../services/browser-storage.service';
import { asyncLoadDatabase, asyncLoadMessaging } from './loaders';

export type onMessagePayloadFCM = MessagePayload;

export async function turnOnNotifications(
  userId: string,
  swRegistration: ServiceWorkerRegistration,
  onMessageSub: NextFn<onMessagePayloadFCM> | Observer<onMessagePayloadFCM>
) {
  const { messaging, getToken, onMessage, isSupported } =
    await asyncLoadMessaging();

  const canBeEnabled = await isSupported();

  if (!canBeEnabled) {
    console.log('[FCM] Cloud messaging is not supported');
    return;
  }

  if (messaging) {
    const { database, ref, set, remove } = await asyncLoadDatabase();

    const canUseLS = browserStorageService.canUseLocalStorage();
    const existingToken = canUseLS
      ? localStorage.getItem(LocalStorageKeyEnum.FCM_TOKEN)
      : null;

    try {
      const token = await getToken(messaging, {
        vapidKey: publicEnv.firebase.fcmVapidKey,
        serviceWorkerRegistration: swRegistration
      });

      if (existingToken !== token) {
        await set(ref(database, `/fcmTokens/${userId}/${token}`), true);
      }

      if (canUseLS) {
        localStorage.setItem(LocalStorageKeyEnum.FCM_TOKEN, token);
      }

      return onMessage(messaging, onMessageSub);
    } catch (err) {
      console.log('[FCM] User denied notifications');
      if (existingToken) {
        await remove(
          ref(database, `/fcmTokens/${userId}/${existingToken}`)
        ).catch();
      }
    }
  }
}
