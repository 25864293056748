export const prodProjectId = 'b2wprod-94e64';

export const firebaseCfg = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CFG);

export const mainApiOrigin =
  process.env.NEXT_PUBLIC_MAIN_API_ORIGIN || 'https://localhost:3001';

export const imgProxy = {
  origin: process.env.NEXT_PUBLIC_IMGPROXY_URL
};

export const publicEnv = {
  gmaps: {
    apiKey: process.env.NEXT_PUBLIC_GMAPS_API_KEY,
    defaultCenter: { lat: 55.40115249999999, lng: 10.3868776 },
    defaultZoom: 10
  },
  firebase: {
    fcmVapidKey: process.env.NEXT_PUBLIC_FCM_VAPID_KEY,
    config: firebaseCfg
  },
  stripe: {
    apiKey: process.env.NEXT_PUBLIC_STRIPE_API_KEY
  },
  paypal: {
    clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
    redirectUrl: process.env.NEXT_PUBLIC_PAYPAL_RETURN_URL
  },
  app: {
    hostUrl: process.env.NEXT_PUBLIC_HOST,
    isProd: firebaseCfg.projectId === prodProjectId,
    isDev: firebaseCfg.projectId !== prodProjectId,
    hoursLeftToShowBookingInfoMsg: 4
  },
  emailVerification: {
    timer_sec: 60,
    timer_step_sec: 1
  }
};

export const sharedEnv = {
  paypal: {
    currency: 'EUR'
  },
  stripe: {
    currency: 'EUR'
  },
  paynamics: {
    currency: 'PHP'
  },
  app: {
    ownerFee: 0.2,
    renterFee: 0.07
  },
  company: {
    registeredAt:
      'Harju-maakond, Tallinn, Kesklinna-linnaosa, Juhkentali tn 8, 10132, Estonia. Registration code: 143444789'
  },
  bookings: {
    cancelPolicy: {
      // 72 hours -> 3 days
      hourUpper: 72,
      hourLower: 72
    }
  },
  imgUpload: {
    supportedFileTypes: ['image/jpg', 'image/jpeg', 'image/png'],
    maxFileSize_mb: 10,
    maxFileSize_bytes: 10 * 1024 * 1024,
    emptyAvatar: 'https://www.gravatar.com/avatar/?d=mm&size=130'
  },
  fileUpload: {
    supportedFileTypes: ['file/pdf'],
    maxFileSize_mb: 10,
    maxFileSize_bytes: 10 * 1024 * 1024,
  },
  email: {
    verificationCodeExp_min: 30
  }
};

export const companyName = 'Book2Wheel';

export const widgets = {
  facebookChat: {
    pageId: process.env.NEXT_PUBLIC_FACEBOOK_CHAT_PAGE_ID || ''
  }
};
