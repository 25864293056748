/**
 * @desc Compare and concatenate two strings
 */
export function getCombinedStrings(uid1: string, uid2: string) {
  if (uid1 < uid2) {
    return uid1 + uid2;
  } else {
    return uid2 + uid1;
  }
}

export function getRandomString(numOfChars = 4) {
  // https://stackoverflow.com/a/28997977
  return (0 | (Math.random() * 9 * 10 ** numOfChars)).toString(36);
}

/**
 * @desc Slugify a string
 *
 * @param str target string
 * @param seperator (optional) seperator. Default is _ (underscore)
 */
export function slugify(str: string, seperator = '_') {
  if (!str) {
    return '';
  }

  return str
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(new RegExp(`[^a-z0-9 ${seperator}]`, 'g'), '') // remove all chars not letters, numbers and spaces (to be replaced);
    .replace(/\s+/g, seperator) // replace whitespaces with seperator
    .replace(new RegExp(`${seperator}+`, 'g'), seperator); // collapse multiple separators together
}

/** Get ISO timestamp of current time  */
export const getTimestamp = () => new Date().toISOString();

/** Check if new copy of value was changed and does equal to expected
 * `value`  */
export const wasValueChangedTo = (value: any, oldCopy: any, newCopy: any) =>
  oldCopy !== value && newCopy === value;
