import { RefObject } from 'react';
import { getOwnerDocument, isTabbable } from '@b2w/shared/utility';
import { useUpdateEffect } from './useUpdateEffect';

export interface UseFocusOnHideOptions {
  /**
   * Element that must be focused when popover hides.
   */
  focusRef: RefObject<HTMLElement>;
  /**
   * Indicates whether popover is visible/hidden.
   */
  isVisible?: boolean;
  /**
   * Indicates whether the hook is enabled.
   */
  enabled?: boolean;
}

function preventReturnFocus(containerRef: React.RefObject<HTMLElement>) {
  const node = containerRef.current;
  if (!node) {
    return false;
  }

  const activeElement = getOwnerDocument(node).activeElement;

  // if there is no focused element on the page
  if (!activeElement) return false;
  // if focus is ON or WITHIN container element
  if (node === activeElement || node.contains(activeElement)) return false;
  // if focused element is tabbable
  if (isTabbable(activeElement as any)) return true;

  return false;
}

/**
 * React hook to manage focus when the popover closes or hides.
 *
 * We either want to return focus back to the popover trigger or
 * let focus proceed normally if user moved to another interactive
 * element in the viewport.
 */
export function useFocusOnHide(
  containerRef: RefObject<HTMLElement>,
  options: UseFocusOnHideOptions
) {
  const { enabled, isVisible, focusRef } = options;

  const shouldFocus = enabled && !isVisible;

  useUpdateEffect(() => {
    if (!shouldFocus) return;
    if (preventReturnFocus(containerRef)) return;

    const node = focusRef?.current || containerRef.current;

    if (node) {
      node.focus();
    }
  }, [shouldFocus, containerRef, focusRef]);
}
