import { useState, useCallback } from 'react';

export function useToggler(
  initialState: boolean
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return [isOpen, toggle, setIsOpen];
}
