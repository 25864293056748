import * as React from 'react';

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
export function getValidChildren<T = any>(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) =>
    React.isValidElement(child)
  ) as React.ReactElement<T>[];
}

/**
 * Recursively find all children based on condition
 *
 * @param children the children
 */
export function deepFindAllChildren<T = React.ReactElement>(
  children: React.ReactNode,
  finder: (child: React.ReactElement) => boolean,
  map?: <T>(child: React.ReactElement) => T
): T[] {
  return React.Children.toArray(children).reduce<T[]>((acc, child) => {
    if (!React.isValidElement(child)) return acc;

    const val = finder(child)
      ? acc.concat((map ? map(child) : child) as T)
      : acc;

    if (child.props.children) {
      return val.concat(deepFindAllChildren(child.props.children, finder, map));
    }

    return val;
  }, []);
}
