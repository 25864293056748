import React, {
  useReducer,
  useContext,
  createContext,
  Context,
  useCallback
} from 'react';
import {
  Alert,
  AlertAction,
  AlertState,
  SetAlert,
  DispatchAlert,
  AlertClickAction
} from '@/components/Alert/alert.types';
import { getRandom } from '@b2w/shared/utility';

const alertReducer = (state: Alert[], action: AlertAction): Alert[] => {
  switch (action.type) {
    case 'set':
      return [...state, action.payload];
    case 'remove':
      return state.filter((alert: Alert) => alert.id !== action.payload);
    default:
      return state;
  }
};

const AlertStateContext: Context<AlertState> = createContext([] as AlertState);
const AlertSetContext: Context<SetAlert> = createContext({} as SetAlert);
const AlertDispatchContext: Context<DispatchAlert> = createContext(
  {} as DispatchAlert
);

const AlertProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [alerts, dispatch] = useReducer(alertReducer, []);

  const setAlert = useCallback(
    (
      msg: string,
      type: string,
      timeout = 5000,
      clickAction?: AlertClickAction
    ) => {
      const id = getRandom(0, 1000);
      const alert: Alert = { id, msg, type, timeout, clickAction };

      dispatch({
        type: 'set',
        payload: alert
      });

      if (timeout > 0) {
        setTimeout(() => {
          dispatch({ type: 'remove', payload: id });
        }, timeout);
      }

      return id;
    },
    []
  );

  return (
    <AlertStateContext.Provider value={alerts}>
      <AlertSetContext.Provider value={setAlert}>
        <AlertDispatchContext.Provider value={dispatch}>
          {children}
        </AlertDispatchContext.Provider>
      </AlertSetContext.Provider>
    </AlertStateContext.Provider>
  );
};

const useAlert = () => useContext(AlertStateContext);
const useSetAlert = () => useContext(AlertSetContext);
const useAlertDispatch = () => useContext(AlertDispatchContext);

export { AlertProvider, useAlert, useSetAlert, useAlertDispatch };
