import { forwardRef, useState } from 'react';
import { IconHalfStar, IconSize, IconStar } from '../Icon';
import { ComponentPropsWithRef } from '../types';
import { Tooltip } from '../Tooltip';
import { twCx } from '../twMerge';

export type RatingOwnProps = {
  size?: IconSize;
  numOfStars?: number;
  /** Number which reflects how many stars are `selected` */
  rating?: number;
  /** If passed, rating is selectable */
  onRate?: (numOfSelectedStars: number) => void;
  /** Show tooltip on hover */
  title?: string;
};

export type RatingProps = ComponentPropsWithRef<'div', RatingOwnProps>;

const COLOR_SELECTED = 'tw-text-warning-400';
const COLOR_HOVER = 'tw-text-warning-700';
const COLOR_NOT_SELECTED = 'tw-text-gray-100';
const PRECISION = 0.5;

export const Rating = forwardRef<HTMLDivElement, RatingProps>((props, ref) => {
  const {
    size,
    onRate,
    numOfStars = 5,
    rating,
    title = '',
    ...htmlProps
  } = props;

  const [hoveredStar, setHoveredStar] = useState(-1);

  return (
    <Tooltip.Conditional arrow distancePx={12} showWhen={!!title} title={title}>
      <div
        ref={ref}
        {...htmlProps}
        className={twCx('tw-inline-flex', htmlProps.className)}
      >
        {[...Array(numOfStars).fill(null)].map((_, pos) => {
          const isMarked = rating && pos < rating;
          const isInHoverMarkChain = onRate && pos <= hoveredStar;
          const showHalfStar =
            !isInHoverMarkChain && isMarked && rating - pos <= PRECISION;

          let starClassName = COLOR_NOT_SELECTED;

          if (onRate && hoveredStar >= 0) {
            starClassName = isInHoverMarkChain
              ? COLOR_HOVER
              : COLOR_NOT_SELECTED;
          } else if (isMarked) {
            starClassName = COLOR_SELECTED;
          }

          return (
            <div
              key={pos}
              onClick={() => {
                onRate?.(pos + 1);
              }}
              className={twCx(
                'tw-relative tw-flex tw-items-center',
                !!onRate && 'tw-cursor-pointer'
              )}
              onMouseOver={
                onRate
                  ? () => {
                      setHoveredStar(pos);
                    }
                  : undefined
              }
              onMouseLeave={
                onRate
                  ? () => {
                      setHoveredStar(-1);
                    }
                  : undefined
              }
            >
              {showHalfStar ? (
                <>
                  <IconHalfStar
                    className={twCx('tw-absolute', COLOR_SELECTED)}
                    size={size}
                  />
                  <IconStar className={twCx(COLOR_NOT_SELECTED)} size={size} />
                </>
              ) : (
                <IconStar className={twCx(starClassName)} size={size} />
              )}
            </div>
          );
        })}
      </div>
    </Tooltip.Conditional>
  );
});
