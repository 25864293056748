import React, {
  createContext,
  forwardRef,
  useContext,
  useRef,
  useState
} from 'react';
import { callAllHandlers } from '@b2w/shared/utility';
import {
  ComponentPropsWithRef,
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { IconEllipsisHorizontal } from '../Icon';
import { twCx } from '../twMerge';
import { Button, ButtonProps } from '../Button';

type BreadcrumbsCtxValue = {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const BreadcrumbsCtx = createContext<BreadcrumbsCtxValue>(null as any);
const useBreadcrumbsCtx = () => useContext<BreadcrumbsCtxValue>(BreadcrumbsCtx);

export type BreadcrumbsItemProps = ComponentPropsWithRef<'li'>;

const BreadcrumbsItem = forwardRef(
  (props: BreadcrumbsItemProps, ref: React.ForwardedRef<HTMLLIElement>) => {
    return (
      <li ref={ref} {...props}>
        {props.children}
      </li>
    );
  }
);

export type BreadcrumbsCollapseButtonProps = Omit<ButtonProps<'button'>, 'as'>;

const BreadcrumbsCollapseButton = forwardRef(
  (
    props: BreadcrumbsCollapseButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const { setIsExpanded } = useBreadcrumbsCtx();

    return (
      <Button
        aria-label="Show path"
        variant="solid"
        colorScheme="neutral"
        {...props}
        ref={ref}
        className={twCx('tw-flex tw-px-1 tw-h-5', props.className)}
        type="button"
        onClick={callAllHandlers(props.onClick, () => setIsExpanded(true))}
      >
        {props.children ?? <IconEllipsisHorizontal size="lg" />}
      </Button>
    );
  }
);

export type BreadcrumbsOwnProps = {
  /**
   * Custom separator
   * @default '/'
   */
  separator?: React.ReactNode;
  /**
   * Whether to attach separator as last element
   * @default false
   */
  separatorAsLastItem?: boolean;
  /**
   * If max items is exceeded, the number of items to show after the ellipsis.
   * @default 1
   */
  itemsAfterCollapse?: number;
  /**
   * If max items is exceeded, the number of items to show before the ellipsis.
   * @default 1
   */
  itemsBeforeCollapse?: number;
  /**
   * Specifies the maximum number of breadcrumbs to display. When there are more
   * than the maximum number, only the first `itemsBeforeCollapse` and last `itemsAfterCollapse`
   * will be shown, with an ellipsis in between.
   * @default 8
   */
  maxItems?: number;
  /**
   * Custom renderer for button to collapse Breadcrumbs
   *
   * @example
   * renderCollapseButton={() => (
   *   <Breadcrumbs.CollapseButton className="overrides">
   *     <IconSomething />
   *   </Breadcrumbs.CollapseButton>
   * )}
   */
  renderCollapseButton?: () => React.ReactElement;
};

export type BreadcrumbsProps<C extends React.ElementType = 'nav'> =
  PolymorphicComponentPropsWithRef<C, BreadcrumbsOwnProps>;

type BreadcrumbsComponent = <C extends React.ElementType = 'nav'>(
  props: BreadcrumbsProps<C>
) => JSX.Element;

function insertSeparators(
  items: React.JSX.Element[],
  separator: React.ReactNode,
  separatorAsLastItem: boolean
) {
  const size = separatorAsLastItem ? items.length : items.length - 1;

  return items.reduce<React.JSX.Element[]>((acc, current, index) => {
    if (index < size) {
      acc = acc.concat(
        current,
        <BreadcrumbsItem
          aria-hidden
          key={`separator-${index}`}
          className="tw-flex tw-select-none tw-mx-2"
        >
          {separator}
        </BreadcrumbsItem>
      );
    } else {
      acc.push(current);
    }

    return acc;
  }, []);
}

const BreadcrumbsRoot: BreadcrumbsComponent = forwardRef(
  <T extends React.ElementType = 'nav'>(
    props: PolymorphicComponentProps<T, BreadcrumbsOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      as: Component = 'nav',
      separator = '/',
      separatorAsLastItem = false,
      itemsAfterCollapse = 1,
      itemsBeforeCollapse = 1,
      maxItems = 8,
      children,
      renderCollapseButton,
      ...htmlProps
    } = props;

    const listRef = useRef<HTMLOListElement>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    const allItems = React.Children.toArray(children).map((child, index) => (
      <React.Fragment key={`item-${index}`}>{child}</React.Fragment>
    ));

    const renderItemsBeforeAndAfter = () => {
      if (itemsBeforeCollapse + itemsAfterCollapse >= allItems.length) {
        console.error(
          [
            '(Breadcrumbs): Invalid combination of props.',
            `itemsAfterCollapse={${itemsAfterCollapse}} + itemsBeforeCollapse={${itemsBeforeCollapse}} >= maxItems={${maxItems}}`,
            'Rendering all items instead.'
          ].join('\n')
        );

        return allItems;
      }

      return [
        ...allItems.slice(0, itemsBeforeCollapse),
        <BreadcrumbsItem key="collapse">
          {typeof renderCollapseButton === 'function' ? (
            renderCollapseButton()
          ) : (
            <BreadcrumbsCollapseButton />
          )}
        </BreadcrumbsItem>,
        ...allItems.slice(allItems.length - itemsAfterCollapse, allItems.length)
      ];
    };

    return (
      <BreadcrumbsCtx.Provider value={{ setIsExpanded }}>
        <Component
          {...htmlProps}
          ref={ref}
          className={twCx('tw-text-gray-500', htmlProps.className)}
        >
          <ol
            ref={listRef}
            className="tw-flex tw-flex-wrap tw-items-center tw-p-0 tw-m-0"
          >
            {insertSeparators(
              isExpanded || maxItems >= allItems.length
                ? allItems
                : renderItemsBeforeAndAfter(),
              separator,
              separatorAsLastItem
            )}
          </ol>
        </Component>
      </BreadcrumbsCtx.Provider>
    );
  }
) as BreadcrumbsComponent;

export const Breadcrumbs = Object.assign(BreadcrumbsRoot, {
  Item: BreadcrumbsItem,
  CollapseButton: BreadcrumbsCollapseButton
});
