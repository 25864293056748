import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { useEffect } from 'react';
import { AuthUser, UserClaimsDoc } from '@b2w/shared/types';
import { useHandleUserPresence } from '@/custom-hooks/profile/useHandleUserPresence';
import { useHandleFcm } from '@/custom-hooks/profile/useHandleFcm';
import { useSetAlert } from './alert.context';
import FullWindowSpinner from '@/components/FullWindowSpinner';
import Router, { useRouter } from 'next/router';
import { appendRedirectQueryString } from '@/lib/client-side.redirects';
import { authService, User } from '@/lib/services/auth.service';
import { browserStorageService } from '@/lib/services/browser-storage.service';
import {
  LocalStorageKeyEnum,
  SessionStorageKeyEnum
} from '@/lib/browser-storage.keys';
import {
  clearUserForExceptionReport,
  setUserForExceptionReport
} from '@/lib/exception-reporter';
import { resolveAppHref } from '@/components/AppLink';
import { AFF_REF_PARAM_NAME } from '@/components/affiliate-dashboard/affiliate.param';
import { profileService } from '@/lib/services/profile.service';
import { SignupEvent, gtmDataLayer } from '@/components/analytics/gtm-events';
import { asyncLoadFirestore } from '@/lib/firebase/loaders';
import { FireCollection } from '@repo/firestore-collections';

type UserState = {
  user: AuthUser | null;
  isAlreadyLoggedIn: boolean;
  isUserLoading: boolean;
  isHandlingRedirect: boolean;
};

type UserContext = UserState & {
  isLoggedOut: boolean;
};

type SetUserContext = (authUser?: User) => void;

const userContext = createContext<UserContext>({} as UserContext);
const setUserContext = createContext<SetUserContext>({} as SetUserContext);
export const useUser = () => useContext(userContext);
export const useSetUser = () => useContext(setUserContext);
export const useUserRedirect = (to = '/signup') => {
  const user = useUser();

  useEffect(() => {
    if (user.isLoggedOut) {
      Router.replace(
        resolveAppHref(appendRedirectQueryString(to), Router.query)
      );
    }
  }, [user.isLoggedOut, to]);

  return user;
};
export const useUserPublicRedirect = () => {
  const context = useUser();
  const { user } = context;

  useEffect(() => {
    if (user) {
      const queryStr = Router.query['next'];
      if (queryStr && typeof queryStr === 'string') {
        Router.replace(queryStr);
      } else {
        Router.replace(resolveAppHref('/dashboard', Router.query));
      }
    }
  }, [user]);

  return context;
};

export const UserProvider = ({ children }) => {
  const router = useRouter();
  const isRouterReady = !!router?.isReady;
  const referrerIdParam = router?.query[AFF_REF_PARAM_NAME] as string;
  const [state, setState] = useState<UserState>({
    isUserLoading: true,
    user: null,
    isHandlingRedirect: false,
    isAlreadyLoggedIn: false
  });
  const userSubsRef = useRef<(() => void)[]>([]);
  const isLoggedOut = !state.user && !state.isUserLoading;
  const uid = state.user?.uid;

  const setAlert = useSetAlert();
  useHandleUserPresence(uid);
  useHandleFcm(uid);

  const setUser = useCallback((authUser?: User) => {
    setState((p) => ({
      ...p,
      isHandlingRedirect: false,
      isUserLoading: false,
      user: authUser
        ? {
            uid: authUser.uid,
            emailVerified: authUser.emailVerified,
            email: authUser.email,
            phoneNumber: authUser.phoneNumber,
            providerData: authUser.providerData
          }
        : null
    }));

    if (browserStorageService.canUseLocalStorage()) {
      if (authUser) {
        localStorage.setItem(LocalStorageKeyEnum.AUTH_USER_ID, authUser.uid);
      } else {
        localStorage.removeItem(LocalStorageKeyEnum.AUTH_USER_ID);
      }
    }

    if (authUser) {
      setUserForExceptionReport(authUser.uid);
    } else {
      clearUserForExceptionReport();

      userSubsRef.current.forEach((unsub) => {
        unsub && unsub();
      });
    }
  }, []);

  useEffect(() => {
    if (!isRouterReady) {
      return;
    }

    const canUseLS = browserStorageService.canUseLocalStorage();

    const isAuthRedirect = canUseLS
      ? ['signin', 'signup'].includes(
          sessionStorage.getItem(SessionStorageKeyEnum.AUTH_REDIRECT)
        )
      : false;
    const isUserLoggedIn = canUseLS
      ? !!localStorage.getItem(LocalStorageKeyEnum.AUTH_USER_ID)
      : false;

    if (isAuthRedirect) {
      // handle auth redirect
      setState((p) => ({
        ...p,
        isHandlingRedirect: true,
        isAlreadyLoggedIn: isUserLoggedIn
      }));
      authService
        .getRedirectResult()
        .then(async (credential) => {
          if (credential) {
            const { isNewUser } = await authService.getAdditionalUserInfo(
              credential
            );

            if (credential.user && isNewUser && referrerIdParam) {
              // if redirect successful, pass client-side data to profile creation
              // cloud function will pick-up data and create profile for user
              await profileService
                .createProfileForAuthUser({
                  referrerId: referrerIdParam
                })
                .catch(() => {
                  //
                });
            }
          }

          if (!credential) {
            // if no credential, do other stuff
            canUseLS &&
              sessionStorage.removeItem(SessionStorageKeyEnum.AUTH_REDIRECT);
          }

          return credential;
        })
        .then(async (credential) => {
          if (credential) {
            const { isNewUser, providerId } =
              await authService.getAdditionalUserInfo(credential);

            if (isNewUser) {
              const { firstName, lastName } =
                authService.extractFirstLastNameFromDisplayName(
                  credential.user.displayName
                );

              let authProvider: SignupEvent['authProvider'] = 'unknown';

              if (providerId.includes('facebook')) {
                authProvider = 'facebook';
              }
              if (providerId.includes('google')) {
                authProvider = 'google';
              }

              gtmDataLayer.signup({
                authProvider,
                userId: credential.user.uid,
                firstName,
                lastName
              });
            }

            setAlert(
              isNewUser ? 'Successfully signed up' : 'Successfully signed in',
              'success',
              4000
            );
            setUser(credential.user);
          } else {
            setUser(null);
          }
          canUseLS &&
            sessionStorage.removeItem(SessionStorageKeyEnum.AUTH_REDIRECT);
        })
        .catch((err) => {
          setUser();
          setAlert(err.message, 'danger', 6000);
        });
    } else {
      // get already authenticated user
      setState((p) => ({
        ...p,
        isAlreadyLoggedIn: isUserLoggedIn
      }));

      let unsub: () => any;

      authService
        .onAuthStateChanged(
          (authObj) => {
            unsub && unsub();
            if (authObj) {
              setUser(authObj);
              listenAndRefreshUserClaims(authObj.uid).then((unsubscribe) => {
                userSubsRef.current.push(unsubscribe);
              });
            } else {
              setUser();
            }
          },
          (err) => {
            unsub && unsub();
            setAlert(err.message, 'danger', 10000);
          }
        )
        .then((unsubscribe) => {
          unsub = unsubscribe;
        });
    }
  }, [setUser, setAlert, isRouterReady, referrerIdParam]);

  return (
    <>
      <FullWindowSpinner
        isVisible={state.isHandlingRedirect}
        text="Signing in..."
      />
      <setUserContext.Provider value={setUser}>
        <userContext.Provider value={{ ...state, isLoggedOut }}>
          {children}
        </userContext.Provider>
      </setUserContext.Provider>
    </>
  );
};

// listens for user claims collection and refreshes auth token on demand
async function listenAndRefreshUserClaims(userId: string) {
  const { firestore, onSnapshot, doc } = await asyncLoadFirestore();

  let lastCommitted: UserClaimsDoc['_lastCommitted'] | undefined;

  return onSnapshot(
    doc(firestore, FireCollection.userClaims.path(), userId),
    async (snap) => {
      const data = snap.data() as UserClaimsDoc;

      if (data && data._lastCommitted) {
        if (lastCommitted && !data._lastCommitted.isEqual(lastCommitted)) {
          console.log('Force refresh user token');
          await authService.getAuthUser().then((user) => user.getIdToken(true));
        }
        lastCommitted = data._lastCommitted;
      }
    },
    (err) => {
      console.log('[ERR REFRESH CLAIMS]', err.message);
    }
  );
}
