import { mapReportTypeToLabel } from '@/lib/data-to-label.mappers';
import { getRelativeTime } from '@/lib/dayjs.common';
import { bellService } from '@/lib/services/bell.service';
import {
  B2wApiError,
  BellNotificationItemModel,
  BellNotificationType,
  BookingForBellEntry,
  GetBellNotificationsQueryDTO,
  ProfileReport,
  ProfileReviewModel,
  ProfileSuspension,
  SupportInboxHistoryItemModel,
  SupportInboxHistoryItemType
} from '@b2w/shared/types';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import useSWR from 'swr';
import { twCx } from '@b2w/react-ui/core2';
import LinkToPrivate from '../LinkToPrivate';
import ShortFormLoader from '../loaders/ShortFormLoader';
import SecondaryBtn from '../SecondaryBtn';
import React from 'react';
import { useBellNotificationsCount } from './useBellNotificationsCount';
import AppLink from '../AppLink';
import { useToggler } from '@/custom-hooks/useToggler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useBellList = (options?: GetBellNotificationsQueryDTO) => {
  const { data, error, mutate } = useSWR<
    BellNotificationItemModel[],
    B2wApiError,
    [string, number | undefined, boolean | undefined]
  >(
    ['bellService.getNotificationsList', options?.limit, options?.isUnread],
    ([_key, limit, isUnread]) =>
      bellService.getNotificationsList({
        limit,
        isUnread
      })
  );
  const isLoading = !data && !error;

  return {
    notifications: data,
    notificationsError: error,
    isNotificationsLoading: isLoading,
    mutateBellList: mutate
  };
};

type BellItemLayoutProps = {
  createdAt: string;
  children: React.ReactNode;
  isUnread: boolean;
  onReadUnreadClick: () => any;
  onRemoveClick: () => any;
};

const BellItemLayout = ({
  createdAt,
  children,
  isUnread,
  onReadUnreadClick,
  onRemoveClick
}: BellItemLayoutProps) => {
  const [isOpened, toggle] = useToggler(false);

  return (
    <div className="d-flex">
      <div className="flex-fill">
        <div className={twCx(isUnread ? 'text-dark' : 'text-secondary-dark')}>
          {isUnread && (
            <span
              className="bg-primary-light rounded-circle d-inline-block mr-2"
              style={{ width: 10, height: 10 }}
            />
          )}
          {children}
        </div>
        <div
          className={twCx(
            'mt-1 small',
            isUnread
              ? 'font-weight-500 text-primary-light'
              : 'text-secondary-dark'
          )}
        >
          {getRelativeTime(createdAt, 'en')}
        </div>
      </div>

      <div className="ml-2 d-flex align-items-center justify-content-center">
        <Dropdown
          isOpen={isOpened}
          toggle={toggle}
          className="d-flex align-items-center"
        >
          <DropdownToggle
            tag="div"
            data-toggle="dropdown"
            aria-expanded={isOpened}
            className="cursor-pointer"
          >
            <SecondaryBtn className="btn-sm rounded-circle text-secondary-dark">
              <FontAwesomeIcon icon="ellipsis-h" />
            </SecondaryBtn>
          </DropdownToggle>

          <DropdownMenu right className="p-2" style={{ minWidth: 175 }}>
            <SecondaryBtn
              className="d-block w-100 d-flex align-items-center mb-2"
              onClick={() => {
                onReadUnreadClick();
                toggle();
              }}
            >
              <FontAwesomeIcon icon="check" size="xs" className="mr-2" /> Mark
              as {isUnread ? 'read' : 'unread'}
            </SecondaryBtn>
            <SecondaryBtn
              className="d-block w-100 d-flex align-items-center"
              onClick={() => {
                onRemoveClick();
                toggle();
              }}
            >
              <FontAwesomeIcon icon="times" size="xs" className="mr-2" /> Remove
            </SecondaryBtn>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

const DetailsLink = ({ href, children, onClick }) => (
  <AppLink href={href} className="link-unstyled" onClick={onClick}>
    {children}
  </AppLink>
);

const Notificationtitle = ({ children, isUnread }) => (
  <div className={twCx('d-inline', isUnread && 'font-weight-500')}>
    {children}
  </div>
);

type BellItemProps = {
  item: BellNotificationItemModel;
  onReadUnread: (item: BellNotificationItemModel) => any;
  onMarkAsRead: (item: BellNotificationItemModel) => any;
  onRemove: (item: BellNotificationItemModel) => any;
  rounded?: boolean;
};

const BellItem = ({
  item,
  onReadUnread,
  onMarkAsRead,
  onRemove,
  rounded
}: BellItemProps) => {
  const handleMarkAsRead = () => {
    if (item.isUnread) {
      onMarkAsRead(item);
    }
  };

  const renderItem = () => {
    if (item.type === BellNotificationType.supportInboxEntry) {
      const data = item.data as SupportInboxHistoryItemModel;

      switch (data.type) {
        case SupportInboxHistoryItemType.report: {
          const report = data.data as ProfileReport;

          return (
            <DetailsLink
              href="/support?tab=VIOLATIONS"
              onClick={handleMarkAsRead}
            >
              <Notificationtitle isUnread={item.isUnread}>
                You received a report for{' '}
                <u>{mapReportTypeToLabel(report.reason.type)}</u>
              </Notificationtitle>
            </DetailsLink>
          );
        }

        case SupportInboxHistoryItemType.suspension: {
          const suspension = data.data as ProfileSuspension;

          return (
            <DetailsLink href="/support" onClick={handleMarkAsRead}>
              <Notificationtitle isUnread={item.isUnread}>
                You have been suspended for{' '}
                <u>{mapReportTypeToLabel(suspension.reason.type)}</u>. During
                suspension you are able to access Book2Wheel in read-only mode
              </Notificationtitle>
            </DetailsLink>
          );
        }

        case SupportInboxHistoryItemType.suspensionRemoval: {
          return (
            <DetailsLink href="/support" onClick={handleMarkAsRead}>
              <Notificationtitle isUnread={item.isUnread}>
                Your latest suspension has been removed
              </Notificationtitle>
            </DetailsLink>
          );
        }

        case SupportInboxHistoryItemType.message: {
          return item.isAdmistrative ? (
            <DetailsLink
              href="/_admin/support-inbox"
              onClick={handleMarkAsRead}
            >
              <Notificationtitle isUnread={item.isUnread}>
                (Administrative) New unread message in the Support Centre
              </Notificationtitle>
            </DetailsLink>
          ) : (
            <DetailsLink href="/support?tab=CHAT" onClick={handleMarkAsRead}>
              <Notificationtitle isUnread={item.isUnread}>
                New unread messages in Book2Wheel Support Centre
              </Notificationtitle>
            </DetailsLink>
          );
        }
      }
    }

    if (item.type === BellNotificationType.newBooking) {
      const booking = item.data as BookingForBellEntry;

      return (
        <DetailsLink href={`/booking/${booking.id}`} onClick={handleMarkAsRead}>
          <Notificationtitle isUnread={item.isUnread}>
            Incoming booking for {booking.vehicle.brand} {booking.vehicle.model}
          </Notificationtitle>
        </DetailsLink>
      );
    }

    if (item.type === BellNotificationType.profileReview) {
      const review = item.data as ProfileReviewModel;

      return (
        <DetailsLink href={`/reviews/${review.id}`} onClick={handleMarkAsRead}>
          <Notificationtitle isUnread={item.isUnread}>
            You received a new review
          </Notificationtitle>
        </DetailsLink>
      );
    }

    return null;
  };

  return (
    <div className="bell-item-wrapper px-2 py-2 cursor-pointer">
      <style jsx>
        {`
          .bell-item-wrapper:not(:last-child) {
            margin-bottom: 5px;
          }

          .bell-item-wrapper {
            transition: background-color 0.05s;
            border-radius: ${rounded ? '6px' : '0px'};
          }

          .bell-item-wrapper:hover {
            background-color: #f1f1f1;
          }
        `}
      </style>

      <BellItemLayout
        createdAt={item.createdAt}
        isUnread={item.isUnread}
        onReadUnreadClick={() => onReadUnread(item)}
        onRemoveClick={() => onRemove(item)}
      >
        {renderItem()}
      </BellItemLayout>
    </div>
  );
};

type BellListProps = {
  limit?: number;
  /** @default false */
  showSeeAllButton?: boolean;
  /** @default false */
  roundedListItems?: boolean;
};

export default function BellList({
  limit = 5,
  showSeeAllButton = false,
  roundedListItems = false
}: BellListProps) {
  const { notifications, notificationsError, mutateBellList } = useBellList({
    limit
  });
  const { mutateNotificationsCount } = useBellNotificationsCount();

  const onRemove = async (item: BellNotificationItemModel) => {
    try {
      await bellService.deleteNotificationById(item.id);
      mutateBellList<BellNotificationItemModel[]>((data) =>
        data.filter((n) => n.id !== item.id)
      );
      mutateNotificationsCount<number>(
        (data) => (data !== undefined ? data - 1 : data),
        { revalidate: false }
      );
    } catch (err) {
      //
    }
  };

  const onReadUnread = async (item: BellNotificationItemModel) => {
    try {
      await bellService.markNotification({
        id: item.id,
        isUnread: !item.isUnread
      });
      mutateBellList();
      mutateNotificationsCount();
    } catch (err) {
      //
    }
  };

  const onMarkAsRead = async (item: BellNotificationItemModel) => {
    try {
      await bellService.markNotification({
        id: item.id,
        isUnread: false
      });
      mutateBellList();
      mutateNotificationsCount<number>(
        (data) => (data !== undefined ? data - 1 : data),
        { revalidate: false }
      );
    } catch (err) {
      //
    }
  };

  const onMarkAllAsRead = async () => {
    try {
      await bellService.markAllAsRead();
      mutateBellList<BellNotificationItemModel[]>(
        (data) => data.map((n) => ({ ...n, isUnread: false })),
        {
          revalidate: false
        }
      );
      mutateNotificationsCount(0, { revalidate: false });
    } catch (err) {
      //
    }
  };

  const onMarkAllAsUnread = async () => {
    try {
      await bellService.markAllAsUnread();
      mutateBellList<BellNotificationItemModel[]>(
        (data) => data.map((n) => ({ ...n, isUnread: true })),
        {
          revalidate: false
        }
      );
      mutateNotificationsCount(notifications?.length ?? 0);
    } catch (err) {
      //
    }
  };

  const onClearAll = async () => {
    try {
      await bellService.clearAll();
      mutateBellList([], {
        revalidate: false,
        optimisticData: [],
        populateCache: true
      });
      mutateNotificationsCount(0, { revalidate: false });
    } catch (err) {
      //
    }
  };

  if (notificationsError) {
    return <div>{notificationsError.message}</div>;
  }

  if (notifications) {
    return (
      <div>
        <div className="px-2">
          <div className="d-flex justify-conent-between rounded-lg mb-3">
            <SecondaryBtn
              className="btn-sm mr-2"
              disabled={!notifications.some((n) => n.isUnread)}
              onClick={onMarkAllAsRead}
            >
              Mark all as read
            </SecondaryBtn>
            <SecondaryBtn
              className="btn-sm mr-2"
              disabled={notifications.every((n) => n.isUnread)}
              onClick={onMarkAllAsUnread}
            >
              Mark all as unread
            </SecondaryBtn>
            <SecondaryBtn
              className="btn-sm"
              disabled={notifications.length === 0}
              onClick={onClearAll}
            >
              Clear all
            </SecondaryBtn>
          </div>

          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="font-weight-500 small">Recent</div>
            {showSeeAllButton && (
              <LinkToPrivate
                href="/bell-notifications"
                passHref
                className="link-unstyled"
              >
                <Button size="sm" color="link" className="py-0">
                  See all
                </Button>
              </LinkToPrivate>
            )}
          </div>
        </div>

        {notifications.length > 0 ? (
          <div className="d-flex flex-column">
            {notifications.map((n) => (
              <React.Fragment key={n.id}>
                <BellItem
                  item={n}
                  onReadUnread={onReadUnread}
                  onMarkAsRead={onMarkAsRead}
                  onRemove={onRemove}
                  rounded={roundedListItems}
                />
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className="text-secondary-dark px-2">
            No recent notifications
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="text-center p-2">
      <ShortFormLoader
        uniqueKey="addcard-loader"
        preserveAspectRatio="none"
        style={{ height: 140, width: '100%' }}
      />
    </div>
  );
}
