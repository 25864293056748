import AppLink from '@/components/AppLink';

type Props = {
  onLinkClick?: () => any;
  showSupportLink?: boolean;
  title?: string;
};

const YourAccSuspendedMsg = ({
  onLinkClick,
  title = 'Account suspended',
  showSupportLink = true
}: Props) => {
  return (
    <div>
      <div className="font-weight-500 text-danger">{title}</div>
      {showSupportLink && (
        <div className="small text-secondary-dark">
          Visit{' '}
          <AppLink href="/support" onClick={onLinkClick}>
            <b>Help & Support</b>
          </AppLink>{' '}
          for more information
        </div>
      )}
    </div>
  );
};

export default YourAccSuspendedMsg;
