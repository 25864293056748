import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * `useEffect` that runs only on update. It doesn't run on mount.
 */
export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      return effect();
    }

    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return mounted.current;
};
