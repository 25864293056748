import { Children, forwardRef } from 'react';
import { IconProps, Icon } from './Icon';

export type CreateIconOptions = Omit<IconProps, 'path'> & {
  pathOrChildren?: string | JSX.Element;
};

/**
 * Create specific icon component which composes base `Icon` component
 */
export function createIcon(options: CreateIconOptions) {
  const { viewBox, pathOrChildren, ...htmlProps } = options;

  const children =
    typeof pathOrChildren === 'string' ? (
      <path fill="currentColor" d={pathOrChildren} />
    ) : (
      Children.toArray(pathOrChildren)
    );

  const Comp = forwardRef<SVGSVGElement, Omit<IconProps, 'viewBox'>>(
    (props, ref) => (
      <Icon {...htmlProps} {...props} ref={ref} viewBox={viewBox}>
        {children}
      </Icon>
    )
  );

  return Comp;
}
