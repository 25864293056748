import { useRef } from 'react';
import { twCx } from '../twMerge';
import { ModalProps, Modal } from '../Modal';
import { Button, ButtonColorScheme } from '../Button';
import { IconInfo, IconWarning, createIcon } from '../Icon';

export type PromptDialogPreset = 'warning' | 'alert' | 'info';

export interface PromptDialogProps
  extends Pick<ModalProps, 'close' | 'isOpen' | 'children' | 'id'> {
  cancelBtnText?: string;
  approveBtnText?: string;
  /**
   * @default true
   */
  titleIcon?: boolean;
  /**
   * Modal title
   */
  title?: string;
  /**
   * Callback to fire when submit button is clicked and modal starts to close
   */
  onApproved?: () => any;
  /**
   * Color theme of the dialog
   *
   * @default 'alert'
   */
  preset?: PromptDialogPreset;
}

type PresetConfig = {
  approveBtnColor: ButtonColorScheme;
  TitleIcon: ReturnType<typeof createIcon>;
  titleIconClassName: string;
};

// TODO ICONS
const presetToConfig: Record<PromptDialogPreset, PresetConfig> = {
  alert: {
    approveBtnColor: 'error',
    TitleIcon: IconWarning,
    titleIconClassName: 'tw-text-error-500'
  },
  info: {
    approveBtnColor: 'dark',
    TitleIcon: IconInfo,
    titleIconClassName: 'tw-text-dark-500'
  },
  warning: {
    approveBtnColor: 'warning',
    TitleIcon: IconWarning,
    titleIconClassName: 'tw-text-warning-500'
  }
};

export const PromptDialog = (props: PromptDialogProps) => {
  const {
    title = 'Are you sure?',
    cancelBtnText = 'Cancel',
    approveBtnText = 'Continue',
    preset = 'alert',
    titleIcon = true,
    children,
    close,
    onApproved,
    ...modalProps
  } = props;

  const cancelBtnRef = useRef<HTMLButtonElement>(null);

  const presetCfg = presetToConfig[preset];

  const handleApproveClick = async () => {
    close();
    onApproved?.();
  };

  return (
    <Modal
      {...modalProps}
      close={close}
      hasCloseButton={false}
      initialFocusRef={cancelBtnRef}
    >
      <Modal.Header className="tw-flex tw-items-center">
        {titleIcon && (
          <presetCfg.TitleIcon
            className={twCx('tw-mr-2', presetCfg.titleIconClassName)}
            size="xl"
          />
        )}
        {title}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="tw-gap-2">
        <Button ref={cancelBtnRef} colorScheme="inherit" onClick={close}>
          {cancelBtnText}
        </Button>
        <Button
          colorScheme={presetCfg.approveBtnColor}
          onClick={handleApproveClick}
        >
          {approveBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
