import { forwardRef } from 'react';
import { useFormControlCtx } from '../form-control.init';
import { RequiredIndicator } from '../../RequiredIndicator';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../../types';
import { twCx } from '../../twMerge';

export type FormLabelOwnProps = {
  /**
   * Whether to show required indicator.
   */
  requiredIndicator?: boolean;
};

export type FormLabelProps<C extends React.ElementType = 'label'> =
  PolymorphicComponentPropsWithRef<C, FormLabelOwnProps>;

type FormLabelComponent = <C extends React.ElementType = 'label'>(
  props: FormLabelProps<C>
) => JSX.Element;

/**
 * Used to enhance the usability of form controls.
 *
 * It is used to inform users as to what information
 * is requested for a form field.
 *
 * ♿️ Accessibility: Every form field should have a form label.
 */
export const FormLabel: FormLabelComponent = forwardRef(
  <T extends React.ElementType = 'label'>(
    props: PolymorphicComponentProps<T, FormLabelOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      requiredIndicator,
      as: Component = 'label',
      ...restOfPassedProps
    } = props;

    const formControl = useFormControlCtx();

    const labelProps = formControl?.getFormLabelProps(
      restOfPassedProps,
      ref
    ) ?? {
      ...restOfPassedProps,
      ref
    };
    const htmlFor =
      Component !== 'label' ? restOfPassedProps.htmlFor : labelProps.htmlFor;

    const className = twCx(
      'tw-inline-block tw-text-left tw-mb-2 tw-font-medium tw-text-sm',
      labelProps.className
    );

    const mustShowIndicator = requiredIndicator ?? !!formControl?.isRequired;

    return (
      <Component {...labelProps} htmlFor={htmlFor} className={className}>
        {labelProps.children}
        {mustShowIndicator && <RequiredIndicator className="tw-ml-1.5" />}
      </Component>
    );
  }
) as FormLabelComponent;
