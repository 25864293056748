export const bottomSafeSpace = {
  add: (val: string) =>
    document.body.style.setProperty('--bottom-safe-space', val),
  remove: () => document.body.style.removeProperty('--bottom-safe-space')
};

export const navbarSpace = {
  set: (val: string) =>
    document.body.style.setProperty('--top-navbar-height', val)
};
