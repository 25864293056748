import { FirestoreTimestamp } from './firestore';
import { DeepKeyOf, DeepPartial } from './type.helpers';
import { PaynamicsResponseStatus } from './paynamics.xml-types';

type AuthProviderData = {
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
};

export type AuthUser = {
  uid: string;
  email: string | null;
  emailVerified: boolean;
  phoneNumber: string | null;
  providerData: AuthProviderData[];
};

export enum RoleEnum {
  /**
   * Full admin access
   */
  admin = 'admin',
  /**
   * Partial admin access
   */
  agent = 'agent',
  /**
   * Can view `Performance` page only
   */
  viewer = 'viewer',
  /**
   * Can view `Vehicles` page and edit vehicles
   */
  vehicleEditor = 'vehicle_editor',
  /**
   * Can view `Bookings` page only, no export available
   */
  bookingsViewer = 'viewer_bookings',
  /**
   * Can view and edit `CityLanding` only
   */
  cityLandingEditor = 'city_landing_editor'
}

export enum RankEnum {
  silver = 'silver',
  gold = 'gold',
  trustedPartner = 'trusted_partner'
}

export enum PayoutMethodEnum {
  cash = 'cash',
  paypal = 'paypal',
  stripe = 'stripe'
}

export enum PaymentChannelEnum {
  stripe = 'stripe',
  paypal = 'paypal',
  paynamics = 'paynamics'
}

export enum VerificationSource {
  facebook = 'facebook',
  google = 'google',
  phone = 'phone',
  driverLicense = 'driver_license'
}

export type PaypalPayoutAccountInfo = {
  payer_id: string;
  verified_account: boolean;
  primaryEmail: {
    value: string;
    primary: boolean;
    confirmed?: boolean;
  };
};

export type PaypalPayoutShortAccountInfo = {
  email: string;
};

export type ProfileB2wPoints = {
  /** Total points earned from the system */
  earnedTotal: number;
  /** Current points balance */
  earnedCurrent: number;
  /** If present, shows when current balance will be reset (set to 0).
   * @B2wPointsTransactionModel will update this timestamp according to config when transaction `actionType` is `earn` */
  currentBalanceExpiresAt: string | null;
};

export type ProfileRankings = {
  [key in RankEnum]?: boolean;
};

export class ProfileModel {
  id!: string;
  firstName!: string;
  lastName!: string;
  avatar!: string;
  createdAt!: FirestoreTimestamp;
  preferences!: {
    sms: boolean;
    unreadMessageSound: boolean;
  };
  email?: string;
  birthDate?: {
    day: number;
    month: number;
    year: number;
  };
  address?: {
    city?: string;
    address?: string;
    postalCode?: string;
    country?: string;
  };
  nationality?: string;
  phoneNumber?: string;
  /** Secondary phone number is used solely for SMS notifications */
  secondaryPhoneNumber?: string;
  description?: string;
  facebookProfileUrl?: string;
  whatsappContact?: string;
  viberContact?: string;
  roles?: RoleEnum[];
  verifications?: VerificationSource[];
  payoutMethod!: PayoutMethodEnum;
  paypal?: PaypalPayoutAccountInfo;
  stripe?: {
    /** Customer id in Stripe */
    stripe_customer_id: string;
    /** Connected account id in Stripe (used for payouts) */
    stripe_connected_id?: string;
  };
  rankings?: ProfileRankings;

  bookings!: {
    total: number;
    expired: number;
    accepted: number;
    canceledByRenter: number;
    canceledByRenterAfterAccepted: number;
    canceledByOwner: number;
    canceledByOwnerAfterAccepted: number;
    canceledByAdmin: number;
    completed: number;
  };

  rentals!: {
    total: number;
    expired: number;
    accepted: number;
    canceledByRenter: number;
    canceledByRenterAfterAccepted: number;
    canceledByOwner: number;
    canceledByOwnerAfterAccepted: number;
    canceledByAdmin: number;
    completed: number;
  };

  totalReviews!: number;
  avgRating!: number;
  totalVehicles!: number;
  avgResponseTime?: number;

  currentSuspensionId?: string;
  supportInboxId?: string;
  isDisabled?: boolean;
  /** Id of uploaded driver license.
   *
   * Note that the license can be uploaded but NOT verified. For verification, check
   * `verifications` array.  */
  driverLicenseDocId?: string;
  /**
   * Affiliate user id. Points to `AffiliateModel`.
   */
  affiliateId?: string;
  /**
   * Id of affiliate who invited user to sign up. Points to `AffiliateModel`.
   */
  signedUpWithAffiliateId?: string;
  /**
   * Whether profile should be treat as a business
   */
  isBusinessProfile?: boolean;
  /**
   * Whether verifications for bookings must be skipped if this profile acts as owner
   */
  skipRentalOutVerifications?: boolean;
  /**
   * Comments added to the user by admin. Visible only to admin role
   */
  adminComments?: string;
  /** Points that user can use for various discounts */
  b2wPoints!: ProfileB2wPoints;
}

export type ProfileDocumentField = DeepKeyOf<ProfileModel>;

export interface ProfileDTO {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  totalReviews: number;
  totalVehicles: number;
  avgRating: number;
  createdAt: string;
  rentals: {
    owner: number;
    renter: number;
  };
  nationality?: string;
  description?: string;
  verifications?: VerificationSource[];
  payoutMethod: PayoutMethodEnum;
  bookingAcceptanceRate?: number;
  avgResponseTime?: number;
  rankings?: {
    [key in RankEnum]?: boolean;
  };
  isSuspended: boolean;
  isDisabled: boolean;
  isBusinessProfile: boolean;
  isAdmin: boolean;
  personAge?: number;
}

export interface PrivateProfileDTO extends Omit<ProfileModel, 'createdAt'> {
  createdAt: string;
  isProfileCompleted: boolean;
  isBusinessProfile: boolean;
  skipRentalOutVerifications: boolean;
}

export interface CreateProfileDTO {
  firstName?: string;
  lastName?: string;
  referrerId?: string;
}

export interface SetUserPhoneAdminActionOptionsDTO {
  userId: string;
  phoneNumber: string;
}

export interface FormDataFile {
  filepath: string;
  toBuffer: () => Promise<Buffer>;
  file: NodeJS.ReadableStream;
  fieldname: string;
  filename: string;
  encoding: string;
  mimetype: string;
  fileSizeInBytes: number;
}

export type FormDataFiles = Record<string, FormDataFile[]>;

export class EarningsModel {
  id!: string;
  month!: string;
  rentals!: number;
  income!: {
    [currency: string]: number;
  };
}

export type EarningsDocumentField = DeepKeyOf<EarningsModel>;

export type ProfileEarningsDTO = Omit<EarningsModel, 'id'>;

export interface InputValidationError {
  /**
   * Field name that has validation error.
   */
  field: string;
  /**
   * Error description.
   */
  message: string;
}

type CityAirportItem = {
  name: string;
  link: string;
};

type CityFaqItem = {
  question: string;
  answer: string;
};

export class CityLandingModel {
  id!: string;
  slug!: string;
  meta!: {
    title: string;
    description: string;
    ogImage: string;
  };
  header!: {
    title: string;
  };
  cheap!: {
    title: string;
    vehicleIds: string[];
  };
  popular!: {
    title: string;
    vehicleIds: string[];
  };
  map!: {
    title: string;
    center: {
      lat: number;
      lng: number;
    };
    lookupCityNames: string[];
    vehicleCategory: string;
  };
  stats!: {
    title: string;
    popularOwnerUid: string;
    popularType: string;
    goodDeals: ActivityGoodDeal[];
  };
  faq!: {
    title: string;
    questions: CityFaqItem[];
  };
  airports?: {
    title: string;
    list: CityAirportItem[];
  };
  extraText?: string | null;
}

export type GetCityLandingsFilterDTO = {
  id?: string;
  slug?: string;
  limit?: number;
};

export type GetActivityDataQueryDTO = {
  currency?: string;
};

export type CreateNewCityLandingDTO = Omit<CityLandingModel, 'id'>;

export enum ProfileReviewType {
  booking = 'booking'
}

export type ProfileReviewDataForBooking = {
  bookingId: string;
  renterUid: string;
  ownerUid: string;
};

export type ProfileReviewAuthor = {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
};

export class ProfileReviewModel {
  /** Review id */
  id!: string;
  /** When review was created. Follows extended ISO8601 */
  createdAt!: string;
  /** Id of the user who received review */
  forUserId!: string;
  /** Embedded author information which is always up-to-date */
  author!: ProfileReviewAuthor;
  /** Optional review comment from review author */
  message?: string;
  /** Review rating, varies from `1-5` */
  rating!: number;
  /** For what event user gets a review */
  type!: ProfileReviewType;
  /** `type`-specific data */
  data!: ProfileReviewDataForBooking;
  /** Author provided pictures. Uploaded to storage `/reviews/{id}/{fileName}` */
  pictures?: string[];
}

export type ProfileReviewDocumentField = DeepKeyOf<ProfileReviewModel>;

export type ProfileReviewDataDTOForBooking = {
  /** Indicates whether review author is booking renter */
  isAuthorRenter: boolean;
};

export type ProfileReviewDTO = Pick<
  ProfileReviewModel,
  'id' | 'createdAt' | 'author' | 'message' | 'rating' | 'forUserId'
> & {
  details: ProfileReviewDataDTOForBooking;
  pictures: {
    large: string;
    preview: string;
  }[];
};

export type GetProfileReviewsFilterDTO = {
  limit?: number;
};

export type GetProfileRentalsQueryDTO = {
  orderBy?: string;
  orderDir?: OrderDirection;
};

export type PostNewReviewForProfileBodyDTO = {
  message?: string;
  rating: number;
  bookingId: string;
};

export type GetProfileDocumentsFilterDTO = {
  type?: [DocumentVerificationType.driverLicense];
  limit?: number;
};

export type GetProfileQueryOptionsDTO = {
  avatarW?: number;
  avatarH?: number;
};

export type DeleteUserProfileOptionsDTO = {
  deletionConfirmed: boolean;
};

export type GetProfilesQueryDTO = GetProfileQueryOptionsDTO & {
  ids?: string[];
  limit?: number;
  limitToLast?: number;
  orderBy?: string;
  orderDir?: OrderDirection;
  startAfter?: string | number;
  endBefore?: string | number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  isTrustedPartner?: boolean;
  isSuperOwner?: boolean;
};

export class FavoriteProfileVehicleModel {
  id!: string;
  userId!: string;
  vehicleId!: string;
}

export type FavoriteProfileVehicleDocumentField =
  DeepKeyOf<FavoriteProfileVehicleModel>;

type VehicleLocation = {
  lat: number;
  lng: number;
  details: string;
  country: string;
  city: string;
  streetName: string;
  streetNumber: string;
  timezone: string;
};

export type VehicleDriver = {
  dayPrice: number;
};

export type VehicleDiscount = {
  /**
   * Discount % applied 7 days of rental
   *
   * Example: `5`
   */
  week: number;
  /**
   * Discount % applied after 28 days of rental
   *
   * Example: `40`
   */
  month: number;
};

export type NewVehicleAccessory = {
  name: string;
  chargeType: string;
  price: number;
};

export type VehicleAccessory = NewVehicleAccessory & {
  id: string;
};

export type VehicleRule = {
  checked: boolean;
  text: string;
};

type VehicleBookingStats = {
  total: number;
  canceledByRenter: number;
  canceledByOwner: number;
  canceledByAdmin: number;
  expired: number;
  completed: number;
};

export type VehicleFeature =
  | 'air_conditioning'
  | 'bluetooth'
  | 'cruise_control'
  | 'heated_seats'
  | 'parking_sensor'
  | 'winter_tires';

/**
 * @deprecated
 * Unused, but kept for older bookings
 */
type VehicleDelivery = {
  insideCity: number;
  outsideCity: number;
};

export class VehicleModel {
  id!: string;
  type!: string;
  category!: string;
  /**
   * @deprecated
   * Unused, but kept for older bookings. Replaced with accessories.
   */
  delivery?: VehicleDelivery;
  driver?: VehicleDriver;
  gears?: number;
  displacement?: number;
  plateNumber?: string;
  mileage?: number;
  /** If unlimited, value is set to `INCLUDED_KM_UNLIMITED_OPTION` */
  includedKmPerDay?: number;
  seats?: number;
  fuel?: string;
  transmission?: string;
  shortInfo!: string;
  brand!: string;
  model!: string;
  year!: number;
  minimumDays!: number;
  dayPrice!: number;
  discount!: VehicleDiscount;
  isInstantBookingOnly?: boolean;
  insurance!: string[];
  insuranceDetails?: string;
  termsAndConditions!: string;
  active!: boolean;
  accessories!: VehicleAccessory[];
  features?: VehicleFeature[];
  rules!: VehicleRule[];
  /** (Only for cars and motorbikes) List of driver id categories which owner requires for rental. */
  driverIdCategories?: DriverLicenseCategory[];
  preparationTimeInHours!: number;
  currency!: string;
  location!: VehicleLocation;
  thumbnail!: string;
  images!: string[];
  ownerUid!: string;
  bookings!: VehicleBookingStats;
  createdAt!: FirestoreTimestamp;
  __upd?: any;
}

export type VehicleDocumentField = DeepKeyOf<VehicleModel>;

export interface VehicleDTO
  extends Omit<VehicleModel, 'bookings' | 'createdAt'> {
  createdAt: string;
  imagesRaw: string[];
}

export class CancelBookingBodyDTO {
  cancelReason!: string;
  cancelAsAdmin?: boolean;
}

export enum BookingStatusCodeEnum {
  /**
   * Booking record was created in the db but booking itself was not
   * finished (e.g. renter did not complete payment)
   */
  'CREATED' = 'BR000',
  /**
   * Booking request was succesfully created, renter awaits for owner
   * response
   */
  'PENDING' = 'BR001',
  /**
   * Booking request was accepted by the owner but have not started yet
   */
  'ACCEPTED' = 'BR002',
  /**
   * Booking started and currently 'goes on'
   */
  'IN_PROCESS' = 'BR003',
  /**
   * Booking succesfully ended
   */
  'ENDED' = 'BR004',
  /**
   * Booking was canceled by the owner
   */
  'CANCELED_BY_OWNER' = 'BR005',
  /**
   * Booking was canceled by the renter
   */
  'CANCELED_BY_RENTER' = 'BR006',
  /**
   * Booking expired due to owner has not provided any action
   */
  'EXPIRED' = 'BR007',
  /**
   * Booking was canceled by an admin
   */
  'CANCELED_BY_ADMIN' = 'BR008'
}

export enum BookingStatusMessageEnum {
  'CREATED' = 'Created',
  'PENDING' = 'Pending',
  'ACCEPTED' = 'Accepted',
  'IN_PROCESS' = 'In process',
  'ENDED' = 'Ended',
  'CANCELED_BY_OWNER' = 'Canceled by owner',
  'CANCELED_BY_RENTER' = 'Canceled by renter',
  'EXPIRED' = 'Expired',
  'CANCELED_BY_ADMIN' = 'Canceled by admin'
}

export type BookingStatus = {
  createdAt: string;
  code: BookingStatusCodeEnum;
  message: BookingStatusMessageEnum;
  description?: string;
  actionByUserId?: string;
};

export enum BookingPaymentProvider {
  'STRIPE' = 'stripe',
  'PAYPAL' = 'paypal',
  'PAYNAMICS' = 'paynamics'
}

export enum BookingPayoutProvider {
  'STRIPE' = 'stripe',
  'PAYPAL' = 'paypal'
}

export enum BookingPaymentState {
  /** Payment attempt failed for some reason. */
  'PAYMENT_CREATION_ERROR' = 'payment_creation_error',
  /** Payment session has not been finished, or it is still active. */
  'TO_BE_PAID' = 'to_be_paid',
  /** Funds were authorized but not captured yet. */
  'AUTHORIZED' = 'authorized',
  /** Attempt to authorize funds failed. */
  'AUTHORIZATION_FAILED' = 'authorization_failed',
  /** Payment was canceled, authorized funds were released. */
  'CANCELED' = 'canceled',
  /** Attempt to cancel payment (cancel authorization) failed. */
  'CANCELLATION_FAILED' = 'cancellation_failed',
  /** Payment successfully went through. Funds were captured. */
  'CAPTURED' = 'captured',
  /** Attempt to capture funds was not successful. */
  'CAPTURE_FAILED' = 'capture_failed',
  /** Payment was refunded. */
  'REFUNDED' = 'refunded',
  /** Attempt to refund payment was not successful. */
  'REFUND_FAILED' = 'refund_failed'
}

export type StripePaymentData = {
  paymentIntentId: string;
  refundId?: string;
  error?: any;
};

export type PaypalPaymentData = {
  orderId: string;
  authorizationId: string;
  captureId?: string;
  refundId?: string;
  error?: any;
};

export type PaynamicsPaymentData = {
  sale: {
    merchantRefNo: string;
    paynamicsRefNo: string;
    responseStatus?: PaynamicsResponseStatus;
  };
  refund?: {
    merchantRefNo: string;
    paynamicsRefNo: string;
    responseStatus?: PaynamicsResponseStatus;
  };
  error?: any;
};

export type BookingPayment = {
  updatedAt: string;
  state: BookingPaymentState;
  total: number;
  currency: string;
  originalPrice: {
    total: number;
    currency: string;
  };
  exchangeRates: ExchangeRatePaymentLink;
  provider: BookingPaymentProvider;
  providerData?: StripePaymentData | PaypalPaymentData | PaynamicsPaymentData;
};

export enum BookingPayoutState {
  'TO_BE_PAID' = 'to_be_paid',
  'INITIATED' = 'initiated',
  'SUCCESS' = 'success',
  'FAILED' = 'failed',
  'CANCELED' = 'canceled'
}

export type StripePayout = {
  transferId?: string;
  error?: any;
};

export type PaypalPayout = {
  payoutBatchId?: string;
  error?: any;
};

export type BookingEarningsPayout = {
  /**
   * Follows ISO 8601 date and time in UTC, e.g. `2022-04-10T06:00:00.000Z`
   */
  updatedAt: string;
  state: BookingPayoutState;
  /**
   * Present only if state is canceled.
   */
  cancelReason?: string;
  total: number;
  currency: string;
  originalPrice: {
    total: number;
    currency: string;
  };
  exchangeRates: ExchangeRatePaymentLink;
  provider: BookingPayoutProvider;
  providerData?: StripePayout | PaypalPayout;
};

export type PaypalFulfilBookingPaymentParams = {
  orderId: string;
  authorizationId?: string;
  paymentError?: Record<'message', any>;
};

export type EmbeddedBookingReview = {
  reviewId: string;
  authorId: string;
};

/**
 * @deprecated
 * Unused, but kept for older bookings
 */
enum LocationTypeEnum {
  OWNER = 'owner',
  INSIDE_CITY = 'inside_city',
  OUTSIDE_CITY = 'outside_city'
}

export type BookingDetails = {
  messageForOwner: string;
  /**
   * @deprecated
   * Unused, but kept for older bookings
   */
  pickupLocationType?: LocationTypeEnum;
  /**
   * @deprecated
   * Unused, but kept for older bookings
   */
  returnLocationType?: LocationTypeEnum;
  /** If unlimited, value is set to `INCLUDED_KM_UNLIMITED_OPTION` */
  includedKm: number;
  extraKm: number;
  /** If `includedKm` is unlimited, `totalKmContracted` is set to `INCLUDED_KM_UNLIMITED_OPTION` */
  totalKmContracted: number;
  /** Array of accessory ids */
  pickedAccessories?: string[];
  /** Whether booking was immediately `accepted` after `created` state, without going through `pending` state */
  isInstantBooking?: boolean;
};

export type BookingPriceRates = {
  /**
   * Currency for price rates.
   */
  currency: string;
  /**
   * Indicates how many % of discount renter gets for selected time period.
   *
   * The value varies from `0-100`.
   */
  periodDiscountPercentage: number;
  /**
   * Indicates how much discount renter gets for selected time period.
   */
  periodDiscountTotal: number;
  /**
   * Gross price for the selected period. It does NOT consider discounts.
   */
  periodPriceNoDiscount: number;
  /**
   * Pure price for the selected period. It considers all discounts.
   */
  periodPriceTotal: number;
  /**
   * % that renter gives up to the platform. Used to calculate
   * `renterFeeTotal`
   *
   * Examples: 0.2 (20%), 0.07 (7%)
   */
  renterFeeRate: number;
  /**
   * How much renter gives up to the platform.
   */
  renterFeeTotal: number;
  /**
   * % that owner gives up to the platform. Used to calculate
   * `ownerFeeTotal`
   *
   * Examples: 0.2 (20%), 0.07 (7%)
   */
  ownerFeeRate: number;
  /**
   * How much owner gives up to the platform.
   */
  ownerFeeTotal: number;
  /**
   * How much owner makes from this booking.
   */
  ownerEarningsTotal: number;
  /**
   * Gross platform earning from this booking. This value does not consider
   * any discounts (coupons, affiliate).
   */
  serviceFeeGross: number;
  /**
   * Pure and final platform earning from this booking. This value
   * considers all discounts (coupons, affiliate).
   */
  serviceFeeTotal: number;
  /**
   * % that affiliate earns from this booking. Used to calculate
   * `affiliateTotal`. If booking does not have a referrer, this value will
   * be set to `null`.
   *
   * Examples: 0.2 (20%), 0.07 (7%)
   */
  affiliateShareRate: number | null;
  /**
   * How much referrer (affiliate) gets from this booking. If booking does
   * not have a referrer, this value will be set to `0`.
   */
  affiliateShareTotal: number;
  /**
   * How many % must be the discount for using referral link provided by
   * the affiliate to create this booking. Used to calculate
   * `affiliateDiscountTotal`. If booking does not have a referrer this
   * value will be set to `null`.
   *
   * Examples: 0.2 (20%), 0.07 (7%)
   */
  affiliateLinkDiscountRate: number | null;
  /**
   * Total amount discounted for using referral link provided by the
   * affiliate to create this booking. If booking does not have a referrer,
   * this value will be set to `0`
   */
  affiliateLinkDiscountTotal: number;
  /**
   * Total price for driver service.
   */
  driverTotal: number;
  /**
   * Price per single extra km.
   */
  perExtraKmPrice: number;
  /**
   * Total price for picked extra kilometers.
   *
   * For bicycle this value will be always set to `0`
   */
  extraKmTotal: number;
  /**
   * Total price for picked vehicle accessories.
   */
  accessoriesTotal: number;
  /**
   * @deprecated
   * Unused, but kept for older bookings
   *
   * Total price for delivery service on pick-up date.
   */
  pickupDeliveryTotal?: number;
  /**
   * @deprecated
   * Unused, but kept for older bookings
   *
   * Total price for delivery service on return date.
   */
  returnDeliveryTotal?: number;
  /**
   * Total amount discounted for applying a coupon code.
   *
   * If coupon was not applied, this value will be `0`
   */
  couponDiscountTotal: number;
  /**
   * Total amount for choosing er care coverage.
   *
   * If coverage was not applied, this value will be `0`, or for older bookings not present at all
   */
  erCareTotal?: number;
  /**
   * Total amount discounted for using b2w points.
   *
   * If points were not applied, this value will be `0`
   */
  b2wPointsDiscountTotal: number;
  /**
   * Price that must be paid online by renter.
   */
  totalPriceOnline: number;
  /**
   * Price that must be paid in cash by renter.
   */
  totalPriceCash: number;
  /**
   * Total gross booking price. This total does not consider discounts and
   * fees, only period price + extras/services.
   */
  totalPriceNoRenterFee: number;
  /**
   * Total booking price that must be paid by renter.
   */
  totalPrice: number;
};

export type BookingCancellationPolicy = {
  /**
   * Timestamp that indicates at which point booking becomes non-refundable.
   *
   * Provided in ISO format.
   */
  refundableBefore: string;
};

export type BookingPersonContactDetails = Pick<
  ProfileModel,
  | 'id'
  | 'phoneNumber'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'nationality'
>;

export type B2wPointsConfigInBooking = {
  pointsConfig: {
    bookingOwnerEarnsPercent: number;
    bookingRenterEarnsPercent: number;
  };
  /** Booking currency to point value currency */
  exchangeRates: ExchangeRatePaymentLink;
  /** How many points owner earns. Final value calculated from platform pure earnings. */
  ownerEarnsPoints: number;
  /** how many points renter earns. Final value calculated from platform pure earnings. */
  renterEarnsPoints: number;
};

export type ErCareConfigInBooking = {
  /** Selected ER Care option for the booking */
  erCareOption: ErCareInsuranceCoverageOption;
  /** `erCareOption.costCurrency` to booking currency */
  exchangeRates: ExchangeRatePaymentLink;
};

/**
 * Model that represents booking
 *
 * @relations
 * - ProfileModel
 * - VehicleModel
 * - ProfileReviewModel
 * - CouponModel
 * - AffiliateModel
 */
export class BookingModel {
  id!: string;
  createdAt!: string;
  expiresAt!: string;
  /**
   * Timestamp which indicates when booking eventually becomes settled.
   *
   * Settlement period is time period between booking end time (ENDED
   * status) and `settlesAt`. During that period booking payment can be
   * refunded and earnings payout (if any) can be canceled. If payment
   * operations take place after settlement date, they stay outside and are
   * invisbile to the system.
   *
   * Follows ISO 8601 date and time in UTC, e.g. `2022-04-10T06:00:00.000Z`
   */
  settlesAt!: string;
  status!: BookingStatus;
  statusHistory!: BookingStatus[];
  payment!: BookingPayment;
  reviews!: EmbeddedBookingReview[];

  /**
   * Owner earnings payout method.
   */
  payoutMethod!: PayoutMethodEnum;
  /**
   * Owner earnings payout object.
   *
   * This object will NOT be present when `payoutMethod` is set to `cash`.
   */
  earningsPayout?: BookingEarningsPayout;

  /**
   * Profile id of renter.
   */
  renterUid!: string;
  /**
   * The vehicle used for this booking.
   */
  vehicle!: VehicleDTO;

  /**
   * Booking start date timestamp.
   *
   * Follows ISO 8601 date and time in UTC, e.g. `2022-04-10T06:00:00.000Z`
   */
  from!: string;
  /**
   * Booking end date timestamp.
   *
   * Follows ISO 8601 date and time in UTC, e.g. `2022-04-10T06:00:00.000Z`
   */
  to!: string;
  /**
   * Booking start date timestamp.
   *
   * Follows ISO 8601 date and time WITHOUT UTC, e.g. `2022-04-10T09:00`
   */
  fromLocalIso!: string;
  /**
   * Booking end date timestamp.
   *
   * Follows ISO 8601 date and time WITHOUT UTC, e.g. `2022-04-10T09:00`
   */
  toLocalIso!: string;
  cancellationPolicy!: BookingCancellationPolicy;
  /**
   * Coupon code used for discount.
   *
   * If coupon was not applied, this value will be `null`
   */
  couponCodeUsed!: string | null;
  /**
   * Id of affiliate user who is the referrer for this booking. Points to
   * `AffiliateModel`.
   */
  affiliateReferrerId!: string | null;

  /**
   * Booking-specific data such as extra km, picked accessories, etc.
   */
  details!: BookingDetails;
  /**
   * Price breakdown for this booking.
   */
  priceRates!: BookingPriceRates;

  /**
   * Owner contact details. Data stays up-to-date while booking is active,
   * then data becomes stale for historical purposes.
   */
  ownerContactData!: BookingPersonContactDetails;
  /**
   * Renter contact details. Data stays up-to-date while booking is active,
   * then data becomes stale for historical purposes.
   */
  renterContactData!: BookingPersonContactDetails;
  /**
   * Renter driver license data. Data stays up-to-date while booking is
   * active, then data becomes stale for historical purposes.
   */
  renterDriverLicense!: DocumentVerificationModel | null;
  /**
   * Earning points summary at the time of booking (if any)
   */
  b2wPointsEarningsSummary?: B2wPointsConfigInBooking;
  /**
   * ER Care summary at the time of booking (if any)
   */
  erCareSummary?: ErCareConfigInBooking;
  /**
   * How many points was applied for this booking to get a discount
   *
   * If none, value is null
   */
  b2wPointsAppliedForDiscount!: number | null;
}

export type BookingDocumentField = DeepKeyOf<BookingModel>;

export type DriverLicensePreviewForBooking = {
  id: string;
  allowedCategories: DriverLicenseCategory[];
  allowedTransmission: DriverLicenseTransmission[];
  isVerified: boolean;
};

export type BookingDTO = {
  id: string;
  createdAt: string;
  status: BookingStatus;
  payment: BookingPayment;
  reviews: EmbeddedBookingReview[];
  payoutMethod: PayoutMethodEnum;
  renterUid: string;
  vehicle: VehicleDTO;
  from: string;
  to: string;
  fromLocalIso: string;
  toLocalIso: string;
  details: BookingDetails;
  /** Pricing table for booking. If `currency` setting is supplied, priceRates
   * shows prices converted to target currency. */
  priceRates: BookingPriceRates;
  /** Pricing table for booking. Always shows prices in original currency. */
  priceRatesOriginal: BookingPriceRates;
  couponCodeUsed: string | null;
  affiliateReferrerId: string | null;
  /** Corresponds to `renterContactData` in `BookingModel` */
  renterContactData: BookingPersonContactDetails;
  /** Corresponds to `ownerContactData` in `BookingModel` */
  ownerContactData: BookingPersonContactDetails;
  /** Renter driver license.
   *
   * If not present, user does not have enough permission to view it. If
   * `null` - license is not uploaded */
  renterDriverLicensePreview?: DriverLicensePreviewForBooking | null;
  /**
   * Earnings payout object.
   *
   * If not present, user does not have enough permission to view it. If
  /**
   * Earnings payout object.
   *
   * If not present, user does not have enough permission to view it. If
   * `null` - payout is not carried online */
  earningsPayout?: BookingEarningsPayout | null;
  settlesAt: string;
  /** If not present, user does not have enough permission to view it */
  settlement?: {
    isInSettlementPeriod: boolean;
    canRefund: boolean;
    canPayout: boolean;
    canCancelPayout: boolean;
  };
  /** Present only when user is the owner */
  payoutInfo?: {
    state: BookingPayoutState;
    updatedAt: string;
    cancelReason?: string;
  };
  /** Present only when user is renter or admin */
  erCareCoverage?: Pick<
    ErCareInsuranceCoverageOption,
    'coverageAmount' | 'coverageCurrency'
  >;
};

export type GetReviewsFilterDTO = {
  authorId?: string;
  forId?: string;
  bookingId?: string;
  limit?: number;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export enum AskOwnerStatus {
  pending = 'pending',
  approved = 'approved',
  declined = 'declined'
}

export class AskOwnerModel {
  id!: string;
  vehicleId!: string;
  from!: string;
  to!: string;
  message!: string;
  renterUid!: string;
  ownerUid!: string;
  status!: AskOwnerStatus;
  createdAt!: FirestoreTimestamp;
}

export interface AskOwnerDTO extends Omit<AskOwnerModel, 'createdAt'> {
  createdAt: string;
}

export class ChatRoomModel {
  id!: string;
  users!: string[];
  createdAt!: FirestoreTimestamp;
  latestMessage?: ChatMessageModel;
  lastAskOwnerId?: string;
  lastBookingId?: string;
}

export type ChatRoomDocumentField = DeepKeyOf<ChatRoomModel>;

export enum ChatMessageType {
  'normal' = 'normal',
  'booking_new' = 'booking_new',
  'booking_canceled' = 'booking_canceled',
  'booking_canceled_by_admin' = 'booking_canceled_by_admin',
  'booking_accepted' = 'booking_accepted',
  'booking_expired' = 'booking_expired',
  'booking_ended' = 'booking_ended',
  'ask_owner_new' = 'ask_owner_new',
  'ask_owner_approved' = 'ask_owner_approved',
  'ask_owner_declined' = 'ask_owner_declined'
}

export type BookingPreviewInChatMessage = Pick<
  BookingModel,
  | 'id'
  | 'status'
  | 'renterUid'
  | 'from'
  | 'to'
  | 'fromLocalIso'
  | 'toLocalIso'
  | 'expiresAt'
> & {
  vehicle: Pick<
    VehicleModel,
    'id' | 'ownerUid' | 'brand' | 'model' | 'thumbnail'
  >;
  priceRates: Pick<
    BookingPriceRates,
    'currency' | 'ownerEarningsTotal' | 'totalPrice'
  >;
  messageFromRenterToOwner: string;
};

export type ChatMessageDataForBookingType = {
  /** Embedded short data of related booking. If more data needed, send a separate
   * query for the booking. */
  booking: BookingPreviewInChatMessage;
};

export type ChatMessageDataForNormalType = {
  /** Chat message text that user wrote */
  content: string;
  /** Indicates whether message `content` was modified against phone
   * numbers or prohibited strings */
  wasContentMaskedOut?: boolean;
};

export type ChatMessageDataForAskOwnerType = {
  /** Embedded `AskOwnerModel` of related ask owner query. */
  askOwner: AskOwnerModel;
};

export type ChatMessageDataForAskOwnerTypeDTO = {
  askOwner: AskOwnerDTO;
};

export class ChatMessageModel {
  id!: string;
  authorUid!: string;
  createdAt!: FirestoreTimestamp;
  type!: ChatMessageType;
  data!:
    | ChatMessageDataForBookingType
    | ChatMessageDataForNormalType
    | ChatMessageDataForAskOwnerType;
}

export type ChatMessageDocumentField = DeepKeyOf<ChatMessageModel>;

export type SendMessageToChatDTO = {
  message: string;
};

export type SendMessageToChatResponseDTO = {
  warning: string | null;
};

export type CreateNewChatRoom = {
  createdAt: FirestoreTimestamp;
  users: string[];
};

export enum OrderDirection {
  'ASCENDING' = 'asc',
  'DESCENDING' = 'desc'
}

export type GetChatMessagesFilterDTO = {
  limitToLast?: number;
  endBefore?: any;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export type ChatMessageDTO = Pick<
  ChatMessageModel,
  'id' | 'authorUid' | 'type'
> & {
  createdAt: string;
  data:
    | ChatMessageDataForNormalType
    | ChatMessageDataForBookingType
    | ChatMessageDataForAskOwnerTypeDTO;
};

export type ChatMessageDTOWithAuthor = ChatMessageDTO & {
  author?: ProfileDTO;
};

/** The same as `ChatRoomModel` but Timestamp is converted to ISO string */
export interface ChatRoomModelClientSide
  extends Omit<ChatRoomModel, 'createdAt' | 'latestMessage'> {
  createdAt: string;
  latestMessage?: Omit<ChatRoomModel['latestMessage'], 'createdAt'> & {
    createdAt: string;
  };
}

export interface ChatRoomDTO extends ChatRoomModelClientSide {
  talkingTo?: ProfileDTO;
}

export enum DocumentVerificationType {
  driverLicense = 'driver_license'
}

export enum DocumentVerificationCodeEnum {
  'EXPIRED' = -2,
  'REJECTED' = -1,
  'PENDING' = 0,
  'VERIFIED' = 1
}

export enum DocumentVerificationMsgEnum {
  'EXPIRED' = 'Expired',
  'REJECTED' = 'Rejected',
  'PENDING' = 'Pending',
  'VERIFIED' = 'Verified'
}

export type DocumentVerificationStatus = {
  code: DocumentVerificationCodeEnum;
  message: DocumentVerificationMsgEnum;
  rejectionReason?: string;
  verifiedAt?: string;
  rejectedAt?: string;
};

export enum DriverLicenseCategory {
  AM = 'AM',
  A1 = 'A1',
  A = 'A',
  B1 = 'B1',
  B = 'B',
  C1 = 'C1',
  C = 'C',
  D1 = 'D1',
  D = 'D'
}

export enum DriverLicenseTransmission {
  MANUAL = 'MT',
  AUTO = 'AT'
}

export interface DriverLicenseVerification {
  licenseId: string;
  frontPic: string;
  backPic: string;
  driverSelfiePic: string;
  allowedCategories: DriverLicenseCategory[];
  allowedTransmission: DriverLicenseTransmission[];
  licenseExpiresAtISO: string;
}

export class DocumentVerificationModel {
  id!: string;
  userId!: string;
  createdAt!: string;
  status!: DocumentVerificationStatus;
  type!: DocumentVerificationType;

  data!: DriverLicenseVerification;
}

export type DocumentVerificationDocumentField =
  DeepKeyOf<DocumentVerificationModel>;

export type GetDocumentsFilterDTO = {
  statusCode?: number;
  hasBookingsOnly?: boolean;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export type UploadDriverLicenceBodyDTO = {
  licenseId: string;
  categories: DriverLicenseCategory[];
  transmission: DriverLicenseTransmission[];
  expiresAt: {
    year: number;
    month: number;
    day: number;
  };
};

export type CalendarRange = {
  from: string;
  to: string;
};

export class VehicleCalendarModel {
  id!: 'calendar';
  updatedAt!: FirestoreTimestamp;
  timezone!: string;
  bookings!: CalendarRange[];
  disabled!: CalendarRange[];
}

export type VehicleCalendarDocumentField = DeepKeyOf<VehicleCalendarModel>;

export class VehicleCalendarDTO {
  id!: string;
  updatedAt!: string;
  timezone!: string;
  bookings!: CalendarRange[];
  disabled!: CalendarRange[];
}

type BookingStats = {
  total: number;
  expired: number;
  canceledByRenter: number;
  canceledByRenterAfterAccepted: number;
  canceledByOwner: number;
  canceledByOwnerAfterAccepted: number;
  canceledByAdmin: number;
  completed: number;
};

type UserStats = {
  total: number;
  verifiedFacebook: number;
  verifiedGoogle: number;
  verifiedPhone: number;
  connectedToStripe: number;
  connectedToPaypal: number;
};

type ReviewStats = {
  total: number;
  stars1: number;
  stars2: number;
  stars3: number;
  stars4: number;
  stars5: number;
};

type VehicleStats = {
  total: number;
  active: number;
  notActive: number;
  car: number;
  motorcycle: number;
  bike: number;
};

type ChatStats = {
  total: number;
};

export class ProjectStatsModel {
  id!: string;
  bookings!: BookingStats;
  users!: UserStats;
  reviews!: ReviewStats;
  vehicles!: VehicleStats;
  chat!: ChatStats;
}

export type ProjectStatsDocumentField = DeepKeyOf<ProjectStatsModel>;

export class EmailVerificationModel {
  id!: string;
  otp!: string;
  createdAt!: number;
  expiresAt!: number;
}

export class VerifyEmailOtpBodyDTO {
  otp!: string;
}

export class SetNewEmailBodyDTO {
  email!: string;
}

export type ComprehensiveInsuranceBodyDTO = {
  name: string;
  email: string;
  phoneNumber: string;
  bikeModel: string;
  bikeYear: string;
  bikeValue: string;
  text?: string;
};

export type ErCareInsuranceBodyDTO = {
  name: string;
  email: string;
  phoneNumber: string;
  startDate: string;
  endDate: string;
  text?: string;
};

export type ThirdPartyInsuranceBodyDTO = {
  name: string;
  email: string;
  phoneNumber: string;
  bikeModel: string;
  bikeValue: string;
  city: string;
  text?: string;
};

export enum EmailTemplateEnum {
  NEW_BOOKING_FOR_RENTER = 'new_booking_for_renter',
  NEW_BOOKING_FOR_OWNER = 'new_booking_for_owner',
  BOOKING_ENDED_TO_OWNER = 'booking_ended_to_owner',
  BOOKING_ENDED_TO_RENTER = 'booking_ended_to_renter',
  BOOKING_ACCEPTED_TO_OWNER = 'booking_accepted_to_owner',
  BOOKING_ACCEPTED_TO_RENTER = 'booking_accepted_to_renter',
  BOOKING_CANCELLED_BY_OWNER_TO_OWNER = 'booking_cancelled_by_owner_to_owner',
  BOOKING_CANCELLED_BY_OWNER_TO_RENTER = 'booking_cancelled_by_owner_to_renter',
  BOOKING_CANCELLED_BY_RENTER_TO_OWNER = 'booking_cancelled_by_renter_to_owner',
  BOOKING_CANCELLED_BY_RENTER_TO_RENTER = 'booking_cancelled_by_renter_to_renter',
  BOOKING_EXPIRED_TO_RENTER = 'booking_expired_to_renter',
  BOOKING_EXPIRED_TO_OWNER = 'booking_expired_to_owner',
  BOOKING_IN_PROCESS_TO_OWNER = 'booking_in_process_to_owner',
  BOOKING_IN_PROCESS_TO_RENTER = 'booking_in_process_to_renter',
  NEW_ACCOUNT_CREATED = 'new_account_created',
  PI_INSURANCE_FORM = 'pi_insurance_form',
  TP_INSURANCE_FORM = 'tp_insurance_form',
  REVIEW_NOTIFICATION = 'review_notification',
  CMP_INSURANCE_FORM = 'cmp_insurance_form',
  NEW_VEHICLE_UPLOAD = 'new_vehicle_upload',
  UNREAD_MESSAGE = 'unread_message'
}

export type NewBookingForRenterEmailTemplate = {
  name: EmailTemplateEnum.NEW_BOOKING_FOR_RENTER;
  vars: {
    BOOKING_ID: string;
    RENTER_NAME: string;
    OWNER_NAME: string;
    MODEL: string;
    BRAND: string;
    CITY: string;
    PICK_DATE: string;
    RETURN_DATE: string;
    PERIOD: string;
    CURRENCY: string;
    SERVICE_FEE: string;
    TOTAL: string;
    VEHICLE_IMG: string;
    PAID_ONLINE: string;
    TO_PAY_CASH: string;
  };
};

export type ComprehensiveInsuranceEmailTemplate = {
  name: EmailTemplateEnum.CMP_INSURANCE_FORM;
  vars: {
    FULL_NAME: string;
    EMAIL: string;
    PHONE_NUMBER: string;
    MAKE_AND_MODEL: string;
    VEHICLE_YEAR: string;
    VEHICLE_VALUE: string;
    COMMENTS: string;
  };
};

export type ThirdPartyInsuranceEmailTemplate = {
  name: EmailTemplateEnum.TP_INSURANCE_FORM;
  vars: {
    FULL_NAME: string;
    EMAIL: string;
    PHONE_NUMBER: string;
    MODEL: string;
    VEHICLE_VALUE: string;
    CITY: string;
    COMMENTS: string;
  };
};

export type ErCareInsuranceEmailTemplate = {
  name: EmailTemplateEnum.PI_INSURANCE_FORM;
  vars: {
    FULL_NAME: string;
    EMAIL: string;
    PHONE_NUMBER: string;
    START: string;
    END: string;
    COMMENTS: string;
  };
};

export type MailTemplate =
  | NewBookingForRenterEmailTemplate
  | ComprehensiveInsuranceEmailTemplate
  | ThirdPartyInsuranceEmailTemplate
  | ErCareInsuranceEmailTemplate;

export type EmailContactDTO = {
  email: string;
  name: string;
  message: string;
};

type StripeDataPaymentLink = {
  paymentIntentId: string;
};

export type ExchangeRatePaymentLink = {
  from: string;
  to: string;
  rate: string;
};

export class PaymentLinkModel {
  id!: string;
  amount!: number;
  currency!: string;
  createdAt!: string;
  paid!: boolean;
  description!: string;
  payment?: {
    lastError?: any;
    paymentDate: string;
    provider: 'stripe';
    providerData: StripeDataPaymentLink;
    paymentCurrency: string;
    exchangeRates: ExchangeRatePaymentLink;
  };
}

export type PostNewPaymentLinkBodyDTO = {
  amount: number;
  currency: string;
  description: string;
};

export type PayForPaymentLinkBodyDTO = {
  receipt_email?: string;
  payment_method_id?: string;
  payment_intent_id?: string;
};

export type PayForSvDonationResponseDTO =
  | {
      requires_action: boolean;
      payment_intent_client_secret: string;
    }
  | { success: boolean }
  | { error: string };

export type InitNewSvDonationBodyDTO = {
  amount: number;
  name: string;
  email: string;
};

export type PayForSvDonationBodyDTO = {
  donationId: string;
  payment_method_id?: string;
  payment_intent_id?: string;
};

export type CreatePaypalOrderForDonationResponseDTO = {
  orderId: string;
  donationId: string;
};

export type CapturePaypalOrderForDonationResponseDTO = {
  captureId: string;
  donationId: string;
};

export type CreatePaypalOrderForDonationBodyDTO = {
  donationId: string;
};

export type CapturePaypalOrderForDonationBodyDTO = {
  orderId: string;
};

export type SetupPaymentIntentForSvReturnDTO = {
  clientSecret: string;
};

export type SvDonationCampaignInfo = {
  currency: string;
  amountGoal: number;
  amountCollected: number;
  totalDonations: number;
};

export enum SvDonationPaymentProvider {
  stripe = 'stripe',
  paypal = 'paypal'
}

export enum SvDonationPaymentState {
  'TO_BE_PAID' = 'to_be_paid',
  'CAPTURED' = 'captured',
  'FAILED' = 'failed'
}

export type SvPaymentStripeData = {
  paymentIntentId: string;
};

export type SvPaymentPaypalData = {
  orderId: string;
  captureId?: string;
};

/**
 * Model for silicon valley donation
 */
export class SvDonationModel {
  id!: string;
  createdAt!: string;
  amount!: number;
  currency!: string;
  email!: string;
  name!: string;

  payment!: {
    updatedAt: string;
    provider?: SvDonationPaymentProvider;
    state: SvDonationPaymentState;
    providerData?: SvPaymentStripeData | SvPaymentPaypalData;
    currency: string;
    total: number;
  };
}

export class ExchangeRateModel {
  id!: string;
  /** Source currency */
  base!: string;
  /** Date in format 'YYYY-MM-DD' */
  date!: string;
  rates!: {
    [currency: string]: number;
  };
  error?: any;
}

export type ExchangeRatesDocumentField = DeepKeyOf<ExchangeRateModel>;

export enum PaymentProviderMainCurrencyEnum {
  PAYPAL = 'EUR',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  STRIPE = 'EUR',
  PAYNAMICS = 'PHP'
}

export type PayForPaymentLinkResponseDTO =
  | {
      requires_action: boolean;
      payment_intent_client_secret: string;
    }
  | { success: boolean }
  | { error: string };

export type GetPaymentLinksFilterDTO = {
  paid?: boolean;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export type CreateNewPaymentLinkBodyDTO = {
  amount: number;
  currency: string;
  description: string;
};

export type UserForAbilityCheck = {
  uid: string;
  roles?: RoleEnum[];
  email?: string;
  emailVerified?: boolean;
  phoneNumber?: string;
  isSuspended?: boolean;
};

export type StripeAccountDTO = {
  id: string;
  email?: string | null;
  requirements?: {
    disabled_reason?: string | null;
  };
  capabilities?: {
    transfers?: 'active' | 'inactive' | 'pending';
  };
};

export type StripePaymentMethodDTO = {
  id: string;
  billing_details: {
    email: string | null;
    name: string | null;
    phone: string | null;
  };
  card?: {
    brand: string;
    country: string | null;
    exp_month: number;
    exp_year: number;
    funding: string;
    last4: string;
  };
};

export class StripeProfileSubject {
  constructor(attrs: DeepPartial<StripeProfileSubject>) {
    Object.assign(this, attrs);
  }

  userId!: string;
  customerId?: string;
  connectedId?: string;
}

export class ReplaceVehicleImageBodyDTO {
  srcToReplace!: string;
}

export class PostVehicleBodyDTO {
  category!: string;
  type!: string;
  shortInfo!: string;
  brand!: string;
  model!: string;
  year!: number;
  minimumDays!: number;
  dayPrice!: number;
  discount!: VehicleDiscount;
  insurance!: string[];
  insuranceDetails?: string;
  termsAndConditions!: string;
  active!: boolean;
  isInstantBooking!: boolean;
  accessories!: NewVehicleAccessory[];
  rules!: VehicleRule[];
  requiredLicenseCategories!: DriverLicenseCategory[];
  currency!: string;
  location!: Omit<VehicleLocation, 'timezone'>;
  thumbnailFileName?: string;
  driver?: VehicleDriver;
  gears?: number;
  features?: VehicleFeature[];
  displacement?: number;
  plateNumber?: string;
  mileage?: number;
  includedKmPerDay?: number;
  seats?: number;
  fuel?: string;
  transmission?: string;
}

export type GeonamesTimezone = {
  lng: number;
  lat: number;
  gmtOffset: number;
  rawOffset: number;
  dstOffset: number;
  sunrise?: string;
  sunset?: string;
  countryCode?: string;
  countryName?: string;
  time?: string;
  timezoneId?: string;
};

export type CloudTaskResultMeta = {
  /** Whether Cloud Task was scheduled */
  isSuccess: boolean;
  /** Timestamp when Cloud Task was attempted to be scheduled */
  scheduledAt: string;
  /** Cloud Task name if successfully scheduled */
  taskName?: string;
  /** Error if Cloud Task schedule failed and was not scheduled */
  error?: string;
};

export class CloudTaskModel {
  id!: string;
  /** ISO date and time */
  createdAt!: string;
  /**
   * Name of Cloud Tasks queue where the task must be pushed into
   *
   * @example 'update-booking-status'
   */
  queueName!: string;
  /**
   * Cloud function that the task must invoke. Must be exposed to
   * HTTP.
   *
   * @example 'updateBookingStatus'
   */
  functionName!: string;
  /**
   * When the task must be run. Supplied in miliseconds.
   */
  date!: number;
  /**
   * Payload that `functionName` will receive as `request.body`
   */
  payload!: Record<any, any>;
  /** Metadata about scheduled/failed Cloud Task */
  resultMeta?: CloudTaskResultMeta;
  /** Set to true to trigger task schedule retry. Task will not be retried if already scheduled */
  forceAttempt?: boolean;
}

export type CloudTaskDocumentField = DeepKeyOf<CloudTaskModel>;

export type GetBookingsQueryDTO = {
  orderBy?: string;
  orderDir?: OrderDirection;
  statusCodes?: BookingStatusCodeEnum[];
  limit?: number;
  startAfter?: string | string[];
  createdAtMin?: string;
  createdAtMax?: string;
  ids?: string[];
  ownerPhone?: string;
  ownerLastName?: string;
  ownerEmail?: string;
  renterPhone?: string;
  renterLastName?: string;
  renterEmail?: string;
  vehicleId?: string;
  paymentProvider?: BookingPaymentProvider | '-1';
};

export type GetBookingsStatsQueryDTO = {
  year: number;
};

export enum BookingExportFormat {
  JSON = 'json',
  CSV = 'csv'
}

export type ExportBookingsQueryDTO = {
  start?: string;
  end?: string;
  format?: BookingExportFormat;
};

export type CreateEarningsPayoutTransferBodyDTO = {
  bookingId: string;
};

export type IssueBookingPaymentRefundBodyDTO = {
  bookingId: string;
};

export type CancelEarningsPayoutBodyDTO = {
  bookingId: string;
  cancelReason: string;
};

export type ListRequiredVerificationsForBookingQueryDTO = {
  vehicleId: string;
};

export type GetConfigForBookingJourneyQueryDTO = {
  /**
   * Currency for which to convert the prices in config options.
   */
  currency?: string;
};

export type ErCareInsuranceCoverageOptionDTO = ErCareInsuranceCoverageOption & {
  id: string;
};

export type BookingJourneyConfig = {
  erCare?: {
    coverageOptions?: ErCareInsuranceCoverageOptionDTO[];
  };
};

export type ExportBookingsResponseDTO = {
  format: BookingExportFormat;
  value: string;
};

export interface CreateNewBookingRequestBodyDTO {
  vehicleId: string;
  from: string;
  to: string;
  message: string;
  renterContactEmail: string;
  renterContactPhoneNumber: string;
  extraKm?: number;
  /** Array of ids */
  accessories?: string[];
  /**
   * Coupon code to be used in the booking to get a discount.
   */
  discountCode?: string;
  /**
   * ER Care option to be selected for the booking.
   */
  erCareOptionId?: string;
  /**
   * Affiliate id who is the referrer for this booking request.
   */
  referrerId?: string;
  /**
   * Whether to apply b2w points discount
   */
  applyB2wPoints?: boolean;
}

export interface CreateNewBookingRequestForPaynamicsBodyDTO
  extends CreateNewBookingRequestBodyDTO {
  responseUrl: string;
  cancelUrl: string;
}

export type NewBooking = {
  message: string;
  accessories?: VehicleAccessory[];
  extraKm?: number;
  renterNationality?: string;
  renterPhoneNumber?: string;
  renterEmail?: string;
  termsAgree: boolean;
};

type CancelPolicyFee = {
  /** hours @example 0 */
  from: number;
  /** hours @example 72 */
  to?: number;
};

export type AffiliatePayoutProviderSettings = {
  name: AffiliatePayoutProvider;
  currency: string;
  minimumAmount: number;
};

type PromoBanner = {
  mainText: string;
  callToActionText: string;
  href: string;
  isActive: boolean;
  expiresAt?: string | null;
};

export type B2wPointValue = {
  amount: number;
  currency: string;
};

export type B2wPointsConfig = {
  /**
   * Formula-based value. Supported units are described by @TimeUnit
   * @examples '6M' - 6 months
   *
   * If not set, points will not expire
   */
  expireAfter?: string;
  /**
   * How many points renter earns from making a booking.
   * Value expressed as percentage and calculated from platform pure earnings.
   * @example 10, 20, 30
   */
  bookingRenterEarnsPercent?: number;
  /**
   * How many points owner earns from rental out.
   * Value expressed as percentage and calculated from platform pure earnings.
   * @example 10, 20, 30
   */
  bookingOwnerEarnsPercent?: number;
  /**
   * One point translates into amount, currency
   */
  onePointValue?: B2wPointValue;
  /**
   * How many % of discount points can give.
   * Value expressed as percentage
   * @example 10, 20, 30
   */
  discountPointsGivePercent?: number;
};

export type EmailMarketingSection = {
  title: string;
  vehicleIds?: string[];
};

export type ErCareInsuranceCoverageOption = {
  /** How much amount the option covers */
  coverageAmount: number;
  /** Currency for `coverageAmount` */
  coverageCurrency: string;
  /** How much the option costs */
  costAmount: number;
  /** Currency for `costAmount` */
  costCurrency: string;
};

export type ErCareInsuranceConfig = {
  coverageOptions?: ErCareInsuranceCoverageOption[];
};

export class ProjectAppConfigModel {
  id!: 'app_config';
  bookings!: {
    /** How many days after booking ENDS must be reserved for settlement.
     * Affects when owner receives a payout. */
    settlementDays: number;
    /** How many days to wait before booking becomes EXPIRED. Timer starts when booking becomes pending. */
    expiresAfterDays: number;
    /**
     * How many % platform collects from owner.
     *
     * Examples: 0.2 (means 20%), 0.07 (means 7%)
     */
    ownerFee: number;
    /**
     * How many % platform collects from renter.
     *
     * Examples: 0.2 (means 20%), 0.07 (means 7%)
     */
    renterFee: number;
    cancelPolicy: {
      fullPriceFee: CancelPolicyFee;
      noFee: CancelPolicyFee;
    };
  };
  paymentProviders!: {
    paypal: {
      currency: string;
    };
    stripe: {
      currency: string;
    };
    paynamics: {
      currency: string;
    };
  };
  affiliate!: {
    /**
     * List of supported payout methods/providers for affiliates.
     */
    payoutProviders: AffiliatePayoutProviderSettings[];
    /**
     * % that affiliate earns from a booking.
     *
     * Examples: 0.2 (means 20%), 0.07 (means 7%)
     */
    referrerShareRate: number;
    /**
     * Fixed amount that affiliate earns from rental of new user's first vehicle.
     * @example 10
     */
    referrerFixedShareAmount: number;
    /**
     * Currency for `referrerFixedShareAmount`
     * @example 'EUR'
     */
    referrerFixedShareCurrency: string;
    /**
     * How many % must be the discount for the renter for using affiliate
     * link.
     *
     * Examples: 0.2 (means 20%), 0.07 (means 7%)
     */
    referrerLinkDiscountRate: number;
    /**
     * Array of images to be used as image-widgets.
     */
    imageWidgets: string[];
  };
  banners?: {
    /** Promo banner shown on main pages on the websites */
    promo?: PromoBanner | null;
  };
  /** Email marketing config */
  emailMarketing?: {
    /* List of sections that appear at the bottom in every email */
    sections?: EmailMarketingSection[];
  };
  b2wPoints?: B2wPointsConfig;
  erCareInsurance?: ErCareInsuranceConfig;
}

export type ProjectAppConfigDocumentField = DeepKeyOf<ProjectAppConfigModel>;

export enum TimeUnit {
  month = 'M',
  day = 'D'
}

export interface BookingRequestForStripeResponse {
  bookingId: string;
  clientSecret: string;
}

export interface BookingRequestForPaypalResponse {
  bookingId: string;
  orderId: string;
}

export interface BookingRequestForPaynamicsResponse {
  bookingId: string;
  encodedSaleRequest: string;
  paymentUrl: string;
}

export interface GetPaymentInfoParams {
  paymentChannel: PaymentChannelEnum;
  paymentTotalBookingCurrency: number;
  bookingCurrency: string;
}

export interface AuthorizeBookingRequestForPaypalResponse {
  authorizationId: string | null;
  bookingId: string;
}

export interface AuthorizeBookingRequestForPaypalBodyDTO {
  orderId: string;
}

export interface HandleConnectToPaypalBodyDTO {
  authorizationCode: string;
}

export interface AffiliatePayoutRequestBodyDTO {
  provider: AffiliatePayoutProvider;
}

export interface BookingRefundStateDTO {
  canBeRefunded: boolean;
  refundableBefore: string;
  paymentProvider: BookingPaymentProvider;
  currentBookingStatus: BookingStatus;
}

export class GetVehicleQueryOptionsDTO {
  imagesW?: number;
  imagesH?: number;
  thumbW?: number;
  thumbH?: number;
  currency?: string;
}

export class GetVehiclesFilterDTO extends GetVehicleQueryOptionsDTO {
  ownerUid?: string;
  active?: boolean;
  limit?: number;
  limitToLast?: number;
  cities?: string[];
  category?: string;
  orderBy?: string;
  orderDir?: OrderDirection;
  startAfter?: string | number;
  endBefore?: string | number;
}

export type FileExtended = File & { preview: string; originalPreview?: string };

export type UserConnection = {
  platform: 'web' | 'mobile';
  connectedAt_ms: number;
};

export type UserPresence = {
  connections?: Record<string, UserConnection>;
  lastOnline_ms?: number;
};

export type UserConnectionData = {
  [userId: string]: {
    presence?: UserPresence;
  };
};

export type FCMPayload = {
  title: string;
  body: string;
  link?: string;
  showInForeground?: string;
  customData?: Record<string, string>;
};

export type VehicleSearchPricingResult = {
  periodDays: number;
  periodHours: number;
  activeDiscountPercentage: number;
  activeDiscount: number;
  driverTotal: number;
  periodPriceNoDiscount: number;
  renterFee: number;
  totalPriceWithRenterFee: number;
};

export type VehicleOwnerAlgolia = {
  /**
   * Owner profile id
   */
  id: string;
  /**
   * Owner first name
   */
  firstName: string;
  /**
   * Owner last name
   */
  lastName: string;
  /**
   * Owner avatar
   */
  avatar: string;
  /**
   * Owner average profile rating
   */
  avgRating: number;
  /**
   * Number of reviews owner has for his profile
   */
  totalReviews: number;
  /**
   * Number of times owner successfully rented out a vehicle
   */
  totalCompletedRentals: number;
  /**
   * Timestamp in miliseconds when owner was online last time
   */
  lastSeenTime: number;
  /**
   * Indicates whether owner account is disable and he cannot login
   */
  isAccountDisabled: boolean;
  /**
   * Indicates whether owner account is suspended and owner has read-only access
   */
  isSuspended: boolean;
  /**
   * Indicates whether owner has gold trophy
   */
  isSuperOwner: boolean;
  /**
   * Average acceptance rate for bookings
   */
  bookingAcceptanceRate?: number;
  /**
   * Average booking response time in miliseconds
   */
  avgResponseTime?: number;
  /**
   * Whether business profile
   */
  isBusinessProfile?: boolean;
};

export type VehicleAlgoliaModel = {
  /**
   * Algolia object id. Equals to vehicle id.
   */
  objectID: string;
  /**
   * Vehicle geo location coords object.
   */
  _geoloc: {
    lat: number;
    lng: number;
  };
  /**
   * Vehicle location city name
   */
  city: string;
  /**
   * Vehicle owner embedded information
   */
  owner: VehicleOwnerAlgolia;
  /**
   * Vehicle driver price
   */
  driverDayPrice?: number;
  /**
   * Vehicle displacement
   */
  displacement?: number;
  /**
   * Vehicle discount
   */
  discount: VehicleModel['discount'];
  /**
   * Represents busy time slots when vehicle is not available for rental.
   *
   * @example
   * {
   *   '26-01-2019': ['13:00', '14:00'],
   *   '26-07-2019': ['05:00', '06:00', '07:00'],
   * }
   */
  calendar?: {
    [date: string]: string[];
  };
  /**
   * Vehicle thumbnail (preview pic)
   */
  thumbnail: string;
  /**
   * Vehicle daily price
   */
  dayPrice: number;
  /**
   * Vehicle currency
   */
  currency: string;
  /**
   * Vehicle type
   */
  type: string;
  /**
   * Vehicle category
   */
  category: string;
  /**
   * Category priority. Starts from `0`. 0 - highest rank, 1 - lower, 2 -
   * more lower, etc.
   */
  categoryRanking: number;
  /**
   * Number of hours that must be present between current time and booking
   * pick-up time
   */
  preparationTimeInHours: number;
  gears?: number;
  seats?: number;
  fuel?: string;
  transmission?: string;
  shortInfo: string;
  brand: string;
  model: string;
  year: number;
  minimumDays: number;
  insurance: string[];
  driver?: boolean;
  active: boolean;
  isInstantBookingOnly: boolean;
  /** Higher number = lower rank */
  _customRandomRank: number;
  /**
   * Number of times this vehicle was successfully rented out
   */
  totalCompletedRentals: number;
  /** If unlimited, value is set to `INCLUDED_KM_UNLIMITED_OPTION` */
  includedKmPerDay?: number;
};

export type VehicleOwnerSearchDTO = Pick<
  VehicleOwnerAlgolia,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'avatar'
  | 'avgRating'
  | 'bookingAcceptanceRate'
  | 'avgResponseTime'
  | 'isBusinessProfile'
  | 'isSuperOwner'
  | 'totalCompletedRentals'
>;

export type VehicleSearchDTO = Pick<
  VehicleAlgoliaModel,
  | 'active'
  | 'isInstantBookingOnly'
  | 'driverDayPrice'
  | 'displacement'
  | 'discount'
  | 'thumbnail'
  | 'dayPrice'
  | 'currency'
  | 'type'
  | 'category'
  | 'gears'
  | 'seats'
  | 'fuel'
  | 'transmission'
  | 'shortInfo'
  | 'brand'
  | 'model'
  | 'year'
  | 'minimumDays'
  | 'insurance'
  | 'driver'
  | 'includedKmPerDay'
> & {
  /**
   * Id of vehicle
   */
  id: string;
  /**
   * Vehicle location information
   */
  location: {
    lat: number;
    lng: number;
    /**
     * City name where vehicle is located
     */
    city: string;
  };
  /**
   * Vehicle owner information
   */
  owner: VehicleOwnerSearchDTO;
  /**
   * Booking pricing for this vehicle. Use `embed` option to attach
   * `pricing`.
   */
  pricing?: VehicleSearchPricingResult;
};

export interface UpdateBookingStatusTaskPayload {
  bookingId: string;
  newStatus: BookingStatus;
}

interface GeolocationCoordinates {
  readonly accuracy: number;
  readonly altitude: number | null;
  readonly altitudeAccuracy: number | null;
  readonly heading: number | null;
  readonly latitude: number;
  readonly longitude: number;
  readonly speed: number | null;
}

export interface GeolocationPosition {
  readonly coords: GeolocationCoordinates;
  readonly timestamp: number;
}

export interface GeolocationPositionError {
  readonly code: number;
  readonly message: string;
  readonly PERMISSION_DENIED: number;
  readonly POSITION_UNAVAILABLE: number;
  readonly TIMEOUT: number;
}

export class OnboardUserForStripeConnectBodyDTO {
  returnUrl!: string;
  refreshUrl!: string;
}

export enum SearchVehiclesSortBy {
  recommended = 'recommended',
  priceAsc = 'price_asc'
}

export enum SearchVehiclesEmbedOption {
  pricing = 'pricing'
}

export interface SearchVehiclesQueryDTO {
  query?: string;
  sortBy?: SearchVehiclesSortBy;
  price?: number[];
  type?: string[];
  insurance?: string[];
  driver?: boolean;
  isVerifiedBusiness?: boolean;
  isUnlimitedKmToDrive?: boolean;
  isSuperOwner?: boolean;
  isInstantBookingOnly?: boolean;
  category?: string;
  seats?: number;
  fuel?: string[];
  transmission?: string[];
  gears?: number;
  availableFrom?: string;
  availableTo?: string;
  currentLocalTimeISO?: string;
  hitsPerPage?: number;
  page?: number;
  aroundLat?: number;
  aroundLng?: number;
  aroundRadiusInKm?: number;
  cities?: string[];
  ids?: string[];
  currency?: string;
  embed?: SearchVehiclesEmbedOption[];
}

export interface SearchVehiclesResultDTO {
  /**
   * Search results for current page
   */
  hits: VehicleSearchDTO[];
  /**
   * [Pagination] Current page index. First page starts from `0`
   */
  page: number;
  /**
   * [Pagination] Total number of pages
   */
  pagesTotal: number;
  /**
   * Total number of search hits
   */
  hitsTotal: number;
}

export type UnavailableReason = {
  type: 'period';
  /**
   * Message explaining why unavailability reason.
   */
  message: string;
};

export interface AskOwnerForDatesBodyDTO {
  message: string;
  from: string;
  to: string;
}

export interface AskOwnerForDatesCreatedDTO {
  chatId: string;
  askOwnerId: string;
}

export enum ReportTypeEnum {
  inappropriateBehavior = 'inappropriate_behavior',
  scam = 'scam',
  spam = 'spam',
  rentalDataMismatch = 'rental_data_mismatch'
}

export interface CreateNewProfileReportBodyDTO {
  type: ReportTypeEnum;
  description: string;
}

export interface CreateNewSupportInboxBodyDTO {
  forProfileId: string;
}

export interface SuspendUserProfileBodyDTO {
  type: ReportTypeEnum;
  description: string;
}

export interface DisableAccountBodyDTO {
  closeSupportInbox?: boolean;
}

export interface RemoveSuspensionFromUserProfileBodyDTO {
  comments?: string;
}

export interface CreateAppealForCurrentSuspensionBodyDTO {
  description: string;
}

export interface FileAttachment {
  name: string;
  downloadUrl: string;
  mimeType: string;
}

export interface SupportInboxChatMessageBodyDTO {
  content: string;
  attachmentFileNames?: string[];
}

export enum SupportInboxHistoryItemType {
  report = 'report',
  suspension = 'suspension',
  suspensionAppeal = 'suspensionAppeal',
  suspensionRemoval = 'suspensionRemoval',
  message = 'message'
}

export class ProfileReport {
  forUid!: string;
  authorUid!: string;
  reason!: {
    type: ReportTypeEnum;
    description: string;
  };
}

export interface ProfileSuspension {
  forUid: string;
  authorUid: string;
  reason: {
    type: ReportTypeEnum;
    description: string;
  };
  suspensionRemovalId?: string;
  suspensionRemovedAt?: string;
  appealId?: string;
}

export interface ProfileSuspensionAppeal {
  suspensionId: string;
  authorUid: string;
  description: string;
}

export interface ProfileSuspensionRemoval {
  suspensionId: string;
  authorUid: string;
  comments?: string;
}

export interface SupportInboxChatMessage {
  authorUid: string;
  content: string;
  attachments?: FileAttachment[];
}

/**
 * Support inbox history model.
 *
 * Referenced in `SupportInboxModel`, `BellNotificationItemModel`
 */
export class SupportInboxHistoryItemModel {
  id!: string;
  createdAt!: string;
  type!: SupportInboxHistoryItemType;

  data!:
    | ProfileReport
    | ProfileSuspension
    | ProfileSuspensionAppeal
    | ProfileSuspensionRemoval
    | SupportInboxChatMessage;
}

export type SupportInboxHistoryItemDocumentField =
  DeepKeyOf<SupportInboxHistoryItemModel>;

export class SupportInboxModel {
  id!: string;
  createdAt!: string;
  profileId!: string;
  isOpened!: boolean;
  lastHistoryItem?: SupportInboxHistoryItemModel;
  userIsDisabled?: boolean;
  reports!: {
    total: number;
    totalSinceLastSuspensionRemoval: number;
  };
  suspensions!: {
    total: number;
    currentSuspensionId?: string;
    lastSuspensionId?: string;
  };
}

export type SupportInboxDocumentField = DeepKeyOf<SupportInboxModel>;

export interface SupportInboxDTO extends SupportInboxModel {
  profile?: PrivateProfileDTO;
}

export type GetSupportInboxHistoryOptionsDTO = {
  limitToLast?: number;
  endBefore?: any;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export interface FileForUpload {
  mimeType: string;
  fileName: string;
  pathOnDisk: string;
}

export type GetReportsForOthersOptionsDTO = {
  orderBy?: string;
  orderDir?: OrderDirection;
};

export class ReportForProfileDTO {
  id!: string;
  createdAt!: string;
  type!: SupportInboxHistoryItemType.report;

  data!: Omit<ProfileReport, 'authorUid'>;
}

export class UserClaimsDoc {
  id!: string;
  roles?: RoleEnum[];
  isSuspended?: boolean;
  _lastCommitted?: FirestoreTimestamp;
}

export type UserClaimsDocumentField = DeepKeyOf<UserClaimsDoc>;

export class UserInAuthSystemSubject {
  constructor(attrs: UserInAuthSystemSubject) {
    Object.assign(this, attrs);
  }
  uid!: string;
}

export enum CouponType {
  bookingDiscount = 'booking_discount'
}

export type CouponEmbeddedStats = {
  timesClaimed: number;
};

/**
 * Model for coupon code to be used for various discounts.
 */
export class CouponModel {
  /**
   * Autogenerated id.
   */
  id!: string;
  /**
   * Actual coupon code to be used to claim a discount.
   */
  couponCode!: string;
  /**
   * Timestamp when coupon was created. Follows extended ISO8601
   */
  createdAt!: string;
  /**
   * Timestamp when coupon becomes expired. After that time the coupon
   * cannot be claimed.
   *
   * If set to `null` - coupon does not have expiration time.
   */
  expiresAt!: string | null;
  /**
   * How many % discount this coupon provides.
   *
   * Possible numbers vary from 1 to 99
   */
  discountPercentage!: number;
  /**
   * Determines where coupon can be applied.
   */
  type!: CouponType;
  /**
   * Indicates how many times this coupon can be claimed.
   *
   * `maxClaims` will have value `0` to indicate that the coupon can be
   * claimed unlimited times.
   */
  maxClaims!: number;
  /**
   * Embedded statistics about coupon usage/performance.
   */
  stats!: CouponEmbeddedStats;
}

export type CouponDocumentField = DeepKeyOf<CouponModel>;

/**
 * Model that represents coupon claim.
 *
 * Related to: `CouponModel`
 */
export class CouponClaimModel {
  /**
   * Autogenerated id.
   */
  id!: string;
  /**
   * Coupon id that is claimed. Do NOT misconfuse `id` with `couponCode`.
   */
  couponId!: string;
  /**
   * Timestamp when coupon was claimed. Follows extended ISO8601
   */
  claimedAt!: string;
  /**
   * Id of the user who used the coupon.
   */
  claimedByUid!: string | null;
}

export type CouponClaimDocumentField = DeepKeyOf<CouponClaimModel>;

export type CreateNewCouponDTO = {
  /**
   * Coupon code to use for the coupon. If not passed, coupon code will be
   * autogenerated.
   */
  couponCode?: string;
  /**
   * Optional timestamp when coupon has to expire. Provided in extended ISO8601.
   */
  expiresAt?: string | null;
  /**
   * How many times this coupon can be claimed. Pass `0` for unlimited.
   */
  maxClaims: number;
  /**
   * Coupon application area.
   */
  type: CouponType;
  /**
   * How much % of discount this coupon gives.
   */
  discountPercentage: number;
};

export type GetCouponsFilterDTO = {
  couponCode?: string;
  isUsed?: boolean;
  orderBy?: string;
  orderDir?: OrderDirection;
};

export type BookingPeriod = {
  /**
   * Pick-up time in local ISO format.
   */
  from: string;
  /**
   * Return time in local ISO format.
   */
  to: string;
};

type CheckoutPriceParams = {
  /**
   * Id of the booked vehicle.
   */
  vehicleId: string;
  /**
   * Picked extra kilometers for the booking.
   *
   * If vehicle does not support kilometers (e.g. bicycle), extraKm will be
   * ignored.
   */
  extraKm?: number;
  /**
   * Picked vehicle accessories for the booking. Array of ids.
   */
  accessories?: string[];
  /**
   * Coupon code to apply for booking.
   *
   * Discount will be given for the amount payable online.
   */
  discountCode?: string;
  /**
   * User selected erCare option.
   */
  erCareOptionId?: string;
  /**
   * Affiliate id who is the referrer for this booking request.
   */
  referrerId?: string;
  /**
   * Currency for which to convert.
   */
  currency?: string;
  /**
   * Whether to apply b2w points discount
   */
  applyB2wPoints?: boolean;
};

type CheckoutPriceResultShared = {
  /**
   * Price for the selected period without discount consideration.
   */
  periodPriceGross: number;
  /**
   * Price that must be paid online by renter.
   *
   * If booking is fully paid online, `totalPriceOnline` will equal
   * `totalPrice`
   */
  totalPriceOnline: number;
  /**
   * Price that must be paid in cash by renter.
   *
   * This value is populated when booking is partially paid online and the
   * rest must be paid in cash. In this scenario, `isSplitPayment` will be
   * set to `true`
   */
  totalPriceCash: number;
  /**
   * Indicates whether booking payment must be partially paid online and
   * the rest must be paid in cash.
   */
  isSplitPayment: boolean;
  /**
   * Indicates whether booking request will be automatically accepted when
   * created.
   */
  isInstantBooking: boolean;
  /**
   * Currency for the pricing.
   */
  currency: string;
  /**
   * Coupon code used for discount.
   *
   * If coupon was not applied, this value will be `null`
   */
  couponCodeUsed: string | null;
  /**
   * Select ER Care.
   *
   * If er care was not selected, this value will be `null`
   */
  erCareSelected: Pick<
    ErCareInsuranceCoverageOption,
    'coverageAmount' | 'coverageCurrency'
  > | null;
  /**
   * Becomes `true` when `from` parameter (pick-up time) is very close to
   * current time.
   *
   * Can be used to show a banner that pick-up is very soon and it is
   * better to change pick-up time to increase chances of booking request
   * get accepted.
   */
  adviseToChangeDates: boolean;
  /**
   * Indicates whether vehicle can be rented on selected time period.
   */
  isAvailable: boolean;
  /**
   * Array of errors/reasons why `isAvailable` is `false`. If vehicle can
   * be rented, `unavailableReasons` will be empty.
   */
  unavailableReasons: UnavailableReason[];
  /**
   * Array of time ranges (from-to) which make vehicle unavailable for
   * rental.
   *
   * `unavailableIntersectedDates` will be populated with time ranges that
   * intercept with selected time period.
   *
   * If selected period does NOT intercept with vehicle calendar,
   * `unavailableIntersectedDates` will be an empty array.
   */
  unavailableIntersectedDates: CalendarRange[];
};

export interface CalculateCheckoutPriceResult {
  /**
   * Indicates how many days booking lasts.
   */
  days: number;
  /**
   * Indicates how many hours last booking day has. Used along with `days`.
   */
  hours: number;
  /**
   * Indicates how many minutes last booking hours has. Used along with
   * `days`, `hours`.
   */
  minutes: number;
  /**
   * Indicates how much discount renter gets for selected time period.
   *
   * If there is no discount, `activeDiscount` will be `0`.
   */
  activeDiscount: number;
  /**
   * Indicates how many % of discount renter gets for selected time period.
   *
   * The value varies from `0-100`.
   */
  activeDiscountPercentage: number;
  /**
   * Total price for driver service.
   */
  driverTotal: number;
  /**
   * How many kilometers included by default with selected period.
   *
   * If unlimited, value is set to `INCLUDED_KM_UNLIMITED_OPTION`
   *
   * For bicycle this value will be always set to `0`
   */
  includedKm: number;
  /**
   * Indicates how much discount renter gets for selected time period.
   */
  periodPrice: number;
  /**
   * Gross price for the selected period. It does NOT consider discounts.
   */
  periodPriceNoDiscount: number;
  /**
   * Total booking price without renter fee and coupon discount.
   */
  totalPriceGross: number;
  /**
   * How much owner gives up to the platform.
   */
  ownerFeeTotal: number;
  /**
   * How much renter gives up to the platform.
   */
  renterFeeTotal: number;
  /**
   * How much owner makes from this booking.
   */
  ownerEarnings: number;
  /**
   * Gross platform earning from this booking. This value does not consider
   * any discounts.
   */
  platformEarningsGross: number;
  /**
   * Pure and final platform earning from this booking. This value
   * considers all discounts.
   */
  platformEarningsPure: number;
  /**
   * Total price for picked extra kilometers.
   *
   * For bicycle this value will be always set to `0`
   */
  extraKmTotal: number;
  /**
   * Total price for picked vehicle accessories.
   */
  accessoriesTotal: number;
  /**
   * Total amount discounted for applying a coupon code.
   *
   * If coupon was not applied, this value will be `0`
   */
  couponDiscountTotal: number;
  /**
   * Total amount for choosing er care coverage.
   *
   * If coverage was not applied, this value will be `0`
   */
  erCareTotal: number;
  /**
   * Total amount discounted for using affiliate link.
   *
   * If affiliate referrer is not present, this value will be `0`
   */
  affiliateLinkDiscountTotal: number;
  /**
   * Total amount that affiliate earns from booking.
   *
   * If affiliate referrer is not present, this value will be `0`
   */
  affiliateShareTotal: number;
  /**
   * Price that must be paid online by renter.
   *
   * If booking is fully paid online, `totalPriceOnline` will equal
   * `totalPrice`
   */
  totalPayableOnline: number;
  /**
   * Price that must be paid in cash by renter.
   *
   * This value is populated when booking is partially paid online and the
   * rest must be paid in cash. In this scenario, `isSplitPayment` will be
   * set to `true`
   */
  totalPayableInCash: number;
  /**
   * Total booking price that must be paid by renter.
   */
  totalPrice: number;
  /** How many points can be redeemed for the booking */
  b2wPointsCanBeRedeemed: number;
  /** How many points are redemeed */
  b2wPointsRedeemed: number;
  /** Total amount discount from using b2w points. */
  b2wPointsDiscountTotal: number;
  selectedAccessories: VehicleAccessory[];
}

export type GetCheckoutPriceQueryDTO = BookingPeriod & CheckoutPriceParams;

export type GetPreCheckoutPriceQueryDTO = BookingPeriod &
  Pick<
    CheckoutPriceParams,
    | 'vehicleId'
    | 'referrerId'
    | 'currency'
    | 'discountCode'
    | 'accessories'
    | 'erCareOptionId'
  >;

export type CheckoutPriceResultDTO = BookingPeriod &
  Pick<
    CheckoutPriceResultShared,
    | 'periodPriceGross'
    | 'isSplitPayment'
    | 'isInstantBooking'
    | 'couponCodeUsed'
    | 'erCareSelected'
    | 'currency'
    | 'totalPriceOnline'
    | 'totalPriceCash'
    | 'adviseToChangeDates'
    | 'isAvailable'
    | 'unavailableReasons'
    | 'unavailableIntersectedDates'
  > &
  Pick<
    CalculateCheckoutPriceResult,
    | 'days'
    | 'hours'
    | 'minutes'
    | 'periodPrice'
    | 'activeDiscountPercentage'
    | 'activeDiscount'
    | 'includedKm'
    | 'extraKmTotal'
    | 'driverTotal'
    | 'accessoriesTotal'
    | 'renterFeeTotal'
    | 'couponDiscountTotal'
    | 'erCareTotal'
    | 'affiliateLinkDiscountTotal'
    | 'totalPriceGross'
    | 'totalPrice'
    | 'b2wPointsCanBeRedeemed'
    | 'b2wPointsRedeemed'
    | 'b2wPointsDiscountTotal'
  > & {
    /** How many points renter can earn from this booking */
    b2wPointsCanBeEarned: number;
    pickedAccessories: VehicleAccessory[];
  };

export type PreCheckoutPriceResultDTO = BookingPeriod &
  Pick<
    CheckoutPriceResultShared,
    | 'isAvailable'
    | 'adviseToChangeDates'
    | 'isInstantBooking'
    | 'isSplitPayment'
    | 'periodPriceGross'
    | 'unavailableIntersectedDates'
    | 'unavailableReasons'
    | 'currency'
    | 'couponCodeUsed'
    | 'erCareSelected'
  > &
  Pick<
    CalculateCheckoutPriceResult,
    | 'days'
    | 'hours'
    | 'minutes'
    | 'periodPrice'
    | 'activeDiscountPercentage'
    | 'activeDiscount'
    | 'driverTotal'
    | 'includedKm'
    | 'renterFeeTotal'
    | 'affiliateLinkDiscountTotal'
    | 'totalPrice'
    | 'couponDiscountTotal'
    | 'erCareTotal'
    | 'accessoriesTotal'
  > & {
    pickedAccessories: VehicleAccessory[];
  };

export type PreCheckoutEarningsResultDTO = BookingPeriod &
  Pick<
    CheckoutPriceResultShared,
    | 'isAvailable'
    | 'adviseToChangeDates'
    | 'isInstantBooking'
    | 'isSplitPayment'
    | 'periodPriceGross'
    | 'unavailableIntersectedDates'
    | 'unavailableReasons'
    | 'currency'
  > &
  Pick<
    CalculateCheckoutPriceResult,
    | 'days'
    | 'hours'
    | 'minutes'
    | 'periodPrice'
    | 'activeDiscountPercentage'
    | 'activeDiscount'
    | 'driverTotal'
    | 'includedKm'
    | 'ownerFeeTotal'
    | 'ownerEarnings'
  >;

export type ActivityGoodDeal = {
  currency: string;
  /** Good deal price for related currency  */
  price: number;
};

export type ActivityDataDTO = {
  city: CityLandingModel | null;
  allRentals: VehicleSearchDTO[];
  cheapRentals: VehicleSearchDTO[];
  mostSearchedRentals: VehicleSearchDTO[];
  mostPopularOwner: ProfileDTO | null;
  mostPopularVehicleType: string | null;
  goodDeals: ActivityGoodDeal[];
};

/**
 * Model which represents deleted user record.
 *
 * Once user is deleted, `DeletedUserModel` record will be created.
 */
export class DeletedUserModel {
  /** Id corresponds to deleted user id */
  id!: string;
  /** `ProfileModel` serialized into string at the moment of deletion */
  profileDump!: string;
  /** Array of `DocumentVerificationModel` serialized into string at the
   * moment of deletion */
  documentsDump!: string;
  /**
   * Timestamp of when user account was deleted. Follows extended ISO8601
   * */
  createdAt!: string;
}

export type DeletedUserDocumentField = DeepKeyOf<DeletedUserModel>;

export enum AffiliatePayoutProvider {
  paypal = 'paypal'
}

export enum AffiliatePayoutState {
  'REQUESTED' = 'requested',
  'SUCCESS' = 'success',
  'FAILED' = 'failed'
}

export type AffiliatePayoutProviderResponse = PaypalPayout;
export type AffiliatePayoutProviderAccount = PaypalPayoutAccountInfo;
export type AffiliatePayoutShortProviderAccount = PaypalPayoutShortAccountInfo;

/**
 * Model that represents affiliate profile for a user.
 *
 * @relations
 * - ProfileModel
 * - AffiliatePayoutModel
 */
export class AffiliateModel {
  /**
   * Autogenerated or user-entered id. Identifies affiliate user.
   */
  id!: string;
  /**
   * Id of main profile of affiliate.
   */
  profileId!: string;
  /**
   * Actual affiliate funds balance that affiliate can request to payout.
   *
   * The record is `currency:amount` pair
   */
  actualBalance!: Record<string, number>;

  /**
   * Not finalized, on-hold affiliate funds balance.
   *
   * This balance accumulates funds that can be potentially moved into
   * `actualBalance`. Funds are "on-hold" due to multiple reasons such as
   * pending/active bookings. The affiliate was referenced but the deal
   * (booking) has not been finished yet.
   *
   * Once funds become available (deal finished), they are removed from
   * `pendingBalance` and accumulated in `actualBalance`. If deal fails to
   * finish, funds from `pendingBalance` are removed.
   *
   * The record is `currency:amount` pair
   */
  pendingBalance!: Record<string, number>;
  payoutMethods!: {
    [provider in AffiliatePayoutProvider]?: AffiliatePayoutProviderAccount;
  };
  /**
   * Timestamp of when user became an affiliate. Follows extended ISO8601
   */
  createdAt!: string;

  /**
   * Embedded stats of affiliate references
   */
  referenceStats?: {
    /**
     * Total number of references by gained status
     */
    totalByGainedState?: Record<AffiliateReferenceState, number>;
    /**
     * Total number of references by reference source
     */
    totalBySource?: Record<AffiliateReferenceSource, number>;
    /**
     * Total number of references by reference source
     */
    totalByProgressHistoryType?: Record<
      AffiliateReferenceProgressItemType,
      number
    >;
  };

  /**
   * General embedded stats of affiliate
   */
  otherStats?: {
    /**
     * Total number of users who signed up with user as the referrer
     */
    signups: number;
  };
}

export type AffiliateDocumentField = DeepKeyOf<AffiliateModel>;

export enum AffiliateReferenceState {
  /**
   * Affiliate was referenced. From now on, state remains unknown until
   * state is updated with a known state.
   */
  created = 'created',
  /**
   * Reference paid off. E.g. booking ended.
   */
  successful = 'successful',
  /**
   * Reference was not completed. E.g. booking was cancelled.
   */
  notSuccessful = 'not_successful'
}

export enum AffiliateReferenceShareType {
  /**
   * Share amount is fixed.
   */
  fixed = 'fixed',
  /**
   * Share amount is based on `shareRate` percentage.
   */
  rateBased = 'rate_based'
}

export enum AffiliateReferenceProgressItemType {
  bookingCreate = 'booking_create',
  bookingFail = 'booking_fail',
  bookingSuccess = 'booking_success',
  vehicleFirstRentalCreate = 'vehicle_first_rental_create',
  vehicleFirstRentalFail = 'vehicle_first_rental_fail',
  vehicleFirstRentalSuccess = 'vehicle_first_rental_success'
}

export type AffiliateReferenceProgressItem = {
  type: AffiliateReferenceProgressItemType;
  createdAt: string;
};

export enum AffiliateReferenceSource {
  /**
   * Reference originates from booking.
   */
  booking = 'booking',
  /**
   * Reference originates from signup.
   */
  firstRental = 'first_rental'
}

/**
 * Model that represents an "event" when affiliate user was used as the
 * referrer for a booking.
 *
 * @relations
 * - AffiliateModel
 * - BookingModel
 */
export class AffiliateReferenceModel {
  /**
   * Autogenerated id.
   */
  id!: string;
  /**
   * Id of related affiliate. Points to `AffiliateModel`.
   */
  affiliateId!: string;
  /**
   * Related booking id.
   *
   * If `refSource` originates from booking, this value is id of the
   * booking where affiliate user is the referrer.
   *
   * If `refSource` originates from firstrentalout flow, this
   * value is id of the booking for the first uploaded vehicle.
   *
   * Points to `BookingModel`.
   */
  bookingId!: string;
  /**
   * Id of vehicle that is being rented out for the first time.
   *
   * It is present when reference is created in firstrentalout flow.
   */
  vehicleId?: string;
  /**
   * Id of user who is the subject for firstrentalout flow.
   *
   * It is present when reference is created in firstrentalout flow.
   */
  invitedUserId?: string;
  /**
   * Reference state: successful, not succesfull, etc.
   */
  state!: AffiliateReferenceState;
  /**
   * Reference source.
   */
  refSource!: AffiliateReferenceSource;
  /**
   * Progress history. Basically, it is "reference lifecycle" which varies
   * based on `refSource`.
   */
  progressHistory!: AffiliateReferenceProgressItem[];
  /**
   * Timestamp of when this reference state was updates. Follows extended ISO8601
   */
  stateUpdatedAt!: string;
  /**
   * Reference details. How much affiliate gets from this reference.
   */
  share!: {
    currency: string;
    amount: number;
    type: AffiliateReferenceShareType;
    /**
     * % that affiliate earns from this booking.
     *
     * Present only if share type is `rate_based`, otherwise `null`
     */
    shareRate: number | null;
  };
  /**
   * Timestamp of when this reference was created. Follows extended ISO8601
   */
  createdAt!: string;
}

export type AffiliateReferenceDocumentField =
  DeepKeyOf<AffiliateReferenceModel>;

export type AffiliateReferenceListQueryDTO = {
  startAfter?: string;
  endBefore?: string;
  limit?: number;
};

export type AffiliateReferenceDTO = Pick<
  AffiliateReferenceModel,
  'id' | 'createdAt' | 'state'
> & {
  amount: number;
  currency: string;
};

export type AffiliatePayoutSource = {
  currency: string;
  amount: number;
  /**
   * Total amount available in source currency at the time of this
   * payout.
   */
  currentTotalAmount: number;
  exchangeRates: ExchangeRatePaymentLink;
  /**
   * Object with converted amount according to `exchangeRates`.
   */
  convertedAmount: {
    amount: number;
    currentTotalAmount: number;
    currency: string;
  };
};

/**
 * Model that represents single payout requested by an affiliate.
 *
 * @relations
 * - AffiliateModel
 */
export class AffiliatePayoutModel {
  /**
   * Autogenerated payout id.
   */
  id!: string;
  /**
   * Id of affiliate who requested this payout. Points to `AffiliateModel`.
   */
  affiliateId!: string;
  /**
   * State of this payout request. Success or fail.
   */
  state!: AffiliatePayoutState;
  payoutProvider!: AffiliatePayoutProvider;
  payoutProviderAccount!: AffiliatePayoutProviderAccount;
  payoutProviderResponse!: AffiliatePayoutProviderResponse;
  amount!: number;
  currency!: string;
  sources!: AffiliatePayoutSource[];
  updatedAt!: string;
  /**
   * Timestamp of when payout was created/requested. Follows extended ISO8601
   */
  createdAt!: string;
}

export type AffiliatePayoutDocumentField = DeepKeyOf<AffiliatePayoutModel>;

export type AffiliatePayoutDTO = Pick<
  AffiliatePayoutModel,
  'amount' | 'currency' | 'createdAt' | 'id' | 'state'
>;

export type AffiliateDayStats = {
  bookings: {
    /**
     * Total number of bookings that referenced the affiliate.
     */
    total: number;
    /**
     * Total number of ended bookings that referenced the affiliate.
     */
    successful: number;
    /**
     * Total number of cancelled/expired bookings that referenced the affiliate.
     */
    notSuccessful: number;
    /**
     * Earnings for this day by currency.
     *
     * The record is `currency:amount` pair
     */
    earningsByCurrency: Record<string, number>;
  };
};

/**
 * Model that represents affiliate statistics for a month.
 *
 * @relations
 * - AffiliateModel
 */
export class AffiliateMonthStatsModel {
  /**
   * This is unique id. Id equals `{affiliateId}_{monthISO}`
   */
  id!: string;
  /**
   * Month in ISO format. Example: `2021-05`
   */
  monthISO!: string;
  /**
   * Id of affiliate who "owns" this stats
   */
  affiliateId!: string;
  /**
   * Per day statistics for this month. Some months can not be present if
   * there are no stats for that day.
   *
   * Object keys are days in ISO format. Example: `2021-05-01`
   */
  dayStats!: Record<string, AffiliateDayStats>;
}

export type AffiliateMonthStatsDocumentField =
  DeepKeyOf<AffiliateMonthStatsModel>;

export type AffiliatePayoutMethodsShortForm = {
  [provider in AffiliatePayoutProvider]?: AffiliatePayoutShortProviderAccount;
};

export type AffiliateMonthStatsDTO = Pick<
  AffiliateMonthStatsModel,
  'monthISO' | 'dayStats'
>;

export type AffiliatePayoutPublicDTO = Pick<
  AffiliatePayoutModel,
  | 'id'
  | 'amount'
  | 'currency'
  | 'payoutProvider'
  | 'createdAt'
  | 'state'
  | 'updatedAt'
  | 'affiliateId'
> & {
  payoutProviderError?: string;
  payoutProviderAccount: AffiliatePayoutShortProviderAccount;
};

export type AmountCurrency = {
  currency: string;
  amount: number;
};

export type AffiliateReferencePublicDTO = Pick<
  AffiliateReferenceModel,
  'createdAt' | 'affiliateId' | 'id' | 'state'
> & {
  share: AmountCurrency;
};

export type AffiliateDashboardDTO = Pick<AffiliateModel, 'id' | 'profileId'> & {
  payoutMethods: AffiliatePayoutMethodsShortForm;
  payoutPayable: AmountCurrency[];
  payoutPending: AmountCurrency[];
  totalSales: number | string;
  totalSignups: number | string;
  totalRentalOuts: number | string;
  canWithdraw: boolean;
  withdrawMessage: string;
};

export type SetupReferenceIdQueryDTO = {
  refId?: string;
};

export interface TransferBookingEarningsFnPayload {
  bookingId: string;
}

export enum BookingReminderType {
  soonExpires = 'soon_expires',
  soonStarts = 'soon_starts'
}

export interface SendBookingReminderPayload {
  bookingId: string;
  type: BookingReminderType;
  notifyWith: {
    email: boolean;
    sms: boolean;
  };
}

export interface HandleSettlementEndsPayload {
  bookingId: string;
}

export interface GetBookingQueryOptionsDTO {
  currency?: string;
}

type ChartDataMonthly = {
  title: string;
  footer: {
    title?: string;
    text?: string | number;
  };
  labels: string[];
  datasets: {
    label: string;
    data: (number | string)[];
    backgroundColor?: string | string[];
    borderColor?: string | string[];
    borderWidth?: number;
  }[];
};

export type BookingsTotalStatsListItem = {
  title: string;
  value: number;
};

export type BookingsTotalStatsItem = {
  title: string;
  list: BookingsTotalStatsListItem[];
};

export type BookingsTotalTopListItem = {
  title: string;
  value: string;
  entityType: 'vehicle' | 'city';
};

export type BookingsTotalTopListSection = {
  title: string;
  list: BookingsTotalTopListItem[];
};

export type BookingsStatsData = {
  lowestYear: number;
  lastUpdatedAtMs: number;
  bookingsByStatusMonthly: ChartDataMonthly;
  averageSaleByCurrency: ChartDataMonthly;
  rentalDaysByStatusMonthly: ChartDataMonthly;
  companyProfitMonthlyByCurrency: ChartDataMonthly;
  companyProfitMonthlyEurByCategory: ChartDataMonthly;
  companyProfitMonthlyPhpByCategory: ChartDataMonthly;
  moneyFlowMonthly: ChartDataMonthly;
  ownersIncomeMonthly: ChartDataMonthly;
  moneyRefundedMonthly: ChartDataMonthly;
  returningCustomers: ChartDataMonthly;
  rentersNationalities: ChartDataMonthly;
  vehicleCategories: ChartDataMonthly;
  vehicleTypes: ChartDataMonthly;
  totals: BookingsTotalStatsItem[];
  topList: BookingsTotalTopListSection[];
};

export type AffiliateAdminTableItem = AffiliateModel & {
  totalSuccessfullBookingReferences: number;
  totalSignedUpWithReference: number;
  totalSuccessfullFirstRentalOuts: number;
  /** Affiliate balance information in payout currency */
  _payoutCurrency: {
    currency: string;
    actualBalance: number;
    pendingBalance: number;
  };
};

export type AdminDashboardData = {
  lastUpdatedAtMs: number;
  payoutProviderSettings: AffiliatePayoutProviderSettings;
  affiliatesInfo: AffiliateAdminTableItem[];
};

export type SupportInboxAdminUnread = {
  unreadMessages?: number;
};

export type UnreadCountersForUser = {
  chat_presence?: {
    [roomId: string]: boolean;
  };
  chat_unread_messages?: {
    [roomId: string]: number;
  };
  support_inbox_presence?: {
    [inboxId: string]: boolean;
  };
  support_inbox_unread_messages?: {
    [roomId: string]: number;
  };
};

export interface RemoteConfigDTO {
  activePromoBanner: Pick<
    PromoBanner,
    'mainText' | 'callToActionText' | 'href'
  > | null;
}

export interface UpdatePromoBannerBodyDTO {
  mainText: string;
  callToActionText: string;
  href: string;
  isActive: boolean;
  includeExpiresAt: boolean;
  expiresAt?: string | null;
}

export enum BellNotificationType {
  /**
   * Support inbox message or entry user must be aware of
   */
  supportInboxEntry = 'support_inbox',
  /**
   * New review for you
   */
  profileReview = 'profile_review',
  /**
   * New booking user must be aware of
   */
  newBooking = 'booking_new'
}

export interface VehicleForBellEntry {
  id: string;
  thumbnail: string;
  brand: string;
  model: string;
  year: number;
}

export interface BookingForBellEntry {
  id: string;
  vehicle: VehicleForBellEntry;
}

/**
 * Describes bell entry in the database
 */
export class BellNotificationItemModel {
  id!: string;
  createdAt!: string;
  readAt!: string | null;
  /** Who must be notified */
  userId!: string;
  /** Whether notification is read/unread by the user */
  isUnread!: boolean;
  /** Whether notification is intended for administrative user (i.e. users with 'admin' role) */
  isAdmistrative!: boolean;
  /** Type of notification which describes what shape `data` will be */
  type!: BellNotificationType;
  /** Corresponds to `type` of notification */
  data!:
    | SupportInboxHistoryItemModel
    | ProfileReviewModel
    | BookingForBellEntry;
}

export type BellNotificationItemDocumentField =
  DeepKeyOf<BellNotificationItemModel>;

export interface MarkBellNotificationQueryDTO {
  /** Id of notification */
  id: string;
  /** If `true` mark as unread, if `false` mark as read */
  isUnread: boolean;
}

export interface GetBellNotificationsQueryDTO {
  limit?: number;
  /** If `true` query only unread, if `false` query only read */
  isUnread?: boolean;
}

export enum B2wPointsSource {
  booking = 'booking'
}

export enum B2wPointsActionType {
  earn = 'earn',
  spend = 'spend',
  return = 'return',
  expire = 'expire'
}

export class B2wPointsTransactionModel {
  id!: string;
  createdAt!: string;
  /** Points to @ProfileModel */
  profileId!: string;
  /** How many points were earned, spent, or returned. Value can be a negative number. */
  pointsValueChange!: number;
  /** Describes the transaction */
  actionType!: B2wPointsActionType;
  /** If present, shows the origin of transaction */
  source?: B2wPointsSource;
  /** This field is present if source is `booking`. Points to @BookingModel */
  bookingId?: string;
}

export type B2wPointsTransactionDocumentField =
  DeepKeyOf<B2wPointsTransactionModel>;

export interface ExpireB2wPointsStatusTaskPayload {
  profileId: string;
  expireAt: string;
}

export interface SendB2wPointsExpirationReminderTaskPayload {
  profileId: string;
  expireAt: string;
}

export type LandingBlogItem = {
  image: string;
  href?: string;
};

export class LandingBlogModel {
  id!: 'landing_blog';
  items!: LandingBlogItem[];
}

export interface UpdateLandingBlogBodyDTO {
  items: LandingBlogItem[];
}

export interface UpdateEmailMarketingBodyDTO {
  sections?: EmailMarketingSection[];
}

export type PopularDestinationItem = {
  name: string;
  href: string;
  imgSrc?: string;
};

export class PopularDestinationModel {
  id!: 'landing_popular_destination';
  sectionTitle!: string;
  descriptionBlock!: {
    title: string;
    text: string;
  };
  destinations!: PopularDestinationItem[];
  coverImg!: string;
}

export type UpdatePopularDestinationBodyDTO = Omit<
  PopularDestinationModel,
  'id'
>;

export type PeriodicItem = {
  /** ISO date */
  startDate: string;
  /** ISO date */
  finalDate: string;
  /** Total number of items between `startDate` and `finalDate` */
  total: number;
};

export type VehiclesStatsDTO = {
  totalCreated: number;
  totalCreatedPeriodic: number;
  /** How many vehicles succesfully rented at least once */
  totalRentedOut: number;
  /** Percentage of `totalRentedOut` / `totalCreated` */
  percentageRentedOut: string | number;
  createdPeriodic: PeriodicItem[];
};

export type ProfilesStatsDTO = {
  totalSignedUp: number;
  totalOwners: number;
  totalSignedUpPeriodic: number;
  signedUpPeriodic: PeriodicItem[];
};

export type ProjectChartStatsDTO = {
  vehicles: VehiclesStatsDTO;
  profiles: ProfilesStatsDTO;
  lastUpdatedAt: string;
};

export type SmsNotificationProvider = 'twilio' | 'semaphore' | '__local__';

export type SmsNotificationResultMeta = {
  /** Whether SMS was sent */
  isSuccess: boolean;
  /** Timestamp when sms was attempted */
  sentAt: string;
  /** SMS service provider name that was used */
  provider: SmsNotificationProvider;
  /** Provider-specific SMS message id if SMS was sent */
  messageId?: string;
  /** Error if sms failed and was not sent */
  error?: string;
};

/**
 * Describes SMS notification to be sent
 */
export class SmsNotificationModel {
  id!: string;
  /** ISO date and time */
  createdAt!: string;
  /** Profile id associated with sms */
  profileId?: string;
  /** Target phone number in international format */
  to!: string;
  /** SMS content */
  body!: string;
  /** Metadata about sent/failed SMS */
  resultMeta?: SmsNotificationResultMeta;
  /** Set to true to trigger sms retry. SMS will not be retried if already sent*/
  forceAttempt?: boolean;
}

export type SmsNotificationDocumentField = DeepKeyOf<SmsNotificationModel>;

export type FcmNotificationResultMeta = {
  /** Whether FCM was sent */
  isSuccess: boolean;
  /** Timestamp when FCM was attempted */
  sentAt: string;
  /** The number of FCMs that were successfully sent */
  successTokensCount?: number;
  /** Error if FCM failed and was not sent */
  error?: string;
};

/**
 * Describes FCM notification to be sent
 */
export class FcmNotificationModel {
  id!: string;
  /** ISO date and time */
  createdAt!: string;
  /** Profile id for which to get FCM tokens */
  profileId!: string;
  /** FCM notification title */
  title!: string;
  /** FCM notification body */
  body!: string;
  /** The link to open when the user clicks on the notification. If not provided, app website is used. */
  linkForClick?: string;
  /** URL to the notification icon. If not provided, app website icon is used. */
  icon?: string;
  /** Data to be passed along with message */
  data?: {
    [key: string]: string;
  } & {
    /** Whether notification must be shown as in-app alert/toast. @default "false" */
    showInForeground?: 'true' | 'false';
  };
  /** Metadata about sent/failed FCM */
  resultMeta?: FcmNotificationResultMeta;
  /** Set to true to trigger FCM retry. FCM will not be retried if already sent */
  forceAttempt?: boolean;
}

export type FcmNotificationDocumentField = DeepKeyOf<FcmNotificationModel>;

export type EmailNotificationResultMeta = {
  /** Whether Email was sent */
  isSuccess: boolean;
  /** Timestamp when Email was attempted */
  sentAt: string;
  /** Final message-Id from transport (if present), otherwise empty string. */
  messageId?: string;
  /** Last SMTP response from the server (if present), otherwise empty string. */
  response?: string;
  /** Error if Email failed and was not sent */
  error?: string;
};

export type EmailNotificationAttachment = {
  /**
   * Filename of the attached file (appears in email)
   */
  fileName: string;
  /**
   * Url to download the file
   */
  downloadUrl: string;
};

/**
 * Describes Email notification to be sent
 */
export class EmailNotificationModel {
  id!: string;
  /** ISO date and time */
  createdAt!: string;
  /** Profile id associated with this email */
  profileId?: string;
  /** Recepient email address or array of recepients */
  to!: string | string[];
  /** Email subject */
  subject!: string;
  /** Optional list of CC's */
  cc?: string | string[];
  /** Optional list of BCC's */
  bcc?: string | string[];
  /** The HTML version of the message. Field `template` has priority over this field */
  html?: string;
  /** The TEMPLATE version of the message. This field has priority over field `html` */
  template?: {
    /** Template name */
    name: string;
    /** Template variables */
    vars: Record<string, any>;
  };
  /** Email attachments */
  attachments?: EmailNotificationAttachment[];
  /** Metadata about sent/failed Email */
  resultMeta?: EmailNotificationResultMeta;
  /** Set to true to trigger Email retry. Email will not be retried if already sent */
  forceAttempt?: boolean;
}

export type EmailNotificationDocumentField = DeepKeyOf<EmailNotificationModel>;
