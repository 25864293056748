import { useCallback, useEffect, useRef } from 'react';
import { createPopper, Instance, VirtualElement } from '@popperjs/core';
import type { Placement } from '@popperjs/core';
import * as customModifiers from './modifiers';
import { cssVars, getEventListenerOptions } from './utils';

export type PopperArrowProps = {
  /**
   * The size of the popover arrow.
   * This sets the `--popper-arrow-size` css property
   */
  size?: string | number;
  /**
   * The box-shadow color of the popover arrow.
   * This sets the `--popper-arrow-shadow-color` css property
   */
  shadowColor?: string;
  /**
   * The background color of the popper arrow.
   * This sets the `--popper-arrow-bg` css property.
   */
  bg?: string;
};

export interface UsePopperProps {
  /**
   * The main and cross-axis offset to displace popper element
   * from its reference element.
   */
  offset?: [number, number];
  /**
   * The distance or margin between the reference and popper.
   * It is used internally to create an `offset` modifier.
   *
   * NB: If you define `offset` prop, it'll override the gutter.
   * @default 8
   */
  gutter?: number;
  /**
   * If `true`, will prevent the popper from being cut off and ensure
   * it's visible within the boundary area.
   * @default true
   */
  preventOverflow?: boolean;
  /**
   * If `true`, the popper will change its placement and flip when it's
   * about to overflow its boundary area.
   * @default true
   */
  flip?: boolean;
  /**
   * If `true`, the popper will match the width of the reference at all times.
   * It's useful for `autocomplete`, `date-picker` and `select` patterns.
   */
  matchWidth?: boolean;
  /**
   * The boundary area for the popper. Used within the `preventOverflow` modifier
   * @default "clippingParents"
   */
  boundary?: 'clippingParents' | 'scrollParent' | HTMLElement;
  /**
   * If provided, determines whether the popper will reposition itself on `scroll`
   * and `resize` of the window.
   */
  eventListeners?: boolean | { scroll?: boolean; resize?: boolean };
  /**
   * The CSS positioning strategy to use.
   * @default "absolute"
   */
  strategy?: 'absolute' | 'fixed';
  /**
   * The placement of the popper relative to its reference.
   *
   * @default "bottom"
   */
  placement?: Placement;
}

export function usePopper(props: UsePopperProps = {}) {
  const {
    placement = 'bottom',
    strategy = 'absolute',
    eventListeners = true,
    offset,
    gutter = 8,
    flip = true,
    boundary = 'clippingParents',
    preventOverflow = true,
    matchWidth
  } = props;

  const reference = useRef<Element | VirtualElement | null>(null);
  const popper = useRef<HTMLElement | null>(null);
  const instance = useRef<Instance | null>(null);

  const cleanup = useRef<() => void>();

  const setupPopper = useCallback(() => {
    if (!reference.current || !popper.current) return;

    // If popper instance exists, destroy it so we can create a new one
    cleanup.current?.();

    instance.current = createPopper(reference.current, popper.current, {
      placement,
      modifiers: [
        customModifiers.innerArrow,
        customModifiers.positionArrow,
        customModifiers.transformOrigin,
        { ...customModifiers.matchWidth, enabled: !!matchWidth },
        {
          name: 'eventListeners',
          ...getEventListenerOptions(eventListeners)
        },
        {
          name: 'offset',
          options: {
            offset: offset ?? [0, gutter]
          }
        },
        {
          name: 'flip',
          enabled: !!flip,
          options: { padding: 8 }
        },
        {
          name: 'preventOverflow',
          enabled: !!preventOverflow,
          options: { boundary }
        }
      ],
      strategy
    });

    // force update one-time to fix any positioning issues
    instance.current.forceUpdate();

    cleanup.current = instance.current.destroy;
  }, [
    placement,
    matchWidth,
    eventListeners,
    offset,
    gutter,
    flip,
    preventOverflow,
    boundary,
    strategy
  ]);

  useEffect(() => {
    return () => {
      /**
       * Fast refresh might call this function and tear down the popper
       * even if the reference still exists. This checks against that
       */
      if (!reference.current && !popper.current) {
        instance.current?.destroy();
        instance.current = null;
      }
    };
  }, []);

  const referenceRef = useCallback(
    <T extends Element | VirtualElement>(node: T | null) => {
      reference.current = node;
      setupPopper();
    },
    [setupPopper]
  );

  const popperRef = useCallback(
    <T extends HTMLElement>(node: T | null) => {
      popper.current = node;
      setupPopper();
    },
    [setupPopper]
  );

  const getRefProps = () => ({
    ref: referenceRef
  });

  const getElProps = () => ({
    ref: popperRef,
    style: {
      position: strategy,
      minWidth: '0px',
      inset: '0 auto auto 0'
    }
  });

  const getArrowProps = (props: PopperArrowProps = {}) => {
    const { size, shadowColor, bg } = props;

    const computedStyle: React.CSSProperties & Record<string, any> = {
      position: 'absolute'
    };

    if (size) {
      computedStyle['--popper-arrow-size'] = size;
    }
    if (shadowColor) {
      computedStyle['--popper-arrow-shadow-color'] = shadowColor;
    }
    if (bg) {
      computedStyle['--popper-arrow-bg'] = bg;
    }

    return {
      'data-popper-arrow': '',
      style: computedStyle
    };
  };

  const getArrowInnerProps = () => ({
    'data-popper-arrow-inner': ''
  });

  return {
    update() {
      return instance.current?.update();
    },
    forceUpdate() {
      instance.current?.forceUpdate();
    },
    transformOrigin: cssVars.transformOrigin.varRef,
    getRefProps,
    getElProps,
    getArrowProps,
    getArrowInnerProps,
    referenceRef,
    popperRef
  };
}

export type UsePopperReturn = ReturnType<typeof usePopper>;
