import { createContext, useContext } from 'react';
import { ButtonProps } from './Button';

export type ButtonGroupCtxValue = Pick<
  ButtonProps<'button'>,
  'colorScheme' | 'variant' | 'size' | 'disabled'
>;

export const ButtonGroupCtx = createContext<ButtonGroupCtxValue | null>(null);
export const useButtonGroupCtx = () => useContext(ButtonGroupCtx);
