import { forwardRef, useRef, useState } from 'react';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { mergeRefs } from '@b2w/shared/react-utils';
import { callAllHandlers } from '@b2w/shared/utility';
import { FormControlMainProps, useInputFormControl } from '../FormControl';
import { twCx } from '../twMerge';

export type InputVariant = 'outline' | 'flushed' | 'unstyled';
export type InputSize = 'md' | 'lg';

const invalidClassName = twCx(
  'data-[invalid=true]:tw-border-error-300 data-[invalid=true]:tw-ring-error-300',
  'data-[invalid=true]:[&:not([data-focused=true])]:hover:tw-border-error-400 data-[invalid=true]:[&:not([data-focused=true])]:hover:tw-ring-error-400',
  'data-[invalid=true]:data-[focused=true]:tw-border-error-500 data-[invalid=true]:data-[focused=true]:tw-ring-error-500'
);

const variantToClassName: Record<InputVariant, string> = {
  outline: twCx(
    'tw-bg-white tw-rounded-xl tw-border tw-border-gray-200 hover:tw-border-gray-300 data-[focused=true]:tw-border-main-500 tw-ring-main-500 data-[focused=true]:tw-ring-1',
    invalidClassName
  ),
  flushed: twCx(
    'tw-bg-white tw-border-b tw-border-gray-200 hover:tw-border-gray-300 data-[focused=true]:tw-border-main-500 tw-ring-main-500 data-[focused=true]:[box-shadow:_0_1px_0_0_var(--tw-ring-color)]',
    invalidClassName
  ),
  unstyled: ''
};

const sizeToClassName: Record<InputSize, Record<InputVariant, string>> = {
  md: {
    outline: 'tw-py-2 tw-px-3 tw-text-base tw-gap-2',
    flushed: 'tw-py-2 tw-text-base tw-gap-2',
    unstyled: 'tw-text-base tw-gap-2'
  },
  lg: {
    outline: 'tw-py-3 tw-px-4 tw-text-lg tw-gap-3',
    flushed: 'tw-py-3 tw-text-lg tw-gap-3',
    unstyled: 'tw-text-lg tw-gap-3'
  }
};

const wrapperMouseDownPreventDefault = (
  ev: React.MouseEvent<HTMLDivElement, MouseEvent>
) => {
  if (ev.currentTarget === ev.target) {
    ev.preventDefault();
  }
};

export type InputOwnProps = FormControlMainProps & {
  /**
   * @default 'outline'
   */
  variant?: InputVariant;
  /**
   * Element to appear inside the input on the left
   */
  leftAddon?: React.ReactNode;
  /**
   * Element to appear inside the input on the right
   */
  rightAddon?: React.ReactNode;
  /** @default 'md' */
  size?: InputSize;
  /** Classes of underlying `<input />` */
  classNameInput?: string;
  /** Classes of input wrapper which visually looks like input.
   * For real input classes, use prop `classNameInput` */
  className?: string;
  /** Ref of input wrapper */
  refWrapper?: React.Ref<HTMLDivElement>;
  /** `onClick` of input wrapper */
  onClickWrapper?: React.MouseEventHandler<HTMLDivElement>;
};

export type InputProps<C extends React.ElementType = 'input'> =
  PolymorphicComponentPropsWithRef<C, InputOwnProps>;

type InputComponent = <C extends React.ElementType = 'input'>(
  props: InputProps<C>
) => JSX.Element;

export const Input: InputComponent = forwardRef(
  <T extends React.ElementType = 'input'>(
    props: PolymorphicComponentProps<T, InputOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      as: Component = 'input',
      type: typeProp,
      variant = 'outline',
      className: classNameWrapper,
      classNameInput,
      leftAddon,
      rightAddon,
      size = 'md',
      refWrapper,
      onClickWrapper,
      ...inputHtmlProps
    } = props;

    const type = Component === 'input' ? typeProp ?? 'text' : typeProp;
    const inputRef = useRef<HTMLInputElement>();

    const inputProps = useInputFormControl(inputHtmlProps);

    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleContainerClick = () => inputRef.current?.focus();
    const handleInputFocus = () => setIsInputFocused(true);
    const handleInputBlur = () => setIsInputFocused(false);

    return (
      <div
        ref={refWrapper}
        onClick={callAllHandlers(onClickWrapper, handleContainerClick)}
        onMouseDown={wrapperMouseDownPreventDefault}
        data-focused={isInputFocused || undefined}
        data-disabled={inputProps.disabled || undefined}
        data-invalid={inputProps['aria-invalid']}
        className={twCx(
          'tw-flex tw-flex-wrap tw-items-center tw-cursor-text',
          'tw-transition-common tw-duration-100',
          'data-[disabled=true]:tw-opacity-40 data-[disabled=true]:tw-cursor-not-allowed',
          variantToClassName[variant],
          sizeToClassName[size][variant],
          classNameWrapper
        )}
      >
        {leftAddon}
        <Component
          {...inputProps}
          ref={mergeRefs(inputRef, ref)}
          type={type}
          onFocus={callAllHandlers(inputProps.onFocus as any, handleInputFocus)}
          onBlur={callAllHandlers(inputProps.onBlur as any, handleInputBlur)}
          className={twCx(
            'tw-flex-1 tw-min-w-[100px] tw-appearance-none tw-outline-none [background:none] [cursor:inherit]',
            classNameInput
          )}
        />
        {rightAddon}
      </div>
    );
  }
) as InputComponent;
