import { useRef, useState } from 'react';
import { useEventCallback } from './useEventCallback';

// ** Mix of **
// https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/utils/src/useControlledState.ts
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-controllable.ts

function useControlModeChangeWarning(isControlled: boolean) {
  const ref = useRef(isControlled);
  const wasControlled = ref.current;

  if (wasControlled !== isControlled) {
    console.warn(
      `WARN: A component changed from ${
        wasControlled ? 'controlled' : 'uncontrolled'
      } to ${isControlled ? 'controlled' : 'uncontrolled'}.`
    );
  }

  ref.current = isControlled;
}

type StateUpdater<T> = (value: T | ((prevState: T) => T)) => void;

export type UseControllableStateOptions<T> = {
  /**
   * The value to used in controlled mode
   */
  value?: T;
  /**
   * The initial value to be used, in uncontrolled mode
   */
  defaultValue?: T | (() => T);
  /**
   * The callback fired when the value changes
   */
  onChange?: (value: T) => void;
};

/**
 * React hook that allows components to handle
 * both controlled and uncontrolled modes.
 *
 * Useful when a reusable component must support both usage types.
 *
 * @example
 * const ReusableComponent = ({ defaultValue, value, onChange }) => {
 *   const [state, setState] = useControllableState({
 *     defaultValue, value, onChange
 *    });
 *
 *   const increment = () => setState((v) => v + 1);
 *   const decrement = () => setState((v) => v - 1);
 *
 *   return (
 *     <div>
 *       <div>Counter: {state}</div>
 *       <Button onClick={increment}>+</Button>
 *       <Button onClick={decrement}>-</Button>
 *     </div>
 *   );
 * }
 *
 * // Later when using ReusableComponent
 *
 * // works
 * <ReusableComponent defaultValue={15} />
 *
 * // also works
 * <ReusableComponent state={controlledState} onChange={setControlledState} />
 *
 */
export function useControllableState<T>(
  options: UseControllableStateOptions<T>
): [T, StateUpdater<T>] {
  const { value: valueProp, defaultValue, onChange: onChangeProp } = options;

  const [uncontrolledState, setUncontrolledState] = useState(
    valueProp ?? (defaultValue as T)
  );
  const isControlled = valueProp !== undefined;
  const value = isControlled ? valueProp : uncontrolledState;

  useControlModeChangeWarning(isControlled);

  const setValue: StateUpdater<T> = useEventCallback((next) => {
    const setter = next as (prevState?: T) => T;
    const nextValue = typeof next === 'function' ? setter(value) : next;

    if (!isControlled) {
      setUncontrolledState(nextValue);
    }

    onChangeProp?.(nextValue);
  });

  return [value, setValue];
}
