import { MainApiService } from './main-api.service';
import {
  browserStorageService,
  BrowserStorageService
} from './browser-storage.service';
import { LocalStorageKeyEnum } from '../browser-storage.keys';
import {
  SetNewEmailBodyDTO,
  SetUserPhoneAdminActionOptionsDTO,
  VerifyEmailOtpBodyDTO
} from '@b2w/shared/types';
import { SharedRealtimeDbSubStore } from '../realtime-db.sub-store';
import { asyncLoadAuth, asyncLoadDatabase } from '../firebase/loaders';

import type {
  User,
  ConfirmationResult,
  UserCredential,
  PhoneAuthCredential,
  AuthCredential,
  ApplicationVerifier,
  NextOrObserver,
  ErrorFn
} from 'firebase/auth';
export type {
  User,
  ConfirmationResult,
  UserCredential,
  PhoneAuthCredential,
  ApplicationVerifier,
  NextOrObserver,
  ErrorFn
};

// https://stackoverflow.com/a/36978360
class AuthService extends MainApiService {
  private prefix = '/auth';
  private static _instance: AuthService;

  private constructor(private browserStorageService: BrowserStorageService) {
    super();
  }

  public static Instance(browserStorageService: BrowserStorageService) {
    return this._instance || (this._instance = new this(browserStorageService));
  }

  async getAuthUser() {
    const { auth } = await asyncLoadAuth();

    return auth.currentUser;
  }

  async getAdditionalUserInfo(credential: UserCredential) {
    const { getAdditionalUserInfo } = await asyncLoadAuth();

    return getAdditionalUserInfo(credential);
  }

  async onAuthStateChanged(
    nextOrObserver: NextOrObserver<User>,
    error?: ErrorFn
  ) {
    const { auth, onAuthStateChanged } = await asyncLoadAuth();

    return onAuthStateChanged(auth, nextOrObserver, error);
  }

  async getRedirectResult() {
    const { auth, getRedirectResult } = await asyncLoadAuth();

    return getRedirectResult(auth);
  }

  async signInWithEmailAndPassword(email: string, password: string) {
    const { auth, signInWithEmailAndPassword } = await asyncLoadAuth();

    return signInWithEmailAndPassword(auth, email, password);
  }

  async signUpWithEmailAndPassword(email: string, password: string) {
    const { auth, createUserWithEmailAndPassword } = await asyncLoadAuth();

    return createUserWithEmailAndPassword(auth, email, password);
  }

  async sendPasswordResetEmail(email: string) {
    const { auth, sendPasswordResetEmail } = await asyncLoadAuth();

    return sendPasswordResetEmail(auth, email);
  }

  async signInWithPhoneNumber(
    phoneNumber: string,
    applicationVerifier: ApplicationVerifier
  ) {
    const { auth, signInWithPhoneNumber } = await asyncLoadAuth();

    return signInWithPhoneNumber(auth, phoneNumber, applicationVerifier);
  }

  async createPhoneCredential(
    verificationId: string,
    verificationCode: string
  ) {
    const { PhoneAuthProvider } = await asyncLoadAuth();

    return PhoneAuthProvider.credential(verificationId, verificationCode);
  }

  async signInWithCredential(credential: AuthCredential) {
    const { auth, signInWithCredential } = await asyncLoadAuth();

    return signInWithCredential(auth, credential);
  }

  async linkWithCredential(credential: AuthCredential) {
    const { auth, linkWithCredential } = await asyncLoadAuth();

    return linkWithCredential(auth.currentUser, credential);
  }

  async updatePhoneNumber(phoneAuthCredential: PhoneAuthCredential) {
    const { auth, updatePhoneNumber } = await asyncLoadAuth();

    return updatePhoneNumber(auth.currentUser, phoneAuthCredential);
  }

  async updatePassword(
    newPassword: string,
    extra?: {
      userEmail: string;
      currentPassword: string;
    }
  ) {
    const {
      auth,
      updatePassword,
      reauthenticateWithCredential,
      EmailAuthProvider
    } = await asyncLoadAuth();

    if (extra) {
      const credential = EmailAuthProvider.credential(
        extra.userEmail,
        extra.currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
    }

    await updatePassword(auth.currentUser, newPassword);
  }

  async createRecaptchaVerifier(
    containerId: string,
    params: Record<string, any>
  ) {
    const { auth, RecaptchaVerifier } = await asyncLoadAuth();

    return new RecaptchaVerifier(auth, containerId, params);
  }

  async signInWithFacebook() {
    const { auth, signInWithRedirect, FacebookAuthProvider } =
      await asyncLoadAuth();

    const provider = new FacebookAuthProvider();

    return signInWithRedirect(auth, provider);
  }
  async linkFacebook() {
    const { auth, linkWithPopup, FacebookAuthProvider } = await asyncLoadAuth();

    const credential = await linkWithPopup(
      auth.currentUser,
      new FacebookAuthProvider()
    );

    await auth.currentUser.getIdToken(true);

    return credential;
  }
  async unlinkFacebook() {
    const { auth, unlink } = await asyncLoadAuth();

    const user = await unlink(auth.currentUser, 'facebook.com');

    await auth.currentUser.getIdToken(true);

    return user;
  }

  async signInWithGoogle() {
    const { auth, signInWithRedirect, GoogleAuthProvider } =
      await asyncLoadAuth();

    const provider = new GoogleAuthProvider();

    return signInWithRedirect(auth, provider);
  }
  async linkGoogle() {
    const { auth, linkWithPopup, GoogleAuthProvider } = await asyncLoadAuth();

    const credential = await linkWithPopup(
      auth.currentUser,
      new GoogleAuthProvider()
    );

    await auth.currentUser.getIdToken(true);

    return credential;
  }
  async unlinkGoogle() {
    const { auth, unlink } = await asyncLoadAuth();

    const user = await unlink(auth.currentUser, 'google.com');

    await auth.currentUser.getIdToken(true);

    return user;
  }

  async signOut(uid: string, currentConnectionId: string) {
    const [{ auth, signOut }, { database, serverTimestamp, ref, update, off }] =
      await Promise.all([asyncLoadAuth(), asyncLoadDatabase()]);

    const updates = {
      [`users/${uid}/presence/connections/${currentConnectionId}`]: null,
      [`unread_counters/${uid}/chat_presence`]: null,
      [`unread_counters/${uid}/support_inbox_presence`]: null,
      [`users/${uid}/presence/lastOnline_ms`]: serverTimestamp()
    };

    if (this.browserStorageService.canUseLocalStorage()) {
      const fcmToken = localStorage.getItem(LocalStorageKeyEnum.FCM_TOKEN);

      if (fcmToken) {
        localStorage.removeItem(LocalStorageKeyEnum.FCM_TOKEN);
        updates[`fcmTokens/${uid}/${fcmToken}`] = null;
      }
    }

    // save status to db
    await update(ref(database), updates);

    SharedRealtimeDbSubStore.forEach((sub) => {
      off(sub);
    });

    // actual logout
    await signOut(auth);
  }

  private async signInWithCustomToken(token: string) {
    const { auth, signInWithCustomToken } = await asyncLoadAuth();

    return signInWithCustomToken(auth, token);
  }

  async reauthenticate() {
    const endpoint = this.prefix + '/newToken';
    const idToken = await this.get<string>(endpoint);
    return this.signInWithCustomToken(idToken);
  }

  async setNewEmail(data: SetNewEmailBodyDTO) {
    const endpoint = this.prefix + '/email';
    const idToken = await this.post<string>(endpoint, data);
    return this.signInWithCustomToken(idToken);
  }

  async sendOtpForEmail() {
    const endpoint = this.prefix + '/email/send-otp';
    return this.get<void>(endpoint);
  }

  async verifyOtpForEmail(data: VerifyEmailOtpBodyDTO) {
    const endpoint = this.prefix + '/email/verify-otp';
    return this.post<void>(endpoint, data);
  }

  async setUserPhoneAdminAction(data: SetUserPhoneAdminActionOptionsDTO) {
    const endpoint = this.prefix + '/_admin/phone';

    return this.post<void>(endpoint, data);
  }

  extractFirstLastNameFromDisplayName(displayName?: string) {
    if (!displayName) {
      return { firstName: '', lastName: '' };
    }

    const nameSplit = displayName.split(' ');
    const firstName = nameSplit[0];
    const lastName = nameSplit.length > 1 ? nameSplit.slice(1).join(' ') : '';

    return { firstName, lastName };
  }
}

export const authService = AuthService.Instance(browserStorageService);
