/**
 * The solution is based on:
 *
 * https://stackoverflow.com/a/48764436
 */

const powers = [
  1, 1e1, 1e2, 1e3, 1e4, 1e5, 1e6, 1e7, 1e8, 1e9, 1e10, 1e11, 1e12, 1e13, 1e14,
  1e15, 1e16, 1e17, 1e18, 1e19, 1e20, 1e21, 1e22
];

const intpow10 = (power: number) => {
  if (power < 0 || power > 22) {
    return Math.pow(10, power);
  }
  return powers[power];
};

const isRound = (num: number, decimalPlaces: number) => {
  const p = intpow10(decimalPlaces);
  return Math.round(num * p) / p === num;
};

type AdjustType = 'round' | 'ceil' | 'floor' | 'trunc';

const decimalAdjust = (type: AdjustType, num: number, decimalPlaces = 0) => {
  if (type !== 'round' && isRound(num, decimalPlaces)) return num;

  const p = intpow10(decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math[type](n) / p;
};

const polyfilMath = () => {
  if (Number.EPSILON === undefined) {
    // @ts-expect-error epsilon support if missing
    Number.EPSILON = Math.pow(2, -52);
  }

  if (Math.trunc === undefined) {
    Math.trunc = function (v) {
      return v < 0 ? Math.ceil(v) : Math.floor(v);
    };
  }
};

/**
 * Round number to precision. Precision is handled properly to avoid JS math
 * pitfals.
 *
 * @param num number to round
 * @param decimalPlaces precision. It supports negative values for left-hand
 * rounding before decimal point
 */
export const round = (num: number, decimalPlaces: number) => {
  polyfilMath();

  return decimalAdjust('round', num, decimalPlaces);
};
