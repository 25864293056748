const LOCAL_STORAGE_KEY = 'app:consentMode';

export const consentHtml = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

try {
  if (localStorage.getItem('${LOCAL_STORAGE_KEY}') === null) {
    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'denied'
    });
  } else {
    gtag('consent', 'default', JSON.parse(localStorage.getItem('${LOCAL_STORAGE_KEY}')));
  }
} catch (error) {
  gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'denied'
  });
}
`;

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export type ConsentInputs = {
  necessary: boolean;
  analytics: boolean;
  preferences: boolean;
  marketing: boolean;
};

export type ConsentValue = 'granted' | 'denied';

export type Consent = {
  ad_storage: ConsentValue;
  ad_user_data: ConsentValue;
  ad_personalization: ConsentValue;
  analytics_storage: ConsentValue;
  functionality_storage: ConsentValue;
  personalization_storage: ConsentValue;
  security_storage: ConsentValue;
};

export const setConsent = (inputs: ConsentInputs) => {
  const consentMode: Consent = {
    ad_storage: inputs.marketing ? 'granted' : 'denied',
    ad_user_data: inputs.marketing ? 'granted' : 'denied',
    ad_personalization: inputs.marketing ? 'granted' : 'denied',
    analytics_storage: inputs.analytics ? 'granted' : 'denied',
    functionality_storage: inputs.necessary ? 'granted' : 'denied',
    personalization_storage: inputs.preferences ? 'granted' : 'denied',
    security_storage: inputs.necessary ? 'granted' : 'denied'
  };

  try {
    window.gtag('consent', 'update', consentMode);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(consentMode));
  } catch (err) {
    //
  }
};

export const getConsent = (): Consent | null => {
  const saved = localStorage.getItem(LOCAL_STORAGE_KEY);

  try {
    return JSON.parse(saved) as Consent;
  } catch (err) {
    return null;
  }
};
