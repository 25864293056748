import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { flushSync } from 'react-dom';
import { mergeRefs } from '@b2w/shared/react-utils';
import { callAllHandlers } from '@b2w/shared/utility';
import {
  useControllableState,
  useEventCallback,
  useOutsideClick
} from '@b2w/shared/react-hooks';
import { ComponentProps, ComponentPropsWithRef } from '../types';
import { AnimatedScroll, ScrollToItemTargetPosition } from '../animatedScroll';
import { NativeSelectProps } from '../NativeSelect';
import { SelectableListItemProps } from '../SelectableList';
import { useInputFormControl } from '../FormControl';
import { OptionObject, OptionsManager } from './OptionsManager';
import { PopupProps } from '../Popup';

const emptyFn = () => {
  //
};

export type SelectLogicCtxValue<TValue, TMultiple> = UseInitSelectReturn<
  TValue,
  TMultiple
>;

export const SelectLogicCtx = createContext<SelectLogicCtxValue<any, any>>(
  null as any
);
export const useSelectLogicCtx = <TValue = any, TMultiple = false>() =>
  useContext<SelectLogicCtxValue<TValue, TMultiple>>(SelectLogicCtx);

export type SelectOption<TValue> = {
  /** Option value */
  value: TValue;
  /** Option label */
  label: string;
  /** Whether option is disabled */
  disabled?: boolean;
};

export type SelectGroupedOption<TValue> = {
  /** Group label */
  label: string;
  /** Group options */
  options: SelectOption<TValue>[];
};

export type SelectOptionWithIndex<TValue> = SelectOption<TValue> & {
  index: number;
};

export type SelectGroupedOptionWithIndex<TValue> =
  SelectGroupedOption<TValue> & {
    groupIndex: number;
    /** Options belonging to this group after filtering */
    groupOptionsWithIndex: SelectOptionWithIndex<TValue>[];
  };

export type SelectValue<TValue, TMultiple = false> = TMultiple extends true
  ? TValue[] | null
  : TValue | null;

export type UseInitSelectProps<TValue, TMultiple> = {
  /** List of options and/or option groups. If options values are objects, supply prop `valueEqualityFn` */
  options: (SelectOption<TValue> | SelectGroupedOption<TValue>)[];
  /**
   * Selected value in controlled mode.
   * If `value` is an object (non-primitive), supply prop `valueEqualityFn`
   */
  value?: SelectValue<TValue, TMultiple>;
  /** Initial `value` in uncontrolled mode */
  defaultValue?: SelectValue<TValue, TMultiple>;
  /** `value` change handler in controlled mode */
  onChange?: (selected: SelectValue<TValue, TMultiple>) => any;
  /**
   * Whether to keep listbox open when option is selected
   *
   * @default false
   */
  keepOpenedOnSelect?: boolean;
  /**
   * Equality check for option value.
   * The function checks whether option value indeed equals selected option value.
   *
   * If option `value` is a NON-primitive, e.g. object, make sure to pass this prop!
   * Otherwise, select will have unexpected behavior.
   *
   * @default (value, selectedValue) => value === selectedValue
   */
  valueEqualityFn?: SelectValueEqualityFn<TValue>;
  /**
   * Whether to allow deselect selected option
   *
   * @default false
   */
  unselectOnSelectedClick?: boolean;
  /**
   * If true, listbox will support multiple selections.
   *
   * @default false
   */
  selectMultiple?: TMultiple;
  /** `isOpen` in controlled mode */
  isOpen?: boolean;
  /** `isOpen` change handler in controlled mode */
  onOpenChange?: (isOpen: boolean) => any;
  /** Initial `isOpen` in uncontrolled mode */
  defaultIsOpen?: boolean;
  /**
   * If prop `options` changes dynamically, pass an external key
   * which instructs the select to re-evaluate options. This is sort of a cache key.
   *
   * Along with `revalidateOptionsKey`, Select will re-evaluate options when `options.length` changes.
   * However, `options.length` can easily fail when the order of elements changes but not length.
   * This is especially important if using grouped options.
   * Therefore, pass `revalidateOptionsKey` when working with too dynamic lists.
   *
   * For example, `revalidateOptionsKey` can be input's value when doing something like autocompletion.
   *
   * This prop exists to avoid heavy computation on each keystroke.
   */
  revalidateOptionsKey?: string | number | boolean;
  /**
   * Value to show in trigger when no options selected
   *
   * @default 'Select an option'
   */
  placeholder?: string;
  /**
   * What to be displayed in trigger when option is selected.
   *
   * @default
   * 'value' - the value of Select.Option
   */
  whenSelectedShow?: 'label' | 'value';
  /**
   * Popup animation
   *
   * @default
   * 0 - no animation
   */
  popupAnimationsMs?: PopupProps['animationsMs'];
  /**
   * Popup strategy
   *
   * @default
   * 'absolute'
   */
  popupStrategy?: PopupProps['strategy'];
  /**
   * Whether opened popup must match width
   *
   * @default
   * true
   */
  popupMatchWidth?: PopupProps['matchWidth'];
  /**
   * Placement of popup
   *
   * @default
   * 'bottom'
   */
  popupPlacement?: PopupProps['placement'];
  /**
   * @internal
   *
   * Pass callback to determine how options are filtered
   */
  filterOption?: (option: SelectOption<TValue>) => boolean;
  /**
   * @internal
   *
   * Whether to refocus trigger element when popup opened, or when option selected
   *
   * @default true
   */
  refocusTriggerOnSelectAndOpen?: boolean;
  /**
   * @internal
   *
   * Imperative `ref` to access internals of select
   */
  controller?: React.Ref<SelectController<TValue, TMultiple>>;
};

export type UseInitSelectPublicProps<TValue, TMultiple> = Omit<
  UseInitSelectProps<TValue, TMultiple>,
  'filterOption' | 'refocusTriggerOnSelectAndOpen' | 'controller'
>;

export type SelectController<TValue, TMultiple> = Pick<
  UseInitSelectReturn<TValue, TMultiple>,
  | 'setActiveOption'
  | 'setActiveToFirstLastOrSelected'
  | 'listboxRef'
  | 'isSelected'
  | 'optionsManager'
  | 'keepOpenedOnSelect'
  | 'popupAnimationsMs'
  | 'selectMultiple'
  | 'valueEqualityFn'
  | 'allPossibleSingleOptions'
  | 'selected'
  | 'scrollToOption'
>;

export type SelectValueEqualityFn<T = any> = (
  optionValue: T,
  currentOptionValue?: T
) => boolean;

const DEFAULT_VALUE_EQUALITY_FN: SelectValueEqualityFn = (v1, v2) => v1 === v2;

export const useInitSelect = <TValue, TMultiple>(
  props: UseInitSelectProps<TValue, TMultiple>
) => {
  const {
    selectMultiple = false,
    value: valueProp,
    onChange: onChangeProp,
    defaultValue: defaultValueProp = selectMultiple ? [] : null,
    keepOpenedOnSelect = false,
    unselectOnSelectedClick = false,
    isOpen: isOpenProp,
    onOpenChange: onOpenChangeProp,
    defaultIsOpen: defaultIsOpenProp = false,
    valueEqualityFn = DEFAULT_VALUE_EQUALITY_FN,
    options,
    revalidateOptionsKey = '',
    filterOption,
    placeholder = 'Select an option',
    whenSelectedShow = 'value',
    popupAnimationsMs = 0,
    popupMatchWidth = true,
    popupPlacement = 'bottom',
    refocusTriggerOnSelectAndOpen = true,
    controller,
    popupStrategy
  } = props;

  const [isOpen, setIsOpen] = useControllableState({
    value: isOpenProp,
    onChange: onOpenChangeProp,
    defaultValue: defaultIsOpenProp
  });
  const [selected, setSelected] = useControllableState<
    SelectValue<TValue, TMultiple>
  >({
    value: valueProp,
    onChange: onChangeProp,
    defaultValue: defaultValueProp as SelectValue<TValue, TMultiple>
  });
  const [active, setActive] = useState<OptionObject<TValue> | null>(null);

  const optionsManager = useMemo(() => new OptionsManager<TValue>(), []);
  const popupBodyRef = useRef<HTMLDivElement>(null);
  /** Element that triggers popup (input/button) */
  const triggerRef = useRef<HTMLElement>(null);
  /** Element for listening clicks outside to close popup */
  const clickOutsideRef = useRef<HTMLElement>(null);
  const listboxRef = useRef<HTMLUListElement>(null);

  const isSelected = selectMultiple
    ? (selected as TValue[])?.length > 0
    : !!selected;

  const uuid = useId();
  const rootId = `select-${uuid}`;

  const scrollToOption = useCallback(
    (
      optionObject: OptionObject<TValue>,
      scrollTargetPosition: ScrollToItemTargetPosition = 'auto'
    ) => {
      if (!listboxRef.current) return;

      optionObject.node &&
        AnimatedScroll.scrollToSelectorOrElement(
          optionObject.node,
          listboxRef.current,
          scrollTargetPosition,
          0
        );
    },
    []
  );

  const setActiveOption = useCallback(
    (
      optionObject: OptionObject<TValue> | null,
      updateScroll = true,
      scrollTargetPosition: ScrollToItemTargetPosition = 'auto'
    ) => {
      setActive(optionObject);

      if (updateScroll && optionObject) {
        scrollToOption(optionObject, scrollTargetPosition);
      }
    },
    [scrollToOption]
  );

  const setActiveToFirstLastOrSelected = (firstOrLast: 'first' | 'last') => {
    if (isSelected) {
      // focus selected
      const targetValue = selectMultiple
        ? (selected as TValue[])?.[0]
        : selected;

      const nextActive = optionsManager.getByCallback((opt) =>
        valueEqualityFn(opt.value, targetValue as TValue)
      );

      if (nextActive?.node) {
        setActiveOption(nextActive, true, 'start');
      } else {
        // if selected value is invalid
        const first = optionsManager.getFirstEnabledOption();

        if (first?.node) {
          setActiveOption(first, false);
        }
      }
    } else {
      // focus first or last
      const nextActive =
        firstOrLast === 'first'
          ? optionsManager.getFirstEnabledOption()
          : optionsManager.getLastEnabledOption();

      if (nextActive?.node) {
        setActiveOption(nextActive, firstOrLast === 'last', 'start');
      }
    }
  };

  const isCustomFilter = !!filterOption;

  const {
    allSingleOptionsFiltered,
    flattenedFilteredListItems,
    allPossibleSingleOptions
  } = useMemo(
    () => {
      let flattenedFilteredListItems: (
        | SelectOptionWithIndex<TValue>
        | SelectGroupedOptionWithIndex<TValue>
      )[] = [];
      let allSingleOptionsFiltered: SelectOptionWithIndex<TValue>[] = [];

      let groupIndex = -1;

      const allPossibleSingleOptions = options.reduce<
        SelectOptionWithIndex<TValue>[]
      >((acc, val) => {
        let flatOptions: SelectOption<TValue>[];

        if ('options' in val) {
          groupIndex++;
          flatOptions = val.options;
        } else {
          flatOptions = [val];
        }

        const singleOptionsBlock: SelectOptionWithIndex<TValue>[] =
          flatOptions.map((o, idx) => ({
            ...o,
            index: acc.length + idx
          }));

        const filteredSingleOptionsBlock = isCustomFilter
          ? singleOptionsBlock.filter((opt) => filterOption(opt))
          : singleOptionsBlock;

        allSingleOptionsFiltered = allSingleOptionsFiltered.concat(
          filteredSingleOptionsBlock
        );

        if ('options' in val) {
          flattenedFilteredListItems = flattenedFilteredListItems.concat({
            ...val,
            groupIndex,
            groupOptionsWithIndex: filteredSingleOptionsBlock
          } as SelectGroupedOptionWithIndex<TValue>);
        }

        flattenedFilteredListItems = flattenedFilteredListItems.concat(
          filteredSingleOptionsBlock
        );

        return acc.concat(singleOptionsBlock);
      }, []);

      return {
        allPossibleSingleOptions,
        allSingleOptionsFiltered,
        flattenedFilteredListItems
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length, revalidateOptionsKey, isCustomFilter]
  );

  useEffect(() => {
    const nodes = listboxRef.current?.querySelectorAll('li[role="option"]');

    optionsManager.initOptions(
      allSingleOptionsFiltered.map((opt, pos) => ({
        index: opt.index,
        node: (nodes?.item(pos) as HTMLLIElement) ?? null,
        value: opt.value,
        disabled: !!opt.disabled
      }))
    );
  }, [allSingleOptionsFiltered, optionsManager]);

  const setActiveInDirection = (direction: 'up' | 'down') => {
    const nextActive =
      direction === 'down'
        ? optionsManager.getNextEnabledOption(active?.index ?? -1)
        : optionsManager.getPrevEnabledOption(
            active?.index ?? allSingleOptionsFiltered.length + 1
          );

    if (nextActive?.node) {
      setActiveOption(nextActive);
    }
  };

  const focusTriggerIfNeeded = () => {
    if (document.activeElement !== triggerRef.current) {
      triggerRef.current?.focus();
    }
  };

  const setSelectedWithLogic = useCallback(
    (optionValue: TValue) => {
      setSelected((currentValue) => {
        let nextValue: TValue | TValue[] | null;

        if (selectMultiple) {
          const arr = (currentValue ?? []) as TValue[];
          const existingValueIndex = arr.findIndex((val) =>
            valueEqualityFn(optionValue, val)
          );

          if (unselectOnSelectedClick && existingValueIndex >= 0) {
            nextValue = arr.filter((_, index) => index !== existingValueIndex);
          } else {
            nextValue = existingValueIndex >= 0 ? arr : arr.concat(optionValue);
          }
        } else {
          nextValue =
            unselectOnSelectedClick &&
            valueEqualityFn(optionValue, currentValue as TValue)
              ? null
              : optionValue;
        }

        return nextValue as SelectValue<TValue, TMultiple>;
      });

      if (refocusTriggerOnSelectAndOpen) {
        focusTriggerIfNeeded();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectMultiple,
      unselectOnSelectedClick,
      setSelected,
      refocusTriggerOnSelectAndOpen
    ]
  );

  const close = useCallback(
    (mustRefocusTrigger = true) => {
      setIsOpen(false);
      setActiveOption(null, false);

      if (mustRefocusTrigger) {
        focusTriggerIfNeeded();
      }
    },
    [setIsOpen, setActiveOption]
  );

  const open = (focusFirstOrLast: 'first' | 'last' = 'first') => {
    flushSync(() => {
      setIsOpen(true);
    });

    if (popupAnimationsMs > 0) {
      setTimeout(() => {
        setActiveToFirstLastOrSelected(focusFirstOrLast);
      }, 5);
    } else {
      setActiveToFirstLastOrSelected(focusFirstOrLast);
    }

    if (refocusTriggerOnSelectAndOpen) {
      focusTriggerIfNeeded();
    }
  };

  // option utilities

  const isOptionActive = (option: SelectOption<TValue>) =>
    valueEqualityFn(option.value, active?.value);

  const isOptionSelected = (option: SelectOption<TValue>) =>
    selectMultiple
      ? ((selected ?? []) as TValue[]).some((val) =>
          valueEqualityFn(option.value, val)
        )
      : valueEqualityFn(option.value, selected as TValue);

  const handleOptionHover = useCallback(
    (
      option: SelectOption<TValue>,
      index: number,
      node: HTMLLIElement | null
    ) => {
      if (node) {
        setActiveOption(
          { disabled: !!option.disabled, value: option.value, index, node },
          false
        );
      }
    },
    [setActiveOption]
  );

  const handleOptionClick = useCallback(
    (option: SelectOption<TValue>) => {
      setSelectedWithLogic(option.value);

      if (!keepOpenedOnSelect) {
        close();
      }
    },
    [keepOpenedOnSelect, close, setSelectedWithLogic]
  );

  // popup-specific

  useOutsideClick({
    enabled: isOpen,
    ref: popupBodyRef,
    handler: (event, didUserScroll) => {
      if (
        !clickOutsideRef.current?.contains(event.target as HTMLElement) &&
        !didUserScroll
      ) {
        close(false);
      }
    }
  });

  const selectOptionValueInDirection = (direction: 'left' | 'right') => {
    if (selectMultiple) return;

    if (isSelected) {
      const currentSelectedIndex = allPossibleSingleOptions.findIndex((opt) =>
        valueEqualityFn(opt.value, selected as TValue)
      );

      const nextOption =
        direction === 'left'
          ? optionsManager.getPrevEnabledOption(currentSelectedIndex)
          : optionsManager.getNextEnabledOption(currentSelectedIndex);

      if (nextOption) {
        setSelected(nextOption.value);
      }
    } else {
      const nextOption =
        direction === 'left'
          ? optionsManager.getLastEnabledOption()
          : optionsManager.getFirstEnabledOption();

      if (nextOption) {
        setSelected(nextOption.value);
      }
    }
  };

  useImperativeHandle(controller, () => ({
    setActiveOption,
    setActiveToFirstLastOrSelected,
    listboxRef,
    isSelected,
    selected,
    optionsManager,
    keepOpenedOnSelect,
    popupAnimationsMs,
    selectMultiple,
    valueEqualityFn,
    allPossibleSingleOptions,
    scrollToOption
  }));

  return {
    isOpen,
    close,
    open,
    setIsOpen,
    selected,
    optionsManager,
    rootId,
    active,
    triggerRef,
    clickOutsideRef,
    setSelected,
    setActiveOption,
    setActiveInDirection,
    listboxRef,
    isSelected,
    keepOpenedOnSelect,
    unselectOnSelectedClick,
    selectMultiple,
    setSelectedWithLogic,
    valueEqualityFn,
    options,
    isOptionActive,
    isOptionSelected,
    handleOptionHover,
    handleOptionClick,
    setActiveToFirstLastOrSelected,
    filterOption,
    flattenedFilteredListItems,
    scrollToOption,
    selectOptionValueInDirection,
    allSingleOptionsFiltered,
    allPossibleSingleOptions,
    focusTriggerIfNeeded,
    popupBodyRef,
    placeholder,
    whenSelectedShow,
    popupAnimationsMs,
    popupMatchWidth,
    popupPlacement,
    popupStrategy
  };
};

export type UseInitSelectReturn<TValue, TMultiple> = ReturnType<
  typeof useInitSelect<TValue, TMultiple>
>;

export type UseSelectTriggerProps = Omit<
  NativeSelectProps<'button'>,
  'ref' | 'disabled' | 'required'
> &
  Pick<NativeSelectProps<'select'>, 'disabled' | 'required'>;

export const useSelectTrigger = <TValue, TMultiple>(
  props: UseSelectTriggerProps,
  inputRef: ComponentPropsWithRef<'input'>['ref']
) => {
  const { children: childrenProp, ...inputProps } = props;

  const {
    rootId,
    triggerRef,
    clickOutsideRef,
    isOpen,
    isSelected,
    allPossibleSingleOptions,
    valueEqualityFn,
    active,
    selected,
    focusTriggerIfNeeded,
    selectMultiple,
    open,
    close,
    setActiveInDirection,
    selectOptionValueInDirection,
    keepOpenedOnSelect,
    setSelectedWithLogic,
    placeholder,
    whenSelectedShow
  } = useSelectLogicCtx<TValue, TMultiple>();

  const triggerId = inputProps.id ?? `${rootId}-trigger`;

  let triggerChildren: React.ReactNode;

  if (typeof childrenProp !== 'undefined') {
    triggerChildren = childrenProp;
  } else {
    if (isSelected) {
      triggerChildren = ((selectMultiple ? selected : [selected]) as TValue[])
        .map((val) => {
          const valueOrLabel =
            whenSelectedShow === 'value'
              ? val
              : allPossibleSingleOptions.find((opt) =>
                  valueEqualityFn(opt.value, val)
                )?.label;

          return typeof valueOrLabel === 'object'
            ? JSON.stringify(valueOrLabel)
            : valueOrLabel;
        })
        .join(', ');
    } else {
      triggerChildren = placeholder;
    }
  }

  const onTriggerKeyDown = (ev: React.KeyboardEvent) => {
    switch (ev.key) {
      case ' ':
      case 'Enter': {
        if (isOpen) {
          const value = active?.value;

          if (value !== undefined) {
            setSelectedWithLogic(value);

            if (keepOpenedOnSelect) {
              ev.preventDefault();
            }
          }
        }
        break;
      }
      case 'ArrowUp': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('up');
        } else {
          open('last');
        }

        break;
      }
      case 'ArrowDown': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('down');
        } else {
          open('first');
        }

        break;
      }
      case 'ArrowLeft': {
        ev.preventDefault();

        if (isOpen) return;

        selectOptionValueInDirection('left');

        break;
      }
      case 'ArrowRight': {
        ev.preventDefault();

        if (isOpen) return;

        selectOptionValueInDirection('right');

        break;
      }
      case 'Escape': {
        if (isOpen) {
          ev.preventDefault();
          close();
        }

        break;
      }
    }
  };

  const onTriggerClick = () => {
    if (isOpen) {
      close();
    } else {
      open('first');
    }
  };

  const triggerProps: ComponentPropsWithRef<'button'> = {
    ...inputProps,
    id: triggerId,
    ref: mergeRefs(triggerRef, clickOutsideRef),
    onClick: callAllHandlers(inputProps.onClick, onTriggerClick),
    'aria-expanded': isOpen,
    'aria-haspopup': 'listbox',
    onKeyDown: callAllHandlers(inputProps.onKeyDown, onTriggerKeyDown),
    children: triggerChildren,
    'aria-activedescendant': active?.node?.id || ''
  };

  const hiddenInputProps: ComponentPropsWithRef<'input'> = {
    ...useInputFormControl({
      isDisabled: inputProps.isDisabled,
      isReadOnly: inputProps.isReadOnly,
      isRequired: inputProps.isRequired,
      isInvalid: inputProps.isInvalid,
      disabled: inputProps.disabled,
      required: inputProps.required
    }),
    ref: inputRef,
    onChange: emptyFn,
    onFocus: () => focusTriggerIfNeeded(),
    // should serialize?
    value: (selected as any) || '',
    tabIndex: -1,
    'aria-hidden': true
  };

  return {
    triggerProps,
    hiddenInputProps,
    isSelected,
    isOpen
  };
};

export type UseSelectOptionOwnProps<TValue> = SelectOption<TValue> & {
  /** If option is hovered a.k.a being active */
  isActive: boolean;
  /** If option is selected */
  isSelected: boolean;
  /** Option position */
  index: number;
  optionsManager: OptionsManager<TValue>;
  handleOptionHover: (
    option: SelectOption<TValue>,
    index: number,
    node: HTMLLIElement | null
  ) => any;
  handleOptionClick: (option: SelectOption<TValue>) => any;
};

export type UseSelectOptionProps<TValue> = Omit<
  SelectableListItemProps,
  | keyof UseSelectOptionOwnProps<TValue>
  | 'ref'
  | 'isDisabled'
  | 'isHovered'
  | 'isSelected'
> &
  UseSelectOptionOwnProps<TValue>;

// option
export const useSelectOption = <TValue>(
  props: UseSelectOptionProps<TValue>,
  ref: ComponentPropsWithRef<'li'>['ref']
) => {
  const {
    value,
    label,
    disabled,
    isActive,
    isSelected,
    index,
    optionsManager,
    handleOptionHover,
    handleOptionClick,
    ...htmlProps
  } = props;

  const autoId = useId();
  const id = htmlProps.id ?? `option-${autoId}`;

  const nodeRef = useRef<HTMLLIElement | null>(null);
  const registerRefCb = useEventCallback((node: HTMLLIElement) => {
    nodeRef.current = node;
    optionsManager.setNodeForOptionAtIndex(index, node);
  });

  const onClick = (ev: React.SyntheticEvent) => {
    if (disabled) return;

    ev.stopPropagation();

    handleOptionClick({ value, label, disabled });
  };

  const onMouseDown = (ev: React.SyntheticEvent) => {
    // prevent input blur when click on mouse
    ev.preventDefault();
  };

  const onMouseOver = () => {
    if (disabled) return;

    handleOptionHover({ value, label, disabled }, index, nodeRef.current);
  };

  const optionProps: ComponentPropsWithRef<'li'> = {
    ...htmlProps,
    id,
    children: htmlProps.children ?? label,
    ref: mergeRefs(ref, registerRefCb),
    role: 'option',
    'aria-selected': isSelected,
    'aria-disabled': disabled,
    onClick: callAllHandlers(htmlProps.onClick, onClick),
    onMouseOver: callAllHandlers(htmlProps.onMouseOver, onMouseOver),
    onMouseDown: callAllHandlers(htmlProps.onMouseDown, onMouseDown)
  };

  return {
    optionProps,
    isDisabled: disabled,
    isSelected,
    isActive
  };
};

export type UseSelectPopupBodyProps = ComponentProps<'div'>;

export const useSelectPopupBody = <TValue, TMultiple>(
  htmlProps: UseSelectPopupBodyProps,
  htmlRef: ComponentPropsWithRef<'div'>['ref']
) => {
  const {
    popupBodyRef,
    isOpen,
    setSelectedWithLogic,
    keepOpenedOnSelect,
    active,
    setActiveInDirection,
    open,
    close
  } = useSelectLogicCtx<TValue, TMultiple>();

  const onPopupKeyDown = (ev: React.KeyboardEvent) => {
    switch (ev.key) {
      case 'Enter': {
        if (isOpen) {
          ev.preventDefault();

          const value = active?.value;

          if (value !== undefined) {
            setSelectedWithLogic(value);

            if (!keepOpenedOnSelect) {
              close();
            }
          }
        }
        break;
      }
      case 'ArrowUp': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('up');
        } else {
          open('last');
        }

        break;
      }
      case 'ArrowDown': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('down');
        } else {
          open('first');
        }

        break;
      }
      case 'Escape': {
        if (isOpen) {
          ev.preventDefault();
          close();
        }

        break;
      }
    }
  };

  const popupBodyProps: ComponentPropsWithRef<'div'> = {
    ...htmlProps,
    tabIndex: -1,
    ref: mergeRefs(htmlRef, popupBodyRef),
    onKeyDown: callAllHandlers(htmlProps.onKeyDown, onPopupKeyDown)
  };

  return {
    popupBodyProps
  };
};

export type UseSelectListboxProps = ComponentProps<'ul'>;

export const useSelectListbox = <TValue, TMultiple>(
  htmlProps: UseSelectListboxProps,
  htmlRef: ComponentPropsWithRef<'ul'>['ref']
) => {
  const {
    rootId,
    isOptionActive,
    isOptionSelected,
    optionsManager,
    handleOptionHover,
    handleOptionClick,
    flattenedFilteredListItems,
    listboxRef,
    allSingleOptionsFiltered
  } = useSelectLogicCtx<TValue, TMultiple>();

  const isNoOptions = allSingleOptionsFiltered.length === 0;

  const listboxId = htmlProps.id ?? `${rootId}-listbox`;

  const listboxProps: ComponentPropsWithRef<'ul'> = {
    ...htmlProps,
    ref: mergeRefs(htmlRef, listboxRef),
    id: listboxId,
    role: 'listbox'
  };

  return {
    listboxProps,
    isOptionActive,
    isOptionSelected,
    optionsManager,
    handleOptionHover,
    handleOptionClick,
    flattenedFilteredListItems,
    isNoOptions
  };
};
