import useSWR from 'swr';
import { B2wApiError } from '@b2w/shared/types';
import { bellService } from '@/lib/services/bell.service';
import { useUser } from '@/context/user.context';

export const useBellNotificationsCount = () => {
  const { user } = useUser();
  const { data, error, mutate } = useSWR<number, B2wApiError>(
    user
      ? ['bellService.getTotalNotificationCountForCurrentUser', user.uid]
      : null,
    () => bellService.getTotalNotificationCountForCurrentUser()
  );
  const isLoading = !data && !error;

  return {
    notificationsCount: data,
    notificationsCountError: error,
    isNotificationsCountLoading: isLoading,
    mutateNotificationsCount: mutate
  };
};
