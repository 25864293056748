import { presenceService } from '@/lib/services/presence.service';
import { useEffect } from 'react';

export function useHandleUserPresence(currentUserId?: string) {
  useEffect(() => {
    if (currentUserId) {
      presenceService.handleUserPresence(currentUserId).catch((err) => {
        console.log('[Presence err]', err.message);
      });
    }
  }, [currentUserId]);
}
