import {
  onMessagePayloadFCM,
  turnOnNotifications
} from '@/lib/firebase/turnOnNotifications';
import { useEffect } from 'react';
import { useSetAlert } from '@/context/alert.context';
import { isLocalhost } from '@b2w/shared/utility';

async function getSwRegistration() {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    const swUrl = isLocalhost ? '/worker.dev.js' : '/worker.js';
    const swRegistration = await navigator.serviceWorker.register(swUrl);
    return swRegistration;
  }

  return null;
}

export function useHandleFcm(currentUserId: string) {
  const setAlert = useSetAlert();

  useEffect(() => {
    if (!currentUserId) {
      return;
    }

    let fcmUnsub: any;

    getSwRegistration()
      .then(async (serviceWorker) => {
        if (!serviceWorker) {
          return;
        }

        fcmUnsub = await turnOnNotifications(
          currentUserId,
          serviceWorker,
          (fcm: onMessagePayloadFCM) => {
            if (
              fcm.data?.showInForeground === 'true' &&
              fcm.notification?.body
            ) {
              // handle how to display foreground messages
              setAlert(
                fcm.notification?.body,
                'info',
                1000 * 60,
                fcm.fcmOptions?.link
                  ? {
                      text: 'Click to see details',
                      // cut https://website.com part
                      href: stripHostFromUrl(fcm.fcmOptions.link).urlPath
                    }
                  : undefined
              );
            }
          }
        );
      })
      .catch((err) => {
        console.log('[FCM] Silenced handler error', err.message);
      });

    return () => {
      fcmUnsub && fcmUnsub();
    };
  }, [setAlert, currentUserId]);
}

function stripHostFromUrl(url: string) {
  const URLsplit = url.split('/');

  const host = URLsplit[0] + '//' + URLsplit[2];
  const urlPath = url.replace(host, '');

  return {
    host,
    urlPath
  };
}
