import { CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { twCx } from '../twMerge';

const backdropTransition =
  '[&_div[data-modal-element=backdrop]]:tw-transition [&_div[data-modal-element=backdrop]]:tw-duration-[--modal-animation-duration]';

const backdrop: CSSTransitionClassNames = {
  appear: twCx(
    backdropTransition,
    '[&_div[data-modal-element=backdrop]]:tw-opacity-0'
  ),
  appearActive: '[&_div[data-modal-element=backdrop]]:!tw-opacity-100',
  exit: twCx(
    backdropTransition,
    '[&_div[data-modal-element=backdrop]]:tw-opacity-100'
  ),
  exitActive: '[&_div[data-modal-element=backdrop]]:!tw-opacity-0'
};

const contentTransition =
  '[&_div[data-modal-element=content]]:tw-transition-all [&_div[data-modal-element=content]]:tw-duration-[--modal-animation-duration]';

const content: CSSTransitionClassNames = {
  appear: twCx(
    contentTransition,
    '[&_div[data-modal-element=content]]:tw-opacity-0 [&_div[data-modal-element=content]]:[transform:var(--modal-animation-content-hidden)]'
  ),
  appearActive:
    '[&_div[data-modal-element=content]]:!tw-opacity-100 [&_div[data-modal-element=content]]:![transform:var(--modal-animation-content-visible)]',
  exit: twCx(
    contentTransition,
    '[&_div[data-modal-element=content]]:tw-opacity-100 [&_div[data-modal-element=content]]:[transform:var(--modal-animation-content-visible)]'
  ),
  exitActive:
    '[&_div[data-modal-element=content]]:!tw-opacity-0 [&_div[data-modal-element=content]]:![transform:var(--modal-animation-content-hidden)]'
};

const animationClassnames: CSSTransitionClassNames = {
  appear: twCx(backdrop.appear, content.appear),
  appearActive: twCx(backdrop.appearActive, content.appearActive),
  exit: twCx(backdrop.exit, content.exit),
  exitActive: twCx(backdrop.exitActive, content.exitActive)
};

export interface AnimationPreset {
  /**
   * @example 300
   */
  animationDurationMs: number;
  /**
   * @example 'translateY(-10px)'
   */
  transformWhenHidden: string;
  /**
   * @example 'translateY(0px)'
   */
  transformWhenVisible: string;
}

export interface AnimateModalProps extends AnimationPreset {
  children: React.ReactNode;
  isOpen: boolean;
}

export const AnimateModal = ({
  children,
  isOpen,
  animationDurationMs = 300,
  transformWhenHidden = 'translateY(-10px)',
  transformWhenVisible = 'translateY(0px)'
}: AnimateModalProps) => {
  const cssVars = {
    '--modal-animation-duration': animationDurationMs + 'ms',
    '--modal-animation-content-hidden': transformWhenHidden,
    '--modal-animation-content-visible': transformWhenVisible
  } as CSSProperties;

  return (
    <CSSTransition
      in={isOpen}
      classNames={animationClassnames}
      timeout={animationDurationMs}
      appear
    >
      <div style={cssVars}>{children}</div>
    </CSSTransition>
  );
};
