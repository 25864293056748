import { Children, cloneElement, forwardRef } from 'react';
import { twCx } from '../twMerge';
import { Popup } from '../Popup';
import { UseTooltipProps, useTooltip } from './useTooltip';

export type TooltipProps = UseTooltipProps & {
  children?: React.ReactNode;
};

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { children, ...useTooltipProps } = props;

  const {
    isOpen,
    title,
    animationsMs,
    arrow,
    distancePx,
    inPortal,
    matchWidth,
    placement,
    getTriggerProps,
    getTooltipBodyProps,
    getTooltipPositionerProps
  } = useTooltip(useTooltipProps);

  if (!title) {
    // If `title` is missing, there's no point showing the tooltip
    return children as JSX.Element;
  }

  const child = Children.only(children) as React.ReactElement & {
    ref?: React.Ref<any>;
  };

  return (
    <Popup
      isOpen={isOpen}
      inPortal={inPortal}
      animationsMs={animationsMs}
      arrowSize={arrow ? 8 : undefined}
      matchWidth={matchWidth}
      placement={placement}
      arrowClassName="tw-text-gray-800"
      distancePx={distancePx}
      distanceAsPadding
    >
      <Popup.Trigger>
        {cloneElement(child, getTriggerProps(child.props, child.ref))}
      </Popup.Trigger>
      <Popup.Positioner {...(getTooltipPositionerProps() as any)}>
        <Popup.Body
          {...(getTooltipBodyProps(
            {
              className: twCx(
                'tw-rounded-md tw-py-1.5 tw-px-3 tw-font-medium tw-text-sm tw-text-dark-50 tw-bg-gray-800',
                !useTooltipProps.matchWidth && 'tw-max-w-[300px]'
              )
            },
            ref
          ) as any)}
        >
          {title}
        </Popup.Body>
      </Popup.Positioner>
    </Popup>
  );
});

export interface ConditionalTooltipProps extends TooltipProps {
  /**
   * Condition when to show the tooltip.
   * If false, it will render `children` without any tooltip.
   */
  showWhen: boolean;
}

const ConditionalTooltip = forwardRef<HTMLDivElement, ConditionalTooltipProps>(
  (props, ref) => {
    const { showWhen, children, ...tooltipProps } = props;

    return showWhen ? (
      <Tooltip {...tooltipProps} ref={ref}>
        {children}
      </Tooltip>
    ) : (
      children
    );
  }
);

const TooltipExport = Object.assign(Tooltip, {
  Conditional: ConditionalTooltip
});
export { TooltipExport as Tooltip };
