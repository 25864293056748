import { useCallback, useEffect, useRef } from 'react';
import { debounce } from '@b2w/shared/utility';
import {
  useControllableState,
  useEventCallback,
  useRefsArray
} from '@b2w/shared/react-hooks';
import { AnimatedScroll, ScrollAnimationRef } from '../animatedScroll';
import { GalleryImage } from './types';

// REFERENCE
// https://www.redblobgames.com/making-of/draggable/

export type UseGalleryProps = {
  images: GalleryImage[];
  /** @default 'touchScreenOnly' */
  dragMode?: 'touchScreenOnly' | 'always' | 'none';
  onSlideClick?: (index: number) => any;
  /**
   * If gallery opens in a modal with delayed animation this can be useful
   *
   * @default true
   */
  canRegisterClickAndDragListeners?: boolean;
  /**
   * Pass milliseconds to automatically play slides on interval
   *
   * @default 0 (none)
   */
  autoPlayMs?: number;
  activeIndex?: number;
  onActiveIndexChange?: (index: number) => any;
  defaultActiveIndex?: number;
};

export const useGallery = (props: UseGalleryProps) => {
  const {
    images,
    dragMode = 'touchScreenOnly',
    onSlideClick,
    autoPlayMs = 0,
    canRegisterClickAndDragListeners = true,
    defaultActiveIndex: defaultActiveIndexProp = 0,
    activeIndex: activeIndexProp,
    onActiveIndexChange: onActiveIndexChangeProp
  } = props;

  const [activeIndex, setActiveInternal] = useControllableState<number>({
    defaultValue: defaultActiveIndexProp,
    onChange: onActiveIndexChangeProp,
    value: activeIndexProp
  });
  const activeRef = useRef(defaultActiveIndexProp);

  const galleryViewportRef = useRef<HTMLDivElement | null>(null);

  const slidesListRef = useRef<HTMLDivElement | null>(null);
  const registerSlidesListRef = useCallback((node: HTMLDivElement) => {
    // set initial position
    slidesListRef.current = node;

    if (node && defaultActiveIndexProp > 0) {
      node.scrollLeft =
        node.getBoundingClientRect().width * defaultActiveIndexProp;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [registerSlideRef] = useRefsArray();

  const runningAnimationRef = useRef<ScrollAnimationRef>();

  const cancelRunningAnimation = () => {
    if (runningAnimationRef.current) {
      AnimatedScroll.cancelAnimation(runningAnimationRef.current);
    }
  };

  const scrollToSlide = (index: number, animate = true) => {
    cancelRunningAnimation();

    const list = slidesListRef.current;

    if (!list) return;

    runningAnimationRef.current = AnimatedScroll.scrollToX(
      index * list.getBoundingClientRect().width,
      list,
      animate ? 350 : 0
    );
  };

  const slideToPrev = () => {
    setActiveInternal((c) => {
      const newVal = (c - 1 + images.length) % images.length;

      scrollToSlide(newVal);
      activeRef.current = newVal;

      return newVal;
    });
  };

  const slideToNext = () => {
    setActiveInternal((c) => {
      const newVal = (c + 1) % images.length;

      scrollToSlide(newVal);
      activeRef.current = newVal;

      return newVal;
    });
  };

  const slideToIndex = useEventCallback((index: number, animate?: boolean) => {
    activeRef.current = index;
    setActiveInternal(index);
    scrollToSlide(index, animate);
  });

  useEffect(() => {
    const handleResize = debounce(async () => {
      scrollToSlide(activeRef.current);
    }, 50);

    const handleKey = (ev: KeyboardEvent) => {
      switch (ev.key) {
        case 'ArrowRight': {
          slideToNext();
          break;
        }
        case 'ArrowLeft': {
          slideToPrev();
          break;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('keyup', handleKey);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keyup', handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autoPlayMs <= 0) return;

    const t = setInterval(() => {
      slideToNext();
    }, autoPlayMs);

    return () => {
      clearInterval(t);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlayMs, activeIndex]);

  useEffect(() => {
    const c = slidesListRef.current;

    if (!canRegisterClickAndDragListeners || !c) return;

    // if touch device
    const canUseDrag =
      dragMode === 'touchScreenOnly'
        ? window.matchMedia('(pointer: coarse)').matches
        : dragMode !== 'none';

    function handleClick() {
      onSlideClick?.(activeRef.current);
    }

    if (!canUseDrag) {
      if (onSlideClick) {
        c.addEventListener('click', handleClick);

        return () => {
          c.removeEventListener('click', handleClick);
        };
      }

      return;
    }

    let startTimeMs = 0;
    let isDragging = false;
    let isTouching = false;
    let isDraggingOnYAxis = false;
    let startPos = {
      scrollLeft: c.scrollLeft,
      rect: c.getBoundingClientRect(),
      x: 0,
      y: 0
    };

    const getPositionFromEvent = (ev: MouseEvent) => {
      return {
        scrollLeft: c.scrollLeft,
        rect: c.getBoundingClientRect(),
        // Get the current mouse position
        x: ev.clientX,
        y: ev.clientY
      };
    };

    function start(ev: PointerEvent) {
      if (ev.button !== 0) return; // allow drag with left click only
      if (ev.ctrlKey) return; // ignore ctrl+click

      isDragging = true;

      startPos = getPositionFromEvent(ev);
      startTimeMs = Date.now();

      c?.setPointerCapture(ev.pointerId);
    }

    function move(ev: PointerEvent) {
      if (!isDragging) return;

      const dx = ev.clientX - startPos.x;
      const dy = ev.clientY - startPos.y;

      isDraggingOnYAxis = Math.abs(dx) < Math.abs(dy);

      if (isTouching && isDraggingOnYAxis) return;

      cancelRunningAnimation();
      runningAnimationRef.current = {
        id: window.requestAnimationFrame(() => {
          if (c) {
            c.scrollLeft = startPos.scrollLeft - dx;
          }
        })
      };
    }

    function end(ev: PointerEvent) {
      if (!isDragging) return;

      isDragging = false;

      const endPos = getPositionFromEvent(ev);

      if (startPos.x === endPos.x && startPos.y === endPos.y) {
        // clicked, not dragged
        handleClick();
        return;
      }

      if (isTouching && isDraggingOnYAxis) {
        slideToIndex(activeRef.current);
        return;
      }

      const timeElapsedMs = Date.now() - startTimeMs;
      const newIndex = Math.round(endPos.scrollLeft / endPos.rect.width);

      if (timeElapsedMs <= 200) {
        // if scrolling fast
        if (endPos.x > startPos.x) {
          slideToPrev();
        } else {
          slideToNext();
        }
        return;
      }

      // if reached left or right edge
      if (startPos.scrollLeft === endPos.scrollLeft) {
        if (endPos.x - startPos.x > endPos.rect.width / 4) {
          // if trying to scroll to left
          slideToPrev();
          return;
        }

        if (startPos.x - endPos.x > endPos.rect.width / 4) {
          // if trying to scroll to right
          slideToNext();
          return;
        }
      }

      slideToIndex(newIndex);
    }

    const touchStart = () => {
      isTouching = true;
    };
    const touchMove = (e: TouchEvent) => {
      if (!isDraggingOnYAxis) {
        e.preventDefault();
      }
    };
    const touchEnd = () => {
      isTouching = false;
    };

    const prevent = (e: DragEvent) => {
      e.preventDefault();
    };

    c.addEventListener('pointerdown', start);
    c.addEventListener('pointermove', move);
    c.addEventListener('pointerup', end);
    c.addEventListener('pointercancel', end);
    // do not scroll page when starting drag
    c.addEventListener('touchstart', touchStart);
    c.addEventListener('touchmove', touchMove);
    c.addEventListener('touchend', touchEnd);
    // prevent image grab
    c.addEventListener('dragstart', prevent);

    return () => {
      c.removeEventListener('pointerdown', start);
      c.removeEventListener('pointermove', move);
      c.removeEventListener('pointerup', end);
      c.removeEventListener('pointercancel', end);
      c.removeEventListener('touchstart', touchStart);
      c.removeEventListener('touchmove', touchMove);
      c.removeEventListener('touchend', touchEnd);
      c.removeEventListener('dragstart', prevent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragMode, canRegisterClickAndDragListeners]);

  return {
    galleryViewportRef,
    registerSlidesListRef,
    registerSlideRef,
    slideToPrev,
    slideToNext,
    slideToIndex,
    activeIndex,
    images
  };
};
