import { useContext, createContext, useState, useId } from 'react';
import { mergeRefs, PropGetter } from '@b2w/shared/react-utils';

export const FormControlContext = createContext<ReturnType<
  typeof useInitFormControl
> | null>(null);
export const useFormControlCtx = () => useContext(FormControlContext);

// =======================================

export interface FormControlMainProps {
  /**
   * If `true`, the form control will be required. This has 2 side effects:
   * - The `FormLabel` will show a required indicator
   * - The form element (e.g, Input) will have `aria-required` set to `true`
   */
  isRequired?: boolean;
  /**
   * If `true`, the form control will be disabled. This has 2 side effects:
   * - The `FormLabel` will have `data-disabled` attribute
   * - The form element (e.g, Input) will be disabled
   */
  isDisabled?: boolean;
  /**
   * If `true`, the form control will be invalid. This has 2 side effects:
   * - The `FormLabel` and `FormErrorIcon` will have `data-invalid` set to `true`
   * - The form element (e.g, Input) will have `aria-invalid` set to `true`
   */
  isInvalid?: boolean;
  /**
   * If `true`, the form control will be readonly
   */
  isReadOnly?: boolean;
}

export interface InitFormControlCtxOptions extends FormControlMainProps {
  /** Form control group id. All elements within group depend on the id. */
  id?: string;
}

/**
 * React hook to get context value for `FormControlContext`
 */
export function useInitFormControl(options: InitFormControlCtxOptions) {
  const { id: idProp, isRequired, isInvalid, isDisabled, isReadOnly } = options;

  const uuid = useId();
  const rootId = idProp ?? uuid;
  const labelId = `${rootId}-label`;
  const inputId = `${rootId}-input`;
  const errorMessageId = `${rootId}-error-message`;
  const descriptionMessageId = `${rootId}-description`;

  /**
   * Track whether the `FormErrorMessage` has been rendered.
   * We use this to append its id to the `aria-describedby` of the `input`.
   * This is managed in `useInputFormControl` hook.
   */
  const [hasErrorMessage, setHasErrorMessage] = useState(false);

  /**
   * Track whether the `FormDescription` has been rendered.
   * We use this to append its id the the `aria-describedby` of the `input`.
   * This is managed in `useInputFormControl` hook.
   */
  const [hasDescriptionMessage, setHasDescriptionMessage] = useState(false);
  /**
   * Track whether the `FormLabel` has been rendered.
   */
  const [hasLabel, setHasLabel] = useState(false);

  const getFormDescriptionProps: PropGetter<'div'> = (
    props = {},
    forwardedRef = null
  ) => ({
    id: descriptionMessageId,
    ...props,
    /**
     * Notify the field context when the help text is rendered on screen,
     * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
     */
    ref: mergeRefs(forwardedRef, (node) => {
      if (node) {
        setHasDescriptionMessage(true);
      }
    })
  });

  const getFormErrorMessageProps: PropGetter<'div'> = (
    props = {},
    forwardedRef = null
  ) => ({
    id: errorMessageId,
    ...props,
    'aria-live': 'polite',
    /**
     * Notify the field context when the error message is rendered on screen,
     * so we can apply the correct `aria-describedby` to the field (e.g. input, textarea).
     */
    ref: mergeRefs(forwardedRef, (node) => {
      if (node) {
        setHasErrorMessage(true);
      }
    })
  });

  const getFormLabelProps: PropGetter<'label'> = (
    props = {},
    forwardedRef = null
  ) => ({
    id: labelId,
    htmlFor: inputId,
    ...props,
    ref: mergeRefs(forwardedRef, (node) => {
      if (node) {
        setHasLabel(true);
      }
    })
  });

  return {
    isRequired: !!isRequired,
    isInvalid: !!isInvalid,
    isReadOnly: !!isReadOnly,
    isDisabled: !!isDisabled,
    inputId,
    labelId,
    errorMessageId,
    descriptionMessageId,
    hasLabel,
    hasErrorMessage,
    hasDescriptionMessage,
    getFormLabelProps,
    getFormDescriptionProps,
    getFormErrorMessageProps
  };
}
