import { Container, Row, Col, Nav } from 'reactstrap';
import { twCx } from '@b2w/react-ui/core2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppLink from '@/components/AppLink';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MAIN_TRAVEL_DIRECTIONS } from '@/lib/directions-links';

type ILinkItem = {
  href: string;
  text: any;
  target?: string;
  icon?: IconProp;
};

type ISocialLinkItem = {
  href: string;
  title: string;
  icon: IconProp;
};

const countriesList: ILinkItem[] = [
  {
    href: MAIN_TRAVEL_DIRECTIONS.ph.searchPageHref,
    text: MAIN_TRAVEL_DIRECTIONS.ph.name
  },
  {
    href: MAIN_TRAVEL_DIRECTIONS.ind.searchPageHref,
    text: MAIN_TRAVEL_DIRECTIONS.ind.name
  },
  {
    href: MAIN_TRAVEL_DIRECTIONS.pk.searchPageHref,
    text: MAIN_TRAVEL_DIRECTIONS.pk.name
  },
  {
    href: '/search',
    text: (
      <div className="mt-4">
        All Countries <FontAwesomeIcon icon="arrow-right" className="ml-1" />
      </div>
    )
  }
];

const companyLinks: ILinkItem[] = [
  { href: '/about', text: 'About Us' },
  { href: '/affiliate', text: 'Become an affiliate' },
  { href: '/team', text: 'Team' },
  { href: '/story', text: 'Our Story' },
  { href: 'http://www.blog.book2wheel.com/', text: 'Blog', target: '_blank' },
  { href: '/policy', text: 'Terms of Service' },
  { href: '/mobile-app', text: 'Mobile application' }
];

const rentalLinks: ILinkItem[] = [
  { href: '/howitworks', text: 'How It Works' },
  { href: '/dashboard/vehicles/add', text: 'Upload Your Car/Bike' },
  { href: '/search', text: 'Rent a Car/Bike' },
  { href: '/insurance', text: 'Insurance' },
  { href: 'https://www.book4wheel.com', text: 'Leasing', target: '_blank' }
];

const contactLinks: ILinkItem[] = [
  {
    href: 'tel:+4550338186',
    text: '+45 50 33 81 86',
    icon: ['fas', 'phone-alt']
  },
  {
    href: 'tel:+639173853917',
    text: '+63 917 385 3917',
    icon: ['fas', 'phone-alt']
  },
  {
    href: 'https://wa.me/+4550338186',
    text: 'WhatsApp chat',
    icon: ['fab', 'whatsapp']
  },
  {
    href: 'mailto:support@book2wheel.com',
    text: 'support@book2wheel.com',
    icon: ['fas', 'envelope']
  },
  { href: 'skype:book2wheel?add', text: 'book2wheel', icon: ['fab', 'skype'] }
];

const socialLinks: ISocialLinkItem[] = [
  {
    href: 'https://www.facebook.com/book2wheel',
    title: 'Go to our Facebook page',
    icon: ['fab', 'facebook']
  },
  {
    href: 'https://www.instagram.com/book2wheel/',
    title: 'Go to our Instagram page',
    icon: ['fab', 'instagram']
  },
  {
    href: 'https://twitter.com/book2wheel',
    title: 'Go to our Twitter page',
    icon: ['fab', 'twitter']
  }
];

const CenteredInlineBlock = ({ children, textLeft }) => {
  return (
    <div className="text-center">
      <div className={twCx('d-inline-block', { 'text-left': textLeft })}>
        {children}
      </div>
    </div>
  );
};

const LinkItem = ({ href, text, target }: ILinkItem) => {
  return (
    <AppLink
      href={href}
      className="text-primary-typo"
      target={target}
      {...(target === '_blank' ? { rel: 'noopener noreferrer' } : {})}
    >
      {text}
    </AppLink>
  );
};

type ILinkList = {
  title: string;
  links: ILinkItem[];
};

const LinkList = ({ title, links }: ILinkList) => {
  return (
    <CenteredInlineBlock textLeft>
      <h6 className="text-primary-typo mb-4 text-uppercase d-inline-block tw-font-medium">
        {title}
      </h6>
      <Nav vertical style={{ width: 130 }}>
        {links.map((l) => (
          <li key={l.text} className="text-primary-typo text-nowrap">
            {l.icon && (
              <FontAwesomeIcon icon={l.icon} fixedWidth className="mr-1" />
            )}
            <LinkItem {...l} />
          </li>
        ))}
      </Nav>
    </CenteredInlineBlock>
  );
};

const BrandingCol = () => {
  return (
    <div className="text-center">
      <img
        src="/images/logo_white.png"
        alt="book2wheel"
        className="mw-100 h-auto mx-auto mb-3 mb-lg-2"
        width="230px"
        height="50px"
      />
      <div className="d-flex justify-content-center align-items-center mt-0 mt-lg-5">
        {socialLinks.map((l, idx, arr) => (
          <a
            href={l.href}
            title={l.title}
            key={l.title}
            className={twCx(
              'text-primary-typo d-flex justify-content-center align-items-center',
              {
                'mr-3': idx + 1 < arr.length
              }
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={l.icon} size="2x" fixedWidth />
          </a>
        ))}
      </div>
    </div>
  );
};

const Footer: React.FC = () => {
  return (
    <footer className="mt-5">
      <style jsx>{`
        .footer {
          width: 100%;
          height: auto;
          margin-bottom: -1px;
        }

        .footer-content {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }
      `}</style>
      <img
        src="/images/footer-top.svg"
        alt="footer"
        width="1040px"
        height="200px"
        className="footer"
      />
      <div className="bg-primary footer-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} lg={2}>
              <div className="mb-5">
                <BrandingCol />
              </div>
            </Col>
            <Col xs={6} lg={2}>
              <LinkList title="Countries" links={countriesList} />
            </Col>
            <Col xs={6} lg={2}>
              <LinkList title="Company" links={companyLinks} />
            </Col>
            <Col xs={6} lg={2} className="mt-5 mt-lg-0">
              <LinkList title="Rental" links={rentalLinks} />
            </Col>
            <Col xs={6} sm={0} className="d-sm-none" />
            <Col xs={6} lg={2} className="mt-5 mt-lg-0">
              <LinkList title="Contact" links={contactLinks} />
            </Col>
            <Col xs={6} sm={0} className="d-sm-none" />
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
