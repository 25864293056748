import {
  BookingDTO,
  CreateProfileDTO,
  DeepPartial,
  DeleteUserProfileOptionsDTO,
  GetProfileQueryOptionsDTO,
  GetProfileRentalsQueryDTO,
  GetProfilesQueryDTO,
  HandleConnectToPaypalBodyDTO,
  PrivateProfileDTO,
  ProfileDTO,
  ProfileEarningsDTO,
  ProfileModel,
  VehicleDTO,
  VerificationSource
} from '@b2w/shared/types';
import { MainApiService } from './main-api.service';

// https://stackoverflow.com/a/36978360
export class ProfileService extends MainApiService {
  private prefix = '/profiles';
  private static _instance: ProfileService;

  private constructor() {
    super();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async getProfiles(options?: GetProfilesQueryDTO): Promise<ProfileDTO[]> {
    const endpointWithOptions = this.buildEndpointWithQueryString(
      this.prefix,
      options
    );

    return this.get<ProfileDTO[]>(endpointWithOptions, {
      withAuth: false
    });
  }

  async getManyProfiles(options?: GetProfilesQueryDTO): Promise<ProfileDTO[]> {
    const endpoint = this.prefix + '/get-many';
    return this.post<ProfileDTO[]>(endpoint, options, { withAuth: false });
  }

  async getProfilesPrivate(
    options?: GetProfilesQueryDTO
  ): Promise<PrivateProfileDTO[]> {
    const endpoint = this.prefix + '/private';

    if (options?.ids) {
      options.ids = options.ids.filter((v, idx, arr) => arr.indexOf(v) === idx);
    }

    return this.post<PrivateProfileDTO[]>(endpoint, options);
  }

  async getPrivateProfileById(
    userId?: string,
    options?: Pick<GetProfilesQueryDTO, 'avatarH' | 'avatarW'>
  ): Promise<PrivateProfileDTO | null> {
    return this.getProfilesPrivate({ ids: [userId], ...options }).then(
      (result) => result[0] ?? null
    );
  }

  async getProfileById(userId: string, options?: GetProfileQueryOptionsDTO) {
    const endpointWithOptions = this.buildEndpointWithQueryString(
      this.prefix + '/' + userId,
      options
    );

    return this.get<ProfileDTO | null>(endpointWithOptions, {
      withAuth: false
    });
  }

  async getProfileForAuthUser(options?: GetProfileQueryOptionsDTO) {
    const endpoint = this.buildEndpointWithQueryString(
      this.prefix + '/me',
      options
    );

    return this.get<PrivateProfileDTO>(
      endpoint,
      { withAuth: true },
      {
        mustRetry: (err, data) => {
          return !err && data?.createdAt === undefined;
        },
        maxRetries: 10,
        waitBetweenRetries: 1500
      }
    );
  }

  async syncVerifications() {
    return this.get<VerificationSource[]>(this.prefix + '/sync-verifications');
  }

  async getEarningsForProfile(profileId: string) {
    return this.get<ProfileEarningsDTO[]>(
      this.prefix + `/${profileId}/earnings`
    );
  }

  async uploadAvatarForProfile(
    profileId: string,
    avatarImg: any,
    avatarImgName?: string
  ) {
    const formData = new FormData();
    formData.append('avatar', avatarImg, avatarImgName);

    return this.post<string>(this.prefix + `/${profileId}/avatar`, formData, {
      bodyIsFormData: true
    });
  }

  async createProfileForAuthUser(data: CreateProfileDTO) {
    return this.post<void>(this.prefix, data);
  }

  async patchProfileById(
    profileId: string,
    initialData: DeepPartial<ProfileModel>,
    newData: DeepPartial<ProfileModel>
  ) {
    const patch = await this.generateJsonPatch(initialData, newData);

    return this.patch<PrivateProfileDTO>(this.prefix + '/' + profileId, patch);
  }

  async getRentalsActiveForProfile(
    profileId: string,
    query?: GetProfileRentalsQueryDTO
  ): Promise<BookingDTO[]> {
    const endpointWithFilters = this.buildEndpointWithQueryString(
      this.prefix + '/' + profileId + '/rentals-active',
      query
    );

    return this.get<BookingDTO[]>(endpointWithFilters);
  }
  async getRentalsActiveCalendarForProfile(
    profileId: string,
    query?: GetProfileRentalsQueryDTO
  ): Promise<BookingDTO[]> {
    const endpointWithFilters = this.buildEndpointWithQueryString(
      this.prefix + '/' + profileId + '/rentals-active-calendar',
      query
    );

    return this.get<BookingDTO[]>(endpointWithFilters);
  }
  async getRentalsArchiveForProfile(
    profileId: string,
    query?: GetProfileRentalsQueryDTO
  ): Promise<BookingDTO[]> {
    const endpointWithFilters = this.buildEndpointWithQueryString(
      this.prefix + '/' + profileId + '/rentals-archive',
      query
    );

    return this.get<BookingDTO[]>(endpointWithFilters);
  }

  async getUnpaidBookingsForProfile(profileId: string): Promise<BookingDTO[]> {
    const endpoint = this.prefix + '/' + profileId + '/bookings/unpaid';
    return this.get<BookingDTO[]>(endpoint);
  }

  async getFavoriteVehiclesForProfile(
    profileId: string
  ): Promise<VehicleDTO[]> {
    return this.get<VehicleDTO[]>(
      `${this.prefix}/${profileId}/favorite-vehicles`
    );
  }

  async getSingleFavoriteVehicleForProfile(
    profileId: string,
    vehicleId: string
  ): Promise<VehicleDTO | null> {
    return this.get<VehicleDTO | null>(
      `${this.prefix}/${profileId}/favorite-vehicles/${vehicleId}`
    );
  }

  async addVehicleToFavorites(profileId: string, vehicleId: string) {
    return this.post<void>(
      `${this.prefix}/${profileId}/favorite-vehicles/${vehicleId}`
    );
  }

  async removeVehicleFromFavorites(profileId: string, vehicleId: string) {
    return this.delete<void>(
      `${this.prefix}/${profileId}/favorite-vehicles/${vehicleId}`
    );
  }

  async handleConnectToPaypal(body: HandleConnectToPaypalBodyDTO) {
    return this.post(this.prefix + '/paypal/connect', body);
  }

  async deleteMyProfile(options: DeleteUserProfileOptionsDTO): Promise<void> {
    const endpoint = this.buildEndpointWithQueryString(
      this.prefix + '/me',
      options
    );

    return this.delete<void>(endpoint);
  }

  isAvatarSet(profile?: PrivateProfileDTO | ProfileDTO) {
    if (!profile?.avatar) {
      return false;
    }

    return profile.avatar && !profile.avatar.startsWith('https://www.gravatar');
  }

  isProfileComplete(profile?: PrivateProfileDTO | ProfileDTO) {
    if (profile) {
      return Boolean(
        profile.firstName && profile.lastName && this.isAvatarSet(profile)
      );
    }

    return false;
  }

  isProfileSuspended(profile?: PrivateProfileDTO | ProfileDTO) {
    if (profile) {
      if ('isSuspended' in profile) {
        return profile.isSuspended;
      }
      if ('currentSuspensionId' in profile) {
        return !!profile.currentSuspensionId;
      }
    }

    return false;
  }

  isAccountDisabled(profile?: PrivateProfileDTO | ProfileDTO) {
    if (profile) {
      if ('isDisabled' in profile) {
        return profile.isDisabled;
      }
    }

    return false;
  }
}

export const profileService = ProfileService.Instance;
