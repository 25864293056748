import {
  DeepPartial,
  GetVehiclesFilterDTO,
  VehicleCalendarDTO,
  VehicleCalendarModel,
  VehicleDTO,
  PostVehicleBodyDTO,
  VehicleModel,
  GetVehicleQueryOptionsDTO,
  AskOwnerForDatesBodyDTO,
  AskOwnerForDatesCreatedDTO,
  ReplaceVehicleImageBodyDTO
} from '@b2w/shared/types';
import { MainApiService } from './main-api.service';

// https://stackoverflow.com/a/36978360
class VehicleService extends MainApiService {
  private prefix = '/vehicles';
  private static _instance: VehicleService;

  private constructor() {
    super();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async getVehicleCalendar(
    vehicleId: string
  ): Promise<VehicleCalendarDTO | null> {
    const endpoint = this.prefix + '/' + vehicleId + '/calendar';
    return this.get<VehicleCalendarDTO | null>(endpoint, {
      withAuth: false
    });
  }

  async getVehicles(filter?: GetVehiclesFilterDTO): Promise<VehicleDTO[]> {
    const endpointWithFilter = this.buildEndpointWithQueryString(
      this.prefix,
      filter
    );

    return this.get<VehicleDTO[]>(endpointWithFilter, { withAuth: false });
  }

  async getVehicleById(
    vehicleId: string,
    options?: GetVehicleQueryOptionsDTO
  ): Promise<VehicleDTO | null> {
    const endpoint = this.buildEndpointWithQueryString(
      this.prefix + '/' + vehicleId,
      options
    );

    return this.get<VehicleDTO | null>(endpoint, { withAuth: false });
  }

  async deleteVehicleById(vehicleId: string): Promise<void> {
    const endpoint = this.prefix + '/' + vehicleId;
    return this.delete<void>(endpoint);
  }

  async patchCalendarByVehicleId(
    vehicleId: string,
    initialData: DeepPartial<VehicleCalendarModel>,
    newData: DeepPartial<VehicleCalendarModel>
  ): Promise<VehicleCalendarDTO | null> {
    const patch = await this.generateJsonPatch(initialData, newData);
    const endpoint = this.prefix + '/' + vehicleId + '/calendar';

    return this.patch<VehicleCalendarDTO | null>(endpoint, patch);
  }

  async patchVehicleById(
    vehicleId: string,
    initialData: DeepPartial<VehicleModel>,
    newData: DeepPartial<VehicleModel>
  ): Promise<VehicleDTO | null> {
    const patch = await this.generateJsonPatch(initialData, newData);
    const endpoint = this.prefix + '/' + vehicleId;

    return this.patch<VehicleDTO | null>(endpoint, patch);
  }

  async uploadNewVehicle(
    data: PostVehicleBodyDTO,
    pictures: any[]
  ): Promise<string> {
    const formData = new FormData();
    pictures.forEach((pic) => {
      formData.append('picture[]', pic);
    });
    formData.append('body', JSON.stringify(data));

    return this.post<string>(this.prefix, formData, {
      bodyIsFormData: true
    });
  }

  async uploadNewImagesForVehicle(
    vehicleId: string,
    pictures: any[]
  ): Promise<VehicleDTO | null> {
    const endpoint = `${this.prefix}/${vehicleId}/images`;

    const formData = new FormData();
    pictures.forEach((pic) => {
      formData.append('picture[]', pic);
    });

    return this.post<VehicleDTO | null>(endpoint, formData, {
      bodyIsFormData: true
    });
  }

  async replaceImageForVehicle(
    vehicleId: string,
    requestData: ReplaceVehicleImageBodyDTO,
    newImage: any
  ): Promise<VehicleDTO | null> {
    const endpoint = `${this.prefix}/${vehicleId}/replace-image`;

    const formData = new FormData();
    formData.append('picture[]', newImage);
    formData.append('body', JSON.stringify(requestData));

    return this.post<VehicleDTO | null>(endpoint, formData, {
      bodyIsFormData: true
    });
  }

  async askOwnerForVehicleDates(
    vehicleId: string,
    payload: AskOwnerForDatesBodyDTO
  ): Promise<AskOwnerForDatesCreatedDTO> {
    const endpoint = this.prefix + '/' + vehicleId + '/askowner';
    return this.post<AskOwnerForDatesCreatedDTO>(endpoint, payload);
  }
}

export const vehicleService = VehicleService.Instance;
