import { isBrowser } from '@b2w/shared/utility';
import Script from 'next/script';
import { memo } from 'react';
import { consentHtml } from './cookieConsent';

const GTM_ID = 'GTM-NG43L74';

const getSnippet = () => {
  if (!isBrowser) return null;

  if (window.location.hostname.match(/(?:book2wheel|b2wprod)/)) {
    // live env
    return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=PWLLwDGexJJ24XNPnMcCVA&gtm_preview=env-1&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');`;
  }

  // dev env
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=yGofvabGd94HfOpPGqzjbQ&gtm_preview=env-50&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GTM_ID}');`;
};

function TagManager() {
  const snippet = getSnippet();

  if (!snippet) return null;

  return (
    <>
      <Script
        id="cookie-consent"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: consentHtml
        }}
      />
      <Script
        id="gtm-module"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: snippet
        }}
      />
    </>
  );
}

export default memo(TagManager);
