import { useEffect } from 'react';

/**
 * Reack hook to prevent page scrolling.
 *
 * @param isLocked whether to prevent scroll or not
 * @param avoidScrollbarReflow whether to add padding to the side
 * when scrollbar dissapears. Defaults to `true`
 */
export function useLockScroll(isLocked: boolean, avoidScrollbarReflow = true) {
  useEffect(() => {
    if (!isLocked) {
      return;
    }

    const body = document.body;

    // Save initial styles
    const originalBodyOverflow = body.style.overflow;
    const originalBodyPaddingRight = body.style.paddingRight;

    const scrollBarWidth = window.innerWidth - body.clientWidth;

    body.style.overflow = 'hidden';
    if (avoidScrollbarReflow) {
      // Avoid width reflow
      body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      body.style.overflow = originalBodyOverflow;
      if (scrollBarWidth && avoidScrollbarReflow) {
        body.style.paddingRight = originalBodyPaddingRight;
      }
    };
  }, [isLocked, avoidScrollbarReflow]);
}

export default useLockScroll;
