import { memo } from 'react';
import { twCx } from '../twMerge';
import { UseGalleryProps, useGallery } from './gallery.init';
import { LeftArrowButton, RightArrowButton } from './Buttons';
import { Thumbs, ThumbsPublicProps } from './Thumbs';

type SlideItemProps = {
  index: number;
  registerRef: (index: number) => (node: HTMLDivElement) => void;
  slideSrc: string;
  isClickable?: boolean;
  /** @default 'full' */
  fitOption?: 'full' | 'fitToSize';
};

export const SlideItem = memo(
  ({
    slideSrc,
    index,
    registerRef,
    isClickable = false,
    fitOption = 'full'
  }: SlideItemProps) => {
    return (
      <div
        ref={registerRef(index)}
        className="tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center"
        style={{
          left: `calc(${index * 100}%)`
        }}
      >
        <img
          src={slideSrc}
          alt={index + ''}
          className={twCx(
            'tw-max-w-full tw-max-h-full',
            fitOption === 'full' &&
              'tw-object-cover tw-object-center tw-w-full tw-h-full',
            isClickable && 'tw-cursor-pointer'
          )}
        />
      </div>
    );
  }
);

export type GalleryProps = Pick<
  React.ComponentPropsWithoutRef<'div'>,
  'className' | 'id'
> &
  UseGalleryProps & {
    /** @default 'auto' */
    thumbsMode?: ThumbsPublicProps['thumbsMode'] | 'none';
    /** @default 'auto' */
    arrowsMode?: 'auto' | 'always' | 'none';
  };

export const Gallery = (props: GalleryProps) => {
  const {
    id,
    className: classNameProp,
    thumbsMode = 'auto',
    arrowsMode = 'auto',
    ...useGalleryProps
  } = props;

  const {
    images,
    galleryViewportRef,
    registerSlidesListRef,
    registerSlideRef,
    activeIndex,
    slideToNext,
    slideToPrev,
    slideToIndex
  } = useGallery(useGalleryProps);

  return (
    <div
      id={id}
      className={twCx(
        'tw-relative tw-w-full tw-overflow-hidden tw-group',
        classNameProp
      )}
    >
      <div className="tw-absolute tw-top-0 tw-w-full tw-h-full">
        <div
          className={twCx(
            'tw-relative tw-flex tw-items-center tw-justify-center',
            'tw-select-none tw-w-full tw-h-full',
            'tw-rounded-lg tw-overflow-hidden'
          )}
          ref={galleryViewportRef}
        >
          <div
            ref={registerSlidesListRef}
            className="tw-relative tw-w-full tw-h-full tw-overflow-hidden"
          >
            {images.map((src, idx) => (
              <SlideItem
                key={src.slideSrc + idx}
                registerRef={registerSlideRef}
                slideSrc={src.slideSrc}
                index={idx}
                isClickable={!!useGalleryProps.onSlideClick}
              />
            ))}
          </div>
          {arrowsMode !== 'none' && (
            <>
              <LeftArrowButton
                onClick={slideToPrev}
                isResponsive={arrowsMode === 'auto'}
                tabIndex={-1}
              />
              <RightArrowButton
                onClick={slideToNext}
                isResponsive={arrowsMode === 'auto'}
                tabIndex={-1}
              />
            </>
          )}
        </div>
      </div>
      {thumbsMode !== 'none' && (
        <Thumbs
          activeIndex={activeIndex}
          images={images}
          onThumbClick={slideToIndex}
          thumbsMode={thumbsMode}
        />
      )}
    </div>
  );
};
