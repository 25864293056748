import { RefObject, useEffect, useRef } from 'react';

/**
 * React hook to capture and focus last active element when trigger changes
 *
 * @param mustCaptureElement - when true, captures last active element;
 * when false, focuses last captured element
 */
export const useFocusLastActiveElement = (
  mustCaptureElement: boolean
): RefObject<HTMLElement | null> => {
  const lastFocusedElemenet = useRef<HTMLElement | null>();

  useEffect(() => {
    if (mustCaptureElement) {
      lastFocusedElemenet.current = document.activeElement as HTMLElement;
    } else {
      if (lastFocusedElemenet.current) {
        lastFocusedElemenet.current.focus();
        lastFocusedElemenet.current = null;
      }
    }
  }, [mustCaptureElement]);

  return lastFocusedElemenet as RefObject<HTMLElement | null>;
};
