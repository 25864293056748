import { useEffect, useState } from 'react';

/**
 * React hook to delay component unmount. Useful for animating components with
 * classnames.
 *
 * @param isMounted - whether component is currently mounted or unmounted
 * @param delayTime - unmount delay time in miliseconds
 *
 * @returns boolean which indicates whether component must be rendered
 * considering the delay
 */
export function useDelayUnmount(isMounted: boolean, delayTime: number) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    let timeoutId: any;

    if (isMounted && !shouldRender) {
      setShouldRender(true);
    } else if (!isMounted && shouldRender) {
      timeoutId = setTimeout(() => setShouldRender(false), delayTime);
    }

    return () => clearTimeout(timeoutId);
  }, [isMounted, delayTime, shouldRender]);

  return shouldRender;
}

export interface DelayUnmountProps {
  children?: React.ReactNode;
  /**
   * Whether component is currently mounted or unmounted
   */
  isMounted: boolean;
  /**
   * Unmount delay time in miliseconds
   */
  delay: number;
}

/**
 *
 * Component to delay component unmount of its children. Useful for animating
 * components with classnames.
 */
export const DelayUnmount = (props: DelayUnmountProps) => {
  const { delay, isMounted, children } = props;

  const canRender = useDelayUnmount(isMounted, delay);

  return (canRender ? children : null) as JSX.Element;
};
