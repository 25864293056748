import { useControllableState } from '@b2w/shared/react-hooks';
import { callAllHandlers } from '@b2w/shared/utility';
import { createContext, useContext } from 'react';
import { CheckboxProps } from './Checkbox';
import { FormControlMainProps } from '../FormControl';

export type CheckboxGroupCtxValue = UseInitCheckboxGroupReturn;

export const CheckboxGroupCtx = createContext<CheckboxGroupCtxValue | null>(
  null
);
export const useCheckboxGroupCtx = () => useContext(CheckboxGroupCtx);

export type UseInitCheckboxGroupReturn = ReturnType<
  typeof useInitCheckboxGroup
>;

export type UseInitCheckboxGroupProps = FormControlMainProps & {
  /** Default selected checkbox values */
  defaultValue?: string[];
  /** Selected values */
  value?: string[];
  /** Callback to fire when `value` changes */
  onChange?: (values: string[]) => any;
};

export const useInitCheckboxGroup = (props: UseInitCheckboxGroupProps) => {
  const {
    defaultValue: defaultValueProp = [],
    onChange: onChangeProp,
    value: valueProp,
    ...formControlProps
  } = props;

  const [value, setValue] = useControllableState<string[]>({
    defaultValue: defaultValueProp,
    onChange: onChangeProp,
    value: valueProp
  });

  const handleCheckboxChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = ev.target.checked;
    const selectedValue = ev.target.value;

    setValue((val) =>
      isChecked
        ? val.concat(selectedValue)
        : val.filter((v) => v !== selectedValue)
    );
  };

  const getCheckboxProps = (cbProps: CheckboxProps): CheckboxProps => {
    return {
      ...formControlProps,
      ...cbProps,
      checked: value.includes(cbProps.value as string),
      onChange: callAllHandlers(handleCheckboxChange, cbProps.onChange)
    };
  };

  return {
    getCheckboxProps
  };
};
