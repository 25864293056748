import { LocalStorage } from '@/lib/services/local-storage.service';
import { IN_APP_CURRENCIES } from '@b2w/shared/utility';
import Router from 'next/router';
import { useContext, createContext, useState, useEffect } from 'react';
import { CURRENCY_PARAM_NAME } from './currency-changer.utils';

type CurrencySettingState = {
  code?: string;
  symbol?: string;
  iconSrc?: string;
};
const CurrencySettingCtx = createContext({} as CurrencySettingState);
export const useCurrencySetting = () => useContext(CurrencySettingCtx);

type CurrencySettingSetter = (
  currencyData:
    | CurrencySettingState
    | ((currentState: CurrencySettingState) => CurrencySettingState)
) => void;
const SetCurrencySettingCtx = createContext({} as CurrencySettingSetter);
export const useSetCurrencySetting = () => useContext(SetCurrencySettingCtx);

type ProviderProps = {
  children: React.ReactNode;
};

export const CurrencySettingProvider: React.FC<ProviderProps> = ({
  children
}) => {
  const [currency, setCurrency] = useState<CurrencySettingState | undefined>();

  useEffect(() => {
    const currencyQueryVal = new URLSearchParams(window.location.search).get(
      CURRENCY_PARAM_NAME
    );
    let persistedCurrCode: string;

    if (currencyQueryVal) {
      persistedCurrCode = currencyQueryVal;
    } else {
      persistedCurrCode = LocalStorage.get<string>('currency', '');
    }

    const found = IN_APP_CURRENCIES.find((c) => c.code === persistedCurrCode);

    setCurrency(
      found
        ? { code: found.code, iconSrc: found.iconSrc, symbol: found.symbol }
        : {}
    );
  }, []);

  useEffect(() => {
    if (!currency) return;

    const queryParams = new URLSearchParams(window.location.search);

    if (currency.code) {
      queryParams.set(CURRENCY_PARAM_NAME, currency.code);
      LocalStorage.set('currency', currency.code);
    } else {
      LocalStorage.remove('currency');
      queryParams.delete(CURRENCY_PARAM_NAME);
    }

    const newQueryStr = queryParams.toString();
    const queryWithQuestionMark = newQueryStr ? '?' + newQueryStr : newQueryStr;

    const url = window.location.pathname + queryWithQuestionMark;
    Router.replace(url, undefined, { shallow: true, scroll: false });
  }, [currency]);

  return (
    <CurrencySettingCtx.Provider value={currency}>
      <SetCurrencySettingCtx.Provider value={setCurrency}>
        {children}
      </SetCurrencySettingCtx.Provider>
    </CurrencySettingCtx.Provider>
  );
};
