import { useRef, useCallback, useEffect } from 'react';

interface EventListeners {
  add<K extends keyof DocumentEventMap>(
    el: EventTarget,
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void;
  add(
    el: EventTarget,
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
  ): void;
  remove<K extends keyof DocumentEventMap>(
    el: EventTarget,
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void;
  remove(
    el: EventTarget,
    type: string,
    listener: EventListenerOrEventListenerObject,
    options?: boolean | EventListenerOptions
  ): void;
}

/**
 * React hook that provides an API to easily add/remove event listeners.
 */
export function useEventListeners(): EventListeners {
  const listeners = useRef(new Map());
  const currentListeners = listeners.current;

  const add = useCallback(
    (el: EventTarget, type: string, listener: () => void, options: any) => {
      listeners.current.set(listener, { type, el, options });
      el.addEventListener(type, listener, options);
    },
    []
  ) as EventListeners['add'];

  const remove = useCallback(
    (el: EventTarget, type: string, listener: () => void, options: any) => {
      el.removeEventListener(type, listener, options);
      listeners.current.delete(listener);
    },
    []
  ) as EventListeners['remove'];

  useEffect(
    () => () => {
      currentListeners.forEach((value, key) => {
        remove(value.el, value.type, key, value.options);
      });
    },
    [remove, currentListeners]
  );

  return { add, remove };
}
