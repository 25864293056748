import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
  children?: React.ReactNode;
  /**
   * @default 'portal-root'
   */
  containerElementId?: string;
}

/**
 * Portal renders children in React Portal. If portal container does not exist,
 * it will be created.
 */
export function Portal(props: PortalProps) {
  const { children, containerElementId = 'portal-root' } = props;

  const [root, setRoot] = useState<Element | null>(null);

  useEffect(() => {
    let container = document.getElementById(containerElementId);

    if (!container) {
      container = document.createElement('div');
      container.id = containerElementId;
      document.body.appendChild(container);
    }

    setRoot(container);
  }, [containerElementId]);

  return root ? createPortal(children, root) : null;
}
