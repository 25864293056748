export const LocalStorageKeyEnum = {
  FCM_TOKEN: 'app:fcmToken',
  AUTH_USER_ID: 'app:uid',
  HIDE_SUSPENSION_MODAL: 'app:hideSuspensionModal',
  VERIFICATIONS_KEY: 'app:verificationsAlert'
};

export const SessionStorageKeyEnum = {
  AUTH_REDIRECT: 'app:authRedirect',
  SMS_LAST_SENT_AT: 'app:lastSmsSentAt',
  SMS_LAST_CONFIRMATION_ID: 'app:lastSmsToken',
  SMS_LAST_PHONE_NUMBER: 'app:lastSmsPhone',
  EMAIL_LAST_SENT_AT: 'app:lastEmailVerificationSentAt',
  EMAIL_LAST_ENTERED: 'app:lastEnteredEmail',
  EMAIL_LAST_TYPE: 'app:lastemailVerificationType'
};
