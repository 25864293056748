import { forwardRef } from 'react';
import { Select, SelectHtmlProps, SelectRoot } from '../Select/Select';
import { UseInitSelectPublicProps } from '../Select/select.init';
import { Input, InputProps } from '../Input';
import { twCx } from '../twMerge';
import {
  ComboboxCtx,
  UseInitComboboxProps,
  useComboboxSearchInput,
  useInitCombobox
} from './combobox.init';

export type ComboboxHtmlProps<TValue, TMultiple> = Omit<
  SelectHtmlProps<TValue, TMultiple>,
  'renderPopupBody'
> & {
  /**
   * Custom renderer for popup in which listbox and searchable input are rendered.
   * Remember to pass `renderedListbox` and `renderedSearchInput` as children
   *
   * @example
   * renderPopupBody={(renderedListbox, renderedSearchInput) => (
   *   <Combobox.PopupBody className="overrides">
   *     {renderedSearchInput}
   *     {renderedListbox}
   *   </Combobox.PopupBody>
   * )}
   */
  renderPopupBody?: (
    renderedListbox: React.JSX.Element,
    renderedSearchInput: React.JSX.Element
  ) => React.ReactElement;
  /**
   * Custom renderer for search input
   *
   * @example
   * renderSearchInput={() => (
   *   <Combobox.SearchInput className="overrides" />
   * )}
   */
  renderSearchInput?: () => React.ReactElement;
};

export type ComboboxProps<TValue, TMultiple> = UseInitSelectPublicProps<
  TValue,
  TMultiple
> &
  UseInitComboboxProps<TValue> &
  ComboboxHtmlProps<TValue, TMultiple>;

type ComboboxComponent = <TValue, TMultiple = false>(
  props: ComboboxProps<TValue, TMultiple>
) => React.JSX.Element;

const ComboboxRoot = (<TValue, TMultiple extends boolean = false>(
  props: ComboboxProps<TValue, TMultiple>
) => {
  const {
    searchFilter,
    searchInputValue,
    onSearchInputValueChange,
    defaultSearchInputValue,
    renderSearchInput,
    renderPopupBody,
    ...selectRootProps
  } = props;

  const { selectRootOverrides, comboboxCtx } = useInitCombobox(
    {
      searchFilter,
      searchInputValue,
      onSearchInputValueChange,
      defaultSearchInputValue
    },
    selectRootProps
  );

  const renderedSearchInput =
    typeof renderSearchInput === 'function' ? (
      renderSearchInput()
    ) : (
      <ComboboxSearchInput />
    );

  const renderPopupBodySearchable: SelectHtmlProps<
    TValue,
    TMultiple
  >['renderPopupBody'] = (renderedListbox) => {
    return typeof renderPopupBody === 'function' ? (
      renderPopupBody(renderedListbox, renderedSearchInput)
    ) : (
      <Select.PopupBody>
        {renderedSearchInput}
        {renderedListbox}
      </Select.PopupBody>
    );
  };

  return (
    <ComboboxCtx.Provider value={comboboxCtx}>
      <SelectRoot
        {...selectRootProps}
        {...selectRootOverrides}
        renderPopupBody={renderPopupBodySearchable}
      />
    </ComboboxCtx.Provider>
  );
}) as ComboboxComponent;

export type ComboboxSearchInputProps = Omit<InputProps<'input'>, 'as'>;

export const ComboboxSearchInput = forwardRef(
  (
    props: ComboboxSearchInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { searchInputProps } = useComboboxSearchInput(props, ref);

    return (
      <Input
        variant="unstyled"
        {...searchInputProps}
        as="input"
        className={twCx(
          'tw-border-b tw-px-3 tw-py-2 tw-mb-2',
          searchInputProps.className
        )}
      />
    );
  }
);

/** Searchable Select component. It composes `Select` component under the hood. */
export const Combobox = Object.assign(ComboboxRoot, {
  SearchInput: ComboboxSearchInput,
  Trigger: Select.Trigger,
  PopupBody: Select.PopupBody,
  Listbox: Select.Listbox,
  Option: Select.Option,
  OptionGroup: Select.OptionGroup,
  NoOptions: Select.NoOptions
});
