import { useEffect, useState } from 'react';
import type { CountryListItem } from './countryList';

export type UseCountryListProps = {
  /** A function to replace standard `phoneMask` with desired one */
  phoneMaskReplacer?: (mask: CountryListItem['phoneMask']) => string;
  /**
   * Toggle list loading
   * @default true
   */
  loadList?: boolean;
  /**
   * Callback to fire when list is loaded
   */
  onLoad?: (list: CountryListItem[]) => any;
};

/**
 * Lazy load list of countries via `props.onLoad` callback
 */
export function useLoadCountryList(props: UseCountryListProps = {}) {
  const { loadList = true, phoneMaskReplacer, onLoad } = props;

  useEffect(() => {
    if (loadList) {
      import('./countryList')
        .then((mod) => {
          const list = mod.countryList.map((c) => ({
            ...c,
            phoneMask: phoneMaskReplacer
              ? phoneMaskReplacer(c.phoneMask)
              : c.phoneMask
          }));
          onLoad?.(list);
        })
        .catch((err) => {
          console.log('Failed to load country list', err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadList]);
}

/**
 * Get lazy loaded list of countries state
 */
export function useCountryList(
  props: Omit<UseCountryListProps, 'onLoad'> = {}
) {
  const { loadList = true, phoneMaskReplacer } = props;

  const [countryList, setCountryList] = useState<CountryListItem[]>([]);

  useLoadCountryList({
    loadList,
    phoneMaskReplacer,
    onLoad: setCountryList
  });

  return countryList;
}
