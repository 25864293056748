import { Children, cloneElement, forwardRef } from 'react';
import { Fade } from '@b2w/react-ui/transitions2';
import { DelayUnmount } from '@b2w/shared/react-hooks';
import { Portal } from '../Portal';
import { FocusLock } from '../FocusLock';
import {
  PopupCtx,
  UseInitPopupProps,
  useInitPopup,
  usePopupCtx
} from './popup.init';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { twCx } from '../twMerge';

export type PopupProps = UseInitPopupProps & {
  children: React.ReactNode;
};

const PopupRoot = (props: PopupProps) => {
  const { children, ...propsForInit } = props;

  const ctxValue = useInitPopup(propsForInit);

  return <PopupCtx.Provider value={ctxValue}>{children}</PopupCtx.Provider>;
};

export interface PopupTriggerProps {
  children?: React.ReactNode;
  /**
   * Prop for which must consider trigger reference.
   * Might be useful when trigger's child component supports ref under different name, e.g. "inputRef"
   *
   * @default 'ref
   */
  refPropName?: string;
}

const PopupTrigger = (props: PopupTriggerProps) => {
  const child: any = Children.only(props.children);
  const refPropName = props.refPropName ?? 'ref';

  const { getTriggerProps } = usePopupCtx();

  return cloneElement(
    child,
    getTriggerProps(child.props, child[refPropName], refPropName)
  );
};

export type PopupPositionerProps = React.ComponentProps<'div'>;

const PopupPositioner = forwardRef<HTMLDivElement, PopupPositionerProps>(
  (props, ref) => {
    const { children, ...positionerProps } = props;

    const {
      isOpen,
      animationsMs,
      inPortal,
      distanceAsPadding,
      distancePx,
      getPositionerProps,
      transformOrigin
    } = usePopupCtx();

    const markup = (
      <div
        {...getPositionerProps(
          {
            ...positionerProps,
            style: {
              ...positionerProps.style,
              '--popup-distance': distancePx + 'px'
            } as any,
            className: twCx(
              'tw-z-popover',
              distanceAsPadding &&
                'data-[popper-placement^=bottom]:tw-pt-[--popup-distance] data-[popper-placement^=top]:tw-pb-[--popup-distance] data-[popper-placement^=right]:tw-pl-[--popup-distance] data-[popper-placement^=left]:tw-pr-[--popup-distance]',
              positionerProps.className
            )
          },
          ref
        )}
      >
        {animationsMs > 0 ? (
          <Fade
            in={isOpen}
            initialScale={0.8}
            finalScale={0.8}
            duration={animationsMs}
            transformOrigin={transformOrigin}
          >
            {children}
          </Fade>
        ) : (
          children
        )}
      </div>
    );

    if (animationsMs > 0) {
      return (
        <DelayUnmount delay={animationsMs} isMounted={isOpen}>
          {inPortal ? <Portal>{markup}</Portal> : markup}
        </DelayUnmount>
      );
    }

    return inPortal ? <Portal>{isOpen && markup}</Portal> : isOpen && markup;
  }
);

export type PopupBodyProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentProps<C>;

type PopupBodyComponent = <C extends React.ElementType = 'div'>(
  props: PolymorphicComponentPropsWithRef<C>
) => JSX.Element;

const PopupBody: PopupBodyComponent = forwardRef<
  HTMLDivElement,
  PopupBodyProps
>(
  <T extends React.ElementType = 'div'>(
    props: PopupBodyProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const { children, as: Component = 'div', ...bodyProps } = props;

    const { arrow, getArrowProps, getArrowInnerProps, focusLock } =
      usePopupCtx();

    const markup = (
      <Component
        {...bodyProps}
        tabIndex={focusLock.enabled ? -1 : bodyProps.tabIndex}
        ref={ref}
        className={twCx(arrow && 'tw-relative', bodyProps.className)}
      >
        {children}
        {!!arrow && (
          <div
            {...getArrowProps({
              bg: 'currentColor',
              size: arrow.size,
              className: arrow.className
            })}
          >
            <div {...getArrowInnerProps()} />
          </div>
        )}
      </Component>
    );

    return focusLock.enabled ? (
      <FocusLock
        initialFocusRef={focusLock.initialRef}
        finalFocusRef={focusLock.finalRef}
        autoFocus={focusLock.autofocus}
        restoreFocus={focusLock.restoreFocus}
      >
        {markup}
      </FocusLock>
    ) : (
      markup
    );
  }
) as PopupBodyComponent;

export const Popup = Object.assign(PopupRoot, {
  Trigger: PopupTrigger,
  Body: PopupBody,
  Positioner: PopupPositioner
});
