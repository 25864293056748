import { forwardRef } from 'react';
import { twCx } from '../twMerge';

// question mark
const fallbackIcon = {
  viewBox: '0 0 24 24',
  path: (
    <g stroke="currentColor" strokeWidth="1.5">
      <path
        strokeLinecap="round"
        fill="none"
        d="M9,9a3,3,0,1,1,4,2.829,1.5,1.5,0,0,0-1,1.415V14.25"
      />
      <path
        fill="currentColor"
        strokeLinecap="round"
        d="M12,17.25a.375.375,0,1,0,.375.375A.375.375,0,0,0,12,17.25h0"
      />
      <circle fill="none" strokeMiterlimit="10" cx="12" cy="12" r="11.25" />
    </g>
  )
};

export type IconSize =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '1x'
  | '2x'
  | '3x'
  | '4x'
  | '5x'
  | '6x'
  | '7x'
  | '8x'
  | '9x'
  | '10x';

const sizes: IconSize[] = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '1x',
  '2x',
  '3x',
  '4x',
  '5x',
  '6x',
  '7x',
  '8x',
  '9x',
  '10x'
];

export const iconDefinition = {
  sizes
};

const size: Record<IconSize, string> = {
  '2xs': 'tw-size-2.5',
  xs: 'tw-size-3',
  sm: 'tw-size-3.5',
  md: 'tw-size-4',
  lg: 'tw-size-5',
  xl: 'tw-size-6',
  '2xl': 'tw-size-8',
  '1x': 'tw-size-4',
  '2x': 'tw-size-8',
  '3x': 'tw-size-12',
  '4x': 'tw-size-16',
  '5x': 'tw-size-20',
  '6x': 'tw-size-24',
  '7x': 'tw-size-28',
  '8x': 'tw-size-32',
  '9x': 'tw-size-36',
  '10x': 'tw-size-40'
};

const css = {
  base: 'tw-inline-block tw-text-current',
  size
};

export interface IconProps
  extends Omit<React.ComponentPropsWithoutRef<'svg'>, 'size'> {
  /**
   * Size has two scales
   * - relative, `2xs` to `2xl`
   * - literal, `1x` to `10x`
   *
   * Relative size goes step up/down from base font-size.
   * Size `md` corresponds to page base font-size.
   * Relative size to be used when icon is aligned along button/input/text/any other ui elements, etc.
   *
   * Literal size starts with base font-size (`1x`) then doubles with each step up.
   * Literal size is good when icon acts more as a standalone element,
   * or when icon does not need to be aligned with other ui elements.
   *
   * @default 'md'
   */
  size?: IconSize;
}

/**
 * Base icon element
 */
export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const {
    viewBox = fallbackIcon.viewBox,
    children = fallbackIcon.path,
    size = 'md',
    focusable = false,
    ...otherProps
  } = props;

  const className = twCx(css.base, css.size[size], otherProps.className);

  return (
    <svg
      {...otherProps}
      ref={ref}
      className={className}
      viewBox={viewBox}
      focusable={focusable}
    >
      {children}
    </svg>
  );
});
