import { forwardRef } from 'react';
import { ButtonGroupCtx, ButtonGroupCtxValue } from './button-group.ctx';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';

export interface ButtonGroupOwnProps extends ButtonGroupCtxValue {
  children?: React.ReactNode;
}

export type ButtonGroupProps<C extends React.ElementType = 'button'> =
  PolymorphicComponentPropsWithRef<C, ButtonGroupOwnProps>;

type ButtonGroupComponent = <C extends React.ElementType = 'div'>(
  props: ButtonGroupProps<C>
) => JSX.Element;

/**
 * Component to pass down props for multiple buttons at once.
 */
export const ButtonGroup: ButtonGroupComponent = forwardRef(
  <T extends React.ElementType = 'div'>(
    props: PolymorphicComponentProps<T, ButtonGroupOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      as: Component = 'div',
      colorScheme,
      variant,
      size,
      disabled,
      children,
      ...otherProps
    } = props;

    const groupCtx: ButtonGroupCtxValue = {
      colorScheme,
      variant,
      size,
      disabled
    };

    return (
      <ButtonGroupCtx.Provider value={groupCtx}>
        <Component {...otherProps} ref={ref}>
          {children}
        </Component>
      </ButtonGroupCtx.Provider>
    );
  }
) as ButtonGroupComponent;
