import { useControllableState } from '@b2w/shared/react-hooks';
import { mergeRefs } from '@b2w/shared/react-utils';
import { callAllHandlers } from '@b2w/shared/utility';
import {
  ComponentPropsWithRef,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { flushSync } from 'react-dom';
import {
  SelectController,
  SelectOption,
  SelectValue,
  UseInitSelectProps,
  useSelectLogicCtx
} from '../Select/select.init';
import { InputProps } from '../Input';
import { ComponentProps } from '../types';

export type AutocompleteCtxValue<TValue, TMultiple> = UseInitAutocompleteReturn<
  TValue,
  TMultiple
>['autocompleteCtx'];

export const AutocompleteCtx = createContext<AutocompleteCtxValue<any, any>>(
  null as any
);
export const useAutocompleteCtx = <TValue, TMultiple>() =>
  useContext<AutocompleteCtxValue<TValue, TMultiple>>(AutocompleteCtx);

export type UseInitAutocompleteProps<TValue> = {
  /**
   * Pass callback to determine how options should be filtered out
   *
   * By default, it will try to filter out by `option.label`
   */
  searchFilter?: (option: SelectOption<TValue>, searchInput: string) => boolean;
  /** Input value in controlled mode */
  inputValue?: string;
  /** `inputValue` change handler in controlled mode */
  onInputValueChange?: (str: string) => any;
  /** Initial `inputValue` in uncontrolled mode */
  defaultInputValue?: string;
  /**
   * Value to show in input when no options selected
   *
   * @default 'Search for options'
   */
  placeholder?: string;
  /**
   * If input gets into focus, input value automatically gets into selection
   *
   * @default false
   */
  inputSelectionOnFocus?: boolean;
  /**
   * Relevant only when `selectMultiple=false`
   *
   * Whether typing nothing in the input must set `value=null`
   *
   * @default true
   */
  clearSelectedValueOnTypingEmpty?: boolean;
  /**
   * Relevant only when `selectMultiple=true`
   *
   * What must be displayed in selected option tag.
   *
   * @default
   * 'value' - the value of Autocomplete.Option
   */
  whenSelectedShow?: 'label' | 'value';
  /**
   * Relevant only when `selectMultiple=true`
   *
   * How many selected option tags to keep visible when input is not focused.
   *
   * @default 0 (unlimited)
   */
  visibleSelectedTagsLimit?: number;
  /**
   * Whether to show button to clear selected value(s)
   *
   * @default true
   */
  hasClearBtn?: boolean;
};

export type UseInitAutocompleteReturn<TValue, TMultiple> = ReturnType<
  typeof useInitAutocomplete<TValue, TMultiple>
>;

export const useInitAutocomplete = <TValue, TMultiple>(
  props: UseInitAutocompleteProps<TValue>,
  selectProps: Pick<
    UseInitSelectProps<TValue, TMultiple>,
    'onChange' | 'revalidateOptionsKey' | 'onOpenChange'
  >
) => {
  const {
    defaultInputValue = '',
    inputValue: inputValueProp,
    onInputValueChange: onInputValueChangeProp,
    placeholder = 'Search for options',
    searchFilter,
    inputSelectionOnFocus = false,
    whenSelectedShow = 'value',
    visibleSelectedTagsLimit = 0,
    clearSelectedValueOnTypingEmpty = true,
    hasClearBtn = true
  } = props;

  const [input, setInput] = useControllableState({
    value: inputValueProp,
    onChange: onInputValueChangeProp,
    defaultValue: defaultInputValue
  });
  const [isFocused, setIsFocused] = useState(false);
  const [mustStopFiltering, setMustStopFiltering] = useState(false);

  const controller = useRef<SelectController<TValue, TMultiple> | null>(null);
  const canUseOpenChangeCb = useRef<boolean>(true);

  const setInputToValue = (value: TValue) => {
    const selectedLabel =
      controller.current?.allPossibleSingleOptions.find((opt) =>
        controller.current?.valueEqualityFn(opt.value, value)
      )?.label ?? '';

    setInput(
      typeof selectedLabel === 'object'
        ? JSON.stringify(selectedLabel)
        : selectedLabel.toString()
    );

    flushSync(() => {
      setMustStopFiltering(true);
    });
  };

  const onOpenChange: UseInitSelectProps<TValue, TMultiple>['onOpenChange'] = (
    isOpen
  ) => {
    selectProps.onOpenChange?.(isOpen);

    if (canUseOpenChangeCb.current) {
      if (!isOpen) {
        if (controller.current && controller.current.isSelected) {
          if (!controller.current.selectMultiple) {
            setInputToValue(controller.current.selected as TValue);
          } else {
            setInput('');
          }
        } else {
          setInput('');
        }
      }
    }

    canUseOpenChangeCb.current = true;
  };

  const onChange: UseInitSelectProps<TValue, TMultiple>['onChange'] = (
    value
  ) => {
    canUseOpenChangeCb.current = false;

    selectProps.onChange?.(value);

    if (controller.current && controller.current.selectMultiple) {
      if (input !== '') {
        flushSync(() => {
          setInput('');
        });

        if (controller.current.keepOpenedOnSelect) {
          controller.current.setActiveToFirstLastOrSelected('first');
        }
      }
    } else {
      if (value) {
        setInputToValue(value as TValue);

        if (controller.current && controller.current.keepOpenedOnSelect) {
          controller.current.setActiveToFirstLastOrSelected('first');
        }
      } else {
        setInput('');
      }
    }
  };

  const filterOption: UseInitSelectProps<TValue, TMultiple>['filterOption'] =
    mustStopFiltering
      ? undefined
      : (option) => {
          if (searchFilter) {
            return searchFilter(option, input);
          }

          return option.label.toLowerCase().indexOf(input.toLowerCase()) === 0;
        };

  const revalidateOptionsKey = input + (selectProps.revalidateOptionsKey ?? '');

  const selectRootOverrides: Partial<UseInitSelectProps<TValue, TMultiple>> = {
    controller,
    filterOption,
    revalidateOptionsKey,
    onChange,
    onOpenChange
  };

  return {
    selectRootOverrides,
    autocompleteCtx: {
      inputSelectionOnFocus,
      placeholder,
      input,
      setInput,
      setMustStopFiltering,
      whenSelectedShow,
      visibleSelectedTagsLimit,
      isFocused,
      setIsFocused,
      clearSelectedValueOnTypingEmpty,
      hasClearBtn,
      inputValueProp,
      setInputToValue
    }
  };
};

export const useAutocompleteInput = <TValue, TMultiple>(
  htmlProps: InputProps,
  htmlRef: ComponentPropsWithRef<'input'>['ref']
) => {
  const {
    input,
    setInput,
    placeholder,
    setMustStopFiltering,
    inputSelectionOnFocus,
    setIsFocused,
    clearSelectedValueOnTypingEmpty,
    setInputToValue,
    inputValueProp
  } = useAutocompleteCtx<TValue, TMultiple>();
  const {
    rootId,
    triggerRef,
    isOpen,
    close,
    open,
    active,
    setSelectedWithLogic,
    keepOpenedOnSelect,
    setActiveInDirection,
    setIsOpen,
    isSelected,
    selected,
    clickOutsideRef,
    setActiveToFirstLastOrSelected,
    optionsManager,
    selectMultiple,
    setSelected,
    setActiveOption,
    allPossibleSingleOptions,
    listboxRef
  } = useSelectLogicCtx<TValue, TMultiple>();

  const id = htmlProps.id ?? `${rootId}-autocomplete-input`;
  const mustSetInitialInput = useRef(
    inputValueProp === undefined && isSelected && !selectMultiple
  );

  // set initial input to selected value
  useEffect(() => {
    if (mustSetInitialInput.current && allPossibleSingleOptions.length) {
      //* setTimeout is required for flushSync inside `setInputToValue`
      setTimeout(() => {
        setInputToValue(selected as TValue);
      });

      mustSetInitialInput.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPossibleSingleOptions.length]);

  const onKeyDown = (ev: React.KeyboardEvent) => {
    switch (ev.key) {
      case 'Enter': {
        ev.preventDefault();

        if (isOpen) {
          const value = active?.value;

          if (value !== undefined) {
            setSelectedWithLogic(value);

            if (!keepOpenedOnSelect) {
              close();
            }
          }
        } else {
          open('first');
        }
        break;
      }
      case 'ArrowUp': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('up');
        } else {
          open('last');
        }

        break;
      }
      case 'ArrowDown': {
        ev.preventDefault();

        if (isOpen) {
          setActiveInDirection('down');
        } else {
          open('first');
        }

        break;
      }
      case 'Escape': {
        if (isOpen) {
          ev.preventDefault();
          close();
        }

        break;
      }
    }
  };

  const onClickInputWrapper = () => {
    if (isOpen) {
      input === '' && close(false);
    } else {
      open('first');
    }
  };

  const setActiveOptionToFirst = () => {
    const opt = optionsManager.getFirstEnabledOption();

    setActiveOption(opt, true, 'start');
  };

  const updateInputValue = (str: string) => {
    setMustStopFiltering(false);

    flushSync(() => {
      setInput(str);

      if (!isOpen) {
        setIsOpen(true);
      }
    });

    if (str === '' && isSelected) {
      if (!selectMultiple && clearSelectedValueOnTypingEmpty) {
        setSelected(null);
        setActiveOptionToFirst();
      } else {
        setActiveToFirstLastOrSelected('first');
      }
    } else {
      setActiveOptionToFirst();
    }
  };

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    updateInputValue(ev.target.value);
  };

  const onFocus = (ev: React.FocusEvent<HTMLInputElement>) => {
    const inputEl = ev.target;
    setIsFocused(true);

    if (inputSelectionOnFocus) {
      if (
        inputEl.value !== '' &&
        inputEl.selectionEnd! - inputEl.selectionStart! === 0
      ) {
        inputEl.select();
      }
    }
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const inputProps: InputProps<'input'> = {
    placeholder,
    autoComplete: 'off',
    autoCapitalize: 'none',
    spellCheck: 'false',
    ...htmlProps,
    id,
    ref: mergeRefs(htmlRef, triggerRef),
    refWrapper: mergeRefs(htmlProps.refWrapper, clickOutsideRef),
    onClickWrapper: callAllHandlers(
      htmlProps.onClickWrapper,
      onClickInputWrapper
    ),
    onKeyDown: callAllHandlers(htmlProps.onKeyDown, onKeyDown),
    value: input,
    onChange: callAllHandlers(htmlProps.onChange, onChange),
    onFocus: callAllHandlers(htmlProps.onFocus, onFocus),
    onBlur: callAllHandlers(htmlProps.onBlur, onBlur),
    'aria-activedescendant': active?.node?.id || '',
    'aria-autocomplete': 'list',
    'aria-controls': isOpen && listboxRef.current ? listboxRef.current.id : ''
  };

  return {
    inputProps
  };
};

export const useAutocompleteSelectedTags = <TValue, TMultiple>() => {
  const { visibleSelectedTagsLimit, isFocused } = useAutocompleteCtx<
    TValue,
    TMultiple
  >();
  const { isOpen, selectMultiple, selected } = useSelectLogicCtx<
    TValue,
    TMultiple
  >();

  let visibleTags: TValue[] = [];
  let visibleTagsNumOfMore = 0;

  if (selectMultiple && selected && (selected as TValue[]).length > 0) {
    visibleTags = selected as TValue[];

    if (visibleSelectedTagsLimit > 0 && !(isOpen || isFocused)) {
      visibleTagsNumOfMore = visibleTags.length - visibleSelectedTagsLimit;

      if (visibleTagsNumOfMore > 0) {
        visibleTags = visibleTags.slice(0, visibleSelectedTagsLimit);
      }
    }
  }

  return {
    visibleTags,
    visibleTagsNumOfMore
  };
};

export const useAutocompleteSelectedTag = <TValue, TMultiple>(
  optionValue: TValue,
  htmlProps: ComponentProps<'div'>,
  htmlRef: ComponentPropsWithRef<'div'>['ref']
) => {
  const { whenSelectedShow } = useAutocompleteCtx<TValue, TMultiple>();
  const {
    selectMultiple,
    setSelected,
    valueEqualityFn,
    allPossibleSingleOptions
  } = useSelectLogicCtx<TValue, TMultiple>();

  const tagProps: ComponentPropsWithRef<'div'> = {
    ...htmlProps,
    ref: htmlRef,
    // prevent refocus
    onMouseDown: callAllHandlers(htmlProps.onMouseDown, (ev) => {
      ev.preventDefault();
    })
  };

  const handleRemoveClick = () => {
    setSelected((currentValue) => {
      let nextValue: TValue | TValue[] | null;

      if (selectMultiple) {
        const arr = (currentValue ?? []) as TValue[];
        const existingValueIndex = arr.findIndex((val) =>
          valueEqualityFn(optionValue, val)
        );

        nextValue =
          existingValueIndex >= 0
            ? arr.filter((_, index) => index !== existingValueIndex)
            : arr;
      } else {
        nextValue = valueEqualityFn(optionValue, currentValue as TValue)
          ? null
          : currentValue;
      }

      return nextValue as SelectValue<TValue, TMultiple>;
    });
  };

  const tagRemoveBtnProps: ComponentPropsWithRef<'button'> = {
    tabIndex: -1,
    'aria-label': 'Remove option',
    title: 'Remove option',
    type: 'button',
    onClick: (ev) => {
      ev.stopPropagation();
      handleRemoveClick();
    }
  };

  let tagLabel: React.ReactNode =
    whenSelectedShow === 'value'
      ? (optionValue as React.ReactNode)
      : allPossibleSingleOptions.find((opt) =>
          valueEqualityFn(opt.value, optionValue)
        )?.label;

  tagLabel = typeof tagLabel === 'object' ? JSON.stringify(tagLabel) : tagLabel;

  return {
    tagProps,
    tagLabel,
    tagRemoveBtnProps
  };
};

export const useAutocompleteClearBtn = <TValue, TMultiple>() => {
  const { isFocused, hasClearBtn } = useAutocompleteCtx<TValue, TMultiple>();
  const {
    selectMultiple,
    setSelected,
    isSelected,
    isOpen,
    optionsManager,
    setActiveOption
  } = useSelectLogicCtx<TValue, TMultiple>();

  const mustShowClearBtn = hasClearBtn && isSelected;
  const isClearBtnVisible = isFocused;

  const clearBtnProps: ComponentPropsWithRef<'button'> = {
    tabIndex: -1,
    'aria-label': 'Clear',
    title: 'Clear',
    type: 'button',
    onMouseDown: (ev) => {
      ev.preventDefault();
    },
    onClick: (ev) => {
      ev.preventDefault();

      setSelected(
        (selectMultiple ? [] : null) as SelectValue<TValue, TMultiple>
      );

      if (isOpen) {
        const opt = optionsManager.getFirstEnabledOption();

        setActiveOption(opt, true, 'start');
      }
    }
  };

  return {
    clearBtnProps,
    mustShowClearBtn,
    isClearBtnVisible
  };
};

export const useAutocompleteCloseOpenBtn = <TValue, TMultiple>() => {
  const { isOpen, open, close } = useSelectLogicCtx<TValue, TMultiple>();

  const label = isOpen ? 'Close' : 'Open';

  const isCloseOpenBtnOpen = isOpen;

  const closeOpenBtnProps: ComponentPropsWithRef<'button'> = {
    tabIndex: -1,
    'aria-label': label,
    title: label,
    type: 'button',
    onMouseDown: (ev) => {
      ev.preventDefault();
    },
    onClick: (ev) => {
      ev.preventDefault();

      if (!isOpen) {
        open();
      } else {
        close();
      }
    }
  };

  return {
    closeOpenBtnProps,
    isCloseOpenBtnOpen
  };
};
