import {
  AskOwnerDTO,
  AskOwnerModel,
  ChatMessageDTO,
  ChatRoomDTO,
  ChatRoomModel,
  ChatRoomModelClientSide,
  DeepPartial,
  GetChatMessagesFilterDTO,
  ProfileDTO,
  SendMessageToChatDTO,
  SendMessageToChatResponseDTO
} from '@b2w/shared/types';
import type { QueryDocumentSnapshot, DocumentData } from 'firebase/firestore';
import { asyncLoadFirestore } from '../firebase/loaders';
import { MainApiService } from './main-api.service';
import { profileService, ProfileService } from './profile.service';
import { FireCollection } from '@repo/firestore-collections';

export const CHAT_PAGE_SIZE = 15;

export type InMemoryProfileCache = {
  [userId: string]: ProfileDTO;
};

// https://stackoverflow.com/a/36978360
class ChatService extends MainApiService {
  private prefix = '/chat';
  private static _instance: ChatService;

  private constructor(private profileService: ProfileService) {
    super();
  }

  public static Instance(profileService: ProfileService) {
    return this._instance || (this._instance = new this(profileService));
  }

  async sendMessageToChat(
    chatId: string,
    payload: SendMessageToChatDTO
  ): Promise<SendMessageToChatResponseDTO> {
    return this.post<SendMessageToChatResponseDTO>(
      this.prefix + '/' + chatId + '/messages',
      payload
    );
  }

  async sendPmToUser(
    toUserId: string,
    payload: SendMessageToChatDTO
  ): Promise<SendMessageToChatResponseDTO> {
    return this.post<SendMessageToChatResponseDTO>(
      this.prefix + '/user/' + toUserId,
      payload
    );
  }

  async getChatMessages(
    chatId: string,
    options?: GetChatMessagesFilterDTO
  ): Promise<ChatMessageDTO[]> {
    const endpointWithOptions = this.buildEndpointWithQueryString(
      this.prefix + '/' + chatId + '/messages',
      options
    );

    return this.get<ChatMessageDTO[]>(endpointWithOptions);
  }

  async getAskOwner(
    chatId: string,
    askOwnerDocId: string
  ): Promise<AskOwnerDTO | null> {
    const endpoint = this.prefix + '/' + chatId + '/ask-owner/' + askOwnerDocId;

    return this.get<AskOwnerDTO | null>(endpoint);
  }

  async patchAskOwner(
    chatId: string,
    askOwnerDocId: string,
    initialData: DeepPartial<AskOwnerModel>,
    newData: DeepPartial<AskOwnerModel>
  ): Promise<AskOwnerDTO | null> {
    const patch = await this.generateJsonPatch(initialData, newData);
    const endpoint = this.prefix + '/' + chatId + '/ask-owner/' + askOwnerDocId;

    return this.patch<AskOwnerDTO | null>(endpoint, patch);
  }

  async $subToChatMessages(
    chatId: string,
    filter: { startAfter?: ChatMessageDTO } = {},
    subscription: (messages: ChatMessageDTO[]) => void,
    onError?: (error: Error) => void
  ) {
    const {
      firestore,
      collection,
      query,
      doc,
      orderBy,
      Timestamp,
      startAfter,
      onSnapshot
    } = await asyncLoadFirestore();

    const constraints = [orderBy('createdAt', 'asc')];

    if (filter.startAfter) {
      const asTimestamp = Timestamp.fromDate(
        new Date(filter.startAfter['createdAt'])
      );
      constraints.push(startAfter(asTimestamp) as any);
    }

    const fireQuery = query(
      collection(
        doc(collection(firestore, FireCollection.chat.path()), chatId),
        FireCollection.chatMessages.collectionGroupName
      ),
      ...constraints
    );

    return onSnapshot(
      fireQuery,
      (snap) => {
        const data = snap.docs.map((doc) => {
          const value = doc.data();

          value.id = doc.id;
          value.createdAt = value.createdAt.toDate().toISOString();

          return value as ChatMessageDTO;
        });

        subscription(data);
      },
      onError
    );
  }

  async getChatRooms(
    profileCache: Map<string, ProfileDTO>,
    userId: string,
    limitResults?: number,
    startAfterRoom?: {
      createdAt: Date | string | number;
      latestMessageCreatedAt?: Date | string | number;
    }
  ): Promise<ChatRoomDTO[]> {
    const {
      firestore,
      getDocs,
      query,
      orderBy,
      where,
      collection,
      limit,
      Timestamp,
      startAfter
    } = await asyncLoadFirestore();

    const extraConstraints = [];

    if (startAfterRoom) {
      const fieldValues = [];

      if (startAfterRoom.latestMessageCreatedAt) {
        fieldValues.push(
          Timestamp.fromDate(new Date(startAfterRoom.latestMessageCreatedAt))
        );
      }

      fieldValues.push(Timestamp.fromDate(new Date(startAfterRoom.createdAt)));

      extraConstraints.push(startAfter(...fieldValues));
    }

    if (typeof limitResults === 'number' && limitResults > 0) {
      extraConstraints.push(limit(limitResults));
    }

    const roomsQuery = query(
      collection(firestore, FireCollection.chat.path()),
      where(`users`, 'array-contains', userId),
      orderBy('latestMessage.createdAt', 'desc'),
      orderBy('createdAt', 'desc'),
      ...extraConstraints
    );

    return getDocs(roomsQuery).then((snap) =>
      this.mapQueryDocsToChatRoomDto(snap.docs, userId, profileCache)
    );
  }

  async $subToChatRoomUpdates(
    profileCache: Map<string, ProfileDTO>,
    userId: string,
    subscription: (rooms: ChatRoomDTO[]) => void,
    onError?: (error: Error) => void
  ) {
    const {
      firestore,
      onSnapshot,
      query,
      orderBy,
      Timestamp,
      where,
      collection,
      startAfter
    } = await asyncLoadFirestore();

    const currentTimeAsTimestamp = Timestamp.fromDate(new Date());

    const fireQuery = query(
      collection(firestore, FireCollection.chat.path()),
      where(`users`, 'array-contains', userId),
      orderBy('latestMessage.createdAt'),
      orderBy('createdAt'),
      startAfter(currentTimeAsTimestamp, currentTimeAsTimestamp)
    );

    return onSnapshot(
      fireQuery,
      async (snap) => {
        const data = await this.mapQueryDocsToChatRoomDto(
          snap.docs,
          userId,
          profileCache
        );

        subscription(data);
      },
      onError
    );
  }

  private async mapQueryDocsToChatRoomDto(
    queryDocs: QueryDocumentSnapshot<DocumentData>[],
    userId: string,
    profileCache: Map<string, ProfileDTO>
  ): Promise<ChatRoomDTO[]> {
    const rooms = queryDocs.map((docSnap) =>
      this.roomSnapToClientSideModel(docSnap)
    );

    const profileIdsToLoad = rooms
      .reduce<string[]>((acc, room) => acc.concat(room.users), [])
      .filter(
        (id, pos, arr) =>
          arr.indexOf(id) === pos && id !== userId && !profileCache.has(id)
      );

    const freshProfiles =
      profileIdsToLoad.length > 0
        ? await profileService.getManyProfiles({
            ids: profileIdsToLoad
          })
        : [];

    freshProfiles.forEach((p) => {
      profileCache.set(p.id, p);
    });

    return rooms.map((room) => {
      const talkingToUid = room.users.find((uid) => uid !== userId)!;
      return { ...room, talkingTo: profileCache.get(talkingToUid) ?? null };
    });
  }

  private roomSnapToClientSideModel(
    roomSnap: QueryDocumentSnapshot<DocumentData>
  ): ChatRoomModelClientSide {
    const data = roomSnap.data() as ChatRoomModel;

    const createdAt = data.createdAt.toDate().toISOString();
    const latestMsgCreatedAt = data.latestMessage?.createdAt
      ?.toDate()
      ?.toISOString();

    const result = {
      id: roomSnap.ref.id,
      ...data
    } as unknown as ChatRoomModelClientSide;

    result.createdAt = createdAt;
    if (latestMsgCreatedAt) {
      result.latestMessage.createdAt = latestMsgCreatedAt;
    }

    return result;
  }
}

export const chatService = ChatService.Instance(profileService);
