import { forwardRef } from 'react';
import {
  RadioGroupCtx,
  UseInitRadioGroupProps,
  useInitRadioGroup
} from './radio-group.init';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';

export type RadioGroupOwnProps = UseInitRadioGroupProps;

export type RadioGroupProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentPropsWithRef<C, RadioGroupOwnProps>;

type RadioGroupComponent = <C extends React.ElementType = 'div'>(
  props: RadioGroupProps<C>
) => JSX.Element;

/** Helps manage the selected state of its children Radio components. */
export const RadioGroup: RadioGroupComponent = forwardRef(
  <T extends React.ElementType = 'div'>(
    props: PolymorphicComponentProps<T, RadioGroupProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      as: Component = 'div',
      defaultValue,
      value,
      onChange,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      name,
      children,
      ...htmlProps
    } = props;

    const ctx = useInitRadioGroup({
      defaultValue,
      value,
      onChange,
      name,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired
    });

    return (
      <RadioGroupCtx.Provider value={ctx}>
        <Component ref={ref} {...htmlProps}>
          {children}
        </Component>
      </RadioGroupCtx.Provider>
    );
  }
) as RadioGroupComponent;
