import { memo, useRef } from 'react';
import { useToggler } from '@/custom-hooks/useToggler';
import {
  useCurrencySetting,
  useSetCurrencySetting
} from './currency-setting.context';
import {
  CurrencyItem,
  groupBy,
  IN_APP_CURRENCIES,
  NO_CURRENCY_PLACEHOLDER
} from '@b2w/shared/utility';
import { twCx } from '@b2w/react-ui/core2';
import { usePopper } from '@b2w/shared/react-popper';
import styles from './ChangeCurrencyBtn.module.scss';
import { useOutsideClick } from '@b2w/shared/react-hooks';
import { mergeRefs } from '@b2w/shared/react-utils';

const currencyBySuggested = groupBy(IN_APP_CURRENCIES, (item) =>
  (item.isSuggested ?? false).toString()
);

const useCurrencyDropdown = () => {
  const [isOpen, toggle, setOpen] = useToggler(false);
  const menuContainerRef = useRef<HTMLButtonElement>();
  const buttonRef = useRef<HTMLButtonElement>();
  const { popperRef, referenceRef } = usePopper({
    placement: 'bottom'
  });
  useOutsideClick({
    ref: menuContainerRef,
    enabled: isOpen,
    handler: (event) => {
      if (!buttonRef.current?.contains(event.target as HTMLElement)) {
        setOpen(false);
      }
    }
  });

  return {
    isOpen,
    toggle,
    menuContainerRef: mergeRefs(menuContainerRef, popperRef),
    menuToggleRef: mergeRefs(buttonRef, referenceRef)
  };
};

const ChangeCurrencyBtn = () => {
  const currency = useCurrencySetting();
  const setCurrency = useSetCurrencySetting();
  const { isOpen, menuContainerRef, menuToggleRef, toggle } =
    useCurrencyDropdown();

  const updateCurrency = (clicked: CurrencyItem) => {
    setCurrency((p) => {
      if (p?.code === clicked?.code) return {};

      return {
        code: clicked.code,
        iconSrc: clicked.iconSrc,
        symbol: clicked.symbol
      };
    });

    toggle();
  };

  const renderCurrencyList = (list: CurrencyItem[]) => {
    return (
      <ul className={styles.currencyList}>
        {list.map((c) => {
          const isSelected = currency?.code === c.code;

          return (
            <li
              key={c.code}
              className={styles.currencyListItem}
              onClick={() => updateCurrency(c)}
            >
              <button
                type="button"
                className={twCx(
                  styles.currencyListItemBtn,
                  isSelected && styles.listItemSelected
                )}
              >
                <span className={styles.currencyCode}>{c.code}</span>
                <span className={styles.currencyName}>{c.displayName}</span>
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <button
        type="button"
        ref={menuToggleRef}
        onClick={toggle}
        className={twCx(styles.toggle)}
      >
        <div className="d-flex align-items-center">
          <div className="mr-2">
            {currency?.symbol ?? NO_CURRENCY_PLACEHOLDER.symbol}
          </div>
          <img
            src={currency?.iconSrc ?? NO_CURRENCY_PLACEHOLDER.iconSrc}
            alt="Currency picker"
            width="24"
            height="12"
            className="h-auto rounded-sm shadow-sm tw-max-w-none"
          />
        </div>
      </button>
      {isOpen && (
        <div ref={menuContainerRef} className={styles.menuContainer}>
          <div className={styles.currencyTitle}>Suggested currencies</div>
          {renderCurrencyList(currencyBySuggested.true)}
          <div className={styles.currencyTitle}>Other currencies</div>
          {renderCurrencyList(currencyBySuggested.false)}
        </div>
      )}
    </>
  );
};

export default memo(ChangeCurrencyBtn);
