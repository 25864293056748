import { RefObject, useEffect, useRef } from 'react';

const FOCUSABLE_TARGETS_DEFAULT =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

export const extendDefaultFocusableTargets = (selector: string) =>
  FOCUSABLE_TARGETS_DEFAULT + ', ' + selector;

/**
 * React hook to get focusable children elements of the parent element.
 *
 * @param targetElement - react ref of parent element
 * @param elementSelector - string-list of children elements to lookup.
 * This string is passed to `targetElement.querySelectorAll`
 *
 * @returns react ref which points to an array of focusable HTML children
 */
export function useFocusableElements<T extends HTMLElement = HTMLElement>(
  targetElement: RefObject<T>,
  elementSelector = FOCUSABLE_TARGETS_DEFAULT
): RefObject<HTMLElement[]> {
  const focusableElementsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (targetElement.current) {
      focusableElementsRef.current = targetElement.current.querySelectorAll(
        elementSelector
      ) as unknown as HTMLElement[];
    }
  }, [targetElement, elementSelector]);

  return focusableElementsRef;
}
