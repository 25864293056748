import { createIcon } from '../createIcon';

export const IconTriangleDown = createIcon({
  pathOrChildren:
    'M21,5H3C2.621,5,2.275,5.214,2.105,5.553C1.937,5.892,1.973,6.297,2.2,6.6l9,12 c0.188,0.252,0.485,0.4,0.8,0.4s0.611-0.148,0.8-0.4l9-12c0.228-0.303,0.264-0.708,0.095-1.047C21.725,5.214,21.379,5,21,5z'
});

export const IconTriangleUp = createIcon({
  pathOrChildren:
    'M12.8,5.4c-0.377-0.504-1.223-0.504-1.6,0l-9,12c-0.228,0.303-0.264,0.708-0.095,1.047 C2.275,18.786,2.621,19,3,19h18c0.379,0,0.725-0.214,0.895-0.553c0.169-0.339,0.133-0.744-0.095-1.047L12.8,5.4z'
});

export const IconArrowRight = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M12.97 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06l6.22-6.22H3a.75.75 0 010-1.5h16.19l-6.22-6.22a.75.75 0 010-1.06z"
      clipRule="evenodd"
    />
  )
});

export const IconArrowShortRight = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z"
      clipRule="evenodd"
    />
  )
});

export const IconArrowLongRight = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M16.72 7.72a.75.75 0 011.06 0l3.75 3.75a.75.75 0 010 1.06l-3.75 3.75a.75.75 0 11-1.06-1.06l2.47-2.47H3a.75.75 0 010-1.5h16.19l-2.47-2.47a.75.75 0 010-1.06z"
      clipRule="evenodd"
    />
  )
});

export const IconArrowLeft = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M11.03 3.97a.75.75 0 010 1.06l-6.22 6.22H21a.75.75 0 010 1.5H4.81l6.22 6.22a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z"
      clipRule="evenodd"
    />
  )
});

export const IconArrowShortLeft = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
      clipRule="evenodd"
    />
  )
});

export const IconArrowLongLeft = createIcon({
  viewBox: '0 0 24 24',
  fill: 'currentColor',
  pathOrChildren: (
    <path
      fillRule="evenodd"
      d="M7.28 7.72a.75.75 0 010 1.06l-2.47 2.47H21a.75.75 0 010 1.5H4.81l2.47 2.47a.75.75 0 11-1.06 1.06l-3.75-3.75a.75.75 0 010-1.06l3.75-3.75a.75.75 0 011.06 0z"
      clipRule="evenodd"
    />
  )
});

export const IconChevronLeft = createIcon({
  viewBox: '0 0 24 24',
  fill: 'none',
  strokeWidth: 2,
  stroke: 'currentColor',
  pathOrChildren: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 19.5L8.25 12l7.5-7.5"
    />
  )
});

export const IconChevronRight = createIcon({
  viewBox: '0 0 24 24',
  fill: 'none',
  strokeWidth: 2,
  stroke: 'currentColor',
  pathOrChildren: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.25 4.5l7.5 7.5-7.5 7.5"
    />
  )
});

export const IconChevronUp = createIcon({
  viewBox: '0 0 24 24',
  fill: 'none',
  strokeWidth: 2,
  stroke: 'currentColor',
  pathOrChildren: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 15.75 7.5-7.5 7.5 7.5"
    />
  )
});

export const IconChevronDown = createIcon({
  viewBox: '0 0 24 24',
  fill: 'none',
  strokeWidth: 2,
  stroke: 'currentColor',
  pathOrChildren: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m19.5 8.25-7.5 7.5-7.5-7.5"
    />
  )
});

export const IconUTurnLeft = createIcon({
  viewBox: '0 0 24 24',
  fill: 'none',
  strokeWidth: 1.5,
  stroke: 'currentColor',
  pathOrChildren: (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
    />
  )
});
