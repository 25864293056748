import { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useSetUser } from './user.context';
import { useSetAlert } from './alert.context';
import { UnreadCountersForUser } from '@b2w/shared/types';
import { SharedRealtimeDbSubStore } from '@/lib/realtime-db.sub-store';
import { useAudioNotification } from '@/lib/useAudio';
import { getTotalFromObject } from '@/lib/totalFromObj';
import { useProfile } from './profile.context';
import { asyncLoadDatabase } from '@/lib/firebase/loaders';
import { useUserDataLayer } from '@/lib/useUserDataLayer';

type UserTempDataContext = {
  isLoading: boolean;
  unreadCounters: UnreadCountersForUser | null;
};

const userTempDataContext = createContext<UserTempDataContext>(
  {} as UserTempDataContext
);
export const useUserTempData = () => useContext(userTempDataContext);

const initialState: UserTempDataContext = {
  isLoading: true,
  unreadCounters: null
};

export const UserTempDataProvider = ({ children }) => {
  useUserDataLayer();

  const [state, setState] = useState<UserTempDataContext>(initialState);
  const { profile } = useProfile();
  const profileId = profile?.id;

  const setAuthUser = useSetUser();
  const setAlert = useSetAlert();
  const [, playNotificationSound] = useAudioNotification();

  useEffect(() => {
    let prevUnreadCounters: UserTempDataContext['unreadCounters'] = undefined;

    if (profile) {
      asyncLoadDatabase()
        .then(({ database, ref, onValue }) => {
          const unreadCountersRef = ref(
            database,
            `unread_counters/${profile.id}`
          );
          SharedRealtimeDbSubStore.set('unread_counters', unreadCountersRef);

          onValue(
            unreadCountersRef,
            (snap) => {
              const val = snap.val() as UserTempDataContext['unreadCounters'];

              if (
                profile.preferences.unreadMessageSound &&
                prevUnreadCounters !== undefined &&
                val
              ) {
                const prevUnread = getTotalFromObject(
                  prevUnreadCounters?.chat_unread_messages
                );
                const newUnread = getTotalFromObject(val?.chat_unread_messages);

                if (newUnread > prevUnread) {
                  playNotificationSound();
                  const pureTitle = document.title.replace(/^\+.*?(\|\s)/, '');
                  document.title = `+${newUnread} unread | ${pureTitle}`;
                }
              }

              prevUnreadCounters = val;

              setState((p) => ({
                ...p,
                isLoading: false,
                unreadCounters: val
              }));
            },
            (err) => {
              console.log('err here', err.message);

              setAlert(
                err.message ?? 'Failed to load unread counter.',
                'danger'
              );
              setState((p) => ({
                ...p,
                isLoading: false,
                unreadCounters: null
              }));
            }
          );
        })
        .catch((err) => {
          setAlert(err.message, 'danger');
          setState({ isLoading: false, unreadCounters: null });
        });
    } else {
      setState({ isLoading: false, unreadCounters: null });
    }

    return () => {
      asyncLoadDatabase()
        .then(({ off }) => {
          const unreadCountersRef =
            SharedRealtimeDbSubStore.get('unread_counters');

          unreadCountersRef && off(unreadCountersRef);
        })
        .catch((err) => {
          console.log('[ERR TEMP DATA UNSUB]', err.message);
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, setAlert, setAuthUser, playNotificationSound]);

  return (
    <userTempDataContext.Provider value={state}>
      {children}
    </userTempDataContext.Provider>
  );
};
