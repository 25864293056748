/**
 * @desc Group array elements by key
 *
 * @param array target array
 * @param groupingKeyFn callback which returns a grouping key
 *
 * @returns object with `groupingKey:groupElements` pairs
 */
export function groupBy<T>(
  array: Array<T>,
  groupingKeyFn: (item: T) => string
): Record<string, Array<T>> {
  return array.reduce((result, item) => {
    const key = groupingKeyFn(item);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {} as Record<string, Array<T>>);
}

/**
 * @desc Chunks an array into smaller parts
 *
 * @param array Array to chunk
 * @param size Chunk size
 *
 * @example
 *
 * const array = [1, 2, 3, 4, 5, 6];
 * const chunked = chunkArr(array, 2);
 * chunked === [ [1, 2], [3, 4], [5, 6] ]
 */
export function chunkArr<T>(array: T[], size = 1) {
  if (size <= 0) {
    return [array];
  }

  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / size);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as T[][]);
}

/**
 * @desc Filters an array to include only unique elements. Only the first
 * occurrence of each element is kept.
 *
 * @param array Source array
 * @param predicate property name, or callback to determine uniqueness of
 * array element
 */
export function uniqBy<T>(
  arr: Array<T>,
  predicate: string | ((item: T) => string)
) {
  const cb =
    typeof predicate === 'function'
      ? predicate
      : (o: T) => (o as any)[predicate];

  const uniqueValues = arr
    .reduce((map, item) => {
      const key = item === null || item === undefined ? item : cb(item);

      map.has(key) || map.set(key, item);

      return map;
    }, new Map<any, T>())
    .values();

  return Array.from(uniqueValues);
}

/**
 * @desc Randomly pick elements from an array.
 *
 * @param array Source array
 * @param pickSize How many elements to pick randomly
 */
export function pickRandomly<T>(arr: Array<T>, pickSize: number): Array<T> {
  if (pickSize < 0) {
    throw new Error('pickRandomly: pickSize must be 0 or greater');
  }

  let len = arr.length;
  const result = Array(pickSize);
  const taken = Array(pickSize);

  if (pickSize > len) {
    throw new RangeError('pickRandomly: more elements taken than available');
  }

  while (pickSize--) {
    const x = Math.floor(Math.random() * len);

    result[pickSize] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }

  return result;
}

/**
 * Retrieve a fixed number of elements from an array, evenly distributed but
 * always including the first and last elements.
 */
export function distributeEvenly<T>(items: T[], n: number) {
  const elements = [items[0]];
  const totalItems = items.length - 2;
  const interval = Math.floor(totalItems / (n - 2));

  for (let i = 1; i < n - 1; i++) {
    elements.push(items[i * interval]);
  }

  elements.push(items[items.length - 1]);

  return elements;
}
