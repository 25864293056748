import { EventKeys } from './types';

export const dataAttr = (condition: boolean | undefined) =>
  condition ? '' : undefined;

export const ariaAttr = (condition: boolean | undefined) =>
  condition ? true : undefined;

export interface BoxModel {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

/**
 * Get the bounding box of a DOM element
 */
export function getBox(node: HTMLElement): BoxModel {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : (rect as DOMRect).top,
    left: 'y' in rect ? rect.y : (rect as DOMRect).left,
    x: 'x' in rect ? rect.x : (rect as DOMRect).left,
    y: 'y' in rect ? rect.y : (rect as DOMRect).top,
    right: rect.right,
    bottom: rect.bottom
  };
}

/**
 * Get the normalized event key across all browsers
 * @param event keyboard event
 */
export function normalizeEventKey(
  event: Pick<KeyboardEvent, 'key' | 'keyCode'>
) {
  const { key, keyCode } = event;

  const isArrowKey =
    keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0;

  const eventKey = isArrowKey ? `Arrow${key}` : key;

  return eventKey as EventKeys;
}

/**
 * Check if mouse event is a right click event.
 */
export function isRightClick(event: Pick<MouseEvent, 'button'>): boolean {
  return event.button !== 0;
}

/**
 * Check if element is a DOM element.
 */
export function isElement(el: any): el is Element {
  return (
    el != null &&
    typeof el == 'object' &&
    'nodeType' in el &&
    el.nodeType === Node.ELEMENT_NODE
  );
}

/**
 * Check if element is a HTML DOM element.
 */
export function isHTMLElement(el: any): el is HTMLElement {
  if (!isElement(el)) {
    return false;
  }

  const win = el.ownerDocument.defaultView ?? window;
  return el instanceof win.HTMLElement;
}

/**
 * Get the owner document of a given node.
 *
 * Fallbacks to window.document if node is invalid
 */
export function getOwnerDocument(node?: Element | null): Document {
  return isElement(node) ? node.ownerDocument ?? document : document;
}
