import { useCallback, useRef } from 'react';

export const useRefsArray = <T = HTMLDivElement>() => {
  const refArrayRef = useRef<T[]>([]);

  const registerRef = useCallback(
    (index: number) => (node: T) => {
      refArrayRef.current[index] = node;
    },
    []
  );

  return [registerRef, refArrayRef] as const;
};
