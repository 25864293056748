import { forwardRef } from 'react';
import { useFormControlCtx } from '../form-control.init';
import { twCx } from '../../twMerge';

export type FormDescriptionProps = React.ComponentPropsWithRef<'div'>;

/**
 * FormDescription
 *
 * Assistive component that conveys additional guidance
 * about the field, such as how it will be used and what
 * types in values should be provided.
 */
export const FormDescription = forwardRef<HTMLDivElement, FormDescriptionProps>(
  (props, ref) => {
    const formControl = useFormControlCtx();

    const descriptionProps = formControl?.getFormDescriptionProps(
      props,
      ref
    ) ?? {
      ...props,
      ref
    };

    const className = twCx(
      'tw-text-sm tw-text-gray-500 tw-mt-2',
      descriptionProps.className
    );

    return <div {...descriptionProps} className={className} />;
  }
);
