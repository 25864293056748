import { copyToClipboard } from '@b2w/shared/utility';
import { useState, useEffect } from 'react';

/**
 * React hook to copy content to clipboard
 *
 * @param text the text to copy
 * @param timeout delay (in ms) to switch back to initial state once copied
 *
 * @example
 * const [value, setValue] = useState('Hello');
 * const { hasCopied, onCopy } = useClipboard(value);
 *
 * <button onClick={onCopy}>
 *   {hasCopied ? 'Copied' : 'Copy'}
 * </button>
 *
 */
export function useClipboard(text: string, timeout = 1500) {
  const [hasCopied, setHasCopied] = useState(false);

  const onCopy = async () => {
    const didCopy = await copyToClipboard(text);
    setHasCopied(didCopy);
  };

  useEffect(() => {
    let timeoutId: number | null = null;

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);

  return { value: text, onCopy, hasCopied };
}
