import { theme } from '@/styles/sc-theme';
import { twCx } from '@b2w/react-ui/core2';
import Footer from './Footer';
import MobileNavMenu from './MobileNavMenu';
import Navbar from './Navbar';

//? fullHeight:
//? page will be 100% of height; outside elements will overflow; ability to set children height to 100%

//? minFullHeight:
//? page will be minimum 100% of height; height will dynamically change; no ability to set children height to 100%

type Props = {
  showFooter?: boolean;
  showNavbar?: boolean;
  showMobileNavs?: boolean;
  fullHeight?: boolean;
  bottomElement?: React.ReactNode;
  minFullHeight?: boolean;
  className?: string;
  children: React.ReactNode;
};

const Layout = ({
  showFooter = true,
  showNavbar = true,
  showMobileNavs = true,
  fullHeight = false,
  minFullHeight = false,
  className = '',
  bottomElement = null,
  children
}: Props) => {
  return (
    <>
      {showNavbar && <Navbar hideOnScrollMobile={!fullHeight} />}
      {showMobileNavs && <MobileNavMenu />}
      <main className={twCx('main', className)}>
        {showNavbar && (
          <div
            style={{
              height: theme.navbarHeight,
              width: '100%',
              backgroundColor: 'transparent'
            }}
          />
        )}
        {children}
        <style jsx>
          {`
            .main {
              height: ${fullHeight ? '100%' : 'auto'};
              min-height: ${minFullHeight ? '100%' : 'auto'};
              ${fullHeight ? 'overflow-y: hidden;' : ''}
            }
          `}
        </style>
      </main>
      {showFooter && <Footer />}
      {bottomElement}
    </>
  );
};

export default Layout;
