import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBellNotificationsCount } from './useBellNotificationsCount';
import { forwardRef } from 'react';

const BellIcon = (_props: any, ref: any) => {
  const { notificationsCount } = useBellNotificationsCount();

  return (
    <Button
      color="link"
      className="py-2 px-2 d-flex align-items-center text-primary-light link-unstyled"
      title="Notifications"
      ref={ref}
    >
      <FontAwesomeIcon icon={['fas', 'bell']} fixedWidth size="lg" />
      {notificationsCount > 0 && (
        <Badge color="primary-light">{notificationsCount}</Badge>
      )}
    </Button>
  );
};

export default forwardRef(BellIcon);
