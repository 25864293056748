import {
  KeyboardEvent,
  MouseEvent,
  useRef,
  useId,
  createContext,
  useContext,
  useState
} from 'react';
import { callAllHandlers, normalizeEventKey } from '@b2w/shared/utility';
import { mergeRefs, PropGetter } from '@b2w/shared/react-utils';
import { modalManager, useModalManager } from './modal.manager';

export type ModalContextValue = ReturnType<typeof useInitModal>;

export const ModalContext = createContext<ModalContextValue>({} as any);
export const useModalCtx = () => useContext(ModalContext);

export type ModalScrollBehavior = 'inside' | 'outside';
export type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

export interface UseInitModalProps {
  /**
   * If `true`, the modal will be open.
   */
  isOpen: boolean;
  /**
   * Callback invoked to close the modal.
   */
  close(): void;
  /**
   * The `id` of the modal
   */
  id?: string;
  /**
   * If `true`, the modal will close when the overlay is clicked
   * @default true
   */
  closeOnOverlayClick?: boolean;
  /**
   * If `true`, the modal will close when the `Esc` key is pressed
   * @default true
   */
  closeOnEsc?: boolean;
  /**
   * Callback fired when the overlay is clicked.
   */
  onOverlayClick?(): void;
  /**
   * Callback fired when the escape key is pressed and focus is within modal
   */
  onEsc?(): void;
  /**
   * Modal window size
   *
   * @default 'md'
   */
  size?: ModalSize;
  /**
   * Trap focus within the modal when opened
   * @default true
   */
  trapFocus?: boolean;
  /**
   * If `true`, the modal will autofocus the first enabled and interactive
   * element within the `ModalContent`
   *
   * @default true
   */
  autoFocus?: boolean;
  /**
   * The `ref` of element to receive focus when the modal opens.
   */
  initialFocusRef?: React.RefObject<HTMLElement>;
  /**
   * The `ref` of element to receive focus when the modal closes.
   */
  finalFocusRef?: React.RefObject<HTMLElement>;
  /**
   * If `true`, the modal will return focus to the element that triggered it when it closes.
   * @default true
   */
  returnFocusOnClose?: boolean;
  /**
   *  If `true`, the modal will be centered on screen.
   *
   * If the content within the modal overflows beyond the viewport, don't use this prop.
   * Use overflow instead.
   *
   * @default false
   */
  isCentered?: boolean;
  /**
   * Where scroll behavior should originate.
   * - If set to `inside`, scroll only occurs within the `ModalBody`.
   * - If set to `outside`, the entire `ModalContent` will scroll within the viewport.
   *
   * @default "outside"
   */
  scrollBehavior?: ModalScrollBehavior;
  /**
   * Prevent page from scrolling when modal is open
   *
   * @default true
   */
  lockBodyScroll?: boolean;
  /**
   * Whether to render modal backdrop
   *
   * @default true
   */
  hasBackdrop?: boolean;
  /**
   * Whether to render close button
   *
   * @default true
   */
  hasCloseButton?: boolean;
}

/**
 * Modal hook that manages all the logic for the modal dialog widget.
 *
 * Return value is passed to the context.
 */
export function useInitModal(props: UseInitModalProps) {
  const {
    id: idProp,
    isOpen,
    close,
    onEsc,
    onOverlayClick: onOverlayClickProp,
    closeOnEsc = true,
    closeOnOverlayClick = true,
    trapFocus = true,
    autoFocus = true,
    returnFocusOnClose = true,
    isCentered = false,
    lockBodyScroll = true,
    scrollBehavior = 'outside',
    size = 'md',
    hasBackdrop = true,
    hasCloseButton = true,
    finalFocusRef,
    initialFocusRef
  } = props;

  const uuid = useId();
  const modalId = idProp ?? `modal-${uuid}`;
  const overlayId = `${modalId}-overlay`;
  const contentId = `${modalId}-content`;
  const headerId = `${modalId}-header`;
  const bodyId = `${modalId}-body`;
  const footerId = `${modalId}-footer`;
  const closeBtnId = `${modalId}-close-btn`;
  const isInsideScrollBehavior = scrollBehavior === 'inside';

  const contentRef = useRef<HTMLElement | null>(null);
  const overlayRef = useRef<HTMLElement | null>(null);

  const [hasHeader, setHasHeader] = useState(false);
  const [hasBody, setHasBody] = useState(false);

  useModalManager(modalId, isOpen);

  const mouseDownTarget = useRef<EventTarget | null>(null);
  const onMouseDown = (event: MouseEvent) => {
    mouseDownTarget.current = event.target;
  };

  const onKeyDown = (ev: KeyboardEvent) => {
    const key = normalizeEventKey(ev);

    if (key === 'Escape') {
      ev.stopPropagation();

      if (closeOnEsc) {
        close?.();
      }

      onEsc?.();
    }
  };

  const onOverlayClick = (event: MouseEvent) => {
    event.stopPropagation();
    /**
     * Make sure the event starts and ends on the same DOM element.
     *
     * This is used to prevent the modal from closing when you
     * start dragging from the content, and release drag outside the content.
     *
     * We prevent this because it is technically not a considered "click outside"
     */
    if (mouseDownTarget.current !== event.target) return;

    /**
     * When you click on the overlay, we want to remove only the topmost modal
     */
    if (!modalManager.isTopModal(modalId)) return;

    if (closeOnOverlayClick) {
      close?.();
    }

    onOverlayClickProp?.();
  };

  const getOverlayProps: PropGetter = (props = {}, ref = null) => ({
    ...props,
    ref: mergeRefs(ref, overlayRef),
    id: overlayId,
    onClick: callAllHandlers(props.onClick, onOverlayClick),
    onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown),
    onMouseDown: callAllHandlers(props.onMouseDown, onMouseDown),
    tabIndex: -1
  });

  const getContentProps: PropGetter = (props = {}, ref = null) => ({
    role: 'dialog',
    ...props,
    ref: mergeRefs(ref, contentRef),
    id: contentId,
    tabIndex: -1,
    'aria-modal': true,
    'aria-labelledby': hasHeader ? headerId : undefined,
    'aria-describedby': hasBody ? bodyId : undefined,
    onClick: callAllHandlers(props.onClick, (ev: MouseEvent) => {
      ev.stopPropagation();
    }),
    'data-modal-element': 'content'
  });

  const getBackdropProps: PropGetter = (props = {}, ref = null) => ({
    ...props,
    ref,
    'data-modal-element': 'backdrop'
  });

  const getHeaderProps: PropGetter = (props = {}, ref = null) => ({
    ...props,
    id: headerId,
    ref: mergeRefs(ref, (node: HTMLElement | null) => {
      setHasHeader(!!node);
    })
  });

  const getBodyProps: PropGetter = (props = {}, ref = null) => ({
    ...props,
    id: bodyId,
    ref: mergeRefs(ref, (node) => {
      setHasBody(!!node);
    })
  });

  return {
    modalId,
    headerId,
    bodyId,
    footerId,
    closeBtnId,
    contentRef,
    getContentProps,
    getOverlayProps,
    getBackdropProps,
    getHeaderProps,
    getBodyProps,
    isOpen,
    close,
    trapFocus,
    autoFocus,
    returnFocusOnClose,
    isCentered,
    lockBodyScroll,
    scrollBehavior,
    isInsideScrollBehavior,
    size,
    finalFocusRef,
    initialFocusRef,
    hasBackdrop,
    hasCloseButton
  };
}
