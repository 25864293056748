import { gtmDataLayer } from '@/components/analytics/gtm-events';
import { useProfile } from '@/context/profile.context';
import { VerificationSource } from '@b2w/shared/types';
import { useEffect } from 'react';
import { formatDate } from './dayjs.common';

export const useUserDataLayer = () => {
  const data = useProfile();
  const { profile } = data;

  const profileId = profile?.id;

  useEffect(() => {
    if (!profile) return;

    gtmDataLayer.setUserData({
      userId: profile.id,
      uFirstName: profile.firstName || '',
      uLastName: profile.lastName || '',
      uEmail: profile.email || '',
      uPhoneNumber: profile.phoneNumber || '',
      uNationality: profile.nationality || '',
      uDob: profile.birthDate
        ? formatDate(
            new Date(
              profile.birthDate.year,
              profile.birthDate.month - 1,
              profile.birthDate.day
            ),
            'analytics'
          )
        : '',
      uCountry: profile.address?.country || '',
      uCity: profile.address?.city || '',
      uZipCode: profile.address?.postalCode || '',
      uHasStripeConnected: !!profile.stripe,
      uHasPaypalConnected: !!profile.paypal,
      uIsBusinessProfile: !!profile.isBusinessProfile,
      uAvgRating: profile.avgRating,
      uIsRenter: profile.bookings.total > 0,
      uIsOwner: profile.rentals.total > 0 || profile.totalVehicles > 0,
      uHasDriverIdUploaded: !!profile.driverLicenseDocId,
      uHasDriverIdVerified: !!profile.verifications?.includes(
        VerificationSource.driverLicense
      ),
      uHasPhoneVerified: !!profile.verifications?.includes(
        VerificationSource.phone
      ),
      uHasFacebookVerified: !!profile.verifications?.includes(
        VerificationSource.facebook
      ),
      uHasGoogleVerified: !!profile.verifications?.includes(
        VerificationSource.google
      ),
      uIsSignedUpThroughAffiliate: !!profile.signedUpWithAffiliateId
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);
};
