import Head from 'next/head';
import { companyName, publicEnv } from '@config';
import { useRouter } from 'next/router';

type SeoProps = {
  title?: string;
  description?: string;
  siteName?: string;
  ogImage?: string;
  ogImageAltText?: string;
  ogUrl?: string;
  canonicalUrl?: string;
};

const defaultSeo: SeoProps = {
  title: '',
  description: `Find scooter rentals in Cebu City, Manila, Davao, Ilo-Ilo using the ${companyName} motorbike sharing service in the Philippines.`,
  siteName: `${companyName}`,
  ogImage:
    'https://firebasestorage.googleapis.com/v0/b/b2wprod-94e64.appspot.com/o/general%2Fsocial_share.png?alt=media&token=6f3cd586-229a-469c-b42a-19dbe479e0c4',
  ogImageAltText: 'Thumbnail',
  ogUrl: publicEnv.app.hostUrl
};

const Seo = (props: SeoProps) => {
  const router = useRouter();
  const currentPage = `${publicEnv.app.hostUrl}${router.asPath}`.split(
    /[?#]/
  )[0];

  const {
    title,
    description,
    siteName,
    ogImage,
    ogImageAltText,
    ogUrl,
    canonicalUrl
  } = {
    ...defaultSeo,
    ...props,
    ogUrl: currentPage,
    canonicalUrl: props.canonicalUrl ?? currentPage
  };

  const enhancedTitle = title ? `${title} | ${companyName}` : companyName;

  return (
    <Head>
      <title key="title">{enhancedTitle}</title>
      <meta name="description" content={description} key="description" />
      <link key={canonicalUrl} rel="canonical" href={canonicalUrl} />

      <meta property="og:title" content={enhancedTitle} key="og:title" />
      <meta property="og:site_name" content={siteName} key="og:site_name" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:url" content={ogUrl} key="og:url" />
      <meta property="og:image" content={ogImage} key="og:image" />

      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta
        name="twitter:image:alt"
        content={ogImageAltText}
        key="twitter:image:alt"
      />
    </Head>
  );
};

export default Seo;
