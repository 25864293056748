import { useRef } from 'react';

/**
 * Creates a constant value over the lifecycle of a component.
 * `useConst` ensures that initializers don't execute twice or more.
 *
 * This hook exists because `useMemo` does NOT guarantee
 * that it won't re-run later on
 *
 * @usecase
 * Remembering initial value of passed prop
 */
export function useConst<T extends any | (() => any)>(init: T) {
  const ref = useRef<T | null>(null);

  if (ref.current === null) {
    ref.current = typeof init === 'function' ? init() : init;
  }

  return ref.current as T;
}
