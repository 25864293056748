import { useCallback, useRef } from 'react';
import { useSafeLayoutEffect } from './useSafeLayoutEffect';

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
export function useEventCallback<Args extends unknown[], Return>(
  fn: ((...args: Args) => Return) | undefined
): (...args: Args) => Return {
  const ref = useRef(fn);

  useSafeLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: Args) => ref.current?.(...args) as any, []);
}
