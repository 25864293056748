type LSKey = 'currency' | 'promo-shown';

const withCatch = <T>(cb: () => T): T => {
  try {
    return cb();
  } catch (error) {
    console.log('Local storage err', {
      type: error.name,
      msg: error.message
    });
  }
};

const resolveKey = (key: string) => `app:${key}`;

export const LocalStorage = {
  get<T = string>(key: LSKey, fallback?: T): T {
    const val = withCatch(() => localStorage.getItem(resolveKey(key)));
    const deserialized = withCatch<T>(() => JSON.parse(val));

    return deserialized ?? fallback ?? (val as any);
  },
  set(key: LSKey, value: any): void {
    withCatch(() =>
      localStorage.setItem(resolveKey(key), JSON.stringify(value))
    );
  },
  remove(key: LSKey): void {
    withCatch(() => localStorage.removeItem(resolveKey(key)));
  }
};
