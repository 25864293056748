import { CSSProperties } from 'react';
import { CommonFadeProps } from './types';

export const assignFadeCssVars = ({
  duration,
  finalOffsetX,
  finalOffsetY,
  finalScale,
  initialOffsetX,
  initialOffsetY,
  initialScale
}: Omit<CommonFadeProps, 'children'>) => {
  return {
    '--fade-duration': duration + 'ms',
    '--fade-initial-offset-x': initialOffsetX + 'px',
    '--fade-final-offset-x': finalOffsetX + 'px',
    '--fade-initial-offset-y': initialOffsetY + 'px',
    '--fade-final-offset-y': finalOffsetY + 'px',
    '--fade-initial-scale': initialScale + '',
    '--fade-final-scale': finalScale + ''
  } as CSSProperties;
};

const transition =
  '[transition:opacity_var(--fade-duration),_transform_var(--fade-duration)] ';

const enter =
  'tw-opacity-0 tw-translate-y-[--fade-initial-offset-y] tw-translate-x-[--fade-initial-offset-x] tw-scale-[--fade-initial-scale]';
const enterActive =
  transition +
  'tw-opacity-100 !tw-translate-y-0 !tw-translate-x-0 !tw-scale-100';
const exit =
  transition +
  'tw-opacity-100 tw-translate-y-[--fade-final-offset-y] tw-translate-x-[--fade-final-offset-x] tw-scale-[--fade-final-scale]';
const exitActive = '!tw-opacity-0';

export const classes = {
  enter,
  enterActive,
  exit,
  exitActive
};
