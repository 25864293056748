import ReactFocusLock from 'react-focus-lock';

const FocusTrap: typeof ReactFocusLock =
  (ReactFocusLock as any).default ?? ReactFocusLock;

interface FocusableElement {
  focus(options?: FocusOptions): void;
}

export interface FocusLockProps
  extends Pick<
    React.ComponentProps<typeof FocusTrap>,
    'crossFrame' | 'persistentFocus' | 'disabled' | 'autoFocus'
  > {
  children: React.ReactNode;
  /**
   * `ref` of the element to receive focus initially
   */
  initialFocusRef?: React.RefObject<FocusableElement>;
  /**
   * `ref` of the element to return focus to when `FocusLock`
   * unmounts
   */
  finalFocusRef?: React.RefObject<FocusableElement>;
  /**
   * If `true`, focus will be restored to the element that
   * triggered the `FocusLock` once it unmounts
   *
   * @default false
   */
  restoreFocus?: boolean;
}

export const FocusLock: React.FC<FocusLockProps> = (props) => {
  const {
    children,
    initialFocusRef,
    finalFocusRef,
    restoreFocus,
    ...trapProps
  } = props;

  const onActivation = () => {
    if (initialFocusRef?.current) {
      initialFocusRef.current.focus();
    }
  };

  const onDeactivation = () => {
    finalFocusRef?.current?.focus();
  };

  const returnFocus = restoreFocus && !finalFocusRef;

  return (
    <FocusTrap
      {...trapProps}
      onActivation={onActivation}
      onDeactivation={onDeactivation}
      returnFocus={returnFocus}
    >
      {children}
    </FocusTrap>
  );
};

export default FocusLock;
