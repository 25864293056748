import { ModalProps, Modal, ModalRootBase, ModalSize } from '../Modal';
import { AnimationPreset } from '../Modal/Animation';
import { twCx } from '../twMerge';

export type DrawerPlacement = 'top' | 'right' | 'bottom' | 'left';
export type DrawerSize = ModalSize;

const placementToClassName: Record<DrawerPlacement, string> = {
  left: 'tw-mr-auto tw-h-full',
  right: 'tw-ml-auto tw-h-full',
  top: 'tw-mb-auto tw-w-full',
  bottom: 'tw-mt-auto tw-w-full'
};

const placementToRounding: Record<DrawerPlacement, string> = {
  left: 'tw-rounded-r-xl',
  right: 'tw-rounded-l-xl',
  top: 'tw-rounded-b-xl',
  bottom: 'tw-rounded-t-xl'
};

const sizeToClassName: Record<ModalSize, string> = {
  xs: 'tw-w-[20rem]',
  sm: 'tw-w-[28rem]',
  md: 'tw-w-[32rem]',
  lg: 'tw-w-[42rem]',
  xl: 'tw-w-[56rem]',
  full: 'tw-w-full tw-h-full'
};

const animationDurationMs = 300;

const placementToAnimationPreset: Record<DrawerPlacement, AnimationPreset> = {
  top: {
    animationDurationMs,
    transformWhenHidden: 'translateY(-100%)',
    transformWhenVisible: 'translateY(0%)'
  },
  left: {
    animationDurationMs,
    transformWhenHidden: 'translateX(-100%)',
    transformWhenVisible: 'translateX(0%)'
  },
  bottom: {
    animationDurationMs,
    transformWhenHidden: 'translateY(100%)',
    transformWhenVisible: 'translateY(0%)'
  },
  right: {
    animationDurationMs,
    transformWhenHidden: 'translateX(100%)',
    transformWhenVisible: 'translateX(0%)'
  }
};

export interface DrawerProps extends Omit<ModalProps, 'isCentered' | 'size'> {
  /**
   * The placement of the drawer
   *
   * @default 'left'
   */
  placement?: DrawerPlacement;
  /**
   * Drawer window size
   *
   * When placement is `top` or `bottom`, all sizes except `full` are irrelevant.
   * Vertical drawers should be fluid.
   *
   * @default 'sm'
   */
  size?: DrawerSize;
  /**
   * Whether to keep edges rounded
   *
   * @default false
   */
  roundedCorners?: boolean;
}

const DrawerRoot = (props: DrawerProps) => {
  const {
    children,
    placement = 'left',
    size = 'sm',
    roundedCorners = false,
    scrollBehavior,
    classNameContent,
    ...modalProps
  } = props;

  const overlayClassName = 'tw-z-modal tw-fixed tw-inset-0 tw-flex';
  const contentClassName = twCx(
    'tw-flex tw-flex-col tw-relative tw-outline-none tw-bg-white tw-color-inherit tw-shadow-lg',
    'tw-max-h-full',
    scrollBehavior !== 'inside' && 'tw-overflow-auto',
    roundedCorners && placementToRounding[placement],
    sizeToClassName[size],
    placementToClassName[placement],
    classNameContent
  );

  return (
    <ModalRootBase
      {...modalProps}
      scrollBehavior={scrollBehavior}
      size={size}
      __overrideOverlayClassName={overlayClassName}
      __overrideContentClassName={contentClassName}
      animationPreset={placementToAnimationPreset[placement]}
    >
      {children}
    </ModalRootBase>
  );
};

export const Drawer = Object.assign(DrawerRoot, {
  Header: Modal.Header,
  Body: Modal.Body,
  Footer: Modal.Footer
});
