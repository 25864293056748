// REFERENCE
// https://codepen.io/matths/pen/nrVEQM

function isPerformanceNowSupported() {
  return 'performance' in window && 'now' in window.performance;
}

export type ScrollAnimationRef = {
  id: number | null;
};

export type ScrollToItemTargetPosition = 'start' | 'center' | 'end' | 'auto';

export const AnimatedScroll = {
  cancelAnimation(ref: ScrollAnimationRef) {
    if (ref.id) {
      window.cancelAnimationFrame(ref.id);
    }
  },
  scrollToY(
    scrollTop: number,
    container: HTMLElement | null = document.documentElement,
    durationMs = 350
  ): ScrollAnimationRef {
    const ref: ScrollAnimationRef = {
      id: null
    };

    if (!container || container.scrollTop === scrollTop) return ref;

    if (durationMs <= 0) {
      container.scrollTop = scrollTop;

      return ref;
    }

    const highPrecision = isPerformanceNowSupported();
    const startTime = highPrecision ? performance.now() : Date.now();

    const initialPos = container.scrollTop;
    const finalPos = scrollTop;

    const delta = finalPos - initialPos;

    const animateY = function animateY(time?: number) {
      time = highPrecision ? time : Date.now();
      const elapsed = time ? time - startTime : 0;

      const factor = easeInOutSine(elapsed, 0, 1, durationMs);

      container.scrollTop = initialPos + delta * factor;

      if (elapsed < durationMs && container.scrollTop !== finalPos) {
        ref.id = window.requestAnimationFrame(animateY);
      }
    };

    animateY();

    return ref;
  },
  scrollToX(
    scrollLeft: number,
    container: HTMLElement | null = document.documentElement,
    durationMs = 350
  ): ScrollAnimationRef {
    const ref: ScrollAnimationRef = {
      id: null
    };

    if (!container || container.scrollLeft === scrollLeft) return ref;

    if (durationMs <= 0) {
      container.scrollLeft = scrollLeft;

      return ref;
    }

    const highPrecision = isPerformanceNowSupported();
    const startTime = highPrecision ? performance.now() : Date.now();

    const initialPos = container.scrollLeft;
    const finalPos = scrollLeft;

    const delta = finalPos - initialPos;

    const animateX = function animateX(time?: number) {
      time = highPrecision ? time : Date.now();
      const elapsed = time ? time - startTime : 0;

      const factor = easeInOutSine(elapsed, 0, 1, durationMs);

      container.scrollLeft = initialPos + delta * factor;

      if (elapsed < durationMs && container.scrollLeft !== finalPos) {
        ref.id = window.requestAnimationFrame(animateX);
      }
    };

    animateX();

    return ref;
  },
  scrollToSelectorOrElement(
    querySelectorStrOrElement: string | HTMLElement,
    container: HTMLElement | null = document.documentElement,
    targetPosition: ScrollToItemTargetPosition = 'auto',
    durationMs = 350,
    marginPx = 0
  ) {
    if (!container) return;

    const item =
      typeof querySelectorStrOrElement === 'string'
        ? (container.querySelector(
            querySelectorStrOrElement
          ) as HTMLElement | null)
        : querySelectorStrOrElement;

    if (!item) return;

    const { offsetHeight, offsetTop } = item;
    const { offsetHeight: parentOffsetHeight, scrollTop: parentScrollTop } =
      container;

    let scrollTopFinal = -1;

    if (targetPosition === 'start') {
      scrollTopFinal = offsetTop;
    } else if (targetPosition === 'center') {
      scrollTopFinal = offsetTop + offsetHeight - parentOffsetHeight / 2;
    } else if (targetPosition === 'end') {
      scrollTopFinal = offsetTop + offsetHeight - parentOffsetHeight;
    } else if (targetPosition === 'auto') {
      const isAbove = offsetTop < parentScrollTop;
      const isBelow =
        offsetTop + offsetHeight > parentScrollTop + parentOffsetHeight;

      if (isAbove) {
        scrollTopFinal = offsetTop;
      } else if (isBelow) {
        scrollTopFinal = offsetTop + offsetHeight - parentOffsetHeight;
      }
    }

    if (scrollTopFinal >= 0) {
      AnimatedScroll.scrollToY(
        scrollTopFinal + marginPx,
        container,
        durationMs
      );
    }
  },
  scrollToPageTop(durationMs = 350) {
    return AnimatedScroll.scrollToY(0, document.documentElement, durationMs);
  }
};

// t = current time or position
// b = begin value
// c = change or delta of value
// d = duration / total time or position
function easeInOutSine(t: number, b: number, c: number, d: number) {
  return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
}
// function easeInSine(t: number, b: number, c: number, d: number) {
//   return -c * Math.cos((t / d) * (Math.PI / 2)) + c + b;
// }
// function easeOutSine(t: number, b: number, c: number, d: number) {
//   return c * Math.sin((t / d) * (Math.PI / 2)) + b;
// }
// function easeInOutQuad(t: number, b: number, c: number, d: number) {
//   if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
//   return (-c / 2) * (--t * (t - 2) - 1) + b;
// }
// function easeInQuad(t: number, b: number, c: number, d: number) {
//   return c * (t /= d) * t + b;
// }
// function easeOutQuad(t: number, b: number, c: number, d: number) {
//   return -c * (t /= d) * (t - 2) + b;
// }
// function easeInOutCubic(t: number, b: number, c: number, d: number) {
//   if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
//   return (c / 2) * ((t -= 2) * t * t + 2) + b;
// }
// function easeInCubic(t: number, b: number, c: number, d: number) {
//   return c * (t /= d) * t * t + b;
// }
// function easeOutCubic(t: number, b: number, c: number, d: number) {
//   return c * ((t = t / d - 1) * t * t + 1) + b;
// }
// function easeInOutExpo(t: number, b: number, c: number, d: number) {
//   if (t === 0) return b;
//   if (t === d) return b + c;
//   if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
//   return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
// }
// function easeInExpo(t: number, b: number, c: number, d: number) {
//   return t === 0 ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
// }
// function easeOutExpo(t: number, b: number, c: number, d: number) {
//   return t === d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
// }
