export type RetryOptions<Data, CustomError> = {
  mustRetry?: (err?: Error | CustomError, data?: Data) => boolean;
  maxRetries?: number;
  waitBetweenRetries?: number;
};

type ExtraApiOptions = {
  withAuth?: boolean;
  bodyIsFormData?: boolean;
};

export abstract class BaseApiService<ApiErrorType> {
  constructor(
    private baseUrl: string,
    private apiErrorBuilder: (body: any) => ApiErrorType,
    private authHeaderBuilder?: () => string | Promise<string>
  ) {}

  protected async get<T>(
    endpoint: string,
    extra?: ExtraApiOptions,
    retryOptions?: RetryOptions<T, ApiErrorType>
  ): Promise<T> {
    const options = await this.getHeaders({ method: 'GET' }, extra);

    return this.fetchJson<T>(endpoint, options, retryOptions);
  }

  protected async post<T>(
    endpoint: string,
    body: any = {},
    extra?: ExtraApiOptions,
    retryOptions?: RetryOptions<T, ApiErrorType>
  ): Promise<T> {
    const options = await this.getHeaders(
      {
        body: extra?.bodyIsFormData ? body : JSON.stringify(body),
        method: 'POST'
      },
      extra
    );

    return this.fetchJson<T>(endpoint, options, retryOptions);
  }

  protected async delete<T>(
    endpoint: string,
    extra?: ExtraApiOptions,
    retryOptions?: RetryOptions<T, ApiErrorType>
  ): Promise<T> {
    const options = await this.getHeaders({ method: 'DELETE' }, extra);

    return this.fetchJson<T>(endpoint, options, retryOptions);
  }

  protected async patch<T>(
    endpoint: string,
    patch: any[],
    extra?: ExtraApiOptions,
    retryOptions?: RetryOptions<T, ApiErrorType>
  ): Promise<T> {
    const options = await this.getHeaders(
      { body: JSON.stringify(patch), method: 'PATCH' },
      extra
    );

    return this.fetchJson<T>(endpoint, options, retryOptions);
  }

  public async generateJsonPatch(
    initialData: Record<string, any> | any[],
    newData: Record<string, any> | any[]
  ) {
    const fastJsonPatch = await import('fast-json-patch');
    return fastJsonPatch.compare(initialData, newData);
  }

  private async getHeaders(
    options?: RequestInit,
    extra: ExtraApiOptions = {}
  ): Promise<RequestInit> {
    const { withAuth = true, bodyIsFormData = false } = extra;
    const additionalHeaders: Record<string, any> = {};

    if (bodyIsFormData === false) {
      const method = options?.method?.toLowerCase();
      if (method === 'patch') {
        additionalHeaders['Content-Type'] = 'application/json-patch+json';
      }
      if (method === 'post') {
        additionalHeaders['Content-Type'] = 'application/json';
      }
    }

    if (withAuth && this.authHeaderBuilder) {
      additionalHeaders.Authentication = await this.authHeaderBuilder();
    }

    if (options) {
      options.headers = {
        ...options.headers,
        ...additionalHeaders
      };
    } else {
      options = { headers: additionalHeaders };
    }

    return options;
  }

  private async fetchJson<T>(
    endpoint: string,
    options?: RequestInit,
    retryOptions: RetryOptions<T, ApiErrorType> = {}
  ): Promise<T> {
    const {
      mustRetry = false,
      maxRetries = 15,
      waitBetweenRetries = 1500
    } = retryOptions;

    let data: T;
    let err: Error | ApiErrorType;

    try {
      const resp = await fetch(this.baseUrl + endpoint, options);
      const body = await resp.json();

      if (resp.ok) {
        data = body.data as T;
      } else {
        err = this.apiErrorBuilder(body ?? {});
      }
    } catch (error) {
      err = error;
    }

    if (mustRetry && mustRetry(err, data) && maxRetries > 0) {
      await this.waitFor(waitBetweenRetries);
      return this.fetchJson(endpoint, options, {
        ...retryOptions,
        maxRetries: maxRetries - 1
      });
    }

    if (err) {
      throw err;
    }

    return data;
  }

  protected buildEndpointWithQueryString(
    endpoint: string,
    queryObj?: Record<string, any>
  ): string {
    if (!queryObj) {
      return endpoint;
    }

    const esc = encodeURIComponent;

    const querySlices = Object.keys(queryObj).reduce<string[]>(
      (slices, paramName) => {
        const paramValue = queryObj[paramName];

        if (paramValue === undefined) {
          return slices;
        }

        const slice = esc(paramName) + '=' + esc(paramValue);

        return slices.concat(slice);
      },
      []
    );

    const query = querySlices.join('&');

    return endpoint + '?' + query;
  }

  private async waitFor(ms: number): Promise<void> {
    return new Promise((rs: any) => {
      setTimeout(() => {
        rs();
      }, ms);
    });
  }
}
