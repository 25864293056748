import { useModalState } from '@b2w/shared/react-hooks';
import { Modal } from '../Modal';
import { Gallery, GalleryProps, SlideItem } from './Gallery';
import { twCx } from '../twMerge';
import { useGallery } from './gallery.init';
import { useEffect, useState } from 'react';
import { CloseButton, LeftArrowButton, RightArrowButton } from './Buttons';

const ActiveIndicator = ({
  currentSlide,
  slidesTotal
}: {
  currentSlide: number;
  slidesTotal: number;
}) => (
  <div className="tw-absolute tw-bottom-0">
    <div className="tw-bg-gray-200 tw-px-3 tw-py-2 tw-rounded-lg tw-font-medium tw-mb-3">
      {currentSlide} / {slidesTotal}
    </div>
  </div>
);

export type GalleryWithPreviewerProps = Omit<
  GalleryProps,
  'onSlideClick' | 'canRegisterClickAndDragListeners'
>;

export const GalleryWithPreviewer = (props: GalleryWithPreviewerProps) => {
  const { close, isOpen, open } = useModalState();
  const [isPreviewerInDom, setIsPreviewerInDom] = useState(false);

  const {
    registerSlidesListRef,
    slideToIndex,
    registerSlideRef,
    slideToNext,
    slideToPrev,
    activeIndex
  } = useGallery({
    ...props,
    activeIndex: undefined,
    onActiveIndexChange: undefined,
    autoPlayMs: 0,
    canRegisterClickAndDragListeners: isPreviewerInDom
  });

  useEffect(() => {
    const t = setTimeout(() => {
      setIsPreviewerInDom(isOpen);
    }, 10);
    return () => {
      clearTimeout(t);
    };
  }, [isOpen]);

  const onSlideClick = (clickedIndex: number) => {
    open();
    setTimeout(() => {
      slideToIndex(clickedIndex, false);
    }, 10);
  };

  return (
    <>
      <Gallery {...props} onSlideClick={onSlideClick} />
      <Modal
        isOpen={isOpen}
        close={close}
        size="full"
        hasCloseButton={false}
        hasBackdrop={false}
        classNameContent="tw-bg-black tw-bg-opacity-90"
      >
        <Modal.Body className="tw-p-0">
          <div
            className={twCx(
              'tw-absolute tw-flex tw-items-center tw-justify-center',
              'tw-select-none tw-w-full tw-h-full',
              'tw-overflow-hidden'
            )}
          >
            <div
              ref={registerSlidesListRef}
              className="tw-relative tw-w-full tw-h-full tw-overflow-hidden"
            >
              {props.images.map((src, idx) => (
                <SlideItem
                  key={src.slideSrc + idx}
                  registerRef={registerSlideRef}
                  slideSrc={src.slideSrc}
                  index={idx}
                  fitOption="fitToSize"
                />
              ))}
            </div>
            <CloseButton onClick={close} />
            <LeftArrowButton onClick={slideToPrev} isResponsive={false} />
            <RightArrowButton onClick={slideToNext} isResponsive={false} />
            <ActiveIndicator
              currentSlide={activeIndex + 1}
              slidesTotal={props.images.length}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
