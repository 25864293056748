import { forwardRef } from 'react';
import { twCx } from '../twMerge';

export type RequiredIndicatorProps = React.ComponentPropsWithRef<'span'>;

/**
 * Used to show an asterisks (*) to indicate that something is required.
 */
export const RequiredIndicator = forwardRef<
  HTMLSpanElement,
  RequiredIndicatorProps
>((props, ref) => {
  const { children, className: classNameProp, ...restOfProps } = props;

  return (
    <span
      {...restOfProps}
      ref={ref}
      role="presentation"
      aria-hidden
      className={twCx('tw-text-error-500', classNameProp)}
    >
      {children || '*'}
    </span>
  );
});
