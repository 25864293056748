import { RefObject, useEffect, useRef } from 'react';

export type UseFocusControlProps = {
  /**
   * When set to true - capture phase begins.
   * When false - uncapture phase begins.
   */
  capture: boolean;
  /**
   * Whether to focus last active element from previous `capture` phase.
   *
   * @default true
   */
  returnFocusOnUncapture?: boolean;
  /**
   * Whether to focus an element on `capture` phase. If set to true,
   * it will try to focus `initialFocusRef`, or `focusableElementsRef`
   * (if initialFocusRef not set).
   *
   * @default true
   */
  autoFocusOnCapture?: boolean;
  /**
   * Reference to an HTML element that will be focused on `capture` phase.
   *
   * @default undefined
   */
  initialFocusRef?: RefObject<HTMLElement>;
  /**
   * Reference to an HTML element that will be focused on `uncapture` phase.
   *
   * @default undefined
   */
  finalFocusRef?: RefObject<HTMLElement>;
  /**
   * Reference to an array of HTML elements.
   * On `capture` phase first element from that array
   * will be focused if `autoFocusOnCapture` is set to true.
   *
   * This prop will be ignored if `initialFocusRef` is passed,
   * or `autoFocusOnCapture` is set to false
   *
   * @default undefined
   */
  focusableElementsRef?: RefObject<HTMLElement[]>;
};

/**
 * React hook to control focus behavior. Mainly to be used in modals
 */
export const useFocusControl = ({
  capture,
  initialFocusRef,
  finalFocusRef,
  focusableElementsRef,
  returnFocusOnUncapture = true,
  autoFocusOnCapture = true
}: UseFocusControlProps) => {
  const lastFocusedElement = useRef<HTMLElement | null>();

  useEffect(() => {
    if (capture) {
      // capture phase

      lastFocusedElement.current = document.activeElement as HTMLElement;

      if (autoFocusOnCapture) {
        if (initialFocusRef) {
          initialFocusRef.current?.focus();
        } else {
          const focusableElements = focusableElementsRef?.current;
          if (focusableElements && focusableElements.length > 0) {
            focusableElements[0].focus();
          }
        }
      } else {
        // if we don't need autofocus, blur last active element
        lastFocusedElement.current.blur();
      }

      const finalFocusEl = finalFocusRef?.current;

      return () => {
        // uncapture phase
        let elementToFocus: HTMLElement | null = null;

        if (returnFocusOnUncapture && lastFocusedElement.current) {
          elementToFocus = lastFocusedElement.current;
        }

        if (finalFocusEl) {
          elementToFocus = finalFocusEl;
        }

        elementToFocus?.focus();
      };
    }

    return undefined;
  }, [
    autoFocusOnCapture,
    capture,
    finalFocusRef,
    focusableElementsRef,
    initialFocusRef,
    returnFocusOnUncapture
  ]);
};

export type UseFocusTrapProps = {
  /**
   * Reference to an array of HTML elements
   * for which apply focus trap.
   * Focus will be locked only within supplied elements.
   * It will NOT jump to other DOM elements.
   */
  focusableElementsRef: RefObject<HTMLElement[]>;
  /**
   * Disable or enable focus trap
   *
   * @default false
   */
  isDisabled?: boolean;
};

/**
 * React hook to apply focus trap when navigating with 'TAB' and 'Shift+TAB'.
 *
 */
export const useFocusTrap = ({
  focusableElementsRef,
  isDisabled = false
}: UseFocusTrapProps) => {
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const onTabPress = (ev: KeyboardEvent) => {
      const focusableElements = focusableElementsRef.current;

      if (!focusableElements || focusableElements.length === 0) {
        return;
      }

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      const isOneOfFocusable = Array.from(focusableElements).some(
        (el) => el === document.activeElement
      );

      if (!isOneOfFocusable) {
        ev.preventDefault();
        firstElement.focus();
        return;
      }

      if (ev.shiftKey) {
        // handle tab backward
        if (document.activeElement === firstElement) {
          ev.preventDefault();
          lastElement.focus();
        }
      } else {
        // handle tab forward
        if (document.activeElement === lastElement) {
          ev.preventDefault();
          firstElement.focus();
        }
      }
    };

    function keyDownListener(ev: KeyboardEvent) {
      if (ev.key === 'Tab') {
        onTabPress(ev);
      }
    }

    document.addEventListener('keydown', keyDownListener);
    return () => document.removeEventListener('keydown', keyDownListener);
  }, [focusableElementsRef, isDisabled]);
};
