import { forwardRef } from 'react';
import { ComponentPropsWithRef } from '../types';
import { twCx } from '../twMerge';

export type CircleDotOwnProps = {
  /**
   * Size in px
   *
   * @default 4
   */
  sizePx?: number;
  className?: string;
};

export type CircleDotProps = ComponentPropsWithRef<'div', CircleDotOwnProps>;

export const CircleDot = forwardRef<HTMLDivElement, CircleDotProps>(
  (props, ref) => {
    const { sizePx = 4, className, style, ...restOfProps } = props;

    return (
      <div
        {...restOfProps}
        ref={ref}
        style={
          {
            ...style,
            '--circle-dot-size': `${sizePx}px`
          } as any
        }
        className={twCx(
          'tw-inline-block tw-min-h-[--circle-dot-size] tw-min-w-[--circle-dot-size] tw-size-[--circle-dot-size] tw-rounded-full',
          'tw-bg-current',
          className
        )}
      />
    );
  }
);
