import { useControllableState } from '@b2w/shared/react-hooks';
import { callAllHandlers } from '@b2w/shared/utility';
import { createContext, useContext } from 'react';
import { RadioProps } from './Radio';
import { FormControlMainProps } from '../FormControl';

export type RadioGroupCtxValue = UseInitRadioGroupReturn;

export const RadioGroupCtx = createContext<RadioGroupCtxValue | null>(null);
export const useRadioGroupCtx = () => useContext(RadioGroupCtx);

export type UseInitRadioGroupReturn = ReturnType<typeof useInitRadioGroup>;

export type UseInitRadioGroupProps = FormControlMainProps &
  Pick<RadioProps, 'name'> & {
    /** Default selected radio value */
    defaultValue?: string;
    /** Selected value */
    value?: string;
    /** Callback to fire when `value` changes */
    onChange?: (value: string) => any;
  };

export const useInitRadioGroup = (props: UseInitRadioGroupProps) => {
  const {
    defaultValue: defaultValueProp = '',
    onChange: onChangeProp,
    value: valueProp,
    name: nameProp,
    ...formControlProps
  } = props;

  const [value, setValue] = useControllableState<string>({
    defaultValue: defaultValueProp,
    onChange: onChangeProp,
    value: valueProp
  });

  const handleRadioChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value);
  };

  const getRadioProps = (cbProps: RadioProps): RadioProps => {
    return {
      ...formControlProps,
      ...cbProps,
      name: nameProp,
      checked: value === cbProps.value,
      onChange: callAllHandlers(handleRadioChange, cbProps.onChange)
    };
  };

  return {
    getRadioProps
  };
};
