export const theme = {
  navbarHeight: 55,
  zIndex: {
    mobileMap: 97,
    alert: 98,
    drawer: 99,
    drawerOverlay: -2,
    navbar: 999
  },
  palette: {
    primary: {
      main: '#005281',
      dark: '#0a445e',
      typo: '#fff',
      light: '#0095d3',
      lightTypo: '#000'
    },
    secondary: {
      main: '#9e9e9e',
      dark: '#707070',
      typo: '#fff',
      light: '#eaeaea',
      lightTypo: '#000'
    },
    accent: {
      main: '#e8593a',
      dark: '#bf3a25',
      typo: '#fff',
      light: '#fcf2f2',
      lightTypo: '#000'
    },
    orange: {
      main: '#F99746'
    }
  }
};
