import { forwardRef } from 'react';
import {
  CheckboxGroupCtx,
  UseInitCheckboxGroupProps,
  useInitCheckboxGroup
} from './checkbox-group.init';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';

export type CheckboxGroupOwnProps = UseInitCheckboxGroupProps;

export type CheckboxGroupProps<C extends React.ElementType = 'div'> =
  PolymorphicComponentPropsWithRef<C, CheckboxGroupOwnProps>;

type CheckboxGroupComponent = <C extends React.ElementType = 'div'>(
  props: CheckboxGroupProps<C>
) => JSX.Element;

/** Helps manage the checked state of its children Checkbox components. */
export const CheckboxGroup: CheckboxGroupComponent = forwardRef(
  <T extends React.ElementType = 'div'>(
    props: PolymorphicComponentProps<T, CheckboxGroupProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      as: Component = 'div',
      defaultValue,
      value,
      onChange,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired,
      children,
      ...htmlProps
    } = props;

    const ctx = useInitCheckboxGroup({
      defaultValue,
      value,
      onChange,
      isDisabled,
      isInvalid,
      isReadOnly,
      isRequired
    });

    return (
      <CheckboxGroupCtx.Provider value={ctx}>
        <Component ref={ref} {...htmlProps}>
          {children}
        </Component>
      </CheckboxGroupCtx.Provider>
    );
  }
) as CheckboxGroupComponent;
