import { B2wApiError } from '@b2w/shared/types';
import { mainApiOrigin } from '@config';
import { asyncLoadAuth } from '../firebase/loaders';
import { BaseApiService } from './base-api.service';

if (mainApiOrigin.includes('localhost')) {
  // allow accessing HTTPS
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

export class MainApiService extends BaseApiService<B2wApiError> {
  constructor() {
    super(
      mainApiOrigin,
      (body) =>
        new B2wApiError(
          body.message,
          body.statusCode,
          body.error,
          body.inputErrors
        ),
      async () => {
        const { auth } = await asyncLoadAuth();

        if (auth.currentUser) {
          const idToken = await auth.currentUser.getIdToken();
          return `Bearer ${idToken}`;
        }

        return '';
      }
    );
  }
}
