import { useEffect } from 'react';

/**
 * Reack hook to prevent page scrolling.
 *
 * @param isLocked whether to prevent scroll or not
 * @param avoidScrollbarReflow whether to add padding to the side
 * when scrollbar dissapears. Defaults to `true`
 */
export function useLockScroll(isLocked: boolean, avoidScrollbarReflow = true) {
  useEffect(() => {
    if (!isLocked) {
      return;
    }

    const html = document.documentElement;
    const body = document.body;

    // Save initial styles
    const originalBodyOverflow = body.style.overflow;
    const originalBodyPosition = body.style.position;
    const originalBodyPaddingRight = body.style.paddingRight;

    const originalHtmlOverflow = html.style.overflow;
    const originalHtmlPosition = html.style.position;

    const scrollBarWidth = window.innerWidth - body.clientWidth;

    /**
     * Lock scroll
     *
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    html.style.overflow = 'hidden'; /* [2] */
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */

    if (avoidScrollbarReflow) {
      // Avoid width reflow
      body.style.paddingRight = `${scrollBarWidth}px`;
    }

    return () => {
      html.style.overflow = originalHtmlOverflow;
      html.style.position = originalHtmlPosition;
      body.style.overflow = originalBodyOverflow;
      body.style.position = originalBodyPosition;

      if (scrollBarWidth && avoidScrollbarReflow) {
        body.style.paddingRight = originalBodyPaddingRight;
      }
    };
  }, [isLocked, avoidScrollbarReflow]);
}

export default useLockScroll;
