import { RefObject, useEffect, useRef } from 'react';
import { getOwnerDocument } from '@b2w/shared/utility';

export interface UseOutsideClickProps {
  /**
   * Whether must listen and react to click events
   */
  enabled?: boolean;
  /**
   * The reference to a DOM element
   */
  ref: RefObject<HTMLElement>;
  /**
   * Function invoked when a click is triggered outside the referenced element
   */
  handler?: (e: Event, didUserScroll: boolean) => void;
}

/**
 * React hook that listen for clicks/touches outside of supplied DOM
 * element.
 *
 * Can be used in components like Dropdown and Popover to close them when a
 * user clicks outside.
 */
export function useOutsideClick(props: UseOutsideClickProps) {
  const { ref, handler, enabled = true } = props;

  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
    startScrollTop: 0
  });

  useEffect(() => {
    if (!enabled) return;

    const didUserScroll = () => {
      return (
        Math.abs(
          document.documentElement.scrollTop - stateRef.current.startScrollTop
        ) > 0
      );
    };

    const onPointerDown: any = (e: PointerEvent) => {
      if (isValidEvent(e, ref)) {
        stateRef.current.isPointerDown = true;
        stateRef.current.startScrollTop = document.documentElement.scrollTop;
      }
    };

    const onMouseUp: any = (event: MouseEvent) => {
      if (stateRef.current.ignoreEmulatedMouseEvents) {
        stateRef.current.ignoreEmulatedMouseEvents = false;
        return;
      }

      if (
        stateRef.current.isPointerDown &&
        handler &&
        isValidEvent(event, ref)
      ) {
        stateRef.current.isPointerDown = false;
        handler(event, didUserScroll());
      }
    };

    const onTouchEnd = (event: TouchEvent) => {
      stateRef.current.ignoreEmulatedMouseEvents = true;
      if (
        handler &&
        stateRef.current.isPointerDown &&
        isValidEvent(event, ref)
      ) {
        stateRef.current.isPointerDown = false;
        handler(event, didUserScroll());
      }
    };

    const doc = getOwnerDocument(ref.current);
    doc.addEventListener('mousedown', onPointerDown, true);
    doc.addEventListener('mouseup', onMouseUp, true);
    doc.addEventListener('touchstart', onPointerDown, true);
    doc.addEventListener('touchend', onTouchEnd, true);

    return () => {
      doc.removeEventListener('mousedown', onPointerDown, true);
      doc.removeEventListener('mouseup', onMouseUp, true);
      doc.removeEventListener('touchstart', onPointerDown, true);
      doc.removeEventListener('touchend', onTouchEnd, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, enabled]);
}

function isValidEvent(
  event: MouseEvent | TouchEvent,
  ref: React.RefObject<HTMLElement>
) {
  const target = event.target as HTMLElement;

  if ('button' in event && event.button > 0) {
    // if not left click of the mouse
    return false;
  }

  if (target) {
    // if the event target is no longer in the document
    const doc = getOwnerDocument(target);
    if (!doc.documentElement.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
}
