export const MAIN_TRAVEL_DIRECTIONS = {
  ph: {
    name: 'Philippines',
    searchPageHref: '/search',
    flagHref: '/images/country/philippines.svg',
    cities: [
      {
        name: 'Manila',
        searchPageHref:
          '/search?pivotLat=10.3156992&pivotLng=123.8854366&aroundLat=14.586745896239155&aroundLng=120.97983856818276&zoom=12'
      },
      {
        name: 'Quezon City',
        searchPageHref:
          '/search?pivotLat=10.3156992&pivotLng=123.8854366&aroundLat=14.676198558425488&aroundLng=121.03813244429223&zoom=12'
      },
      {
        name: 'Cebu City',
        searchPageHref:
          '/search?pivotLat=10.3156992&pivotLng=123.8854366&aroundLat=10.329211049474027&aroundLng=123.89685732753267&zoom=12'
      },
      {
        name: 'Davao City',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=7.190708&pivotLng=125.455341&aroundLat=7.1947229385535625&aroundLng=125.5452931581789&aroundRadiusInKm=78.75&zoom=11'
      },
      {
        name: 'Bacoor',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=10.3402623&pivotLng=123.9415518&aroundLat=14.416022264343574&aroundLng=120.96886041493745&aroundRadiusInKm=19.22&zoom=13'
      },
      {
        name: 'Mandaue City',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=14.554729&pivotLng=121.0244452&aroundLat=10.347403757952444&aroundLng=123.94068934882087&aroundRadiusInKm=9.76&zoom=14'
      },
      {
        name: 'Tarlac City',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=15.4754786&pivotLng=120.5963492&aroundLat=15.473537773605763&aroundLng=120.61364904113438&aroundRadiusInKm=38.25&zoom=18'
      },
      {
        name: 'Baguio City',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=16.4023332&pivotLng=120.5960071&aroundLat=16.39947875685415&aroundLng=120.58840935586923&aroundRadiusInKm=16.04&zoom=13'
      }
    ]
  },
  pk: {
    name: 'Pakistan',
    searchPageHref:
      '/search?pivotLat=30.375321&pivotLng=69.34511599999999&aroundLat=30.592075494855727&aroundLng=69.3480715684695',
    flagHref: '/images/country/pakistan.svg',
    cities: [
      {
        name: 'Lahore',
        searchPageHref:
          '/search?pivotLat=31.5203696&pivotLng=74.35874729999999&aroundLat=31.483188608687136&aroundLng=74.33438930698001&zoom=11'
      },
      {
        name: 'Islamabad',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=33.6844202&pivotLng=73.04788479999999&aroundLat=33.65794223535588&aroundLng=73.06495733543645&aroundRadiusInKm=33.02&zoom=12'
      },
      {
        name: 'Rawalpindi',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=33.5651107&pivotLng=73.0169135&aroundLat=33.56186229565875&aroundLng=73.02219134420328&aroundRadiusInKm=33.06&zoom=12'
      }
    ]
  },
  ind: {
    name: 'Indonesia',
    searchPageHref:
      '/search?pivotLat=-0.789275&pivotLng=113.921327&aroundLat=-3.2190708541539674&aroundLng=118.47388210288685&zoom=5',
    flagHref: '/images/country/indonesia.svg',
    cities: [
      {
        name: 'Bali',
        searchPageHref:
          '/search?pivotLat=-8.670458199999999&pivotLng=115.2126293&aroundLat=-8.390197802628522&aroundLng=115.14252472753242&zoom=10'
      },
      {
        name: 'Nusapenida',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=-8.727807&pivotLng=115.5444231&aroundLat=-8.731594485554961&aroundLng=115.54494939377459&aroundRadiusInKm=19.62&zoom=13'
      },
      {
        name: 'Kecamatan Sukawati',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=-8.5955412&pivotLng=115.2719048&aroundLat=-8.592246624709004&aroundLng=115.27410332434586&aroundRadiusInKm=19.63&zoom=13'
      },
      {
        name: 'Kecamatan Kuta Utara',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=-8.6488988&pivotLng=115.1592718&aroundLat=-8.641800789783764&aroundLng=115.15251351453104&aroundRadiusInKm=19.63&zoom=13'
      }
    ]
  },
  co: {
    name: 'Colombia',
    searchPageHref:
      '/search?query=&from=&to=&pivotLat=4.570868&pivotLng=-74.297333&aroundLat=4.824479432035333&aroundLng=-74.33900608760855&aroundRadiusInKm=4900.35&zoom=5',
    flagHref: '/images/country/colombia.svg',
    cities: [
      {
        name: 'Medellin',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=4.570868&pivotLng=-74.297333&aroundLat=6.2442079483336235&aroundLng=-75.57832457262158&aroundRadiusInKm=39.46&zoom=12'
      }
    ]
  },
  ch: {
    name: 'China',
    searchPageHref:
      '/search?query=&from=&to=&pivotLat=-0.1806532&pivotLng=-78.4678382&aroundLat=34.501075819149435&aroundLng=104.37305853961007&aroundRadiusInKm=1945.18&zoom=6',
    flagHref: '/images/country/china.svg',
    cities: [
      {
        name: 'Tibet',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=-0.1806532&pivotLng=-78.4678382&aroundLat=31.239286115316116&aroundLng=89.89728992324689&aroundRadiusInKm=1036.12&zoom=7'
      }
    ]
  },
  ec: {
    name: 'Ecuador',
    searchPageHref:
      '/search?query=&from=&to=&pivotLat=-1.831239&pivotLng=-78.18340599999999&aroundLat=-1.796912474866934&aroundLng=-76.67992101903046&aroundRadiusInKm=2470.45&zoom=6',
    flagHref: '/images/country/ecuador.svg',
    cities: [
      {
        name: 'Quito',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=-0.1806532&pivotLng=-78.4678382&aroundLat=-0.19730117420730262&aroundLng=-78.43470318014015&aroundRadiusInKm=77.37&zoom=11'
      }
    ]
  },
  vnd: {
    name: 'Vietnam',
    searchPageHref:
      '/search?query=&from=&to=&pivotLat=14.058324&pivotLng=108.277199&aroundLat=13.226570642450575&aroundLng=107.50322537527607&aroundRadiusInKm=975.71&zoom=7',
    flagHref: '/images/country/vietnam.svg',
    cities: [
      {
        name: 'Ho Chi Minh City',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=10.8230989&pivotLng=106.6296638&aroundLat=10.76315936553702&aroundLng=106.6924810680575&aroundRadiusInKm=124.29&zoom=10'
      }
    ]
  },
  dk: {
    name: 'Denmark',
    searchPageHref:
      '/search?query=&from=&to=&pivotLat=55.416640822204464&pivotLng=11.359726209788391&aroundLat=55.416640822204464&aroundLng=11.359726209788391&aroundRadiusInKm=100.81&zoom=9',
    flagHref: '/images/country/denmark.svg',
    cities: [
      {
        name: 'Copenhagen',
        searchPageHref:
          '/search?query=&from=&to=&pivotLat=55.6760968&pivotLng=12.5683371&aroundLat=55.416640822204464&aroundLng=11.359726209788391&aroundRadiusInKm=100.81&zoom=9'
      }
    ]
  }
};
