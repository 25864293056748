import { useEffect } from 'react';
import { useUser } from './user.context';
import { useProfile } from './profile.context';
import { profileService } from '@/lib/services/profile.service';
import { PrivateProfileDTO, VerificationSource } from '@b2w/shared/types';

export const ProfileSyncProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { user } = useUser();
  const { profile, mutateProfile } = useProfile();

  const isLoaded = !!user && !!profile;

  useEffect(() => {
    if (!isLoaded) return;

    /** Makes sure user signin methods from Firebase Auth are in sync with profile verifications */
    async function optionalSync() {
      const providers = user?.providerData || [];
      const verifications = profile?.verifications || [];

      const inUnsynced = providers.some((pd) => {
        return (
          (pd.providerId === 'facebook.com' &&
            !verifications.includes(VerificationSource.facebook)) ||
          (pd.providerId === 'google.com' &&
            !verifications.includes(VerificationSource.google)) ||
          (pd.providerId === 'phone' &&
            !verifications.includes(VerificationSource.phone))
        );
      });

      if (inUnsynced) {
        const syncedVerifications = await profileService.syncVerifications();

        mutateProfile<PrivateProfileDTO>(
          (p) => ({ ...p, verifications: syncedVerifications }),
          false
        );
      }
    }

    optionalSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return children;
};
