import Head from 'next/head';

const WebFonts = () => {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/font/Ludicrous/Ludicrous-Regular.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Ludicrous/Ludicrous-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-latin-400-normal.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-all-400-normal.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-latin-500-normal.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-all-500-normal.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-latin-700-normal.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/font/Roboto/roboto-all-700-normal.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <style global jsx>{`
        @font-face {
          font-family: 'Ludicrous-Regular';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/font/Ludicrous/Ludicrous-Regular.woff') format('woff'),
            url('/font/Ludicrous/Ludicrous-Regular.woff2') format('woff2');
        }
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url('/font/Roboto/roboto-latin-400-normal.woff2') format('woff2'),
            url('/font/Roboto/roboto-all-400-normal.woff') format('woff');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url('/font/Roboto/roboto-latin-500-normal.woff2') format('woff2'),
            url('/font/Roboto/roboto-all-500-normal.woff') format('woff');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
        }
        @font-face {
          font-family: 'Roboto';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url('/font/Roboto/roboto-latin-700-normal.woff2') format('woff2'),
            url('/font/Roboto/roboto-all-700-normal.woff') format('woff');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
            U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
            U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `}</style>
    </>
  );
};

export default WebFonts;
