import { useEffect } from 'react';
import { useUser } from './user.context';
import { B2wApiError, PrivateProfileDTO } from '@b2w/shared/types';
import Router from 'next/router';
import useSWR, { useSWRConfig } from 'swr';
import { appendRedirectQueryString } from '@/lib/client-side.redirects';
import { profileService } from '@/lib/services/profile.service';
import { resolveAppHref } from '@/components/AppLink';

export const useProfile = () => {
  const { user } = useUser();
  const { cache } = useSWRConfig();
  const cacheKey = user ? 'getProfileForAuthUser' : null;

  const {
    data: profile,
    mutate: mutateProfile,
    error: profileError
  } = useSWR<PrivateProfileDTO, B2wApiError>(
    cacheKey,
    () => profileService.getProfileForAuthUser(),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      revalidateOnMount: !cache.get(cacheKey)?.data
    }
  );

  const isProfileCompleted = profileService.isProfileComplete(profile as any);
  const isProfileLoading = !!(profile === undefined && !profileError);

  return {
    profile,
    isProfileCompleted,
    isProfileLoading,
    mutateProfile,
    profileError
  };
};

export const useCompleteProfileRedirect = ({
  redirectIfCompleted = false,
  redirectIfNotCompleted = false
}) => {
  const data = useProfile();
  const { profile, isProfileCompleted } = data;

  useEffect(() => {
    if (profile && redirectIfCompleted !== redirectIfNotCompleted) {
      if (redirectIfCompleted && isProfileCompleted) {
        let location = resolveAppHref('/dashboard', Router.query);

        const queryStr = Router.query['next'];
        if (queryStr && typeof queryStr === 'string') {
          location = queryStr;
        }

        Router.replace(location);
      }

      if (redirectIfNotCompleted && !isProfileCompleted) {
        const location = appendRedirectQueryString('/profile/complete');

        Router.replace(resolveAppHref(location, Router.query));
      }
    }
  }, [
    redirectIfCompleted,
    redirectIfNotCompleted,
    profile,
    isProfileCompleted
  ]);

  return data;
};
