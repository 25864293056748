import { useUser } from '@/context/user.context';
import AppLink, { AppLinkProps } from '@/components/AppLink';
import React, { forwardRef } from 'react';

type Props = React.PropsWithChildren<AppLinkProps>;

const LinkToPrivate = forwardRef(({ href, ...props }: Props, ref: any) => {
  const { user, isAlreadyLoggedIn } = useUser();

  const isLoggedIn = Boolean(user) || isAlreadyLoggedIn;
  const modifiedHref = isLoggedIn
    ? href
    : `/signup?next=${encodeURIComponent(href as string)}`;

  return (
    <AppLink {...props} ref={ref} href={modifiedHref}>
      {props.children}
    </AppLink>
  );
});

export default LinkToPrivate;
