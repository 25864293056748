import ClientPortal from './ClientPortal';
import { Alert, Spinner } from 'reactstrap';
import { theme } from '@/styles/sc-theme';

type Props = {
  text?: string;
  showSpinner?: boolean;
  isVisible: boolean;
};

const FullWindowSpinner = ({
  isVisible,
  text = 'Loading...',
  showSpinner = true
}: Props) => {
  return (
    <ClientPortal>
      <Alert
        isOpen={isVisible}
        className="d-flex justify-content-center align-items-center text-light m-0 border-0 rounded-0"
        style={{
          background: 'rgba(0,0,0,0.75)',
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: theme.zIndex.alert
        }}
      >
        <div className="d-flex justify-content-center align-items-center text-center">
          <span>{text}</span> {showSpinner && <Spinner className="ml-2" />}
          <style jsx>{`
            span {
              font-weight: 500;
              font-size: 1.7rem;
            }
            @media screen and (max-width: 576px) {
              span {
                font-size: 1.45rem;
              }
            }
          `}</style>
        </div>
      </Alert>
    </ClientPortal>
  );
};

export default FullWindowSpinner;
