import { forwardRef } from 'react';
import { useFormControlCtx } from '../form-control.init';
import { twCx } from '../../twMerge';

export type FormErrorMessageProps = React.ComponentPropsWithRef<'div'>;

/**
 * Used to provide feedback about an invalid input,
 * and suggest clear instructions on how to fix it.
 */
export const FormErrorMessage = forwardRef<
  HTMLDivElement,
  FormErrorMessageProps
>((props, ref) => {
  const formControl = useFormControlCtx();

  if (formControl && !formControl.isInvalid) {
    // form is valid
    return null;
  }

  const formErrorProps = formControl?.getFormErrorMessageProps(props, ref) ?? {
    ...props,
    ref
  };

  const className = twCx(
    'tw-text-sm tw-text-error-500 tw-mt-1',
    formErrorProps.className
  );

  return <div {...formErrorProps} className={className} />;
});
