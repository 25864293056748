import { useState, useEffect } from 'react';

type Listener = (this: MediaQueryList, ev: MediaQueryListEvent) => any;

const crossBrowserMediaListeners = (media: MediaQueryList) => {
  return {
    addListener: (listener: Listener) => {
      try {
        // Chrome & Firefox
        media.addEventListener('change', listener);
      } catch (e1) {
        try {
          // Safari
          media.addListener(listener);
        } catch (e2) {
          console.error('[matchMedia.listen]', e2.message);
        }
      }
    },
    removeListener: (listener: Listener) => {
      try {
        // Chrome & Firefox
        media.removeEventListener('change', listener);
      } catch (e1) {
        try {
          // Safari
          media.removeListener(listener);
        } catch (e2) {
          console.error('[matchMedia.unlisten]', e2.message);
        }
      }
    }
  };
};

export const useMediaQuery = (query: string): boolean => {
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

  const [matches, setMatches] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (!supportMatchMedia) {
      return undefined;
    }

    const queryList = window.matchMedia(query);
    const listeners = crossBrowserMediaListeners(queryList);

    const updateMatch = () => {
      if (isMounted) {
        setMatches(queryList.matches);
      }
    };

    updateMatch();
    listeners.addListener(updateMatch);

    return () => {
      isMounted = false;
      listeners.removeListener(updateMatch);
    };
  }, [query, supportMatchMedia]);

  return matches;
};
