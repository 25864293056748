import { useRouter } from 'next/router';
import { ChatRoomsProvider } from '../context/chat-rooms.context';
import { VehicleProvider } from '@/context/vehicle.context';

//? Next.js can't share state across pages (only _app)
//? For that reason, ContextInjector checks current page pathname
//? And provides corresponding context

type Props = {
  children: any;
};

export const ContextInjector = ({ children }: Props) => {
  const { pathname, query } = useRouter();
  const isChatPage = pathname === '/inbox' || pathname === '/inbox/[chatId]';
  const isVehicleEditPage = /\/vehicle\/.*\/edit/.test(pathname);

  if (isChatPage) {
    return <ChatRoomsProvider>{children}</ChatRoomsProvider>;
  }

  if (isVehicleEditPage) {
    return (
      <VehicleProvider vehicleId={query.vId as string}>
        {children}
      </VehicleProvider>
    );
  }

  return children;
};
