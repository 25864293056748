import useLockScroll from '@/lib/useLockScroll';
import { theme } from '@/styles/sc-theme';
import { forwardRef } from 'react';
import ClientPortal from './ClientPortal';
import DrawerStyles from './Drawer.module.scss';

type Props = {
  size?: string;
  customZIndex?: number;
  children: React.ReactNode;
  isDrawerOpened: boolean;
  bgOverlay?: boolean;
  position?: 'top' | 'bottom' | 'right' | 'left';
  contentClassName?: string;
};

const Drawer = (
  {
    size = '80%',
    position = 'left',
    contentClassName = '',
    customZIndex,
    isDrawerOpened,
    bgOverlay,
    children
  }: Props,
  ref: React.MutableRefObject<HTMLDivElement>
) => {
  useLockScroll(isDrawerOpened);

  return (
    <ClientPortal>
      <div
        className={`${DrawerStyles['drawer-wrapper']} ${
          isDrawerOpened ? DrawerStyles['drawer-wrapper-open'] : ''
        }`}
        style={{
          justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
          alignItems: position === 'top' ? 'flex-start' : 'flex-end',
          zIndex: customZIndex ?? theme.zIndex.drawer
        }}
      >
        {bgOverlay && (
          <div
            className={`${DrawerStyles['drawer-overlay']} ${
              isDrawerOpened ? DrawerStyles['drawer-overlay-open'] : ''
            }`}
            style={{ zIndex: theme.zIndex.drawerOverlay }}
          />
        )}
        <div
          ref={ref}
          className={`${DrawerStyles['drawer-content']} ${contentClassName}`}
          style={{
            transform: `translateZ(0) ${getDrawerTranslateResult(
              isDrawerOpened,
              position,
              theme.navbarHeight
            )}`,
            height: position === 'top' || position === 'bottom' ? size : '100%',
            width: position === 'left' || position === 'right' ? size : '100%',
            paddingTop:
              position === 'top' || position === 'bottom'
                ? 0
                : `${theme.navbarHeight + 1}px`
          }}
        >
          {children}
        </div>
      </div>
    </ClientPortal>
  );
};

export default forwardRef(Drawer);

function getDrawerTranslateResult(
  isDrawerOpened: boolean,
  position: string,
  navbarHeight: number
) {
  if (isDrawerOpened) {
    switch (position) {
      case 'left':
        return 'translateX(0)';
      case 'right':
        return `translateX(0)`;
      case 'top':
        return `translateY(${navbarHeight + 1}px)`;
      case 'bottom': {
        return `translateY(0)`;
      }
    }
  }

  switch (position) {
    case 'left':
      return 'translateX(-100%)';
    case 'right':
      return 'translateX(100%)';
    case 'top':
      return 'translateY(-100%)';
    case 'bottom': {
      return `translateY(100%)`;
    }
  }
}
