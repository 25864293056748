export const countryListPhoneMaskDictionary = {
  '#': /[0-9]/
};

export type CountryListItem = {
  /**
   * Country name
   * @example 'Afghanistan'
   */
  name: string;
  /**
   * Country ISO code
   * @example 'AF'
   */
  isoCode: string;
  /**
   * Dial code
   * @example '+93'
   */
  phoneCode: string;
  /**
   * Full phone mask which includes `phoneCode`
   * @example '+93-##-###-####'
   */
  phoneMask: string;
};

export const countryList: CountryListItem[] = [
  {
    name: 'Afghanistan',
    isoCode: 'AF',
    phoneCode: '+93',
    phoneMask: '+93-##-###-####'
  },
  {
    name: 'Albania',
    isoCode: 'AL',
    phoneCode: '+355',
    phoneMask: '+355(###)###-###'
  },
  {
    name: 'Algeria',
    isoCode: 'DZ',
    phoneCode: '+213',
    phoneMask: '+213-##-###-####'
  },
  {
    name: 'American Samoa',
    isoCode: 'AS',
    phoneCode: '+1684',
    phoneMask: '+1(684)###-####'
  },
  {
    name: 'Andorra',
    isoCode: 'AD',
    phoneCode: '+376',
    phoneMask: '+376-###-###'
  },
  {
    name: 'Angola',
    isoCode: 'AO',
    phoneCode: '+244',
    phoneMask: '+244(###)###-###'
  },
  {
    name: 'Anguilla',
    isoCode: 'AI',
    phoneCode: '+1264',
    phoneMask: '+1(264)###-####'
  },
  {
    name: 'Antigua & Barbuda',
    isoCode: 'AG',
    phoneCode: '+1268',
    phoneMask: '+1(268)###-####'
  },
  {
    name: 'Argentina',
    isoCode: 'AR',
    phoneCode: '+54',
    phoneMask: '+54(###)###-####'
  },
  {
    name: 'Armenia',
    isoCode: 'AM',
    phoneCode: '+374',
    phoneMask: '+374-##-###-###'
  },
  {
    name: 'Aruba',
    isoCode: 'AW',
    phoneCode: '+297',
    phoneMask: '+297-###-####'
  },
  {
    name: 'Australia',
    isoCode: 'AU',
    phoneCode: '+61',
    phoneMask: '+61-#-####-####'
  },
  {
    name: 'Austria',
    isoCode: 'AT',
    phoneCode: '+43',
    phoneMask: '+43(###)###-####'
  },
  {
    name: 'Azerbaijan',
    isoCode: 'AZ',
    phoneCode: '+994',
    phoneMask: '+994-##-###-##-##'
  },
  {
    name: 'Bahamas',
    isoCode: 'BS',
    phoneCode: '+1242',
    phoneMask: '+1(242)###-####'
  },
  {
    name: 'Bahrain',
    isoCode: 'BH',
    phoneCode: '+973',
    phoneMask: '+973-####-####'
  },
  {
    name: 'Bangladesh',
    isoCode: 'BD',
    phoneCode: '+880',
    phoneMask: '+880-##-###-###'
  },
  {
    name: 'Barbados',
    isoCode: 'BB',
    phoneCode: '+1246',
    phoneMask: '+1(246)###-####'
  },
  {
    name: 'Belarus',
    isoCode: 'BY',
    phoneCode: '+375',
    phoneMask: '+375(##)###-##-##'
  },
  {
    name: 'Belgium',
    isoCode: 'BE',
    phoneCode: '+32',
    phoneMask: '+32(###)###-###'
  },
  {
    name: 'Belize',
    isoCode: 'BZ',
    phoneCode: '+501',
    phoneMask: '+501-###-####'
  },
  {
    name: 'Benin',
    isoCode: 'BJ',
    phoneCode: '+229',
    phoneMask: '+229-##-##-####'
  },
  {
    name: 'Bermuda',
    isoCode: 'BM',
    phoneCode: '+1441',
    phoneMask: '+1(441)###-####'
  },
  {
    name: 'Bhutan',
    isoCode: 'BT',
    phoneCode: '+975',
    phoneMask: '+975-#-###-###'
  },
  {
    name: 'Bolivia',
    isoCode: 'BO',
    phoneCode: '+591',
    phoneMask: '+591-#-###-####'
  },
  {
    name: 'Bonaire, Sint Eustatius & Saba',
    isoCode: 'BQ',
    phoneCode: '+599',
    phoneMask: '+599-###-##-##'
  },
  {
    name: 'Bosnia & Herzegovina',
    isoCode: 'BA',
    phoneCode: '+387',
    phoneMask: '+387-##-####'
  },
  {
    name: 'Botswana',
    isoCode: 'BW',
    phoneCode: '+267',
    phoneMask: '+267-##-###-###'
  },
  {
    name: 'Brazil',
    isoCode: 'BR',
    phoneCode: '+55',
    phoneMask: '+55(##)#####-####'
  },
  {
    name: 'British Virgin Islands',
    isoCode: 'VG',
    phoneCode: '+1284',
    phoneMask: '+1(284)###-####'
  },
  {
    name: 'Brunei Darussalam',
    isoCode: 'BN',
    phoneCode: '+673',
    phoneMask: '+673-###-####'
  },
  {
    name: 'Bulgaria',
    isoCode: 'BG',
    phoneCode: '+359',
    phoneMask: '+359(###)###-###'
  },
  {
    name: 'Burkina Faso',
    isoCode: 'BF',
    phoneCode: '+226',
    phoneMask: '+226-##-##-####'
  },
  {
    name: 'Burundi',
    isoCode: 'BI',
    phoneCode: '+257',
    phoneMask: '+257-##-##-####'
  },
  {
    name: 'Cambodia',
    isoCode: 'KH',
    phoneCode: '+855',
    phoneMask: '+855-##-###-###'
  },
  {
    name: 'Cameroon',
    isoCode: 'CM',
    phoneCode: '+237',
    phoneMask: '+237-####-####'
  },
  {
    name: 'Canada',
    isoCode: 'CA',
    phoneCode: '+1',
    phoneMask: '+1(###)###-####'
  },
  {
    name: 'Cape Verde',
    isoCode: 'CV',
    phoneCode: '+238',
    phoneMask: '+238(###)##-##'
  },
  {
    name: 'Cayman Islands',
    isoCode: 'KY',
    phoneCode: '+1345',
    phoneMask: '+1(345)###-####'
  },
  {
    name: 'Central African Rep.',
    isoCode: 'CF',
    phoneCode: '+236',
    phoneMask: '+236-##-##-####'
  },
  {
    name: 'Chad',
    isoCode: 'TD',
    phoneCode: '+235',
    phoneMask: '+235-##-##-##-##'
  },
  {
    name: 'Chile',
    isoCode: 'CL',
    phoneCode: '+56',
    phoneMask: '+56-#-####-####'
  },
  {
    name: 'China',
    isoCode: 'CN',
    phoneCode: '+86',
    phoneMask: '+86-##-#####-#####'
  },
  {
    name: 'Colombia',
    isoCode: 'CO',
    phoneCode: '+57',
    phoneMask: '+57(###)###-####'
  },
  {
    name: 'Comoros',
    isoCode: 'KM',
    phoneCode: '+269',
    phoneMask: '+269-##-#####'
  },
  {
    name: 'Congo (Dem. Rep.)',
    isoCode: 'CD',
    phoneCode: '+243',
    phoneMask: '+243(###)###-###'
  },
  {
    name: 'Congo (Rep.)',
    isoCode: 'CG',
    phoneCode: '+242',
    phoneMask: '+242-##-###-####'
  },
  {
    name: 'Cook Islands',
    isoCode: 'CK',
    phoneCode: '+682',
    phoneMask: '+682-##-###'
  },
  {
    name: 'Costa Rica',
    isoCode: 'CR',
    phoneCode: '+506',
    phoneMask: '+506-####-####'
  },
  {
    name: "Côte d'Ivoire",
    isoCode: 'CI',
    phoneCode: '+225',
    phoneMask: '+225-##-###-###'
  },
  {
    name: 'Croatia',
    isoCode: 'HR',
    phoneCode: '+385',
    phoneMask: '+385-##-###-###'
  },
  {
    name: 'Cuba',
    isoCode: 'CU',
    phoneCode: '+53',
    phoneMask: '+53-#-###-####'
  },
  {
    name: 'Curaçao',
    isoCode: 'CW',
    phoneCode: '+599',
    phoneMask: '+599-###-####'
  },
  {
    name: 'Cyprus',
    isoCode: 'CY',
    phoneCode: '+357',
    phoneMask: '+357-##-###-###'
  },
  {
    name: 'Czech Republic',
    isoCode: 'CZ',
    phoneCode: '+420',
    phoneMask: '+420(###)###-###'
  },
  {
    name: 'Denmark',
    isoCode: 'DK',
    phoneCode: '+45',
    phoneMask: '+45-##-##-##-##'
  },
  {
    name: 'Diego Garcia',
    isoCode: 'IO',
    phoneCode: '+246',
    phoneMask: '+246-###-####'
  },
  {
    name: 'Djibouti',
    isoCode: 'DJ',
    phoneCode: '+253',
    phoneMask: '+253-##-##-##-##'
  },
  {
    name: 'Dominica',
    isoCode: 'DM',
    phoneCode: '+1767',
    phoneMask: '+1(767)###-####'
  },
  {
    name: 'Dominican Rep.',
    isoCode: 'DO',
    phoneCode: '+1809',
    phoneMask: '+1(809)###-####'
  },
  {
    name: 'Dominican Rep.',
    isoCode: 'DO',
    phoneCode: '+1829',
    phoneMask: '+1(829)###-####'
  },
  {
    name: 'Dominican Rep.',
    isoCode: 'DO',
    phoneCode: '+1849',
    phoneMask: '+1(849)###-####'
  },
  {
    name: 'Ecuador',
    isoCode: 'EC',
    phoneCode: '+593',
    phoneMask: '+593-#-###-####'
  },
  {
    name: 'Egypt',
    isoCode: 'EG',
    phoneCode: '+20',
    phoneMask: '+20(###)###-####'
  },
  {
    name: 'El Salvador',
    isoCode: 'SV',
    phoneCode: '+503',
    phoneMask: '+503-##-##-####'
  },
  {
    name: 'Equatorial Guinea',
    isoCode: 'GQ',
    phoneCode: '+240',
    phoneMask: '+240-##-###-####'
  },
  {
    name: 'Eritrea',
    isoCode: 'ER',
    phoneCode: '+291',
    phoneMask: '+291-#-###-###'
  },
  {
    name: 'Estonia',
    isoCode: 'EE',
    phoneCode: '+372',
    phoneMask: '+372-###-####'
  },
  {
    name: 'Eswatini',
    isoCode: 'SZ',
    phoneCode: '+268',
    phoneMask: '+268-##-##-####'
  },
  {
    name: 'Ethiopia',
    isoCode: 'ET',
    phoneCode: '+251',
    phoneMask: '+251-##-###-####'
  },
  {
    name: 'Falkland Islands',
    isoCode: 'FK',
    phoneCode: '+500',
    phoneMask: '+500-#####'
  },
  {
    name: 'Faroe Islands',
    isoCode: 'FO',
    phoneCode: '+298',
    phoneMask: '+298-###-###'
  },
  {
    name: 'Fiji',
    isoCode: 'FJ',
    phoneCode: '+679',
    phoneMask: '+679-##-#####'
  },
  {
    name: 'Finland',
    isoCode: 'FI',
    phoneCode: '+358',
    phoneMask: '+358-###############'
  },
  {
    name: 'France',
    isoCode: 'FR',
    phoneCode: '+33',
    phoneMask: '+33-#-##-##-##-##'
  },
  {
    name: 'French Guiana',
    isoCode: 'GF',
    phoneCode: '+594',
    phoneMask: '+594-#####-####'
  },
  {
    name: 'French Polynesia',
    isoCode: 'PF',
    phoneCode: '+689',
    phoneMask: '+689-##-##-##'
  },
  {
    name: 'Gabon',
    isoCode: 'GA',
    phoneCode: '+241',
    phoneMask: '+241-#-##-##-##'
  },
  {
    name: 'Gambia',
    isoCode: 'GM',
    phoneCode: '+220',
    phoneMask: '+220(###)##-##'
  },
  {
    name: 'Georgia',
    isoCode: 'GE',
    phoneCode: '+995',
    phoneMask: '+995(###)###-###'
  },
  {
    name: 'Germany',
    isoCode: 'DE',
    phoneCode: '+49',
    phoneMask: '+49-###-###'
  },
  {
    name: 'Ghana',
    isoCode: 'GH',
    phoneCode: '+233',
    phoneMask: '+233(###)###-###'
  },
  {
    name: 'Gibraltar',
    isoCode: 'GI',
    phoneCode: '+350',
    phoneMask: '+350-###-#####'
  },
  {
    name: 'Greece',
    isoCode: 'GR',
    phoneCode: '+30',
    phoneMask: '+30(###)###-####'
  },
  {
    name: 'Greenland',
    isoCode: 'GL',
    phoneCode: '+299',
    phoneMask: '+299-##-##-##'
  },
  {
    name: 'Grenada',
    isoCode: 'GD',
    phoneCode: '+1473',
    phoneMask: '+1(473)###-####'
  },
  {
    name: 'Guadeloupe',
    isoCode: 'GP',
    phoneCode: '+590',
    phoneMask: '+590-###-##-##-##'
  },
  {
    name: 'Guam',
    isoCode: 'GU',
    phoneCode: '+1671',
    phoneMask: '+1(671)###-####'
  },
  {
    name: 'Guatemala',
    isoCode: 'GT',
    phoneCode: '+502',
    phoneMask: '+502-#-###-####'
  },
  {
    name: 'Guinea',
    isoCode: 'GN',
    phoneCode: '+224',
    phoneMask: '+224-##-###-###'
  },
  {
    name: 'Guinea-Bissau',
    isoCode: 'GW',
    phoneCode: '+245',
    phoneMask: '+245-#-######'
  },
  {
    name: 'Guyana',
    isoCode: 'GY',
    phoneCode: '+592',
    phoneMask: '+592-###-####'
  },
  {
    name: 'Haiti',
    isoCode: 'HT',
    phoneCode: '+509',
    phoneMask: '+509-##-##-####'
  },
  {
    name: 'Honduras',
    isoCode: 'HN',
    phoneCode: '+504',
    phoneMask: '+504-####-####'
  },
  {
    name: 'Hong Kong',
    isoCode: 'HK',
    phoneCode: '+852',
    phoneMask: '+852-####-####'
  },
  {
    name: 'Hungary',
    isoCode: 'HU',
    phoneCode: '+36',
    phoneMask: '+36(###)###-###'
  },
  {
    name: 'Iceland',
    isoCode: 'IS',
    phoneCode: '+354',
    phoneMask: '+354-###-####'
  },
  {
    name: 'India',
    isoCode: 'IN',
    phoneCode: '+91',
    phoneMask: '+91(####)###-###'
  },
  {
    name: 'Indonesia',
    isoCode: 'ID',
    phoneCode: '+62',
    phoneMask: '+62(8##)###-##-###'
  },
  {
    name: 'Iran',
    isoCode: 'IR',
    phoneCode: '+98',
    phoneMask: '+98(###)###-####'
  },
  {
    name: 'Iraq',
    isoCode: 'IQ',
    phoneCode: '+964',
    phoneMask: '+964(###)###-####'
  },
  {
    name: 'Ireland',
    isoCode: 'IE',
    phoneCode: '+353',
    phoneMask: '+353(###)###-###'
  },
  {
    name: 'Israel',
    isoCode: 'IL',
    phoneCode: '+972',
    phoneMask: '+972-#-###-####'
  },
  {
    name: 'Italy',
    isoCode: 'IT',
    phoneCode: '+39',
    phoneMask: '+39(###)####-###'
  },
  {
    name: 'Jamaica',
    isoCode: 'JM',
    phoneCode: '+1876',
    phoneMask: '+1(876)###-####'
  },
  {
    name: 'Japan',
    isoCode: 'JP',
    phoneCode: '+81',
    phoneMask: '+81(###)###-###'
  },
  {
    name: 'Jordan',
    isoCode: 'JO',
    phoneCode: '+962',
    phoneMask: '+962-#-####-####'
  },
  {
    name: 'Kazakhstan',
    isoCode: 'KZ',
    phoneCode: '+7',
    phoneMask: '+7(7##)###-##-##'
  },
  {
    name: 'Kenya',
    isoCode: 'KE',
    phoneCode: '+254',
    phoneMask: '+254-###-######'
  },
  {
    name: 'Kiribati',
    isoCode: 'KI',
    phoneCode: '+686',
    phoneMask: '+686-##-###'
  },
  {
    name: 'Kosovo',
    isoCode: 'XK',
    phoneCode: '+383',
    phoneMask: '+383-####-####'
  },
  {
    name: 'Kuwait',
    isoCode: 'KW',
    phoneCode: '+965',
    phoneMask: '+965-####-####'
  },
  {
    name: 'Kyrgyzstan',
    isoCode: 'KG',
    phoneCode: '+996',
    phoneMask: '+996(###)###-###'
  },
  {
    name: 'Laos',
    isoCode: 'LA',
    phoneCode: '+856',
    phoneMask: '+856-##-###-###'
  },
  {
    name: 'Latvia',
    isoCode: 'LV',
    phoneCode: '+371',
    phoneMask: '+371-##-###-###'
  },
  {
    name: 'Lebanon',
    isoCode: 'LB',
    phoneCode: '+961',
    phoneMask: '+961-#-###-###'
  },
  {
    name: 'Lesotho',
    isoCode: 'LS',
    phoneCode: '+266',
    phoneMask: '+266-#-###-####'
  },
  {
    name: 'Liberia',
    isoCode: 'LR',
    phoneCode: '+231',
    phoneMask: '+231-##-###-###'
  },
  {
    name: 'Libya',
    isoCode: 'LY',
    phoneCode: '+218',
    phoneMask: '+218-21-###-####'
  },
  {
    name: 'Liechtenstein',
    isoCode: 'LI',
    phoneCode: '+423',
    phoneMask: '+423(###)###-####'
  },
  {
    name: 'Lithuania',
    isoCode: 'LT',
    phoneCode: '+370',
    phoneMask: '+370(###)##-###'
  },
  {
    name: 'Luxembourg',
    isoCode: 'LU',
    phoneCode: '+352',
    phoneMask: '+352(###)###-###'
  },
  {
    name: 'Macau',
    isoCode: 'MO',
    phoneCode: '+853',
    phoneMask: '+853-####-####'
  },
  {
    name: 'Madagascar',
    isoCode: 'MG',
    phoneCode: '+261',
    phoneMask: '+261-##-##-#####'
  },
  {
    name: 'Malawi',
    isoCode: 'MW',
    phoneCode: '+265',
    phoneMask: '+265-#-####-####'
  },
  {
    name: 'Malaysia',
    isoCode: 'MY',
    phoneCode: '+60',
    phoneMask: '+60-#-###-###'
  },
  {
    name: 'Maldives',
    isoCode: 'MV',
    phoneCode: '+960',
    phoneMask: '+960-###-####'
  },
  {
    name: 'Mali',
    isoCode: 'ML',
    phoneCode: '+223',
    phoneMask: '+223-##-##-####'
  },
  {
    name: 'Malta',
    isoCode: 'MT',
    phoneCode: '+356',
    phoneMask: '+356-####-####'
  },
  {
    name: 'Marshall Islands',
    isoCode: 'MH',
    phoneCode: '+692',
    phoneMask: '+692-###-####'
  },
  {
    name: 'Martinique',
    isoCode: 'MQ',
    phoneCode: '+596',
    phoneMask: '+596(###)##-##-##'
  },
  {
    name: 'Mauritania',
    isoCode: 'MR',
    phoneCode: '+222',
    phoneMask: '+222-##-##-####'
  },
  {
    name: 'Mauritius',
    isoCode: 'MU',
    phoneCode: '+230',
    phoneMask: '+230-###-####'
  },
  {
    name: 'Mexico',
    isoCode: 'MX',
    phoneCode: '+52',
    phoneMask: '+52-##-##-####'
  },
  {
    name: 'Micronesia',
    isoCode: 'FM',
    phoneCode: '+691',
    phoneMask: '+691-###-####'
  },
  {
    name: 'Moldova',
    isoCode: 'MD',
    phoneCode: '+373',
    phoneMask: '+373-####-####'
  },
  {
    name: 'Monaco',
    isoCode: 'MC',
    phoneCode: '+377',
    phoneMask: '+377-##-###-###'
  },
  {
    name: 'Mongolia',
    isoCode: 'MN',
    phoneCode: '+976',
    phoneMask: '+976-##-##-####'
  },
  {
    name: 'Montenegro',
    isoCode: 'ME',
    phoneCode: '+382',
    phoneMask: '+382-##-###-###'
  },
  {
    name: 'Montserrat',
    isoCode: 'MS',
    phoneCode: '+1664',
    phoneMask: '+1(664)###-####'
  },
  {
    name: 'Morocco',
    isoCode: 'MA',
    phoneCode: '+212',
    phoneMask: '+212-##-####-###'
  },
  {
    name: 'Mozambique',
    isoCode: 'MZ',
    phoneCode: '+258',
    phoneMask: '+258-##-###-###'
  },
  {
    name: 'Myanmar',
    isoCode: 'MM',
    phoneCode: '+95',
    phoneMask: '+95-###-###'
  },
  {
    name: 'Namibia',
    isoCode: 'NA',
    phoneCode: '+264',
    phoneMask: '+264-##-###-####'
  },
  {
    name: 'Nauru',
    isoCode: 'NR',
    phoneCode: '+674',
    phoneMask: '+674-###-####'
  },
  {
    name: 'Nepal',
    isoCode: 'NP',
    phoneCode: '+977',
    phoneMask: '+977-##-###-###'
  },
  {
    name: 'Netherlands',
    isoCode: 'NL',
    phoneCode: '+31',
    phoneMask: '+31-##-###-####'
  },
  {
    name: 'New Caledonia',
    isoCode: 'NC',
    phoneCode: '+687',
    phoneMask: '+687-##-####'
  },
  {
    name: 'New Zealand',
    isoCode: 'NZ',
    phoneCode: '+64',
    phoneMask: '+64(###)###-####'
  },
  {
    name: 'Nicaragua',
    isoCode: 'NI',
    phoneCode: '+505',
    phoneMask: '+505-####-####'
  },
  {
    name: 'Niger',
    isoCode: 'NE',
    phoneCode: '+227',
    phoneMask: '+227-##-##-####'
  },
  {
    name: 'Nigeria',
    isoCode: 'NG',
    phoneCode: '+234',
    phoneMask: '+234(###)###-####'
  },
  {
    name: 'Niue',
    isoCode: 'NU',
    phoneCode: '+683',
    phoneMask: '+683-####'
  },
  {
    name: 'Norfolk Island',
    isoCode: 'AQ',
    phoneCode: '+672',
    phoneMask: '+672-1##-###'
  },
  {
    name: 'North Korea',
    isoCode: 'KP',
    phoneCode: '+850',
    phoneMask: '+850-####-#############'
  },
  {
    name: 'North Macedonia',
    isoCode: 'MK',
    phoneCode: '+389',
    phoneMask: '+389-##-###-###'
  },
  {
    name: 'Northern Mariana Islands',
    isoCode: 'MP',
    phoneCode: '+1670',
    phoneMask: '+1(670)###-####'
  },
  {
    name: 'Norway',
    isoCode: 'NO',
    phoneCode: '+47',
    phoneMask: '+47(###)##-###'
  },
  {
    name: 'Oman',
    isoCode: 'OM',
    phoneCode: '+968',
    phoneMask: '+968-##-###-###'
  },
  {
    name: 'Pakistan',
    isoCode: 'PK',
    phoneCode: '+92',
    phoneMask: '+92(###)###-####'
  },
  {
    name: 'Palau',
    isoCode: 'PW',
    phoneCode: '+680',
    phoneMask: '+680-###-####'
  },
  {
    name: 'Palestine',
    isoCode: 'PS',
    phoneCode: '+970',
    phoneMask: '+970-##-###-####'
  },
  {
    name: 'Panama',
    isoCode: 'PA',
    phoneCode: '+507',
    phoneMask: '+507-###-####'
  },
  {
    name: 'Papua New Guinea',
    isoCode: 'PG',
    phoneCode: '+675',
    phoneMask: '+675(###)##-###'
  },
  {
    name: 'Paraguay',
    isoCode: 'PY',
    phoneCode: '+595',
    phoneMask: '+595(###)###-###'
  },
  {
    name: 'Peru',
    isoCode: 'PE',
    phoneCode: '+51',
    phoneMask: '+51(###)###-###'
  },
  {
    name: 'Philippines',
    isoCode: 'PH',
    phoneCode: '+63',
    phoneMask: '+63(###)###-####'
  },
  {
    name: 'Poland',
    isoCode: 'PL',
    phoneCode: '+48',
    phoneMask: '+48(###)###-###'
  },
  {
    name: 'Portugal',
    isoCode: 'PT',
    phoneCode: '+351',
    phoneMask: '+351-##-###-####'
  },
  {
    name: 'Puerto Rico',
    isoCode: 'PR',
    phoneCode: '+1787',
    phoneMask: '+1787-###-####'
  },
  {
    name: 'Puerto Rico',
    isoCode: 'PR',
    phoneCode: '+1939',
    phoneMask: '+1939-###-####'
  },
  {
    name: 'Qatar',
    isoCode: 'QA',
    phoneCode: '+974',
    phoneMask: '+974-####-####'
  },
  {
    name: 'Réunion',
    isoCode: 'RE',
    phoneCode: '+262',
    phoneMask: '+262-###-###-###'
  },
  {
    name: 'Romania',
    isoCode: 'RO',
    phoneCode: '+40',
    phoneMask: '+40-##-###-####'
  },
  {
    name: 'Russian Federation',
    isoCode: 'RU',
    phoneCode: '+7',
    phoneMask: '+7(###)###-##-##'
  },
  {
    name: 'Rwanda',
    isoCode: 'RW',
    phoneCode: '+250',
    phoneMask: '+250(###)###-###'
  },
  {
    name: 'Saint Helena',
    isoCode: 'SH',
    phoneCode: '+247',
    phoneMask: '+247-####'
  },
  {
    name: 'Saint Helena',
    isoCode: 'SH',
    phoneCode: '+290',
    phoneMask: '+290-####'
  },
  {
    name: 'Saint Kitts & Nevis',
    isoCode: 'KN',
    phoneCode: '+1869',
    phoneMask: '+1(869)###-####'
  },
  {
    name: 'Saint Lucia',
    isoCode: 'LC',
    phoneCode: '+1758',
    phoneMask: '+1(758)###-####'
  },
  {
    name: 'Saint Pierre & Miquelon',
    isoCode: 'PM',
    phoneCode: '+508',
    phoneMask: '+508-##-##-##'
  },
  {
    name: 'Saint Vincent & the Grenadines',
    isoCode: 'VC',
    phoneCode: '+1784',
    phoneMask: '+1(784)###-####'
  },
  {
    name: 'Samoa',
    isoCode: 'WS',
    phoneCode: '+685',
    phoneMask: '+685-##-####'
  },
  {
    name: 'San Marino',
    isoCode: 'SM',
    phoneCode: '+378',
    phoneMask: '+378-####-######'
  },
  {
    name: 'São Tomé & Príncipe',
    isoCode: 'ST',
    phoneCode: '+239',
    phoneMask: '+239-##-#####'
  },
  {
    name: 'Saudi Arabia',
    isoCode: 'SA',
    phoneCode: '+966',
    phoneMask: '+966-#-###-####'
  },
  {
    name: 'Senegal',
    isoCode: 'SN',
    phoneCode: '+221',
    phoneMask: '+221-##-###-####'
  },
  {
    name: 'Serbia',
    isoCode: 'RS',
    phoneCode: '+381',
    phoneMask: '+381-##-###-####'
  },
  {
    name: 'Seychelles',
    isoCode: 'SC',
    phoneCode: '+248',
    phoneMask: '+248-#-###-###'
  },
  {
    name: 'Sierra Leone',
    isoCode: 'SL',
    phoneCode: '+232',
    phoneMask: '+232-##-######'
  },
  {
    name: 'Singapore',
    isoCode: 'SG',
    phoneCode: '+65',
    phoneMask: '+65-####-####'
  },
  {
    name: 'Sint Maarten',
    isoCode: 'SX',
    phoneCode: '+1721',
    phoneMask: '+1(721)###-####'
  },
  {
    name: 'Slovakia',
    isoCode: 'SK',
    phoneCode: '+421',
    phoneMask: '+421(###)###-###'
  },
  {
    name: 'Slovenia',
    isoCode: 'SI',
    phoneCode: '+386',
    phoneMask: '+386-##-###-###'
  },
  {
    name: 'Solomon Islands',
    isoCode: 'SB',
    phoneCode: '+677',
    phoneMask: '+677-#####'
  },
  {
    name: 'Somalia',
    isoCode: 'SO',
    phoneCode: '+252',
    phoneMask: '+252-#-###-###'
  },
  {
    name: 'South Africa',
    isoCode: 'ZA',
    phoneCode: '+27',
    phoneMask: '+27-##-###-####'
  },
  {
    name: 'South Korea',
    isoCode: 'KR',
    phoneCode: '+82',
    phoneMask: '+82-##-###-####'
  },
  {
    name: 'South Sudan',
    isoCode: 'SS',
    phoneCode: '+211',
    phoneMask: '+211-##-###-####'
  },
  {
    name: 'Spain',
    isoCode: 'ES',
    phoneCode: '+34',
    phoneMask: '+34(###)###-###'
  },
  {
    name: 'Sri Lanka',
    isoCode: 'LK',
    phoneCode: '+94',
    phoneMask: '+94-##-###-####'
  },
  {
    name: 'Sudan',
    isoCode: 'SD',
    phoneCode: '+249',
    phoneMask: '+249-##-###-####'
  },
  {
    name: 'Suriname',
    isoCode: 'SR',
    phoneCode: '+597',
    phoneMask: '+597-###-###'
  },
  {
    name: 'Sweden',
    isoCode: 'SE',
    phoneCode: '+46',
    phoneMask: '+46-##-###-####'
  },
  {
    name: 'Switzerland',
    isoCode: 'CH',
    phoneCode: '+41',
    phoneMask: '+41-##-###-####'
  },
  {
    name: 'Syria',
    isoCode: 'SY',
    phoneCode: '+963',
    phoneMask: '+963-##-####-###'
  },
  {
    name: 'Taiwan',
    isoCode: 'TW',
    phoneCode: '+886',
    phoneMask: '+886-####-####'
  },
  {
    name: 'Tajikistan',
    isoCode: 'TJ',
    phoneCode: '+992',
    phoneMask: '+992-##-###-####'
  },
  {
    name: 'Tanzania',
    isoCode: 'TZ',
    phoneCode: '+255',
    phoneMask: '+255-##-###-####'
  },
  {
    name: 'Thailand',
    isoCode: 'TH',
    phoneCode: '+66',
    phoneMask: '+66-##-###-###'
  },
  {
    name: 'Timor-Leste',
    isoCode: 'TL',
    phoneCode: '+670',
    phoneMask: '+670-78#-#####'
  },
  {
    name: 'Togo',
    isoCode: 'TG',
    phoneCode: '+228',
    phoneMask: '+228-##-###-###'
  },
  {
    name: 'Tokelau',
    isoCode: 'TK',
    phoneCode: '+690',
    phoneMask: '+690-####'
  },
  {
    name: 'Tonga',
    isoCode: 'TO',
    phoneCode: '+676',
    phoneMask: '+676-#####'
  },
  {
    name: 'Trinidad & Tobago',
    isoCode: 'TT',
    phoneCode: '+1868',
    phoneMask: '+1(868)###-####'
  },
  {
    name: 'Tunisia',
    isoCode: 'TN',
    phoneCode: '+216',
    phoneMask: '+216-##-###-###'
  },
  {
    name: 'Turkey',
    isoCode: 'TR',
    phoneCode: '+90',
    phoneMask: '+90(###)###-####'
  },
  {
    name: 'Turkmenistan',
    isoCode: 'TM',
    phoneCode: '+993',
    phoneMask: '+993-#-###-####'
  },
  {
    name: 'Turks & Caicos Islands',
    isoCode: 'TC',
    phoneCode: '+1649',
    phoneMask: '+1(649)###-####'
  },
  {
    name: 'Tuvalu',
    isoCode: 'TV',
    phoneCode: '+688',
    phoneMask: '+688-2####'
  },
  {
    name: 'Uganda',
    isoCode: 'UG',
    phoneCode: '+256',
    phoneMask: '+256(###)###-###'
  },
  {
    name: 'Ukraine',
    isoCode: 'UA',
    phoneCode: '+380',
    phoneMask: '+380(##)###-##-##'
  },
  {
    name: 'United Arab Emirates',
    isoCode: 'AE',
    phoneCode: '+971',
    phoneMask: '+971-#-###-####'
  },
  {
    name: 'United Kingdom',
    isoCode: 'GB',
    phoneCode: '+44',
    phoneMask: '+44-####-######'
  },
  {
    name: 'Uruguay',
    isoCode: 'UY',
    phoneCode: '+598',
    phoneMask: '+598-#-###-##-##'
  },
  {
    name: 'US Virgin Islands',
    isoCode: 'VI',
    phoneCode: '+1340',
    phoneMask: '+1(340)###-####'
  },
  {
    name: 'USA',
    isoCode: 'US',
    phoneCode: '+1',
    phoneMask: '+1(###)###-####'
  },
  {
    name: 'Uzbekistan',
    isoCode: 'UZ',
    phoneCode: '+998',
    phoneMask: '+998-##-###-####'
  },
  {
    name: 'Vanuatu',
    isoCode: 'VU',
    phoneCode: '+678',
    phoneMask: '+678-#####'
  },
  {
    name: 'Venezuela',
    isoCode: 'VE',
    phoneCode: '+58',
    phoneMask: '+58(###)###-####'
  },
  {
    name: 'Vietnam',
    isoCode: 'VN',
    phoneCode: '+84',
    phoneMask: '+84(###)####-###'
  },
  {
    name: 'Wallis & Futuna',
    isoCode: 'WF',
    phoneCode: '+681',
    phoneMask: '+681-##-####'
  },
  {
    name: 'Yemen',
    isoCode: 'YE',
    phoneCode: '+967',
    phoneMask: '+967-##-###-###'
  },
  {
    name: 'Zambia',
    isoCode: 'ZM',
    phoneCode: '+260',
    phoneMask: '+260-##-###-####'
  },
  {
    name: 'Zimbabwe',
    isoCode: 'ZW',
    phoneCode: '+263',
    phoneMask: '+263-#-######'
  }
];
