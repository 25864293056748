import { InputValidationError } from './models';

export interface IB2wApiError {
  /**
   * Description of the error. E.g. "User account was deleted"
   */
  message: string;
  /**
   * Status code of the response request
   */
  statusCode: number;
  /**
   * Array of validation errors of request params
   */
  inputErrors?: InputValidationError[];
  /**
   * Generalized error description of response `statusCode`, more like
   * error type. E.g. "Bad Request"
   */
  error?: string;
}

export class B2wApiError extends Error implements IB2wApiError {
  statusCode: number;
  inputErrors?: InputValidationError[];
  error?: string;

  constructor(
    message: string,
    statusCode: number,
    error?: string,
    inputErrors?: InputValidationError[]
  ) {
    super(message);
    this.name = 'B2wApiError';
    this.statusCode = statusCode;
    this.error = error;
    this.inputErrors = inputErrors;

    Object.setPrototypeOf(this, B2wApiError.prototype);
  }

  /**
   * Serializes error into a JSON object. It can be used as an error response
   * body.
   */
  getResponsePayload(): IB2wApiError {
    return {
      message: this.message,
      statusCode: this.statusCode,
      ...(this.inputErrors ? { inputErrors: this.inputErrors } : {}),
      ...(this.error ? { error: this.error } : {})
    };
  }
}
