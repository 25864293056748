export const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const isLocalhost =
  isBrowser &&
  Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export function isInAppBrowser() {
  if (!isBrowser) {
    return false;
  }

  const ua = (window.navigator || navigator)?.userAgent ?? '';

  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|.0.0.0)'
  ];

  const regex = new RegExp(`(${rules.join('|')})`, 'ig');

  return !!ua.match(regex);
}

export function canUseLocalStorage() {
  return isStorageAvailable('localStorage');
}

export function canUseSessionStorage() {
  return isStorageAvailable('sessionStorage');
}

function isStorageAvailable(kind: 'sessionStorage' | 'localStorage'): boolean {
  let storage: Storage | null = null;

  try {
    storage = window[kind];

    const x = '__storage_test__';

    storage.setItem(x, x);
    storage.getItem(x);
    storage.removeItem(x);

    return true;
  } catch (e: any) {
    return (
      e &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

/**
 * Get information about the device the user is using
 */
export function getDeviceInfo() {
  const userAgent = navigator ? navigator.userAgent : null;

  const isApple = navigator.userAgent.search(/OS X|iPhone|iPad|iOS/i) !== -1;

  const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') !== -1;

  const isChromium =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const isAppleMobile =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      ('MacIntel' === navigator.platform && navigator.maxTouchPoints > 1)) &&
    !(window as any).MSStream;

  const isSafari =
    !!('safari' in window) ||
    !(
      !userAgent ||
      !(
        /\b(iPad|iPhone|iPod)\b/.test(userAgent) ||
        (userAgent.match('Safari') && !userAgent.match('Chrome'))
      )
    );

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  const isMobileSafari = isSafari && isAppleMobile;

  const isMobile =
    navigator.maxTouchPoints > 0 &&
    navigator.userAgent.search(
      /iOS|iPhone OS|Android|BlackBerry|BB10|Series ?[64]0|J2ME|MIDP|opera mini|opera mobi|mobi.+Gecko|Windows Phone/i
    ) !== -1;

  return {
    userAgent,
    isApple,
    isAndroid,
    isChromium,
    isAppleMobile,
    isSafari,
    isFirefox,
    isMobileSafari,
    isMobile
  };
}

/**
 * Check if the browser supports touch events
 */
export function isTouchSupported() {
  return (
    'ontouchstart' in window ||
    // @ts-expect-error check touch device
    (window.DocumentTouch && document instanceof DocumentTouch)
  );
}
