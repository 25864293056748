export function appendRedirectQueryString(str: string) {
  return `${str}?next=${encodeURIComponent(
    window.location.pathname + window.location.search
  )}`;
}

export function getRedirectQueryStringAsObj() {
  return {
    next: window.location.pathname + window.location.search
  };
}
