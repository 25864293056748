import { forwardRef } from 'react';
import { IconTriangleDown } from '../Icon';
import {
  PolymorphicComponentProps,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef
} from '../types';
import { FormControlMainProps, useInputFormControl } from '../FormControl';
import { twCx } from '../twMerge';

export type NativeSelectVariant = 'outline' | 'flushed' | 'unstyled';
export type NativeSelectSize = 'md' | 'lg';

const invalidClassName = twCx(
  'aria-[invalid=true]:tw-border-error-300 aria-[invalid=true]:tw-ring-error-300',
  'aria-[invalid=true]:hover:tw-border-error-400 aria-[invalid=true]:hover:tw-ring-error-400',
  'aria-[invalid=true]:focus:tw-border-error-500 aria-[invalid=true]:focus:tw-ring-error-500'
);

const variantToClassName: Record<NativeSelectVariant, string> = {
  outline: twCx(
    'tw-rounded-xl tw-border tw-border-gray-200 hover:tw-border-gray-300 focus:tw-border-main-500 tw-ring-main-500 focus:tw-ring-1',
    invalidClassName
  ),
  flushed: twCx(
    'tw-border-b tw-border-gray-200 hover:tw-border-gray-300 focus:tw-border-main-500 tw-ring-main-500 focus:[box-shadow:_0_1px_0_0_var(--tw-ring-color)]',
    invalidClassName
  ),
  unstyled: ''
};

const sizeToClassName: Record<
  NativeSelectSize,
  Record<NativeSelectVariant, string>
> = {
  md: {
    outline: 'tw-py-2 tw-px-3 tw-pr-9 tw-text-md',
    flushed: 'tw-py-2 tw-text-md',
    unstyled: 'tw-text-md'
  },
  lg: {
    outline: 'tw-py-3 tw-px-4 tw-text-lg',
    flushed: 'tw-py-3 tw-text-lg',
    unstyled: 'tw-text-lg'
  }
};

const sizeToClassNameForIconPadding: Record<
  NativeSelectSize,
  Record<NativeSelectVariant, string>
> = {
  md: {
    outline: 'tw-pr-10',
    flushed: 'tw-pr-6',
    unstyled: 'tw-pr-6'
  },
  lg: {
    outline: 'tw-pr-10',
    flushed: 'tw-pr-7',
    unstyled: 'tw-pr-7'
  }
};

const sizeToIconClassName: Record<
  NativeSelectSize,
  Record<NativeSelectVariant, string>
> = {
  md: {
    outline: 'tw-mr-4',
    flushed: '',
    unstyled: ''
  },
  lg: {
    outline: 'tw-mr-4',
    flushed: '',
    unstyled: ''
  }
};

export type NativeSelectOwnProps = FormControlMainProps & {
  /**
   * @default 'outline''
   */
  variant?: NativeSelectVariant;
  /** @default 'md' */
  size?: NativeSelectSize;
  /** Classes of underlying `<select />` */
  classNameSelect?: string;
  /** Classes of the wrapper.
   * For real select classes, use prop `classNameSelect` */
  className?: string;
  /** className for triangle icon */
  classNameIcon?: string;
  /** @default true */
  showIcon?: boolean;
};

export type NativeSelectProps<C extends React.ElementType = 'select'> =
  PolymorphicComponentPropsWithRef<C, NativeSelectOwnProps>;

type NativeSelectComponent = <C extends React.ElementType = 'select'>(
  props: NativeSelectProps<C>
) => JSX.Element;

export const NativeSelect: NativeSelectComponent = forwardRef(
  <T extends React.ElementType = 'select'>(
    props: PolymorphicComponentProps<T, NativeSelectOwnProps>,
    ref: PolymorphicRef<T>
  ) => {
    const {
      children,
      as: Component = 'select',
      variant = 'outline',
      className: classNameProp,
      classNameSelect,
      classNameIcon,
      size = 'md',
      showIcon = true,
      ...inputHtmlProps
    } = props;

    const inputProps = useInputFormControl<'select'>(inputHtmlProps);

    return (
      <div className={twCx('tw-relative', classNameProp)}>
        <Component
          {...inputProps}
          ref={ref}
          className={twCx(
            'tw-appearance-none tw-outline-none tw-bg-white tw-w-full',
            'disabled:tw-opacity-40 disabled:tw-cursor-not-allowed',
            'tw-transition-common tw-duration-100',
            variantToClassName[variant],
            sizeToClassName[size][variant],
            showIcon && sizeToClassNameForIconPadding[size][variant],
            classNameSelect
          )}
        >
          {children}
        </Component>
        {showIcon && (
          <IconTriangleDown
            size="md"
            className={twCx(
              'tw-absolute tw-pointer-events-none tw-top-1/2 tw-right-0 -tw-translate-y-1/2',
              sizeToIconClassName[size][variant],
              inputProps.disabled && 'tw-opacity-40',
              classNameIcon
            )}
          />
        )}
      </div>
    );
  }
) as NativeSelectComponent;
