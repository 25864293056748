/**
 * @desc
 * Removes shallow values from object if `conditionCallback` returns true.
 * It does not modify original object.
 *
 * @param obj target object
 * @param conditionCallback (optional) condition callback. By default
 * returns true when value is undefined
 *
 * @returns new object
 */
export function removeFromObjectIf<T extends Record<string, any>>(
  obj: T,
  conditionCallback = (value: any) => value === undefined
): T {
  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (conditionCallback(val) === false) {
      (acc as any)[key] = val;
    }

    return acc;
  }, {} as T);
}

/**
 * @desc Only for checking [key: val] objects.
 *
 * Returns `false` for Arrays, Functions and null
 */
export function isObject(obj: any): boolean {
  return obj != null && obj.constructor.name === 'Object';
}

/**
 * @desc Debounce a function.
 *
 * @returns debounced function instance
 */
export function debounce<T>(fn: T, wait: number, immediate = false): T {
  let timeout: any;
  return ((...originalArgs: any[]) => {
    const later = function () {
      timeout = null;
      // @ts-expect-error this
      if (!immediate) fn.apply(this, originalArgs);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    // @ts-expect-error this
    if (callNow) fn.apply(this, originalArgs);
  }) as unknown as T;
}

/**
 * @desc Throttles a function.
 *
 * @returns throttled function instance
 */
export function throttle<T>(fn: T, wait: number) {
  let waiting = false; // Initially, we're not waiting

  return function (...originalArgs: any[]) {
    // We return a throttled function
    if (!waiting) {
      // If we're not waiting
      // @ts-expect-error this
      fn.apply(this, originalArgs); // Execute users function
      waiting = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        waiting = false; // And allow future invocations
      }, wait);
    }
  };
}

const isMergebleObject = (item: any): boolean => {
  return isObject(item) && !Array.isArray(item);
};

/**
 * Deeply merges with condition. Mutates `target`
 *
 * @example
 * const obj = { someVal: 5 };
 *
 * deepMergeWith((a, b) => b, obj, { someVal: 2, deep: { val: 2 } });
 */
export const deepMergeWith = <T extends object = object>(
  updateCallback: (targetValue: any, sourceValue: any) => any,
  target: T,
  ...sources: T[]
): T => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (source === undefined) {
    return target;
  }

  if (isMergebleObject(target) && isMergebleObject(source)) {
    Object.keys(source).forEach(function (key: string) {
      if (isMergebleObject((source as any)[key])) {
        if (!(target as any)[key]) {
          (target as any)[key] = {};
        }
        deepMergeWith(
          updateCallback,
          (target as any)[key],
          (source as any)[key]
        );
      } else {
        const targetValue = (target as any)[key];
        const sourceValue = (source as any)[key] ?? 0;

        (target as any)[key] = updateCallback(targetValue, sourceValue);
      }
    });
  }

  return deepMergeWith(updateCallback, target, ...sources);
};

/**
 * Deeply merges into target. Mutates `target`
 *
 * @example
 * const obj = { someVal: 5 };
 *
 * deepMerge(obj, { someVal: 2, deep: { val: 2 } });
 */
export const deepMerge = <T extends object = object>(
  target: T,
  ...sources: T[]
): T => {
  return deepMergeWith((_a, b) => b, target, ...sources);
};
