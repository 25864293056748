import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const ShortFormLoader = (props: IContentLoaderProps) => {
  return (
    <ContentLoader speed={2} viewBox="0 0 400 100" {...props}>
      <rect x="0" y="0" rx="4" ry="4" width="400" height="9" />
      <rect x="0" y="16" rx="4" ry="4" width="100" height="8" />
      <rect x="0" y="37" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="52" rx="4" ry="4" width="400" height="10" />
      <rect x="0" y="67" rx="4" ry="4" width="100" height="10" />
    </ContentLoader>
  );
};

export default ShortFormLoader;
