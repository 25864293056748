import { useState, useEffect, memo } from 'react';
import { Alert as BAlert, Container } from 'reactstrap';
import { useAlert, useAlertDispatch } from '@/context/alert.context';
import SCSS from './Alert.module.scss';
import { Alert } from '@/components/Alert/alert.types';
import AppLink from '@/components/AppLink';
import ClientPortal from '../ClientPortal';
import SecondaryBtn from '../SecondaryBtn';
import { theme } from '@/styles/sc-theme';

const FADE_TIME = 250;

type AlertItemProps = { alert: Alert };

const AlertItem = ({ alert }: AlertItemProps) => {
  const { id, msg, type, timeout, clickAction } = alert;
  const [isVisible, setIsVisible] = useState(true);
  const alertDispatch = useAlertDispatch();

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setIsVisible(false);
    }, timeout - FADE_TIME);

    return () => {
      clearTimeout(fadeTimeout);
    };
  }, [timeout]);

  return (
    <BAlert
      className="rounded-0 mb-0"
      key={id}
      color={type || 'info'}
      isOpen={isVisible}
      transition={{
        timeout: 150,
        baseClass: SCSS['alert-fade'],
        baseClassActive: SCSS['reveal-alert']
      }}
    >
      {msg}
      {!!clickAction && (
        <AppLink href={clickAction.href} className="text-nowrap link-unstyled">
          <SecondaryBtn
            className="w-100 w-sm-auto ml-sm-2 mt-1 mt-sm-0 bg-white"
            onClick={() => {
              setIsVisible(false);
              setTimeout(() => {
                alertDispatch({ type: 'remove', payload: id });
              }, FADE_TIME);
            }}
          >
            {clickAction.text}
          </SecondaryBtn>
        </AppLink>
      )}
    </BAlert>
  );
};

const AlertComponent = () => {
  const alerts = useAlert();

  return (
    <ClientPortal>
      <Container
        className={`${SCSS['alert-wrapper']}`}
        style={{
          zIndex: theme.zIndex.alert
        }}
      >
        {alerts.map((alert) => (
          <AlertItem key={alert.id} alert={alert} />
        ))}
      </Container>
    </ClientPortal>
  );
};

export default memo(AlertComponent);
