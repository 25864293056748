import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

/** Merge tailwind classNames with override safety */
const twMerge = extendTailwindMerge({
  prefix: 'tw-'
});

/** Utility to join classes. It also supports overrides for tailwind classes */
export const twCx = (...inputs: ClassValue[]) => twMerge(clsx(inputs));
