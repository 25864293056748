import { ZERO_DECIMAL_CURRENCIES } from './currency';
import { IN_APP_CURRENCIES } from './staticAppData';

/**
 * @desc Get random number in range (inclusively)
 */
export function getRandom(min: number, max: number): number {
  return ~~(Math.random() * (max - min + 1)) + min;
}

/**
 * @desc Subtract some % from number
 * @param listPrice target price
 * @param percentOff how many percentages to take from listPrice
 */
export function calculateSalePrice(_listPrice: any, _percentOff: any): number {
  const percentOff = parseInt(_percentOff, 10);
  const listPrice = parseFloat(_listPrice);

  if (percentOff <= 0 || percentOff > 100) return listPrice;

  return listPrice - (percentOff / 100) * listPrice;
}

/**
 * @desc Get original price from sale price and known percentOff (discount)
 * @param salePrice price with discount applied
 * @param discount how many percentages were applied to salePrice
 */
export function calculateListPrice(_salePrice: any, _discount: any): number {
  const salePrice = parseFloat(_salePrice);
  const discount = parseInt(_discount, 10);

  return salePrice / (1 - discount / 100);
}

/**
 * @desc Format number to money look (separate thousands). Amount will be
 * formatted according to currency config.
 *
 * @example
 * (15636750.14, 'EUR') => "€15,636,750.14"
 * (23.143, 'PHP') => "₱23"
 */
export const formatMoney = (money: number, currency: string): string => {
  const currencyCfg = IN_APP_CURRENCIES.find((c) => c.code === currency);

  if (!currencyCfg) {
    return `${money} ${currency}`;
  }

  const asInteger = !!currencyCfg.roundToInteger;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: asInteger ? 0 : 2,
    maximumFractionDigits: asInteger ? 0 : 2
  }).format(money);
};

/**
 * Convert price to stripe-processable format (smallest currency unit)
 *
 * @example
 * 1) 1.35 USD -> 135; 2) 1 USD -> 100
 *
 * @example (zero-decimal currency)
 * 1) 85.4 JPY -> 85; 2) 100 JPY -> 100
 */
export function formatAmountForStripe(
  amount: number,
  currency: string
): number {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase() as any)) {
    return Math.round(amount);
  }

  // keep in smallest unit
  return Math.round(amount * 100);
}

/**
 * Convert price to paypal-processable format
 *
 * @example
 * 1) 40.35 EUR -> 40.35; 2) 40 EUR -> 40
 *
 * @example (zero-decimal currency)
 * 1) 30.9 HUF -> 31; 2) 45 HUF -> 45
 */
export function formatAmountForPaypal(
  amount: number,
  currency: string
): string {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase() as any)) {
    return Math.round(amount).toString();
  }

  if (Number.isInteger(amount)) {
    return amount.toString();
  }

  // always keep 2 decimals
  return (Math.round(amount * 100) / 100).toString();
}

export function formatAmountForPaynamics(
  amount: number,
  currency: string
): string {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase() as any)) {
    return Math.round(amount).toFixed(2);
  }

  // always keep 2 decimals
  return (Math.round(amount * 100) / 100).toFixed(2);
}

/**
 * Get absolute number of booking days. Very close to how the `ceil` method works.
 *
 * @example
 * days = 0             -> 1 day
 * days = 7, hours = 0  -> 7 days
 * days = 2, hours = 5  -> 3 days
 */
export function ceilNumberOfDays(days: number, hours: number) {
  if (days === 0) {
    return 1;
  }

  return hours > 0 ? days + 1 : days;
}

/** `start` included as first element, `end` is not included */
export function numberRange(start: number, end: number): number[] {
  return new Array(end - start).fill(null).map((_, i) => i + start);
}
