import { useConst, useEventCallback } from '@b2w/shared/react-hooks';
import { CSSProperties } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { CommonFadeProps } from './types';
import styles from './Fade.module.scss';

export interface FadeProps extends CommonFadeProps {
  /**
   * Trigger fade
   */
  in: boolean;
  /**
   * Whether to mount and unmout children when animation start/completes
   *
   * @default false
   */
  mountUnmout?: boolean;
  /**
   * Whether to show fade animation when initial `in` state is `true`
   *
   * @default true
   */
  animateOnFirstMount?: boolean;
  transformOrigin?: string;
}

const animationClassnames = {
  appear: styles.fadeEnter,
  appearActive: styles.fadeEnterActive,
  enter: styles.fadeEnter,
  enterActive: styles.fadeEnterActive,
  exit: styles.fadeExit,
  exitActive: styles.fadeExitActive,
  exitDone: styles.fadeExitDone
};

/**
 * Fade effect
 */
export const Fade = ({
  in: animate,
  duration = 300,
  offsetX = 0,
  offsetY = 0,
  initialScale = 1,
  mountUnmout = false,
  animateOnFirstMount = true,
  transformOrigin,
  children
}: FadeProps): JSX.Element => {
  const isInitiallyExited = useConst(animate === false);
  const mustAddExitClass = isInitiallyExited && !mountUnmout;

  const wrapStyles = {
    // css variables are used inside classnames
    '--fade-duration': duration + 'ms',
    '--fade-offsetX': offsetX + 'px',
    '--fade-offsetY': offsetY + 'px',
    '--fade-initial-scale': initialScale,
    transformOrigin
  } as CSSProperties;

  const registerWrapper = useEventCallback((node: HTMLDivElement) => {
    if (node) {
      if (mustAddExitClass) {
        // CSSTransition component does not add exitDone class
        // if initially in={false}, do that manually
        node.classList.add(animationClassnames.exitDone);
      }
    }
  });

  const onEnter: CSSTransitionProps['onEnter'] = useEventCallback((node) => {
    if (node && mustAddExitClass) {
      node.classList.remove(animationClassnames.exitDone);
    }
  });

  return (
    <CSSTransition
      in={animate}
      unmountOnExit={mountUnmout}
      appear={animateOnFirstMount}
      timeout={duration}
      classNames={animationClassnames}
      onEnter={onEnter as any}
    >
      <div style={wrapStyles} ref={registerWrapper}>
        {children}
      </div>
    </CSSTransition>
  );
};
