import AppLink from '@/components/AppLink';
import { useEffect, useState } from 'react';
import { Button, CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useModalState } from '@b2w/shared/react-hooks';
import {
  ConsentInputs,
  getConsent,
  setConsent
} from './analytics/cookieConsent';
import { useRouter } from 'next/router';

type CheckboxDefinition = {
  label: string;
  name: keyof ConsentInputs;
  disabled?: boolean;
  information?: string;
};

const checkboxesDefs: CheckboxDefinition[] = [
  {
    label: 'Necessary',
    name: 'necessary',
    disabled: true
  },
  { label: 'Analytics', name: 'analytics' },
  { label: 'Preferences', name: 'preferences' },
  { label: 'Marketing', name: 'marketing' }
];

const pathnameBlacklist = ['/policy'];

const CookieAlert = () => {
  const { pathname } = useRouter();

  const { close, isOpen, open } = useModalState();
  const {
    close: closeSettings,
    isOpen: isOpenSettings,
    open: openSettings
  } = useModalState();
  const closeCookieWindow = () => {
    closeSettings();
    close();
  };

  const [inputs, setInputs] = useState<ConsentInputs>({
    analytics: true,
    marketing: true,
    necessary: true,
    preferences: true
  });

  useEffect(() => {
    if (pathnameBlacklist.includes(pathname)) return;

    if (!getConsent()) {
      open();
    }
  }, [open, pathname]);

  const onManageClick = async () => {
    openSettings();
  };

  const onAcceptAllClick = async () => {
    setConsent({
      analytics: true,
      marketing: true,
      necessary: true,
      preferences: true
    });
    closeCookieWindow();
  };

  const onNecessaryClick = async () => {
    setConsent({
      necessary: true,
      analytics: false,
      marketing: false,
      preferences: false
    });
    closeCookieWindow();
  };

  const onAcceptSelectionClick = async () => {
    setConsent(inputs);
    closeCookieWindow();
  };

  const handleSingleConsent = (name: keyof ConsentInputs, checked: boolean) => {
    setInputs((p) => ({ ...p, [name]: checked }));
  };

  return (
    <>
      <Modal centered isOpen={isOpen}>
        <ModalBody className="mt-2">
          <h1 className="h4 text-center mb-3">
            Allow the use of cookies by Book2Wheel on this browser?
          </h1>
          <section>
            <p className="mb-2">
              We use cookies to enhance your browsing experience and analyze our
              traffic.
            </p>

            <p className="mb-2">
              By clicking "Accept all", you consent to our use of cookies. Learn
              more about cookies and how we use them in our{' '}
              <AppLink
                href="/policy#cookie"
                className="underlined text-primary"
                target="_blank"
              >
                Cookie Policy
              </AppLink>
              .
            </p>
          </section>

          <section className="mt-3">
            <div>
              <Button
                block
                color="primary-light"
                onClick={onAcceptAllClick}
                className="bg-primary-light"
              >
                Accept all
              </Button>
              <div className="mt-2 d-flex">
                <Button
                  color="dark"
                  className="mr-1 w-50"
                  outline
                  onClick={onNecessaryClick}
                >
                  Only necessary
                </Button>
                <Button
                  color="dark"
                  className="ml-1 w-50"
                  outline
                  onClick={onManageClick}
                >
                  Manage settings
                </Button>
              </div>
            </div>
          </section>
        </ModalBody>
      </Modal>

      <Modal centered isOpen={isOpenSettings} toggle={closeSettings}>
        <ModalHeader className="h5 m-0" toggle={closeSettings}>
          Cookie settings
        </ModalHeader>
        <ModalBody>
          <div className="mb-2">
            We would like your permission to use your data for the following
            purposes:
          </div>
          <div className="d-flex flex-column mb-3">
            {checkboxesDefs.map((def) => (
              <div className="mb-2" key={def.name}>
                <CustomInput
                  type="checkbox"
                  id={`consent-${def.name}`}
                  name={def.name}
                  className="flex-fill"
                  label={def.label}
                  checked={inputs[def.name]}
                  disabled={def.disabled}
                  onChange={(ev) =>
                    handleSingleConsent(def.name, ev.target.checked)
                  }
                />
                {!!def.information && (
                  <div className="mt-1 small">{def.information}</div>
                )}
              </div>
            ))}
          </div>
          <section className="mt-3">
            <div>
              <Button
                block
                color="primary-light"
                onClick={onAcceptAllClick}
                className="bg-primary-light"
              >
                Accept all
              </Button>
              <div className="mt-2 d-flex">
                <Button
                  color="primary-light"
                  className="mr-1 w-50 bg-primary-light"
                  onClick={onAcceptSelectionClick}
                >
                  Save settings
                </Button>
                <Button
                  color="dark"
                  className="ml-1 w-50"
                  outline
                  onClick={onNecessaryClick}
                >
                  Only necessary
                </Button>
              </div>
            </div>
          </section>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CookieAlert;
