import { useDelayUnmount } from '@b2w/shared/react-hooks';

export interface DelayUnmountProps {
  children?: React.ReactNode;
  /**
   * Whether component is currently mounted or unmounted
   */
  isMounted: boolean;
  /**
   * Unmount delay time in miliseconds
   */
  delay: number;
}

/**
 *
 * Component to delay component unmount of its children. Useful for animating
 * components with classnames.
 */
export const DelayUnmount: React.FC<DelayUnmountProps> = (props) => {
  const { delay, isMounted, children } = props;

  const canRender = useDelayUnmount(isMounted, delay);

  return (canRender ? children : null) as JSX.Element;
};
