import { ButtonHTMLAttributes, forwardRef } from 'react';
import SCSS from './SecondaryBtn.module.scss';

export type SecondaryBtnProps = ButtonHTMLAttributes<HTMLButtonElement>;

function SecondaryBtn(
  {
    className = '',
    children,
    type = 'button',
    block = false,
    ...btnProps
  }: SecondaryBtnProps & { block?: boolean },
  ref: React.MutableRefObject<HTMLButtonElement>
) {
  return (
    <button
      {...btnProps}
      type={type}
      ref={ref}
      className={`${
        SCSS['secondary-btn']
      } btn border border-secondary ${className} ${block ? 'w-100' : ''}`}
    >
      {children}
    </button>
  );
}

export default forwardRef(SecondaryBtn);
