import { useControllableState } from '@b2w/shared/react-hooks';
import { mergeRefs } from '@b2w/shared/react-utils';
import { callAllHandlers } from '@b2w/shared/utility';
import {
  ComponentPropsWithRef,
  createContext,
  useContext,
  useRef
} from 'react';
import { flushSync } from 'react-dom';
import {
  SelectController,
  SelectOption,
  UseInitSelectProps,
  useSelectLogicCtx
} from '../Select/select.init';
import { InputProps } from '../Input';

export type ComboboxCtxValue<TValue, TMultiple> = UseInitComboboxReturn<
  TValue,
  TMultiple
>['comboboxCtx'];

export const ComboboxCtx = createContext<ComboboxCtxValue<any, any>>(
  null as any
);
export const useComboboxCtx = <TValue = any, TMultiple = false>() =>
  useContext<ComboboxCtxValue<TValue, TMultiple>>(ComboboxCtx);

export type UseInitComboboxProps<TValue> = {
  /**
   * Pass callback to determine how options should be filtered out
   *
   * By default, it will try to filter out by `option.label`
   */
  searchFilter?: (option: SelectOption<TValue>, searchInput: string) => boolean;
  /** Search input value in controlled mode */
  searchInputValue?: string;
  /** `searchInputValue` change handler in controlled mode */
  onSearchInputValueChange?: (searchStr: string) => any;
  /** Initial `searchInputValue` in uncontrolled mode */
  defaultSearchInputValue?: string;
};

export type UseInitComboboxReturn<TValue, TMultiple> = ReturnType<
  typeof useInitCombobox<TValue, TMultiple>
>;

export const useInitCombobox = <TValue, TMultiple>(
  props: UseInitComboboxProps<TValue>,
  selectProps: Pick<
    UseInitSelectProps<TValue, TMultiple>,
    'onOpenChange' | 'onChange' | 'revalidateOptionsKey'
  >
) => {
  const {
    searchFilter,
    searchInputValue,
    onSearchInputValueChange,
    defaultSearchInputValue = ''
  } = props;

  const [searchInput, setSearchInput] = useControllableState({
    value: searchInputValue,
    onChange: onSearchInputValueChange,
    defaultValue: defaultSearchInputValue
  });

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const controller = useRef<SelectController<TValue, TMultiple>>(null as any);

  const focusSearchableInputIfNeeded = () => {
    if (document.activeElement !== searchInputRef.current) {
      searchInputRef.current?.focus();
    }
  };

  const onOpenChange: UseInitSelectProps<TValue, TMultiple>['onOpenChange'] = (
    isOpen: boolean
  ) => {
    selectProps.onOpenChange?.(isOpen);

    if (isOpen) {
      setTimeout(() => {
        focusSearchableInputIfNeeded();
      }, 5);
    } else {
      setTimeout(() => {
        setSearchInput('');
      }, controller.current.popupAnimationsMs);
    }
  };

  const onChange: UseInitSelectProps<TValue, TMultiple>['onChange'] = (
    value
  ) => {
    selectProps.onChange?.(value);

    if (controller.current.keepOpenedOnSelect) {
      if (searchInput !== '') {
        flushSync(() => {
          setSearchInput('');
        });

        controller.current.setActiveToFirstLastOrSelected('first');
      }

      focusSearchableInputIfNeeded();
    }
  };

  const filterOption: UseInitSelectProps<TValue, TMultiple>['filterOption'] = (
    option
  ) => {
    if (searchFilter) {
      return searchFilter(option, searchInput);
    }

    return option.label.toLowerCase().indexOf(searchInput.toLowerCase()) === 0;
  };

  const revalidateOptionsKey =
    searchInput + (selectProps.revalidateOptionsKey ?? '');

  const selectRootOverrides: Partial<UseInitSelectProps<TValue, TMultiple>> = {
    refocusTriggerOnSelectAndOpen: false,
    revalidateOptionsKey,
    controller,
    onOpenChange,
    onChange,
    filterOption
  };

  return {
    selectRootOverrides,
    comboboxCtx: { setSearchInput, searchInputRef, searchInput }
  };
};

export const useComboboxSearchInput = <TValue, TMultiple>(
  htmlProps: InputProps,
  ref: ComponentPropsWithRef<'input'>['ref']
) => {
  const { setSearchInput, searchInputRef, searchInput } = useComboboxCtx<
    TValue,
    TMultiple
  >();
  const {
    isSelected,
    setActiveToFirstLastOrSelected,
    optionsManager,
    setActiveOption,
    listboxRef,
    isOpen
  } = useSelectLogicCtx<TValue, TMultiple>();

  const handleInputChange = (str: string) => {
    flushSync(() => {
      setSearchInput(str);
    });

    if (str === '' && isSelected) {
      setActiveToFirstLastOrSelected('first');
    } else {
      const opt = optionsManager.getFirstEnabledOption();

      setActiveOption(opt, true, 'start');
    }
  };

  const onInputChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    handleInputChange(ev.target.value);

  const searchInputProps: InputProps<'input'> = {
    placeholder: 'Search for options...',
    ...htmlProps,
    ref: mergeRefs(ref, searchInputRef),
    value: searchInput,
    onChange: callAllHandlers(htmlProps.onChange, onInputChange),
    'aria-autocomplete': 'list',
    'aria-controls': isOpen && listboxRef.current ? listboxRef.current.id : ''
  };

  return { searchInputProps };
};
