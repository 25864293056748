/** Firestore Collections definitions  */
export const FireCollection = {
  _project: {
    docMatch: '_project/{id}',
    path: () => '_project',
    docPath: (id: string) => `_project/${id}`,
    singleDocId: {
      appConfig: 'app_config',
      landingBlog: 'landing_blog',
      landingPopularDestination: 'landing_popular_destination',
      stats: 'stats'
    }
  },
  bookings: {
    docMatch: 'booking/{id}',
    path: () => 'booking',
    docPath: (id: string) => `booking/${id}`
  },
  vehicles: {
    docMatch: 'vehicle/{id}',
    path: () => 'vehicle',
    docPath: (id: string) => `vehicle/${id}`
  },
  vehicleData: {
    docMatch: 'vehicle/{vehicleId}/vehicle_data/{id}',
    collectionGroupName: 'vehicle_data',
    path: (vehicleId: string) => `vehicle/${vehicleId}/vehicle_data`,
    docPath: (vehicleId: string, id: string) =>
      `vehicle/${vehicleId}/vehicle_data/${id}`,
    singleDocId: {
      calendar: 'calendar'
    }
  },
  chat: {
    docMatch: 'chat/{id}',
    path: () => 'chat',
    docPath: (id: string) => `chat/${id}`
  },
  chatMessages: {
    docMatch: 'chat/{chatId}/messages/{id}',
    collectionGroupName: 'messages',
    path: (chatId: string) => `chat/${chatId}/messages`,
    docPath: (chatId: string, id: string) => `chat/${chatId}/messages/${id}`
  },
  chatAskOwner: {
    docMatch: 'chat/{chatId}/ask_owner/{id}',
    collectionGroupName: 'ask_owner',
    path: (chatId: string) => `chat/${chatId}/ask_owner`,
    docPath: (chatId: string, id: string) => `chat/${chatId}/ask_owner/${id}`
  },
  profiles: {
    docMatch: 'profile/{id}',
    path: () => 'profile',
    docPath: (id: string) => `profile/${id}`
  },
  profileDocuments: {
    docMatch: 'profile/{profileId}/documents/{id}',
    collectionGroupName: 'documents',
    path: (profileId: string) => `profile/${profileId}/documents`,
    docPath: (profileId: string, id: string) =>
      `profile/${profileId}/documents/${id}`
  },
  profileEarnings: {
    docMatch: 'profile/{profileId}/earnings/{id}',
    collectionGroupName: 'earnings',
    path: (profileId: string) => `profile/${profileId}/earnings`,
    docPath: (profileId: string, id: string) =>
      `profile/${profileId}/earnings/${id}`
  },
  profileData: {
    docMatch: 'profile/{profileId}/profile_data/{id}',
    collectionGroupName: 'profile_data',
    path: (profileId: string) => `profile/${profileId}/profile_data`,
    docPath: (profileId: string, id: string) =>
      `profile/${profileId}/profile_data/${id}`,
    singleDocId: {
      emailVerification: 'emailVerification'
    }
  },
  exchangeRates: {
    docMatch: 'exchangerates/{id}',
    path: () => 'exchangerates',
    docPath: (id: string) => `exchangerates/${id}`
  },
  favoriteVehicles: {
    docMatch: 'favorite_vehicles/{id}',
    path: () => 'favorite_vehicles',
    docPath: (id: string) => `favorite_vehicles/${id}`
  },
  reviews: {
    docMatch: 'reviews/{id}',
    path: () => 'reviews',
    docPath: (id: string) => `reviews/${id}`
  },
  cities: {
    docMatch: 'cities/{id}',
    path: () => 'cities',
    docPath: (id: string) => `cities/${id}`
  },
  userClaims: {
    docMatch: 'user_claims/{id}',
    path: () => 'user_claims',
    docPath: (id: string) => `user_claims/${id}`
  },
  paymentLinks: {
    docMatch: 'paymentLinks/{id}',
    path: () => 'paymentLinks',
    docPath: (id: string) => `paymentLinks/${id}`
  },
  svDonations: {
    docMatch: 'svDonations/{id}',
    path: () => 'svDonations',
    docPath: (id: string) => `svDonations/${id}`,
    singleDocId: {
      info: '_info'
    }
  },
  cloudTasks: {
    docMatch: 'cloudTasks/{id}',
    path: () => 'cloudTasks',
    docPath: (id: string) => `cloudTasks/${id}`
  },
  affiliates: {
    docMatch: 'affiliates/{id}',
    path: () => 'affiliates',
    docPath: (id: string) => `affiliates/${id}`
  },
  affiliatePayouts: {
    docMatch: 'affiliate_payouts/{id}',
    path: () => 'affiliate_payouts',
    docPath: (id: string) => `affiliate_payouts/${id}`
  },
  affiliateReferences: {
    docMatch: 'affiliate_references/{id}',
    path: () => 'affiliate_references',
    docPath: (id: string) => `affiliate_references/${id}`
  },
  affiliateMonthStats: {
    docMatch: 'affiliate_month_stats/{id}',
    path: () => 'affiliate_month_stats',
    docPath: (id: string) => `affiliate_month_stats/${id}`
  },
  bellNotifications: {
    docMatch: 'bell_notifications/{id}',
    path: () => 'bell_notifications',
    docPath: (id: string) => `bell_notifications/${id}`
  },
  b2wPointsTransactions: {
    docMatch: 'b2w_points_transaction/{id}',
    path: () => 'b2w_points_transaction',
    docPath: (id: string) => `b2w_points_transaction/${id}`
  },
  deletedUsersArchive: {
    docMatch: 'deleted_users_archive/{id}',
    path: () => 'deleted_users_archive',
    docPath: (id: string) => `deleted_users_archive/${id}`
  },
  coupons: {
    docMatch: 'coupons/{id}',
    path: () => 'coupons',
    docPath: (id: string) => `coupons/${id}`
  },
  couponClaims: {
    docMatch: 'coupon_claims/{id}',
    path: () => 'coupon_claims',
    docPath: (id: string) => `coupon_claims/${id}`
  },
  supportInbox: {
    docMatch: 'support_inbox/{id}',
    path: () => 'support_inbox',
    docPath: (id: string) => `support_inbox/${id}`
  },
  supportInboxHistory: {
    docMatch: 'support_inbox/{inboxId}/support_inbox_history/{id}',
    collectionGroupName: 'support_inbox_history',
    path: (inboxId: string) => `support_inbox/${inboxId}/support_inbox_history`,
    docPath: (inboxId: string, id: string) =>
      `support_inbox/${inboxId}/support_inbox_history/${id}`
  },
  smsNotifications: {
    docMatch: 'sms_notifications/{id}',
    path: () => 'sms_notifications',
    docPath: (id: string) => `sms_notifications/${id}`
  },
  fcmNotifications: {
    docMatch: 'fcm_notifications/{id}',
    path: () => 'fcm_notifications',
    docPath: (id: string) => `fcm_notifications/${id}`
  },
  emailNotifications: {
    docMatch: 'email_notifications/{id}',
    path: () => 'email_notifications',
    docPath: (id: string) => `email_notifications/${id}`
  }
};
