import { mergeRefs } from '@b2w/shared/react-utils';
import {
  createContext,
  RefCallback,
  useContext,
  useRef,
  useState
} from 'react';
import { useSafeLayoutEffect } from '../hooks/useSafeLayoutEffect';
import {
  DescendantsManager,
  RegisterDescendantOptions
} from './descendants-manager';

/**
 * React hook that initializes the DescendantsManager
 */
function useInitDescendants<T extends HTMLElement = HTMLElement>() {
  const managerRef = useRef(new DescendantsManager<T>());

  useSafeLayoutEffect(() => {
    return () => managerRef.current.destroy();
  });

  return managerRef.current;
}

type UseInitDescendantsReturn = ReturnType<typeof useInitDescendants>;

/** Descendants context to be used in component-land.
 * - Mount the `DescendantsContextProvider` at the root of the component
 * - Call `useDescendantsContext` anywhere you need access to the descendants
 *   information
 */
const DescendantsContext = createContext<UseInitDescendantsReturn>(
  {} as UseInitDescendantsReturn
);

/**
 * This hook provides information a descendant such as:
 * - Its index compared to other descendants
 * - ref callback to register the descendant
 * - Its enabled index compared to other enabled descendants
 */
function useDescendant<T extends HTMLElement = HTMLElement>(
  options?: RegisterDescendantOptions
) {
  const descendants = useContext(DescendantsContext);
  const [index, setIndex] = useState(-1);
  const ref = useRef<T>(null);

  useSafeLayoutEffect(() => {
    return () => {
      if (!ref.current) return;
      descendants.unregister(ref.current);
    };
  }, []);

  useSafeLayoutEffect(() => {
    if (!ref.current) return;
    const dataIndex = Number(ref.current.dataset.index);
    if (index !== dataIndex && !Number.isNaN(dataIndex)) {
      setIndex(dataIndex);
    }
  });

  const refCallback = options
    ? (descendants.register(options) as RefCallback<T>)
    : (descendants.register as RefCallback<T>);

  return {
    descendants,
    index,
    enabledIndex: descendants.enabledIndexOf(ref.current),
    register: mergeRefs(refCallback, ref)
  };
}

/**
 * Function that provides strongly typed versions of the descendant context
 * and its hooks
 */
export function createDescendantContext<T extends HTMLElement = HTMLElement>() {
  const ContextProvider =
    DescendantsContext.Provider as unknown as React.Provider<
      DescendantsManager<T>
    >;

  const _useDescendantsContext = () =>
    useContext(DescendantsContext) as unknown as DescendantsManager<T>;

  const _useDescendant = (options?: RegisterDescendantOptions) =>
    useDescendant<T>(options);

  const _useInitDescendants = () => useInitDescendants<T>();

  return [
    // context provider
    ContextProvider,
    // call this when you need to read from context
    _useDescendantsContext,
    // descendants state information, to be called and passed to `ContextProvider`
    _useInitDescendants,
    // descendant index information
    _useDescendant
  ] as const;
}
