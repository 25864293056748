import { HTMLAttributes } from 'react';

type Props = {
  isToggled: boolean;
  color: string;
  animate: boolean;
};

const Hamburger: React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({
  isToggled,
  color,
  animate,
  ...props
}) => {
  const mustTransform = animate && isToggled;

  return (
    <div {...props} className="hamburger">
      <div className={`bar1 bg-${color}`} />
      <div className={`bar2 bg-${color}`} />
      <div className={`bar3 bg-${color}`} />
      <style jsx>
        {`
          .hamburger {
            margin: auto 0;
            cursor: pointer;
          }

          .bar1,
          .bar2,
          .bar3 {
            width: 30px;
            height: 4px;
            border-radius: 10%;
            margin: 4px 0;
            transition: 0.3s;
          }

          .bar1 {
            transform: ${mustTransform
              ? 'rotate(-45deg) translate(-6px, 6px)'
              : 'rotate(0deg)'};
          }
          .bar2 {
            opacity: ${mustTransform ? '0' : '1'};
          }
          .bar3 {
            transform: ${mustTransform
              ? 'rotate(45deg) translate(-6px, -6px)'
              : 'rotate(0deg)'};
          }
        `}
      </style>
    </div>
  );
};

export default Hamburger;
