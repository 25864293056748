import { useRefsArray } from '@b2w/shared/react-hooks';
import { memo, useEffect, useRef } from 'react';
import { AnimatedScroll } from '../animatedScroll';
import { twCx } from '../twMerge';
import { GalleryImage } from './types';

type ThumbItemProps = {
  index: number;
  registerRef: (index: number) => (node: HTMLDivElement) => void;
  thumbSrc: string;
  isActive: boolean;
  onThumbClick: (index: number) => any;
};

const ThumbItem = memo(
  ({
    thumbSrc,
    index,
    onThumbClick,
    isActive,
    registerRef
  }: ThumbItemProps) => {
    return (
      <div
        ref={registerRef(index)}
        className="tw-cursor-pointer first:tw-pl-3 last:tw-pr-3"
        onClick={() => onThumbClick(index)}
      >
        <div
          className={twCx(
            'tw-relative tw-rounded-lg tw-transition-all',
            'tw-ring-main-500',
            isActive && 'tw-ring-4'
          )}
        >
          <img
            src={thumbSrc}
            alt={index + ''}
            className="tw-rounded-lg tw-object-cover tw-object-center tw-max-w-none tw-w-[53px] tw-h-[35px] sm:tw-w-[83px] sm:tw-h-[55px]"
          />
        </div>
      </div>
    );
  }
);

export type ThumbsPublicProps = {
  /** @default 'auto' */
  thumbsMode?: 'auto' | 'always';
};

export type ThumbsPrivateProps = {
  activeIndex: number;
  onThumbClick: (index: number) => any;
  images: GalleryImage[];
};

export type ThumbsProps = ThumbsPublicProps & ThumbsPrivateProps;

export const Thumbs = (props: ThumbsProps) => {
  const { thumbsMode = 'auto', activeIndex, onThumbClick, images } = props;

  const isThumbsResponsive = thumbsMode === 'auto';

  const thumbsListRef = useRef<HTMLDivElement | null>(null);
  const [registerThumbRef] = useRefsArray();

  useEffect(() => {
    // sync view with active index
    const tl = thumbsListRef.current;

    if (!tl) return;

    const thumbsScrollLeft = Array.from(tl.children)
      .slice(0, activeIndex)
      .reduce(
        (scrollLeft, thumbEl) =>
          scrollLeft + thumbEl.getBoundingClientRect().width,
        0
      );

    const animationRef = AnimatedScroll.scrollToX(thumbsScrollLeft, tl, 250);

    return () => {
      AnimatedScroll.cancelAnimation(animationRef);
    };
  }, [activeIndex]);

  return (
    <div
      className={twCx(
        'tw-absolute tw-left-0 tw-bottom-0 tw-w-full',
        'tw-justify-center',
        isThumbsResponsive
          ? 'tw-hidden lg:tw-flex tw-transition-all tw-duration-150 tw-delay-300 tw-opacity-0 tw-translate-y-full group-hover:tw-opacity-100 group-hover:tw-translate-y-0 group-hover:tw-delay-0'
          : 'tw-flex'
      )}
    >
      <div
        className="tw-grid tw-auto-cols-min tw-grid-flow-col tw-gap-4 tw-overflow-x-auto tw-pb-4 tw-pt-1"
        ref={thumbsListRef}
      >
        {images.map((src, idx) => (
          <ThumbItem
            key={src.thumbSrc + idx}
            registerRef={registerThumbRef}
            onThumbClick={onThumbClick}
            isActive={idx === activeIndex}
            thumbSrc={src.thumbSrc}
            index={idx}
          />
        ))}
      </div>
    </div>
  );
};
